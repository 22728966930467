import { EvilIcons } from '@expo/vector-icons';
import { useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { AppDrawer } from '../../components/AppDrawer';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import InputWithSelectOption from '../../components/UI/Form/InputForSelectOption';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import InfoDescriptionToggle from '../../components/UI/InfoDescriptionToggle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { carriers, PortoutReasons } from '../../constants/constants';
import { authService } from '../../services/SSO/AuthService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import { PortCarrier } from '../../types/types';
import { isWebsite } from '../../utilities/functions';

import { PortOutData } from '.';

const PortOutDetailView = ({ onPress }: { onPress(data: PortOutData): void }) => {
  const [showCarrierModel, setShowCarrierModel] = useState<boolean>(false);
  const [showReasonModel, setShowReasonModel] = useState<boolean>(false);
  const [carrier, setCarrier] = useState<PortCarrier>();
  const [portOutReason, setPortOutReason] = useState<number>();
  const [portOutReasonDesc, setPortOutReasonDesc] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const email = useSelector((state: RootState) => state.user.email);
  const dispatch = useDispatch();

  return (
    <View
      style={[
        {
          position: 'relative',
          padding: 20,
          backgroundColor: Colors.white,
          flex: 1,
          height: '100%',
        },
        isWebsite()
          ? {
              maxWidth: 400,
              marginHorizontal: 'auto',
              width: '100%',
            }
          : null,
      ]}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginBottom: 80, paddingBottom: 20 }}
      >
        <View
          style={{
            backgroundColor: Colors.backgroundGrey,
            padding: 16,
            borderRadius: 16,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 16,
            }}
          >
            <View
              style={{
                backgroundColor: Colors.errorRed20Percent,
                padding: 4,
                marginRight: 8,
                borderRadius: 10,
              }}
            >
              <EvilIcons name="question" size={16} color={Colors.errorRed} />
            </View>
            <CustomText weight="500" style={{ fontSize: 14, color: Colors.red }}>
              Please be aware!
            </CustomText>
          </View>
          <CustomText style={{ fontSize: 12 }}>
            After submitting a port-out request,{' '}
            <CustomText weight="600">data service usage will be restricted</CustomText> due to data
            calculation. However, you can still use phone and text services.
          </CustomText>
        </View>

        <InputWithSelectOption
          onPress={() => {
            setShowCarrierModel(true);
          }}
          title="To which provider?*"
          value={carrier ?? 'Select the carrier*'}
        />

        <InputWithSelectOption
          onPress={() => {
            setShowReasonModel(true);
          }}
          title="Reason of Port out*"
          value={
            PortoutReasons.find((p) => p.id === portOutReason)?.reason ?? 'Select from the list*'
          }
        />

        <InputWithTitle
          inputValue={portOutReasonDesc}
          onInputChange={setPortOutReasonDesc}
          title="Tell us more about the reason*"
        />

        {error ? (
          <CustomText
            style={{
              color: Colors.errorRed,
              marginTop: 4,
              textAlign: 'center',
            }}
          >
            {error}
          </CustomText>
        ) : null}

        <HorizontalLine
          thinkness={1}
          style={{
            marginVertical: 24,
          }}
        />

        <InfoDescriptionToggle
          title="What is Port out?"
          height={208}
          style={{
            marginHorizontal: 0,
            marginBottom: 16,
          }}
          desc="Porting out allows you to transfer your phone number from PhoneBox to another provider. To keep your current number, ensure your line remains active with PhoneBox until the process is complete. You may contact us to request your account number, IMEI, or PIN. Note: Only Canadian phone numbers are eligible."
        />
        <InfoDescriptionToggle
          title="What should I do after requesting a port out?"
          height={150}
          style={{
            marginHorizontal: 0,
          }}
          desc="Once you request a port out, please allow up to 48 hours to receive your account number via email. Remember, avoid canceling your service until the port out process is fully completed."
        />
      </ScrollView>

      <AppDrawer
        onClose={() => {
          setShowCarrierModel(false);
        }}
        height={500}
        visible={showCarrierModel}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24, textAlign: 'center', marginBottom: 32 }}>
          Select Carrier
        </CustomText>
        <ScrollView showsVerticalScrollIndicator={false}>
          {carriers.map((carrier) => (
            <CustomText
              onPress={() => {
                setCarrier(carrier);
                setShowCarrierModel(false);
              }}
              style={{
                fontSize: 15,
                textAlign: 'center',
                marginBottom: 8,
              }}
              key={carrier}
            >
              {carrier}
            </CustomText>
          ))}
        </ScrollView>
      </AppDrawer>

      <AppDrawer
        onClose={() => {
          setShowReasonModel(false);
        }}
        height={300}
        visible={showReasonModel}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24, textAlign: 'center', marginBottom: 32 }}>
          Reason to Port Out
        </CustomText>
        <ScrollView showsVerticalScrollIndicator={false}>
          {PortoutReasons.map((portoutReason) => (
            <CustomText
              onPress={() => {
                setPortOutReason(portoutReason.id);
                setShowReasonModel(false);
              }}
              style={{
                fontSize: 15,
                textAlign: 'center',
                marginBottom: 8,
              }}
              key={portoutReason.id}
            >
              {portoutReason.reason}
            </CustomText>
          ))}
        </ScrollView>
      </AppDrawer>

      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
        }}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={async () => {
            try {
              dispatch(setIsLoading(true));
              const response = await authService.sendOtpForPortout(email);
              if (response.data.success) {
                const otpUserId = response.data.data.userId;
                onPress({
                  carrier: carrier!,
                  portOutReason: portOutReason!,
                  portOutReasonDesc,
                  otpUserId,
                });
              } else {
                setError(response.data.error.message);
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
              setError(error.message);
            } finally {
              dispatch(setIsLoading(false));
            }
          }}
          textStyle={{ fontSize: 14 }}
          disabled={!carrier || !portOutReason || !portOutReasonDesc}
        >
          Request Port Out
        </Button>
      </View>
    </View>
  );
};

export default PortOutDetailView;
