import { Octicons } from '@expo/vector-icons';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useEffect, useState } from 'react';
import { SafeAreaView, StatusBar, View } from 'react-native';

import ServiceRequestTab from './ServiceRequestTab';
import ShippingTab from './ShippingTab';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { RootStackScreenProps } from '../../types';
import { ShippingStatus } from '../../types/types';

const Tab = createMaterialTopTabNavigator();

const StatusTrackingScreen = ({ navigation }: RootStackScreenProps<'StatusTracking'>) => {
  const [filterShown, setFilterShown] = useState(false);
  const [filterSelected, updateFilterSelected] = useState<ShippingStatus[]>([]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View style={{ position: 'relative', flexDirection: 'row', alignItems: 'center' }}>
          {filterSelected.length !== 0 && (
            <View
              style={{
                position: 'absolute',
                top: -5,
                right: 2,
                width: 14,
                height: 14,
                backgroundColor: Colors.red,
                borderRadius: 24,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CustomText weight="700" style={{ color: Colors.white, fontSize: 8 }}>
                {filterSelected.length}
              </CustomText>
            </View>
          )}
          <Octicons
            name="sort-desc"
            size={24}
            color="black"
            style={{ marginRight: 6 }}
            onPress={() => setFilterShown(true)}
          />
        </View>
      ),
    });
  }, [filterSelected]);

  return (
    <SafeAreaView style={{ flex: 1, height: '100%', marginTop: StatusBar.currentHeight }}>
      <Tab.Navigator
        screenOptions={{
          tabBarLabel: ({ color, children }) => (
            <CustomText weight={'500'} style={{ color, fontSize: 16, textTransform: 'capitalize' }}>
              {children}
            </CustomText>
          ),
        }}
      >
        <Tab.Screen
          name="Shipping"
          component={() => (
            <ShippingTab
              filterShown={filterShown}
              setFilterShown={setFilterShown}
              onFilterApply={(filters) => {
                updateFilterSelected(filters);
              }}
              selectedFilters={filterSelected}
              navigation={navigation}
            />
          )}
        />
        <Tab.Screen
          name="Service Request"
          component={() => <ServiceRequestTab navigation={navigation} />}
        />
      </Tab.Navigator>
    </SafeAreaView>
  );
};

export default StatusTrackingScreen;
