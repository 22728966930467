import {
  Entypo,
  FontAwesome,
  FontAwesome5,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import HorizontalLine from '../../components/HorizontalLine';
import MenuItem from '../../components/UI/ListItem/MenuItem';
import TitleWithSupTitle from '../../components/UI/TitleWithSupTitle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { RootState } from '../../store';
import { RootStackScreenProps } from '../../types';

// type InformationFieldDestination = 'EditName' | 'EditPassword' | 'EditEmail' | 'EditAddress';

export default function AccountScreen({ navigation }: RootStackScreenProps<'Account'>) {
  const { firstName, lastName, email } = useSelector((store: RootState) => store.user);
  const { Address } = useSelector((store: RootState) => store.selfServeCA.accountProfileCA);

  const userIcon = <FontAwesome5 name="user-circle" size={16} color={Colors.ActionLinkColor} />;
  const passwordIcon = <MaterialIcons name="password" size={16} color={Colors.ActionLinkColor} />;
  const emailIcon = <Entypo name="email" size={16} color={Colors.ActionLinkColor} />;
  const addressIcon = (
    <FontAwesome name="address-card-o" size={16} color={Colors.ActionLinkColor} />
  );
  const deleteIcon = (
    <MaterialCommunityIcons name="delete-forever" size={16} color={Colors.ActionLinkColor} />
  );
  const backIcon = <Entypo name="chevron-thin-right" size={16} color={Colors.greyColor} />;

  return (
    <View
      style={{
        flex: 1,
        height: '100%',
        padding: 20,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <CustomText
          style={{ color: Colors.greyColor, fontSize: 12, fontWeight: '500' }}
          weight="500"
        >
          Account Profile
        </CustomText>
      </View>
      <View
        style={{
          backgroundColor: Colors.backgroundGrey,
          padding: 16,
          borderRadius: 16,
          marginTop: 8,
        }}
      >
        <TitleWithSupTitle title={`${firstName} ${lastName}`} supTitle="Name" />
        <HorizontalLine
          style={{
            marginTop: -8,
            marginBottom: 12,
          }}
        />
        <TitleWithSupTitle title={email} supTitle="Email" />
        <HorizontalLine
          style={{
            marginTop: -8,
            marginBottom: 12,
          }}
        />
        <TitleWithSupTitle
          title={Address || 'No Address Added to the Profile'}
          supTitle="Address"
        />
        <HorizontalLine
          style={{
            marginTop: -8,
          }}
        />
      </View>
      <View>
        <CustomText
          weight="600"
          style={{ fontSize: 14, color: Colors.greyColor, marginBottom: 8, marginTop: 32 }}
        >
          Account Management
        </CustomText>
        <View
          style={{ padding: 8, gap: 4, backgroundColor: Colors.backgroundGrey, borderRadius: 16 }}
        >
          <MenuItem
            frontIcon={userIcon}
            title="Update Name"
            backIcon={backIcon}
            onPress={() => navigation.navigate('EditName')}
          />
          <MenuItem
            frontIcon={passwordIcon}
            title="Update Password"
            backIcon={backIcon}
            onPress={() => navigation.navigate('EditPassword')}
          />
          <MenuItem
            frontIcon={emailIcon}
            title="Update Email"
            backIcon={backIcon}
            onPress={() => navigation.navigate('EditEmail')}
          />
          <MenuItem
            frontIcon={addressIcon}
            title="Update Address"
            backIcon={backIcon}
            onPress={() => navigation.navigate('EditAddress')}
          />
          <MenuItem
            frontIcon={deleteIcon}
            title="Delete Account"
            backIcon={backIcon}
            onPress={() => navigation.navigate('DeleteAccount')}
          />
        </View>
      </View>
    </View>
  );
}
