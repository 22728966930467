import { Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import {
  Dimensions,
  DimensionValue,
  Image,
  LayoutChangeEvent,
  LayoutRectangle,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  Pressable,
  ScrollView,
  View,
} from 'react-native';

import { Colors } from '../../constants/Colors';
import { OfferList } from '../../constants/constants';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';

type Props = {
  onOfferSelect?: (selectedOffer: string) => void;
  selectedOffer?: string;
};

const Offering = (props: Props) => {
  const navigation = useNavigation();
  const { width } = Dimensions.get('window');
  interface RectBound extends LayoutRectangle {
    left?: number;
    right?: number;
  }
  const [layout, setLayout] = useState<Record<string, RectBound>>({});

  const [maxScroll, setMaxScroll] = useState<number>(
    Object.keys(OfferList).length * 300 + 100 - width
  );
  const [scrollViewRef, setScrollViewRef] = useState<ScrollView | null>(null);
  const [currentScroll, setCurrentScroll] = useState<number>(0);
  const [scrollWidth, setScrollWidth] = useState<DimensionValue>('auto');

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const xOffset = event.nativeEvent.contentOffset.x;
    setCurrentScroll(xOffset);
    const newPage = Math.round(xOffset / width);
    if (!isWebsite()) {
      if (newPage === 0) {
        setScrollWidth('auto');
      } else {
        setScrollWidth(
          Platform.select({
            default: width,
            web: 310,
          })
        );
      }
    } else {
      setScrollWidth('auto');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (Platform.OS === 'web') {
        if (!props.selectedOffer || !scrollViewRef) return;

        const element = document.getElementById(`offer_${props.selectedOffer}`);
        if (!element) return;

        const scrollLeft = element.parentElement?.offsetLeft;
        if (!scrollLeft) return;

        setCurrentScroll(scrollLeft);
        scrollViewRef.scrollTo({
          x: scrollLeft - 10,
          animated: true,
        });
      } else {
        if (
          props.selectedOffer &&
          scrollViewRef &&
          layout[props.selectedOffer] &&
          layout[props.selectedOffer].x
        ) {
          const scroll = layout[props.selectedOffer].x;
          if (!scroll) return;
          setCurrentScroll(scroll);
          scrollViewRef.scrollTo({
            x: scroll - 10,
            animated: true,
          });
        }
      }
    }, 500);
  }, [props.selectedOffer]);

  return (
    <>
      {Platform.OS === 'web' ? (
        <View
          style={{
            flexDirection: 'row',
            gap: 8,
            alignSelf: 'flex-end',
            marginRight: 20,
            marginBottom: 10,
          }}
        >
          <Pressable
            style={{
              backgroundColor: Colors.gradientLightBlue,
              borderRadius: 50,
              overflow: 'hidden',
              width: 30,
              height: 30,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => {
              if (currentScroll <= 0) {
                return;
              }
              scrollViewRef?.scrollTo({
                x: (() => {
                  setCurrentScroll(currentScroll - 250);
                  return currentScroll - 250;
                })(),
                animated: true,
              });
            }}
          >
            <Entypo name="chevron-left" size={24} color={Colors.white} />
          </Pressable>
          <Pressable
            style={{
              backgroundColor: Colors.gradientLightBlue,
              borderRadius: 50,
              overflow: 'hidden',
              width: 30,
              height: 30,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => {
              if (currentScroll >= maxScroll) {
                return;
              }
              scrollViewRef?.scrollTo({
                x: (() => {
                  setCurrentScroll(currentScroll + 250);
                  return currentScroll + 250;
                })(),
                animated: true,
              });
            }}
          >
            <Entypo name="chevron-right" size={24} color={Colors.white} />
          </Pressable>
        </View>
      ) : null}
      <ScrollView
        style={{
          height: 156,
          width: scrollWidth,
        }}
        contentContainerStyle={Platform.select({
          web: { marginRight: 15 },
          default: null,
        })}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={16}
        pagingEnabled={Platform.select({ web: true, default: false })}
        onScroll={handleScroll}
        ref={(ref) => setScrollViewRef(ref)}
        onLayout={(e: LayoutChangeEvent) =>
          setMaxScroll(Object.keys(OfferList).length * 300 + 100 - e.nativeEvent.layout.width)
        }
      >
        {Object.entries(OfferList).map(([key, offer], index) => (
          <Pressable
            id={`offer_${key}`}
            key={key}
            onPress={() => {
              if (props.onOfferSelect) {
                props.onOfferSelect(key);
              } else {
                navigation.navigate('Benefits', { benefit: key });
              }
            }}
            onLayout={(event) => {
              DevLog.log('Testing: ', event.nativeEvent.layout);
              setLayout((prev) => ({ ...prev, [key]: event.nativeEvent.layout }));
            }}
            style={{
              width: 303,
              height: 156,
              marginLeft: 15,
              marginRight: index === Object.entries(OfferList).length - 1 ? 15 : 0,
              borderWidth: props.selectedOffer === key ? 4 : undefined,
              borderColor: Colors.grey,
              borderRadius: 16,
              overflow: 'hidden',
            }}
          >
            <Image
              source={offer.image}
              style={{ width: '100%', height: '100%', borderRadius: 8 }}
              resizeMode="cover"
            />
          </Pressable>
        ))}
      </ScrollView>
    </>
  );
};

export default Offering;
