import Checkbox from 'expo-checkbox';
import * as Linking from 'expo-linking';
import { useEffect, useState } from 'react';
import { Platform, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';

import { ActivationInfo } from './ActivationInfoView';
import { PortInInfo } from './PortInView';
import { ShippingAddress } from './ShippingAddressView';
import Button from '../../components/Button';
import Badge from '../../components/UI/Badge';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT, DATE_FORMAT_FOR_API } from '../../constants/constants';
import { activationService } from '../../services/MyAccount/ActivationService';
import { setIsLoading } from '../../store/app';
import { ParsedPlan } from '../../types/models/ParsedPlan';
import { ShippingOption } from '../../types/models/ShippingOption';
import { ApiRequest } from '../../types/requests/ApiRequest';
import { GetTotalPostpaidRequest } from '../../types/requests/MyAccount/GetTotalPostpaidRequest';
import { GetTotalPrepaidRequest } from '../../types/requests/MyAccount/GetTotalPrepaidRequest';
import { ApiResponse } from '../../types/responses/ApiResponse';
import { GetTotalPostpaidResponse } from '../../types/responses/MyAccount/GetTotalPostpaidResponse';
import { GetTotalPrepaidResponse } from '../../types/responses/MyAccount/GetTotalPrepaidResponse';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';
import { getProratedDates } from '../../utilities/utilities';

import { SIMType } from '.';

type Props = {
  plan: ParsedPlan;
  selectedSimType: SIMType | null;
  simCardNumber: string | null;
  shippingAddress: ShippingAddress | null;
  activationInfo: ActivationInfo | null;
  selectedShippingOption: ShippingOption | null;
  portInInfo: PortInInfo | null;
  onNext(totalPaymentInfo: GetTotalPrepaidResponse | GetTotalPostpaidResponse): void;
};

export default function ReviewAndPayView({
  plan,
  selectedSimType,
  simCardNumber,
  shippingAddress,
  activationInfo,
  selectedShippingOption,
  portInInfo,
  ...props
}: Props) {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [totalPaymentInfo, setTotalPaymentInfo] = useState<
    GetTotalPrepaidResponse | GetTotalPostpaidResponse | null
  >(null);

  const { activationStartDate, prorateEndDate } = getProratedDates(
    activationInfo && activationInfo.activationDate ? activationInfo.activationDate : new Date()
  );

  const dispatch = useDispatch();

  const feeString = plan && plan.plan_fee ? plan.plan_fee.toFixed(2) : '0.00';

  useEffect(() => {
    if (!activationInfo) {
      return;
    }
    const apiRequestData: ApiRequest<GetTotalPrepaidRequest> | ApiRequest<GetTotalPostpaidRequest> =
      {
        data: {
          orderSimCard:
            (simCardNumber === '' || simCardNumber === null) && selectedSimType !== 'ESIM',
          planID: plan.planId,
          countryId: 42, // Canada
          provinceCode: activationInfo.province.code,
          start: activationStartDate.format(DATE_FORMAT_FOR_API),
          esim: selectedSimType === 'ESIM',
          sfID: selectedShippingOption?.sfID,
        },
      };
    const getTotal = async () => {
      dispatch(setIsLoading(true));
      try {
        const response:
          | ApiResponse<[GetTotalPrepaidResponse]>
          | ApiResponse<[GetTotalPostpaidResponse]> =
          plan.planType === 'monthly'
            ? await activationService.getTotalPostpaid(apiRequestData)
            : await activationService.getTotalPrepaid(apiRequestData);
        setTotalPaymentInfo(response.data[0]);
      } catch (_error) {
        DevLog.log(_error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };
    getTotal();
  }, []);

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={[
          {
            padding: 20,
            backgroundColor: Colors.white,
            flex: 1,
            height: '100%',
            marginBottom: 215,
          },
          isWebsite() ? { maxWidth: 700, width: '100%', marginHorizontal: 'auto' } : {},
        ]}
      >
        <View
          style={{
            padding: 16,
            borderWidth: 1,
            borderColor: Colors.activePlanBorderColor,
            borderRadius: 12,
            backgroundColor: Colors.SelectLineItemHighlightColor,
            position: 'relative',
            marginTop: 10,
            marginLeft: 10,
          }}
        >
          <View
            style={{
              backgroundColor: Colors.activePlanBannerColor1,
              // width: 65,
              paddingLeft: 7,
              paddingRight: 10,
              paddingVertical: 4,
              position: 'absolute',
              top: -10,
              left: -7,
              borderTopLeftRadius: 30,
              borderTopRightRadius: 50,
              borderBottomRightRadius: 50,
              zIndex: 1,
            }}
          >
            <View
              style={{
                position: 'absolute',
                top: Platform.select({ web: 24, default: 28 }),
                left: 0,
                backgroundColor: Colors.activePlanBannerColor2,
                zIndex: 2,
                width: 6.5,
                height: 5,
                borderBottomLeftRadius: 15,
              }}
            ></View>
            <CustomText
              weight="500"
              style={{
                color: Colors.white,
                fontWeight: '500',
                fontSize: 12,
                textAlign: 'center',
              }}
              numberOfLines={1}
            >
              Active
            </CustomText>
          </View>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 8,
            }}
          >
            <CustomText weight="500" style={{ fontSize: 12, color: Colors.QuickActionTextColor }}>
              Canadian Mobile Plan
            </CustomText>
            <Badge
              title={plan.planType === 'monthly' ? 'MONTHLY' : `${plan.planTypeD} DAYS`}
              status="light-blue"
              fontweight="600"
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <CustomText weight="500" style={{ fontSize: 16, color: Colors.canadaButtonColor }}>
              {plan.data}
            </CustomText>
            <CustomText weight="500" style={{ fontSize: 16 }}>
              ${feeString}
            </CustomText>
          </View>
        </View>

        <CustomText weight="600" style={{ fontSize: 14, marginTop: 24 }}>
          Personal Information
        </CustomText>
        <View
          style={{
            backgroundColor: Colors.backgroundGrey,
            padding: 16,
            borderRadius: 16,
            marginTop: 8,
            gap: 8,
          }}
        >
          <View style={styles.infoContainer}>
            <CustomText weight="500" style={styles.infoTitle}>
              Name:
            </CustomText>
            <CustomText weight="500" style={styles.infoText}>
              {activationInfo?.firstname} {activationInfo?.lastname}
            </CustomText>
          </View>
          <View style={styles.infoContainer}>
            <CustomText weight="500" style={styles.infoTitle}>
              Email:
            </CustomText>
            <CustomText weight="500" style={styles.infoText}>
              {activationInfo?.emailAddress}
            </CustomText>
          </View>
          <View style={styles.infoContainer}>
            <CustomText weight="500" style={styles.infoTitle}>
              Activation Date:
            </CustomText>
            <CustomText weight="500" style={styles.infoText}>
              {activationStartDate.format(DATE_FORMAT)}
            </CustomText>
          </View>
          <View style={styles.infoContainer}>
            <CustomText weight="500" style={styles.infoTitle}>
              Province:
            </CustomText>
            <CustomText weight="500" style={styles.infoText}>
              {activationInfo?.province.name}
            </CustomText>
          </View>
        </View>

        {portInInfo ? (
          <>
            <CustomText weight="600" style={{ fontSize: 14, marginTop: 24 }}>
              Port In Information
            </CustomText>
            <View
              style={{
                backgroundColor: Colors.backgroundGrey,
                padding: 16,
                borderRadius: 16,
                marginTop: 8,
                gap: 8,
              }}
            >
              <View style={styles.infoContainer}>
                <CustomText weight="500" style={styles.infoTitle}>
                  Carrier:
                </CustomText>
                <CustomText weight="500" style={styles.infoText}>
                  {portInInfo?.currentCarrier}
                </CustomText>
              </View>
              <View style={styles.infoContainer}>
                <CustomText weight="500" style={styles.infoTitle}>
                  Account No.:
                </CustomText>
                <CustomText weight="500" style={styles.infoText}>
                  {portInInfo?.accountNumber}
                </CustomText>
              </View>
              <View style={styles.infoContainer}>
                <CustomText weight="500" style={styles.infoTitle}>
                  Port In No.
                </CustomText>
                <CustomText weight="500" style={styles.infoText}>
                  {portInInfo?.phoneNumber}
                </CustomText>
              </View>
            </View>
          </>
        ) : null}

        {shippingAddress ? (
          <>
            <CustomText weight="600" style={{ fontSize: 14, marginTop: 24 }}>
              Shipping Information
            </CustomText>
            <View
              style={{
                backgroundColor: Colors.backgroundGrey,
                padding: 16,
                borderRadius: 16,
                marginTop: 8,
                gap: 8,
              }}
            >
              <View style={styles.infoContainer}>
                <CustomText weight="500" style={styles.infoTitle}>
                  Country:
                </CustomText>
                <CustomText weight="500" style={styles.infoText}>
                  {shippingAddress?.country}
                </CustomText>
              </View>
              <View style={styles.infoContainer}>
                <CustomText weight="500" style={styles.infoTitle}>
                  Address:
                </CustomText>
                <CustomText weight="500" style={styles.infoText}>
                  {shippingAddress?.fullAddress}
                </CustomText>
              </View>
            </View>
          </>
        ) : null}

        <CustomText weight="600" style={{ fontSize: 14, marginTop: 24 }}>
          Payment Details
        </CustomText>
        <View
          style={{
            backgroundColor: Colors.backgroundGrey,
            padding: 16,
            borderRadius: 16,
            marginTop: 8,
            marginBottom: 40,
            gap: 8,
          }}
        >
          <View style={styles.infoContainer}>
            <CustomText weight="500" style={styles.infoTitle}>
              SIM Card Fee:
            </CustomText>
            <CustomText weight="500" style={styles.infoText}>
              $10.00
              {/* ${totalPaymentInfo?.simcard_amt.toFixed(2)} */}
            </CustomText>
          </View>
          {selectedSimType === 'ESIM' ? (
            <View style={styles.infoContainer}>
              <CustomText weight="500" style={styles.infoTitle}>
                eSIM Promotion:
              </CustomText>
              <CustomText weight="500" style={[styles.infoText, { color: Colors.red }]}>
                -$10.00
              </CustomText>
            </View>
          ) : null}
          <View style={styles.infoContainer}>
            <CustomText weight="500" style={styles.infoTitle}>
              Shipping Fee:
            </CustomText>
            <CustomText weight="500" style={styles.infoText}>
              ${totalPaymentInfo?.shipping_Fee.toFixed(2)}
            </CustomText>
          </View>
          {plan.planType === 'monthly' ? (
            <View style={styles.infoContainer}>
              <View style={{}}>
                <CustomText weight="500" style={styles.infoTitle}>
                  Prorated Fee:
                </CustomText>
                <CustomText weight="500" style={styles.infoTitle}>
                  ({activationStartDate.format(DATE_FORMAT)} ~ {prorateEndDate.format(DATE_FORMAT)})
                </CustomText>
              </View>
              <CustomText weight="500" style={styles.infoText}>
                ${totalPaymentInfo?.prorate_Amt.toFixed(2)}
              </CustomText>
            </View>
          ) : null}
          <View style={styles.infoContainer}>
            <View style={{}}>
              <CustomText weight="500" style={styles.infoTitle}>
                Plan Fee:
              </CustomText>
              {plan.planType === 'monthly' ? null : (
                <CustomText weight="500" style={styles.infoTitle}>
                  ({totalPaymentInfo?.charge_Duration})
                </CustomText>
              )}
            </View>
            <CustomText weight="500" style={styles.infoText}>
              ${totalPaymentInfo?.plan_Amt.toFixed(2)}
            </CustomText>
          </View>
          <View style={styles.infoContainer}>
            <CustomText weight="500" style={styles.infoTitle}>
              Sub Total:
            </CustomText>
            <CustomText weight="500" style={styles.infoText}>
              ${totalPaymentInfo?.subtotal.toFixed(2)}
            </CustomText>
          </View>
          {totalPaymentInfo ? (
            <View style={styles.infoContainer}>
              <CustomText weight="500" style={styles.infoTitle}>
                Taxes ({totalPaymentInfo.gst_rate + totalPaymentInfo.pst_rate}%):
              </CustomText>
              <CustomText weight="500" style={styles.infoText}>
                ${(totalPaymentInfo.gst_Amt + totalPaymentInfo.pst_Amt).toFixed(2)}
              </CustomText>
            </View>
          ) : null}
        </View>
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            alignSelf: 'center',
            shadowColor: Colors.black,
            shadowOffset: {
              height: -4,
              width: 0,
            },
            shadowRadius: 10,
            shadowOpacity: 0.3,
            alignItems: 'center',
          },
          isWebsite()
            ? {
                width: '100%',
                paddingBottom: 30,
                bottom: 0,
                backgroundColor: Colors.white,
              }
            : null,
        ]}
      >
        <View
          style={[
            { width: '85%', marginTop: 24, gap: 24 },
            isWebsite()
              ? {
                  maxWidth: 400,
                  marginHorizontal: 'auto',
                  width: '100%',
                }
              : null,
          ]}
        >
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <CustomText weight="600" style={{ fontSize: 16, color: Colors.TextBlack }}>
              Total Amount:
            </CustomText>
            <CustomText weight="600" style={{ fontSize: 16, color: Colors.canadaButtonColor }}>
              ${totalPaymentInfo?.total}
            </CustomText>
          </View>
          <View style={{ flexDirection: 'row', gap: 16 }}>
            <Checkbox
              style={{}}
              value={isChecked}
              onValueChange={setIsChecked}
              color={isChecked ? Colors.canadaButtonColor : undefined}
            />
            <CustomText style={{ fontSize: 10, color: Colors.TextBlack }}>
              I have read and understand the{' '}
              {Platform.OS === 'web' ? (
                <a
                  href="https://gophonebox.com/terms-of-service/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CustomText weight="600" style={{ color: Colors.canadaButtonColor }}>
                    Terms & Conditions
                  </CustomText>
                </a>
              ) : (
                <CustomText
                  weight="600"
                  style={{ color: Colors.canadaButtonColor }}
                  onPress={() => {
                    Linking.openURL('https://gophonebox.com/terms-of-service/');
                  }}
                >
                  Terms & Conditions
                </CustomText>
              )}{' '}
              and agree to receive personalized commercial communications from PhoneBox.
            </CustomText>
          </View>
          <View
            style={
              isWebsite()
                ? {
                    maxWidth: 400,
                    marginHorizontal: 'auto',
                    width: '100%',
                  }
                : null
            }
          >
            <Button
              disabled={!isChecked}
              onPress={() => {
                props.onNext(totalPaymentInfo!);
              }}
              textStyle={{ fontSize: 14 }}
            >
              Continue To Checkout
            </Button>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  infoContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  infoText: { color: Colors.TextBlack, fontSize: 12, maxWidth: 200, textAlign: 'right' },
  infoTitle: { color: Colors.greyColor, fontSize: 12 },
});
