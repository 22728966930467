import { DimensionValue, StyleSheet, View, ViewStyle } from 'react-native';

import { Colors } from '../constants/Colors';
import { isWebsite } from '../utilities/functions';

type PropType = {
  children: React.ReactNode;
  backgroundColor?: string;
  height?: DimensionValue;
  style?: ViewStyle;
  maxHeight?: DimensionValue;
};

const BottomWhiteSheet = (props: PropType) => {
  const styles = StyleSheet.create({
    sheetContainer: {
      alignItems: 'center',
      backgroundColor: props.backgroundColor ?? Colors.white,
      borderTopLeftRadius: 40,
      borderTopRightRadius: 40,
      bottom: 0,
      height: props.height ?? 'auto',
      maxHeight: props.maxHeight,
      padding: 10,
      position: 'absolute',
      shadowColor: Colors.black,
      shadowOffset: { height: -10, width: 0 },
      shadowOpacity: 0.15,
      shadowRadius: 30,
      width: '100%',
      ...props.style,
    },
    sheetContainerWeb: {
      alignSelf: 'center',
      maxWidth: 700,
      ...props.style,
    },
  });

  return (
    <View style={[styles.sheetContainer, isWebsite() ? styles.sheetContainerWeb : null]}>
      {props.children}
    </View>
  );
};

export default BottomWhiteSheet;
