/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import DropDownPicker from 'react-native-dropdown-picker';

import CommonStyles from '../constants/CommonStyles';
import DevLog from '../utilities/debug-error';

interface CPAutoCompleteAddressProps {
  // autoCompleteType: string;
  onAutoCompleteAddress: (full_address: string[]) => void;
  selectedCountry: string;
  placeholder?: string;
}

interface Address {
  street: string;
  line1: string;
  line2: string;
  city: string;
  postalCode: string;
  provinceShort: string;
  countryShort: string;
  provinceFull: string;
  countryFull: string;
}

const CanadaPostAutoCompleteAddress: React.FC<CPAutoCompleteAddressProps> = (
  props: CPAutoCompleteAddressProps
) => {
  const [results, setResults] = useState([] as any[]);
  const [locations, setLocations] = useState([] as any[]);
  const [selecedLocation, setSelecedLocation] = useState({} as any);
  const [openDropdown, setOpenDropdown] = useState(false);

  const apiFindUrl = `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws?Key=${process.env.CANADA_POST_API_KEY}`;
  const apiRetrieveUrl = `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.10/json3.ws?Key=${process.env.CANADA_POST_API_KEY}`;

  const handleSelect = async (event: any) => {
    if (event.next === 'Find') {
      const selectedEvent = event;
      handleSearchLastId(selectedEvent.id, selectedEvent.addressCount);
    } else if (event.next === 'Retrieve') {
      handleRetrieve(event);
    }
  };

  const handleRetrieve = async (event: any) => {
    const selectedEventId = event.id;
    const url = `${apiRetrieveUrl}&Id=${encodeURIComponent(selectedEventId)}`;

    try {
      const response = await fetch(url);
      const jsonData = await response.json();
      const data = jsonData.Items[0];

      if (data) {
        const address: Address = {
          street: data.Line1 + (data.Line2 ? ', ' + data.Line2 : ''),
          line1: data.Line1,
          line2: data.Line2,
          city: data.City,
          postalCode: data.PostalCode,
          provinceShort: data.Province,
          countryShort: data.CountryIso2,
          provinceFull: data.ProvinceName,
          countryFull: data.CountryName,
        };
        sendCompleteAddress(address);
        setSelecedLocation({} as any);
        setLocations([] as any[]);
      }
    } catch (error) {
      DevLog.log(error);
    }
  };

  const sendCompleteAddress = (address: Address) => {
    const addressArray = [
      address.street,
      address.city,
      address.provinceShort,
      address.countryShort,
      address.postalCode,
      address.countryFull,
      address.provinceFull,
    ];
    props.onAutoCompleteAddress(addressArray);
  };

  const handleChange = (event: any) => {
    if (event.length <= 1) {
      return;
    }
    setOpenDropdown(true);
    handleSearchTerm(event);
  };

  const handleSearchTerm = async (query: any) => {
    const url = `${apiFindUrl}&SearchTerm=${encodeURIComponent(query)}&Country=${encodeURIComponent(
      props.selectedCountry
    )}&MaxResults=${encodeURIComponent(8)}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      setResults(data.Items);
    } catch (error) {
      DevLog.log(error);
    }
  };

  const handleSearchLastId = async (id: any, retrieveMaximum: any) => {
    const url = `${apiFindUrl}&LastId=${encodeURIComponent(id)}&MaxResults=${encodeURIComponent(
      retrieveMaximum
    )}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      const newResults = data.Items;
      setResults(newResults);

      const tempLocations = newResults.map((result: any) => {
        const maxAddressCount = 10;
        return {
          id: result.Id,
          name: result.Text + ', ' + result.Description,
          next: result.Next,
          addressCount: maxAddressCount,
        };
      });
      setLocations(tempLocations);
      setOpenDropdown(true);
    } catch (error) {
      DevLog.log(error);
    }
  };

  useEffect(() => {
    const tempLocations = results.map((result) => {
      const parts = result.Description.split('-');
      let maxAddressCount = 10;
      if (parts.length > 1) {
        const addressCountString = parts[1].trim().split(' ')[0];
        maxAddressCount = parseInt(addressCountString);
      }

      return {
        id: result.Id,
        name: result.Text + ', ' + result.Description,
        next: result.Next,
        addressCount: maxAddressCount,
      };
    });
    setLocations(tempLocations);
    if (results.length > 0) {
      setOpenDropdown(true);
    }
  }, [results]);

  return (
    <DropDownPicker
      style={{
        ...CommonStyles.input,
        minHeight: 40,
        borderWidth: 0,
      }}
      searchable={true}
      disableLocalSearch={true}
      placeholder={props.placeholder ? props.placeholder : 'Search for an address'}
      searchPlaceholder="123 Main Drive ..."
      listMode="MODAL"
      onChangeSearchText={(query) => handleChange(query)}
      open={openDropdown}
      setOpen={setOpenDropdown}
      value={selecedLocation}
      setValue={setSelecedLocation}
      onSelectItem={(event) => handleSelect(event)}
      schema={{
        value: 'id',
        label: 'name',
      }}
      items={locations}
    />
  );
};

export default CanadaPostAutoCompleteAddress;
