import { EvilIcons } from '@expo/vector-icons';
import moment from 'moment';
import { useState } from 'react';
import { View, ScrollView, SafeAreaView, Alert, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { AppDrawer } from '../../components/AppDrawer';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import InputWithSelectOption from '../../components/UI/Form/InputForSelectOption';
import InfoDescriptionToggle from '../../components/UI/InfoDescriptionToggle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT, DATE_FORMAT_FOR_API, MONTHS } from '../../constants/constants';
import { accountService } from '../../services/MyAccount/AccountService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import { RootStackScreenProps } from '../../types';
import { isWebsite } from '../../utilities/functions';
import { getNextNMonths } from '../../utilities/utilities';

export default function VacationHoldDetailScreen({
  navigation,
}: RootStackScreenProps<'VacationHoldDetail'>) {
  const [showMonthModal, setShowMonthModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [startingMonth, setStartingMonth] = useState<string | null>(null);
  const [endingMonth, setEndingMonth] = useState<string | null>(null);

  const [currentSelectingMonthType, setCurrentSelectingMonthType] = useState<'START' | 'END'>(
    'START'
  );

  const { CurrentBalance } = useSelector((state: RootState) => state.selfServeCA.accountInfoCA);
  const dispatch = useDispatch();

  const months = getNextNMonths(24);

  const verifyVacationDates = () => {
    if (!startingMonth || !endingMonth) {
      return false;
    }
    let startingDate = moment(startingMonth, DATE_FORMAT).date(2);
    let endingDate = moment(endingMonth, DATE_FORMAT).date(2);
    if (Platform.OS !== 'web') {
      const [startMonth, startYear] = startingMonth.split(', ');
      const [endMonth, endYear] = endingMonth.split(', ');
      const startingMonthNumber = MONTHS.indexOf(startMonth) + 1;
      const endingMonthNumber = MONTHS.indexOf(endMonth) + 1;
      startingDate = moment(`${startYear}-${startingMonthNumber}-02`);
      endingDate = moment(`${endYear}-${endingMonthNumber}-02`);
    }
    const dateDiff = endingDate.diff(startingDate, 'month');
    if (startingDate.isSameOrAfter(endingDate) || dateDiff < 1 || dateDiff > 3) {
      if (isWebsite()) {
        return alert('The vacation plan requires a minimum of 1 month and maximum of 3 months.');
      }
      Alert.alert(
        'Invalid End Month',
        'The vacation plan requires a minimum of 1 month and maximum of 3 months.'
      );
      return false;
    }
    return true;
  };

  return (
    <>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView style={{ flex: 1, padding: 20, marginBottom: 80 }}>
          <View
            style={{
              backgroundColor: Colors.errorRed10Percent,
              padding: 16,
              borderRadius: 16,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 16,
              }}
            >
              <View
                style={{
                  backgroundColor: Colors.errorRed20Percent,
                  padding: 4,
                  marginRight: 8,
                  borderRadius: 10,
                }}
              >
                <EvilIcons name="question" size={16} color={Colors.errorRed} />
              </View>
              <CustomText weight="500" style={{ fontSize: 14, color: Colors.red }}>
                Please be aware!
              </CustomText>
            </View>
            <View style={{ gap: 10 }}>
              <CustomText style={{ fontSize: 12 }}>
                The Vacation Plan can only be applied for a full billing cycle with an effective
                date on the next month.
              </CustomText>
              <CustomText weight="400" style={{ fontSize: 12 }}>
                The vacation plan requires{' '}
                <CustomText weight="500">a minimum of 1 month and maximum of 3 months.</CustomText>
              </CustomText>
              <CustomText style={{ fontSize: 12 }}>
                Once the vacation plan expires, the account must be in service for at least 6 months
                to be eligible to apply for another vacation plan suspension.
              </CustomText>
            </View>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 15 }}>
            <InputWithSelectOption
              value={startingMonth ?? 'Select month'}
              title="Start month"
              onPress={() => {
                setCurrentSelectingMonthType('START');
                setShowMonthModal(true);
              }}
              containerStyle={{ flex: 1 }}
            />
            <InputWithSelectOption
              value={endingMonth ?? 'Select month'}
              title="End month"
              onPress={() => {
                setCurrentSelectingMonthType('END');
                setShowMonthModal(true);
              }}
              containerStyle={{ flex: 1 }}
            />
          </View>
          <HorizontalLine style={{ marginVertical: 24 }} />
          <InfoDescriptionToggle
            title="What is Vacation Hold?"
            desc='Please request reactivation from the "My Menu" section. Your plan and number will remain the same.'
            height={100}
            style={{ marginHorizontal: 0, marginBottom: 8 }}
          />
          <InfoDescriptionToggle
            title="Vacation Hold Rules"
            desc="You can place your plan on a Vacation Hold for a minimum of 1 month and up to a maximum of 3 months. Vacation Hold starts on the 2nd of the month and ends on the 1st of the following month. To set it up, please submit your request by the 26th of the month through your account. Note that Vacation Hold requests are not accepted if you activated your plan this month or if you've already placed a hold within the last 6 months. A one-time monthly fee will apply during the Vacation Hold period, which will be credited back to you in the month your hold ends."
            height={330}
            style={{ marginHorizontal: 0 }}
          />
        </ScrollView>
        <View
          style={[
            {
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              paddingTop: 20,
              paddingBottom: 40,
              paddingHorizontal: 20,
              backgroundColor: Colors.white,
            },
            isWebsite()
              ? {
                  maxWidth: 400,
                  marginHorizontal: 'auto',
                  width: '100%',
                }
              : null,
          ]}
        >
          <Button
            onPress={() => {
              if (verifyVacationDates()) {
                setShowConfirmModal(true);
              }
            }}
            disabled={startingMonth === null || endingMonth === null}
          >
            Request Vacation Hold
          </Button>
        </View>
      </SafeAreaView>
      <AppDrawer
        height={400}
        visible={showMonthModal}
        onClose={() => {
          setShowMonthModal(false);
        }}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24, textAlign: 'center' }}>
          Select Vacation Month
        </CustomText>
        <HorizontalLine style={{ marginVertical: 16 }} />
        <ScrollView showsVerticalScrollIndicator={false}>
          {months.map((month) => (
            <CustomText
              onPress={() => {
                if (currentSelectingMonthType === 'START') {
                  setStartingMonth(month);
                } else {
                  setEndingMonth(month);
                }
                setShowMonthModal(false);
              }}
              style={{
                fontSize: 15,
                textAlign: 'center',
                marginBottom: 8,
              }}
              key={month}
            >
              {month}
            </CustomText>
          ))}
        </ScrollView>
      </AppDrawer>
      <AppDrawer
        height={400}
        visible={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24, marginBottom: 4 }}>
          Confirm the Details
        </CustomText>
        <CustomText style={{ fontSize: 14 }}>Your line will be suspended accordingly.</CustomText>
        <HorizontalLine thinkness={1} style={{ marginVertical: 16 }} />
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <CustomText
            weight="600"
            style={{
              fontSize: 14,
              color: Colors.inputTextPlaceholderColor,
            }}
          >
            Suspend Date
          </CustomText>
          <CustomText
            weight="500"
            style={{
              fontSize: 16,
              color: Colors.TextBlack,
            }}
          >
            {startingMonth
              ? moment(startingMonth, DATE_FORMAT).date(2).format(DATE_FORMAT)
              : 'Not Selected'}
          </CustomText>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <CustomText
            weight="600"
            style={{
              fontSize: 14,
              color: Colors.inputTextPlaceholderColor,
            }}
          >
            Resume Date
          </CustomText>
          <CustomText
            weight="500"
            style={{
              fontSize: 16,
              color: Colors.TextBlack,
            }}
          >
            {endingMonth
              ? moment(endingMonth, DATE_FORMAT).date(2).format(DATE_FORMAT)
              : 'Not Selected'}
          </CustomText>
        </View>
        <View
          style={
            isWebsite()
              ? {
                  maxWidth: 400,
                  marginHorizontal: 'auto',
                  width: '100%',
                }
              : null
          }
        >
          <Button
            onPress={async () => {
              if (startingMonth === null || endingMonth === null) {
                return;
              }
              if (CurrentBalance > 0) {
                if (isWebsite()) {
                  return alert('Please pay your outstanding balance first.');
                }
                Alert.alert('Error!', 'Please pay your outstanding balance first.');
                return;
              }
              try {
                dispatch(setIsLoading(true));
                const { data } = await accountService.getStatus();
                if (
                  data.PlanChangeStatus === 'Requested' ||
                  data.SuspensionStatus === 'Requested' ||
                  data.TerminationStatus === 'Requested'
                ) {
                  if (isWebsite()) {
                    return alert(
                      'Sorry, you cannot change your plan due to a pending suspension/plan change/termination request.'
                    );
                  }
                  Alert.alert(
                    'Error!',
                    'Sorry, you cannot change your plan due to a pending suspension/plan change/termination request.'
                  );
                  return;
                }
                const response = await accountService.postSuspension({
                  sdate: moment(startingMonth, DATE_FORMAT).date(2).format(DATE_FORMAT_FOR_API),
                  edate: moment(endingMonth, DATE_FORMAT).date(2).format(DATE_FORMAT_FOR_API),
                  reason: 'vacation',
                });
                if (
                  response.data ===
                  `"ERROR: Previous Vacation Plan Applied within the last 6 months of selected starting date!"`
                ) {
                  if (isWebsite()) {
                    return alert(
                      'Previous Vacation Plan Applied within the last 6 months of selected starting date!'
                    );
                  }
                  Alert.alert(
                    'Error!',
                    'Previous Vacation Plan Applied within the last 6 months of selected starting date!'
                  );
                  return;
                }
                if (
                  response.data.substring(1, 6) === 'ERROR' &&
                  response.data !==
                    `"ERROR: Previous Vacation Plan Applied within the last 6 months of selected starting date!"`
                ) {
                  if (isWebsite()) {
                    return alert('A vacation plan has a maximum duration of 3 months!');
                  }
                  Alert.alert('Error!', 'A vacation plan has a maximum duration of 3 months!');
                  return;
                }
                navigation.navigate('VacationHoldSuccess');
                setShowConfirmModal(false);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (_error: any) {
                if (isWebsite()) {
                  return alert('Failed to suspend your plan!');
                }
                Alert.alert('Error!', 'Failed to suspend your plan!');
              } finally {
                dispatch(setIsLoading(false));
              }
            }}
            style={{
              marginBottom: 8,
              marginTop: 32,
            }}
            disabled={startingMonth === null || endingMonth === null}
          >
            Request Vacation Hold
          </Button>
          <Button
            color={Colors.backgroundGrey}
            textColor={Colors.greyColor}
            onPress={() => {
              setShowConfirmModal(false);
            }}
          >
            Back
          </Button>
        </View>
      </AppDrawer>
    </>
  );
}
