import * as WebBrowser from 'expo-web-browser';
import React, { useEffect } from 'react';
import { View, Image, SafeAreaView, TouchableHighlight, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import HomeIcon from '../../assets/icons/home.png';
import MobileIcon from '../../assets/icons/mobile.png';
import RightArrowIcon from '../../assets/icons/right-arrow.png';
import IllustrationImage from '../../assets/illustrations/Blue16.png';
import { AppGap } from '../../components/AppGap';
import AppGradientContainer from '../../components/AppGradientContainer';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import HorizontalLine from '../../components/HorizontalLine';
import AuthBackground from '../../components/utils/AuthBackgroud';
import CustomText from '../../components/utils/CustomText';
import ScrollViewWithSafeAreaContainer from '../../components/utils/ScrollViewWithSafeAreaContainer';
import { Colors } from '../../constants/Colors';
import CommonStyles from '../../constants/CommonStyles';
import { accountService } from '../../services/MyAccount/AccountService';
import { RootState } from '../../store';
import { setAccountInfoCA } from '../../store/selfServeCA';
import { setActiveUser, setLoggedIn } from '../../store/user';
import { RootStackScreenProps } from '../../types';
import { MultiLineAccountInfo } from '../../types/models/MultiLineAccountInfo';
import { isWebsite } from '../../utilities/functions';
import { formatPhoneNumber } from '../../utilities/utilities';

WebBrowser.maybeCompleteAuthSession();

export default function SelectLineScreen({ navigation }: RootStackScreenProps<'SelectLine'>) {
  const { loggedIn, accounts, cnum } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const ListItem = ({
    type,
    title,
    subtitle,
    accountNumber,
  }: {
    type: 'MOBILE' | 'HOME';
    title: string;
    subtitle: string;
    accountNumber: string;
  }) => (
    <TouchableHighlight
      onPress={async () => {
        dispatch(
          setActiveUser({
            user: {
              firstName: title,
              lastName: type,
              cnum: accountNumber,
            },
          })
        );
        const response = await accountService.getAccountInfo({});
        dispatch(setAccountInfoCA(response.data));
        dispatch(setLoggedIn(true)); // ! May be move this to login page. and move Select line under available pages when logged in.
      }}
      underlayColor={Colors.SelectLineItemHighlightColor} // Color when the button is pressed
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 2,
        padding: 10,
        borderRadius: 10,
      }}
    >
      <Image
        source={type === 'HOME' ? HomeIcon : MobileIcon}
        style={{ width: 50, height: 50, borderRadius: 50 }}
        resizeMode="contain"
      />
      <View style={{ flex: 1, marginLeft: 15 }}>
        <CustomText weight="600" style={{ fontWeight: '600', fontSize: 16 }}>
          {title}
        </CustomText>
        <CustomText style={{ color: Colors.grey }}>
          {type === 'HOME' ? subtitle : formatPhoneNumber(subtitle)}
        </CustomText>
      </View>
      <Image source={RightArrowIcon} style={{ width: 15, height: 15 }} resizeMode="contain" />
    </TouchableHighlight>
  );

  useEffect(() => {
    navigation.navigate('Root');
    return;
    // const haveAccountsOrIsSignedIn = loggedIn || cnum || accounts.length;
    // if (!haveAccountsOrIsSignedIn) {
    //   navigation.navigate('Landing');
    // } else {
    //   if (accounts.length === 1) {
    //   }
    // }
  }, [loggedIn, accounts, cnum]);

  return (
    <ScrollViewWithSafeAreaContainer>
      <AppGradientContainer
        colorLeft={Colors.gradientDarkBlue}
        colorRight={Colors.gradientLightBlue}
        direction="LEFT-RIGHT"
      >
        <SafeAreaView style={{ display: 'flex', height: '100%' }}>
          <AuthBackground />
          <BottomWhiteSheet style={{ padding: 0, ...CommonStyles.webBottomSheet }} maxHeight={550}>
            <View
              style={{
                position: 'absolute',
                top: -175,
                zIndex: 8,
                display: isWebsite() ? 'none' : undefined,
              }}
            >
              <Image
                source={IllustrationImage}
                style={{
                  width: 150,
                  height: 200,
                }}
                resizeMode="contain"
              />
            </View>
            <View style={{ width: '100%' }}>
              <CustomText
                weight="500"
                style={{
                  fontSize: 30,
                  marginTop: 30,
                  marginBottom: 20,
                  textAlign: 'center',
                  fontWeight: '500',
                }}
              >
                Select your Line
              </CustomText>
              <ScrollView>
                {accounts.length > 1 ? (
                  <>
                    <CustomText
                      style={{
                        backgroundColor: Colors.SelectLineTitleBackgroundColor,
                        color: Colors.SelectLineTitleTextColor,
                        fontSize: 16,
                        paddingVertical: 10,
                        paddingHorizontal: 30,
                        marginVertical: 10,
                      }}
                    >
                      Mobile
                    </CustomText>
                    <View style={{ paddingLeft: 30, paddingRight: 30 }}>
                      {(accounts as MultiLineAccountInfo[]).map((e, i) => (
                        <React.Fragment key={`react-fragment-item-mobile-${i}`}>
                          <ListItem
                            subtitle={e.Pnum}
                            title={e.FirstName}
                            accountNumber={e.Cnum}
                            type="MOBILE"
                            key={`item-mobile-${i}`}
                          />
                          {i < accounts.length - 1 ? (
                            <HorizontalLine
                              thinkness={1}
                              color={Colors.backgroundGrey}
                              key={`hl-mobile-${i}`}
                            />
                          ) : null}
                        </React.Fragment>
                      ))}
                    </View>
                  </>
                ) : null}
              </ScrollView>
              <AppGap size={10} direction="vertical" />
            </View>
          </BottomWhiteSheet>
        </SafeAreaView>
      </AppGradientContainer>
    </ScrollViewWithSafeAreaContainer>
  );
}
