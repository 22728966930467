import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useEffect } from 'react';
import { RefreshControl, SafeAreaView, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import ServiceRequestListItem from '../../components/UI/ListItem/ServiceRequestListItem';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { accountService } from '../../services/MyAccount/AccountService';
import { RootState } from '../../store';
import {
  setCustomerServiceRequests,
  setSelectedCustomerServiceRequests,
} from '../../store/selfServeCA';
import { RootStackParamList } from '../../types';

const ServiceRequestTab = ({
  navigation,
}: {
  navigation: NativeStackNavigationProp<RootStackParamList, 'StatusTracking', undefined>;
}) => {
  const dispatch = useDispatch();

  const userEmail = useSelector((state: RootState) => state.user.email);
  const { serviceRequests } = useSelector((state: RootState) => ({
    serviceRequests: state.selfServeCA.serviceRequests,
  }));

  const fetchOrders = async () => {
    const response = await accountService.getCustomerServiceRequests(userEmail);
    dispatch(setCustomerServiceRequests(response.data));
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <SafeAreaView style={{ backgroundColor: Colors.white, height: '100%', flex: 1 }}>
      <CustomText
        weight="500"
        style={{ fontSize: 12, color: Colors.greyColor, marginVertical: 16, marginHorizontal: 20 }}
      >
        Your Request
      </CustomText>
      <ScrollView refreshControl={<RefreshControl refreshing={false} onRefresh={fetchOrders} />}>
        {serviceRequests.map((serviceRequest, index) => (
          <ServiceRequestListItem
            key={`${serviceRequest.requestId}-${index}`}
            title={serviceRequest.requestType}
            date={serviceRequest.RequestTime}
            activationDate={
              serviceRequest.requestType === 'ACTIVATION' ? serviceRequest.StartDate : null
            }
            status={serviceRequest.status!}
            isEven={(index + 1) % 2 === 0}
            onPress={() => {
              dispatch(setSelectedCustomerServiceRequests(serviceRequest));
              navigation.navigate('ServiceRequestTracking', {
                tracking: serviceRequest.requestId!,
                type: serviceRequest.requestType,
              });
            }}
          />
        ))}
      </ScrollView>
    </SafeAreaView>
  );
};

export default ServiceRequestTab;
