import { AntDesign } from '@expo/vector-icons';
import moment from 'moment';
import { Image, View } from 'react-native';
import { useSelector } from 'react-redux';

import Donut from './Donut';
import DataBothWayErrorImage from '../../assets/icons/data-transfer-both-error.png';
import DataBothWayImage from '../../assets/icons/data-transfer-both.png';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT } from '../../constants/constants';
import { RootState } from '../../store';
import { getDataUsage, getRemainingDataString } from '../../utilities/utilities';
import CustomText from '../utils/CustomText';

const DataUsage = () => {
  const accountInfoCA = useSelector((state: RootState) => state.selfServeCA.accountInfoCA);

  const _totalData = accountInfoCA.DataCapacity;

  const _usedDataPercent = getDataUsage(accountInfoCA.DataUsage, accountInfoCA.DataCapacity, true);
  const _usedData = getDataUsage(accountInfoCA.DataUsage, accountInfoCA.DataCapacity);

  const _remainingData = getRemainingDataString(
    accountInfoCA.DataUsage,
    accountInfoCA.DataCapacity,
    { short: true }
  );
  const _unit = 'GB';

  const _currentDate = moment();

  const _resetDateData = moment();
  if (_currentDate.date() !== 1) {
    _resetDateData.add(1, 'months');
  }

  const _resetDate = accountInfoCA.Prepaid
    ? moment(accountInfoCA.EndDate).format(DATE_FORMAT)
    : _resetDateData.format('MMM [02], YYYY');

  return (
    <>
      {parseInt(_remainingData, 10) > 0 ? null : (
        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 18 }}>
          <AntDesign name="exclamationcircle" size={20} color={Colors.errorRed} />
          <CustomText style={{ color: Colors.inputTextPlaceholderColor, marginLeft: 8 }}>
            You have reached your{' '}
            <CustomText style={{ color: Colors.black }}>Data limit</CustomText>
          </CustomText>
        </View>
      )}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Donut
          totalData={_totalData}
          unit={_unit}
          usedData={_usedData.toString().replace('.00', '')}
          progress={parseFloat(_usedDataPercent.toString()) / 100}
        />
        <View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <Image
              source={parseInt(_remainingData, 10) > 0 ? DataBothWayImage : DataBothWayErrorImage}
              style={{ width: 24, height: 24 }}
              resizeMode="contain"
            />
            <CustomText weight="500" style={{ fontSize: 14, fontWeight: '500' }}>
              {_totalData} {_unit} Plan
            </CustomText>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <CustomText
              weight="500"
              style={{
                fontSize: 12,
                fontWeight: '500',
                color: Colors.inputTextPlaceholderColor,
              }}
            >
              Used
            </CustomText>
            <CustomText
              weight="500"
              style={{ fontSize: 12, fontWeight: '500', color: Colors.canadaButtonColor }}
            >
              {_usedData}
              {_unit}
            </CustomText>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <CustomText
              weight="500"
              style={{
                fontSize: 12,
                fontWeight: '500',
                color: Colors.inputTextPlaceholderColor,
              }}
            >
              Remaining
            </CustomText>
            <CustomText weight="500" style={{ fontSize: 12, fontWeight: '500' }}>
              {_remainingData}
              {_unit}
            </CustomText>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <CustomText
              weight="500"
              style={{
                fontSize: 12,
                fontWeight: '500',
                color: Colors.inputTextPlaceholderColor,
              }}
            >
              Resets on
            </CustomText>
            <CustomText weight="500" style={{ fontSize: 12, fontWeight: '500' }}>
              {_resetDate.toString()}
            </CustomText>
          </View>
          <CustomText
            style={{
              fontSize: 10,
              fontWeight: '400',
              color: Colors.inputTextPlaceholderColor,
              marginTop: 8,
            }}
          >
            Data overage fee {accountInfoCA.DataOverage ?? '$15.00/1GB'}
          </CustomText>
        </View>
      </View>
    </>
  );
};

export default DataUsage;
