import { useRef, useState } from 'react';
import { Animated, Platform, View } from 'react-native';
import { useSelector } from 'react-redux';

import { Colors } from '../../../constants/Colors';
import { RootState } from '../../../store';
import HorizontalLine from '../../HorizontalLine';
import CustomText from '../../utils/CustomText';
import VerticalLine from '../../VerticalLine';

const PlanDetails = () => {
  const accountInfoCA = useSelector((state: RootState) => state.selfServeCA.accountInfoCA);

  const [detailVisiable, setDetailVisiable] = useState(false);
  const heightAnim = useRef(new Animated.Value(0)).current;

  const toggleExpand = () => {
    Animated.timing(heightAnim, {
      toValue: detailVisiable ? 0 : Platform.select({ web: 125, default: 145 }),
      duration: 300,
      useNativeDriver: false,
    }).start();
    setDetailVisiable(!detailVisiable);
  };

  return (
    <View
      style={{
        borderColor: Colors.HorizonalLineDefaultColor,
        borderWidth: 1,
        marginHorizontal: 20,
        borderRadius: 8,
        padding: 16,
      }}
    >
      <CustomText weight="500" style={{ fontSize: 14, fontWeight: '500' }}>
        Current Mobile Plan
      </CustomText>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 10,
        }}
      >
        <CustomText weight="600" style={{ fontSize: 26, fontWeight: '600' }}>
          {accountInfoCA.DataCapacityName}
        </CustomText>
        <CustomText
          weight="600"
          style={{
            backgroundColor: Colors.countryToggleBackgroundColor,
            paddingVertical: 4,
            paddingHorizontal: 10,
            borderRadius: 8,
            overflow: 'hidden',
            color: Colors.ActionLinkColor,
          }}
        >
          {accountInfoCA.Prepaid ? accountInfoCA.CurrentPlan_Days + ' days' : 'Monthly'}
        </CustomText>
      </View>
      <View
        style={{
          marginTop: 16,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <View style={{ width: 90 }}>
          <CustomText
            weight="600"
            style={{
              color: Colors.canadaButtonColor,
              fontWeight: '600',
              fontSize: 16,
              marginBottom: 6,
              textAlign: 'center',
            }}
          >
            1,000mins
          </CustomText>
          <CustomText
            style={{
              fontSize: 12,
              fontWeight: '400',
              textAlign: 'center',
              color: Colors.TextBlack,
            }}
          >
            International Calls to 30 countries
          </CustomText>
        </View>
        <VerticalLine thinkness={1} />
        <View
          style={{
            width: 80,
          }}
        >
          <CustomText
            weight="600"
            style={{
              color: Colors.canadaButtonColor,
              fontWeight: '600',
              fontSize: 16,
              marginBottom: 6,
              textAlign: 'center',
            }}
          >
            Unlimited
          </CustomText>
          <CustomText
            style={{
              fontSize: 12,
              fontWeight: '400',
              textAlign: 'center',
              color: Colors.TextBlack,
            }}
          >
            Canada wide Calls & Texts
          </CustomText>
        </View>
        <VerticalLine thinkness={1} />
        <View style={{ width: 80 }}>
          <CustomText
            weight="600"
            style={{
              color: Colors.canadaButtonColor,
              fontWeight: '600',
              fontSize: 16,
              marginBottom: 6,
              textAlign: 'center',
            }}
          >
            Unlimited
          </CustomText>
          <CustomText
            style={{
              fontSize: 12,
              fontWeight: '400',
              textAlign: 'center',
              color: Colors.TextBlack,
            }}
          >
            International Texts
          </CustomText>
        </View>
      </View>
      <HorizontalLine thinkness={1} style={{ marginTop: 16, marginBottom: 10 }} />
      <Animated.View
        style={{
          height: heightAnim,
          opacity: heightAnim.interpolate({
            inputRange: [0, 100],
            outputRange: [0, 1],
          }),
        }}
      >
        {accountInfoCA.Prepaid ? null : (
          <CustomText
            style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
          >
            &bull; Plan will reset on the 2nd of every month
          </CustomText>
        )}
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; UNLIMITED picture messaging
        </CustomText>
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; Supported on the {accountInfoCA.isRogers ? 'Rogers' : 'Telus'} Network
        </CustomText>
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; Call Display
        </CustomText>
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; Voicemail Up to 35 minutes of messages
        </CustomText>
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; Data Overage Rate: $15.00/1GB
        </CustomText>
      </Animated.View>
      <CustomText
        weight="600"
        onPress={toggleExpand}
        style={{
          color: Colors.ActionLinkColor,
          fontWeight: '600',
          fontSize: 12,
          textAlign: 'center',
          marginTop: Platform.select({ web: 8, default: 10 }),
        }}
      >
        {detailVisiable ? 'Hide' : 'Show'} details
      </CustomText>
    </View>
  );
};

export default PlanDetails;
