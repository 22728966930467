import {
  PAYMENT_METHOD_ALIPAY_QR,
  PAYMENT_METHOD_APPLE_PAY,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_GOOGLE_PAY,
  PAYMENT_METHOD_PAYPAL,
  PAYMENT_METHOD_WECHAT_PAY,
} from '../../constants/constants';

export enum PaymentMethodType {
  CreditCard = 1,
  PayPal = 2,
  AliPayQR = 3,
  WxNative = 4,
  ApplePay = 5,
  GooglePay = 6,
}

export enum PaymentMethods {
  'SAVED_CARD' = PAYMENT_METHOD_CREDIT_CARD,
  'NEW_CARD' = PAYMENT_METHOD_CREDIT_CARD,
  'CREDIT_CARD' = PAYMENT_METHOD_CREDIT_CARD,
  'GOOGLE_PAY' = PAYMENT_METHOD_GOOGLE_PAY,
  'PAYPAL' = PAYMENT_METHOD_PAYPAL,
  'WECHAT_PAY' = PAYMENT_METHOD_WECHAT_PAY,
  'ALIPAY_QR' = PAYMENT_METHOD_ALIPAY_QR,
  'APPLE_PAY' = PAYMENT_METHOD_APPLE_PAY,
}

export enum ManagePaymentFlowStep {
  Initial = 'initial',
  AddPaymentCard = 'add-payment-card',
  EditPaymentCard = 'edit-payment-card',
  AddPaymentBank = 'add-payment-bank',
  EditPaymentBank = 'edit-payment-bank',
  UpdateSuccessful = 'update-successful',
}
