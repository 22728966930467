import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useState } from 'react';
import { Image, Platform, Pressable, StatusBar, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { Colors } from '../../../constants/Colors';
import { accountService } from '../../../services/MyAccount/AccountService';
import { RootState } from '../../../store';
import { setDrawerVisible, setIsLoading } from '../../../store/app';
import { setAccountInfoCA, setAccountProfileCA } from '../../../store/selfServeCA';
import { setActiveUser } from '../../../store/user';
import { RootStackParamList } from '../../../types';
import { MultiLineAccountInfo } from '../../../types/models/MultiLineAccountInfo';
import DevLog from '../../../utilities/debug-error';
import { getGradientInfo, isWebsite } from '../../../utilities/functions';
import { formatPhoneNumber, getProfileImage } from '../../../utilities/utilities';
import { AppDrawer } from '../../AppDrawer';
import { AppGap } from '../../AppGap';
import AppGradientContainer from '../../AppGradientContainer';
import Button from '../../Button';
import HorizontalLine from '../../HorizontalLine';
import CustomText from '../../utils/CustomText';

const ActiveUserHeader = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const dispatch = useDispatch();

  const { activeUserFirstName, activeUserLastName, accounts, activeCnum, isMultiLineAccount } =
    useSelector((state: RootState) => state.user);

  const accountProfileCA = useSelector((state: RootState) => state.selfServeCA.accountProfileCA);
  const accountInfoCA = useSelector((state: RootState) => state.selfServeCA.accountInfoCA);

  const [showMultiLineSwitch, setShowMultiLineSwitch] = useState<boolean>(false);

  const selectedAccount = isMultiLineAccount
    ? (accounts as MultiLineAccountInfo[]).find((a) => a.Cnum === activeCnum)
    : null;

  DevLog.log('Test: ', activeUserFirstName, activeUserLastName, selectedAccount);

  const { scrollLeftImage, scrollRightImage, ...gradientInfo } = getGradientInfo(
    accountProfileCA,
    accountInfoCA
  );

  return (
    <>
      <AppGradientContainer
        autoHeight
        colorStart={gradientInfo.start}
        colorEnd={gradientInfo.direction === 'TOP-DOWN' ? gradientInfo.start : gradientInfo.end}
        direction={gradientInfo.direction}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          height: isWebsite() ? 85 : StatusBar.currentHeight ? StatusBar.currentHeight + 80 : 120,
          paddingTop: Platform.select({ default: StatusBar.currentHeight, ios: 48 }),
          // shadowOffset: { width: 0, height: -6 }, // vertical offset
          // shadowOpacity: 0.5, // adjust as needed
          // shadowRadius: 10, // blur radius
          // elevation: 5, // This also applies shadow for Android
          overflow: 'visible',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 20,
          }}
        >
          <View style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
            <Image
              source={getProfileImage(-1)}
              style={{ width: 40, height: 40 }}
              resizeMode="cover"
            />
            <View style={{ marginHorizontal: 8 }}>
              <CustomText
                style={{ fontSize: 14, fontWeight: '400', color: Colors.white, maxWidth: 200 }}
              >
                {selectedAccount ? selectedAccount.FirstName : activeUserFirstName}{' '}
                {selectedAccount ? selectedAccount.LastName : activeUserLastName}
              </CustomText>
              <AppGap direction="vertical" size={4} />
              <CustomText
                weight="500"
                style={{ fontSize: 14, fontWeight: '500', color: Colors.white }}
              >
                {formatPhoneNumber(
                  isMultiLineAccount && selectedAccount && typeof selectedAccount !== 'string'
                    ? selectedAccount!.Pnum
                    : accountProfileCA.PhoneNumber
                )}
              </CustomText>
            </View>
            {isMultiLineAccount ? (
              <Pressable
                onPress={() => {
                  setShowMultiLineSwitch(true);
                  dispatch(setDrawerVisible(true));
                  // alert('Show multi-line here');
                }}
              >
                <MaterialCommunityIcons name="chevron-down" size={28} color={Colors.white} />
              </Pressable>
            ) : null}
          </View>

          {/* <View style={{ flexDirection: 'row' }}> */}
          {/* <Pressable style={{ position: 'relative' }}>
            <View
              style={{
                position: 'absolute',
                width: 8,
                height: 8,
                backgroundColor: Colors.red,
                borderRadius: 4,
                right: -2,
                top: 2,
                zIndex: 1,
              }}
            ></View>
            <AntDesign name="shoppingcart" color={Colors.white} size={25} />
          </Pressable> */}
          {/* <AppGap direction="horizontal" size={6} /> */}
          {/* <Pressable style={{ position: 'relative' }}>
              <View
                style={{
                  position: 'absolute',
                  width: 8,
                  height: 8,
                  backgroundColor: Colors.red,
                  borderRadius: 4,
                  right: 2,
                  top: 2,
                  zIndex: 1,
                }}
              ></View>
              <MaterialCommunityIcons name="bell-outline" color={Colors.white} size={25} />
            </Pressable> */}
          {/* </View> */}
        </View>
        <Image
          source={scrollLeftImage}
          style={{
            width: 15,
            height: 15,
            position: 'absolute',
            bottom: -15,
            left: 0,
          }}
        />
        <Image
          source={scrollRightImage}
          style={{
            width: 15,
            height: 15,
            position: 'absolute',
            bottom: -15,
            right: 0,
          }}
        />
      </AppGradientContainer>
      <AppDrawer
        visible={showMultiLineSwitch}
        height={385}
        containerStyle={{
          paddingHorizontal: 0,
        }}
        onClose={() => {
          setShowMultiLineSwitch(false);
          dispatch(setDrawerVisible(false));
        }}
        showBackDrop
      >
        <CustomText weight="500" style={{ fontSize: 24, marginBottom: 4, paddingHorizontal: 30 }}>
          Select the Account
        </CustomText>
        <CustomText style={{ fontSize: 10, marginBottom: 16, paddingHorizontal: 30 }}>
          Tap to Switch
        </CustomText>

        <CustomText
          weight="500"
          style={{
            fontSize: 12,
            marginBottom: 16,
            paddingVertical: 10,
            paddingHorizontal: 50,
            backgroundColor: Colors.SelectLineTitleBackgroundColor,
          }}
        >
          Accounts
        </CustomText>

        <View style={{ paddingHorizontal: 30 }}>
          {(accounts as MultiLineAccountInfo[]).map((account, index) => (
            <View key={`${account.Cnum}-${index}`}>
              <Pressable
                style={{
                  borderRadius: 16,
                  padding: 10,
                  backgroundColor:
                    account.Cnum === selectedAccount?.Cnum
                      ? Colors.SelectLineItemHighlightColor
                      : Colors.white,
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 16,
                }}
                onPress={
                  account.Cnum === selectedAccount?.Cnum
                    ? () => {}
                    : async () => {
                        try {
                          dispatch(setIsLoading(true));
                          dispatch(
                            setActiveUser({
                              user: {
                                lastName: account.LastName,
                                firstName: account.FirstName,
                                cnum: account.Cnum,
                              },
                            })
                          );
                          navigation.navigate('Loading');
                          const accountInfoResponse = await accountService.getAccountInfo({});
                          const profileResponse = await accountService.getProfile({});
                          dispatch(setAccountInfoCA(accountInfoResponse.data));
                          dispatch(setAccountProfileCA(profileResponse.data));
                          navigation.navigate('Root');
                        } catch (_error) {
                          navigation.navigate('LogIn');
                        } finally {
                          dispatch(setIsLoading(false));
                          setShowMultiLineSwitch(false);
                          dispatch(setDrawerVisible(false));
                        }
                      }
                }
              >
                <Image
                  source={getProfileImage(Math.floor(Math.random() * 8))}
                  style={{ width: 40, height: 40 }}
                  resizeMode="cover"
                />
                <View style={{ flex: 1 }}>
                  <CustomText weight="500" style={{ fontSize: 16, color: Colors.TextBlack }}>
                    {account.FirstName} {account.LastName}
                  </CustomText>
                  <CustomText weight="300" style={{ fontSize: 12, color: Colors.grey }}>
                    {formatPhoneNumber(account.Pnum)}
                  </CustomText>
                </View>
                {account.Cnum === selectedAccount?.Cnum ? (
                  <CustomText style={{ fontSize: 14, color: Colors.canadaButtonColor }}>
                    Selected
                  </CustomText>
                ) : null}
              </Pressable>
              {index < accounts.length - 1 ? (
                <HorizontalLine thinkness={1} style={{ marginVertical: 6 }} />
              ) : null}
            </View>
          ))}
        </View>
        <Button
          style={{
            marginTop: 10,
            alignSelf: isWebsite() ? 'center' : undefined,
          }}
          color={Colors.backgroundGrey}
          textColor={Colors.TextBlack}
          onPress={() => {
            setShowMultiLineSwitch(false);
            dispatch(setDrawerVisible(false));
          }}
        >
          <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
            Close
          </CustomText>
        </Button>
      </AppDrawer>
    </>
  );
};

export default ActiveUserHeader;
