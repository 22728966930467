export const Colors = {
  white: '#FFFFFF',
  black: '#000000',
  lightGrey: 'rgba(112, 112, 112, 0.1)',
  overlay75: 'rgba(0, 0, 0, 0.75)',
  overlay40: 'rgba(0,0,0,0.4)',
  mediumGrey: '#D9D9D9',
  red: '#E93F25',
  redOpacity50: 'rgba(255,0,0,0.5)',
  errorRed: '#EF4444',
  errorRed80Percent: '#F26969',
  errorRed50Percent: '#f26868',
  errorRed40Percent: '#f68c8c',
  errorRed20Percent: '#FBD9D9',
  errorRed10Percent: '#fdecec',
  grey: 'rgba(51, 51, 51, 0.5)',
  green: '#26A989',
  deepBlue: '#04399F',
  darkGreen: 'rgba(67, 134, 118, 0.3)',
  lightGradientBlue: '#3D9BFF',
  lightGradientGreen: '#2CC8B5',
  gradientBlue: '#2F80ED',
  gradientGreen: '#26A989',
  lightGreen: '#d5eee7',
  extraDarkGreen: '#198867',
  planGreen: '#A8DED0',
  lightRed: 'rgba(255,0,0,0.1)',
  backgroundGrey: '#F7F7F9',
  gradientDarkBlue: '#2563EB', // its defined here as well for backward compatability
  gradientLightBlue: '#22D3EE', // its defined here as well for backward compatability
  canadaButtonColor: '#2563EB',
  usaButtonColor: '#10B981',
  noColor: 'rgba(0,0,0,0)',
  greyColor: '#5F6772',
  inputTextPlaceholderColor: '#676B70',
  HorizonalLineDefaultColor: '#EAEAEA',
  countryToggleBackgroundColor: '#E7F8FB',
  SelectLineTitleBackgroundColor: '#F7F7F7',
  SelectLineTitleTextColor: '#737580',
  SelectLineItemHighlightColor: '#E8F7FF',
  IntroSliderDotColor: '#EEEEFF',
  BottomTabInactiveColor: '#777777',
  QuickActionTextColor: '#1B2124',
  PaginationDotColor: '#CCCCCC',
  HomePageHeadingColor: '#161B1D',
  DonutEmptyColor: '#d3f3fb',
  ActionLinkColor: '#32AFC9',
  ActionLinkColorBG: 'rgba(50, 175, 201, 0.1)',
  NoPlanBannerColor1: '#A5A6A7',
  NoPlanBannerColor2: '#4D5969',
  TextBlack: '#121212',
  activePlanBannerColor1: '#2563EB',
  activePlanBannerColor2: '#204B81',
  activePlanBorderColor: '#F2F2F2',
  terminatedPlanBG0: '#EFEFEF',
  MainOrange: '#FF9900',
  gradient: {
    vacation: {
      start: '#129DB2',
      end: '#F0F2EF',
    },
    lostStolen: {
      start: '#FFB84F',
      end: '#F3BB87',
    },
    terminated: {
      start: '#D47676',
      end: '#F0F2EF',
    },
    unpaid: {
      start: '#BBBBBB',
      end: '#EEEEEE',
    },
    default: {
      start: '#2563EB',
      end: '#22D3EE',
    },
  },
  badge: {
    green: {
      color: '#10B981',
      bg: '#E9FBF5',
    },
    red: {
      color: '#EF4444',
      bg: '#FBE9E9',
    },
    grey: {
      color: '#676B70',
      bg: '#f6f6f6',
    },
    blue: {
      color: '#2563EB',
      bg: '#E9EFFB',
    },
    'light-blue': {
      color: '#32AFC9',
      bg: '#E7F8FB',
    },
    orange: {
      color: '#FF9900',
      bg: '#FFF4E5',
    },
  },
  bottomDrawerHandle: '#ECECEC',
  desktop: {
    background: '#f1f1f1',
  },
};
