import { AntDesign } from '@expo/vector-icons';
import { Platform, View } from 'react-native';

import { Colors } from '../../../constants/Colors';
import CustomText from '../../utils/CustomText';

const NoPlan = () => {
  return (
    <View
      style={{
        backgroundColor: Colors.backgroundGrey,
        width: 134,
        height: 104,
        borderRadius: 8,
        position: 'relative',
        marginLeft: 20,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          backgroundColor: Colors.NoPlanBannerColor1,
          width: 65,
          paddingLeft: 7,
          paddingRight: 10,
          paddingVertical: 4,
          position: 'absolute',
          top: -10,
          left: -7,
          borderTopLeftRadius: Platform.OS === 'ios' ? 10 : 30,
          borderTopRightRadius: 50,
          borderBottomRightRadius: 50,
          zIndex: 1,
        }}
      >
        <View
          style={{
            position: 'absolute',
            top: Platform.select({ web: 24, default: 28, ios: 24 }),
            left: 0,
            backgroundColor: Colors.NoPlanBannerColor2,
            zIndex: -1,
            width: 6.5,
            height: 5,
            borderBottomLeftRadius: 15,
          }}
        ></View>
        <CustomText
          weight="500"
          style={{ color: Colors.white, fontWeight: '500', fontSize: 12, textAlign: 'center' }}
          numberOfLines={1}
        >
          No plan
        </CustomText>
      </View>

      <View style={{ alignItems: 'center' }}>
        <AntDesign name="pluscircleo" color={Colors.HorizonalLineDefaultColor} size={35} />
        <CustomText
          weight="600"
          style={{
            color: Colors.PaginationDotColor,
            fontSize: 12,
            fontWeight: '600',
            marginTop: 16,
          }}
        >
          No plan
        </CustomText>
      </View>
    </View>
  );
};

export default NoPlan;
