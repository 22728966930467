import { Feather } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Crisp } from 'crisp-sdk-web';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import * as CrispChatSDK from 'react-native-crisp-chat-sdk';
import uuid from 'react-native-uuid';
import { useDispatch, useSelector } from 'react-redux';

import LinkingConfiguration from './LinkingConfiguration';
import FullScreenLoader from '../components/utils/FullScreenLoader';
import { Colors } from '../constants/Colors';
import { CRED_REMEMBER_ME, REMEBER_ME } from '../constants/constants';
import {
  AuthenticatedUserScreen,
  StackScreens,
  UnAuthenticatedUserScreen,
} from '../constants/Screens';
import { authService } from '../services/SSO/AuthService';
import { tokenService } from '../services/TokenService';
import { RootState } from '../store';
import { setIsLoading } from '../store/app';
import { resetComplete, resetUser, setLoggedIn } from '../store/user';
import { RootStackParamList } from '../types';
import DevLog from '../utilities/debug-error';
import { getUserProfileAsync, isWebsite } from '../utilities/functions';

export default function Navigation() {
  const isAppLoading = useSelector((state: RootState) => state.app.isLoading);
  const [isInitialCheckCompleted, setIsInitialCheckCompleted] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const initialChecks = async () => {
      const rememberMe = (await AsyncStorage.getItem(CRED_REMEMBER_ME)) === REMEBER_ME;
      if (!rememberMe) {
        // If not rememberMe Force Logout user on initial load
        // this is for mobile application only
        if (Platform.OS !== 'web') {
          await tokenService.deleteAccessTokenAsync();
          await tokenService.deleteAccessTokenExpiryAsync();
          await tokenService.deleteCASelfServeTokenAsync();
          await tokenService.deleteRefreshTokenAsync();
          await tokenService.deleteRefreshTokenExpiryAsync();
          await tokenService.deleteRememberMe();
          dispatch(resetUser());
          return;
        }
      }
      const token = await tokenService.getAccessTokenAsync();
      if (!token) {
        // dispatch(setIsLoading(false));
        return;
      }
      setIsLoggedIn(true);
      dispatch(setLoggedIn(true));
    };

    (async () => {
      const isViewed = await AsyncStorage.getItem('viewed-intro-slider');
      DevLog.log({ isViewed });
      setIsNewUser(isViewed !== 'yes');
      await initialChecks();
      setIsInitialCheckCompleted(true);
      await SplashScreen.hideAsync();
    })();
  }, []);

  return isInitialCheckCompleted ? (
    <NavigationContainer linking={LinkingConfiguration}>
      <FullScreenLoader loading={isAppLoading} />
      <RootNavigator
        initialRoute={
          isWebsite()
            ? isLoggedIn
              ? 'Loading'
              : 'Landing'
            : isNewUser
              ? 'IntroSlider'
              : isLoggedIn
                ? 'Loading'
                : 'Landing'
        }
      />
    </NavigationContainer>
  ) : null;
}

const Stack = createNativeStackNavigator<RootStackParamList>();

type Props = {
  initialRoute?: keyof RootStackParamList;
};

function RootNavigator(props: Props) {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { loggedIn, cnum, userReset } = useSelector((state: RootState) => state.user);

  // Persist Login State if RememberMe!
  useEffect(() => {
    dispatch(setIsLoading(true));
    if (isWebsite()) {
      const style = document.createElement('style');
      style.innerHTML = `
        #crisp-chatbox > div > div { 
          bottom: 140px !important;
        }
        #crisp-chatbox > div > a[aria-label="Open chat"] { 
          bottom: 60px !important;
        }
        #crisp-chatbox > div > a[aria-label="Open chat"] [data-id="new_messages"] { 
          bottom: 140px !important;
        }`;
      document.head.appendChild(style);
    }
  }, []);

  useEffect(() => {
    const checkAccessToken = async () => {
      dispatch(setIsLoading(true));
      const token = await tokenService.getAccessTokenAsync();

      if (!token) {
        DevLog.log('Token not found.');
        return;
      }

      const now = new Date();
      const tokenExpiry = await tokenService.getAccessTokenExpiryAsync();
      const refreshTokenExpiry = await tokenService.getRefreshTokenExpiryAsync();

      if (tokenExpiry && +now > +tokenExpiry) {
        DevLog.log('Token no longer valid');

        if (refreshTokenExpiry && +now < +refreshTokenExpiry) {
          DevLog.log('Refreshing...');

          const refreshTokenResponse = await authService.refreshToken({});

          if (
            !refreshTokenResponse ||
            !refreshTokenResponse.data ||
            !refreshTokenResponse.data.success
          ) {
            DevLog.log('Refresh failed, forcing logout.');
            await tokenService.deleteAccessTokenAsync();
            await tokenService.deleteAccessTokenExpiryAsync();
            await tokenService.deleteRefreshTokenAsync();
            await tokenService.deleteRememberMe();
            await tokenService.deleteRefreshTokenExpiryAsync();
            return;
          }

          if (refreshTokenResponse?.data?.success && refreshTokenResponse.data.data.idToken) {
            await tokenService.setAccessTokenAsync(refreshTokenResponse.data.data.idToken);
            await tokenService.setAccessTokenExpiryAsync(
              +now + refreshTokenResponse.data.data.idTokenExpiresIn * 1000
            );
            DevLog.log('refresh succeeded!');
          }
        } else {
          DevLog.log('Refresh token expired, forcing logout.');
          await tokenService.deleteAccessTokenAsync();
          await tokenService.deleteAccessTokenExpiryAsync();
          await tokenService.deleteRefreshTokenAsync();
          await tokenService.deleteRememberMe();
          await tokenService.deleteRefreshTokenExpiryAsync();
          return;
        }
      }

      DevLog.log('Token found.');
      DevLog.log(`Time is now: ${now.toISOString()}`);
      DevLog.log(`token expiry: ${tokenExpiry}`);
    };

    const _checkAccessTokenThen = () => {
      // @ts-expect-error: navigation type conflict
      getUserProfileAsync(dispatch, navigation, cnum)
        .then((res: boolean | undefined | null) => {
          if (res === true) {
            DevLog.info(
              '%cWARNING: Update to Alert!',
              'color: red; font-size: 40px; font-weight: bold; background-color: #fdd; padding: 14px; border: 4px solid red; border-radius: 10px;'
            );
            // alert('Remove this');
            navigation.navigate('Root');
          }
        })
        .catch((err) => {
          DevLog.error(err);
          dispatch(setIsLoading(false));
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _checkAccessTokenCatch = async (err: any) => {
      DevLog.error(err);
      // log user out if refresh is unauthorized (meaning refresh token expired)
      if (err.response.status === 401 && err.code === 'ERR_BAD_REQUEST') {
        try {
          await tokenService.deleteAccessTokenAsync();
          await tokenService.deleteRefreshTokenAsync();
          await tokenService.deleteRememberMe();
          dispatch(setLoggedIn(false));
          dispatch(resetUser());
        } catch (deleteErr) {
          DevLog.error(deleteErr);
        }
      }
      dispatch(setIsLoading(false));
    };

    DevLog.info(
      '%cWARNING: May be remove this? I think Redux is keeping track of state even after reloading.',
      'color: red; font-size: 20px; font-weight: bold; background-color: #fdd; padding: 10px; border: 1px solid red; border-radius: 5px;'
    );

    if (loggedIn) {
      DevLog.info(
        '%cWARNING: May be Fix this? Being called 2 times on initial load.',
        'color: red; font-size: 20px; font-weight: bold; background-color: #fdd; padding: 10px; border: 1px solid red; border-radius: 5px;'
      );
      checkAccessToken().then(_checkAccessTokenThen).catch(_checkAccessTokenCatch);
      if (isWebsite()) {
        Crisp.chat.close();
        Crisp.session.reset();
      } else {
        CrispChatSDK.resetSession();
      }
    } else {
      if (isWebsite()) {
        Crisp.chat.close();
        Crisp.session.reset();
      } else {
        CrispChatSDK.resetSession();
        CrispChatSDK.setTokenId(uuid.v4());
      }
      dispatch(setIsLoading(false));
    }
  }, [loggedIn]);

  useEffect(() => {
    if (userReset === true) {
      navigation.navigate('Landing');
      setTimeout(() => {
        dispatch(resetComplete());
      }, 0);
    }
  }, [userReset]);

  DevLog.log({ props });
  return (
    <Stack.Navigator
      initialRouteName={props.initialRoute ?? 'Landing'}
      screenOptions={{
        headerTintColor: Colors.black,
        gestureEnabled: true,
        animation: 'default',
        headerShown: true,
        headerStyle: { backgroundColor: Colors.white },
        headerTitleStyle: {
          fontFamily: 'Poppins-Medium',
          fontSize: 16,
          fontWeight: '500',
        },
        headerShadowVisible: false,
        title: '',
        contentStyle: { backgroundColor: Colors.white },
        headerLeft: () => (
          <Feather
            name="chevron-left"
            size={24}
            color="black"
            onPress={() => navigation.goBack()}
            style={isWebsite() ? { marginLeft: 20 } : {}}
          />
        ),
        headerTitleAlign: 'center',
      }}
    >
      {!loggedIn
        ? UnAuthenticatedUserScreen.map((screen) => (
            <Stack.Screen
              key={screen.name}
              name={screen.name}
              component={screen.component}
              options={screen.options}
            />
          ))
        : AuthenticatedUserScreen.map((screen) => (
            <Stack.Screen
              key={screen.name}
              name={screen.name}
              component={screen.component}
              options={screen.options}
            />
          ))}
      {StackScreens.map((screen) => (
        <Stack.Screen
          key={screen.name}
          name={screen.name}
          component={screen.component}
          options={screen.options}
        />
      ))}
    </Stack.Navigator>
  );
}
