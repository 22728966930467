import { useEffect, useState } from 'react';
import { SafeAreaView, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import AddPaymentMethodView, { BankPayment, CardPayment } from './AddPaymentMethodView';
import InitialView from './InitialView';
import SuccessView from './SuccessView';
import { AppDrawer } from '../../components/AppDrawer';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { accountService } from '../../services/MyAccount/AccountService';
import { RootState } from '../../store';
import {
  deleteBankInfo,
  deleteCardInfo,
  updateBankAccountInfo,
  updateCardInfo,
} from '../../store/selfServeCA';
import { RootStackScreenProps } from '../../types';
import { ManagePaymentFlowStep } from '../../types/enums/enums';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';
import { convertDataURItoBlob } from '../../utilities/utilities';

const ManagePaymentScreen = ({ navigation }: RootStackScreenProps<'ManagePayment'>) => {
  const dispatch = useDispatch();

  const { CreditCardExpirationMonth, CreditCardExpirationYear, CreditCardLast4Digits } =
    useSelector((state: RootState) => state.selfServeCA.accountProfileCA);

  const isValidCardPayment =
    CreditCardLast4Digits && CreditCardExpirationYear && CreditCardExpirationMonth;
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [showDeletePaymentModal, setShowDeletePaymentModal] = useState(false);

  const [step, setStep] = useState<ManagePaymentFlowStep>(ManagePaymentFlowStep.Initial);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let timeOut: NodeJS.Timeout | number;
    if (error) {
      timeOut = setTimeout(() => {
        setError(null);
      }, 5000);
    }
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [error]);

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          padding: 20,
          backgroundColor: Colors.white,
          marginBottom: step === ManagePaymentFlowStep.Initial ? 150 : 20,
          flex: 1,
          height: '100%',
        }}
      >
        {step === ManagePaymentFlowStep.Initial ? (
          <InitialView
            onAction={(action) => {
              switch (action) {
                case 'SHOW_DELETE_MODAL':
                  setShowAddPaymentModal(true);
                  break;
                case 'DELETE_CREDIT':
                case 'DELETE_BANK':
                  setShowDeletePaymentModal(true);
                  break;
                case 'ADD_BANK':
                  setStep(ManagePaymentFlowStep.AddPaymentBank);
                  break;
                case 'ADD_CREDIT':
                  setStep(ManagePaymentFlowStep.AddPaymentCard);
                  break;
                case 'EDIT_BANK':
                  setStep(ManagePaymentFlowStep.EditPaymentBank);
                  break;
                case 'EDIT_CREDIT':
                  setStep(ManagePaymentFlowStep.EditPaymentCard);
                  break;
                default:
                  break;
              }
              DevLog.log(action);
            }}
          />
        ) : step === ManagePaymentFlowStep.UpdateSuccessful ? (
          <SuccessView
            onPress={() => {
              navigation.navigate('Billing');
            }}
          />
        ) : (
          <AddPaymentMethodView
            step={step}
            onAction={async (action, _data) => {
              try {
                let data = _data;
                if (action === 'SAVE_CARD') {
                  data = _data as CardPayment;
                  const formData = new FormData();
                  formData.append('CCV', data.newCreditCardCVV);
                  formData.append('CreditCardName', data.newCreditCardHolderName);
                  formData.append('CreditCardNumber', data.newCreditCardNumber);
                  formData.append('ExpirationMonth', data.newCreditCardExpirationMonth);
                  formData.append('ExpirationYear', data.newCreditCardExpirationYear);
                  formData.append('PaymentMethod', 'Credit Card');
                  DevLog.log({ action, data, formData, response: 'response' });
                  const response = await accountService.changePaymentMethod(formData);
                  if (response.data === '') {
                    dispatch(
                      updateCardInfo({
                        CreditCardExpirationMonth: data.newCreditCardExpirationMonth,
                        CreditCardExpirationYear: data.newCreditCardExpirationYear,
                        CreditCardHolderName: data.newCreditCardHolderName,
                        CreditCardLast4Digits: data.newCreditCardNumber.substring(
                          data.newCreditCardNumber.length - 4
                        ),
                        CreditCardType: data.newCreditCardType,
                      })
                    );
                    setStep(ManagePaymentFlowStep.UpdateSuccessful);
                  } else {
                    setError('Unable to update your payment method.');
                  }
                } else if (action === 'SAVE_BANK') {
                  data = _data as BankPayment;

                  DevLog.log(data);
                  const formData = new FormData();
                  formData.append('AccountHolder', data.newBankAccountHolder);
                  formData.append('AccountNumber', data.newBankAccount);
                  formData.append('BankInstitution', data.newBankInstitution);
                  formData.append('TransitNumber', data.newBankTransit);
                  // @ts-expect-error: issue with type check
                  formData.append(
                    'ProofImage',
                    isWebsite()
                      ? convertDataURItoBlob(data.newBankImageProof.uri)
                      : {
                          uri: data.newBankImageProof.uri,
                          name: data.newBankImageProof.uri.split('/').pop(), // Extract the filename
                          type: data.newBankImageProof.mimeType,
                        }
                  );
                  formData.append('PaymentMethod', 'Bank Account');
                  DevLog.log({ action, data, formData, response: 'response' });
                  const response = await accountService.changePaymentMethod(formData);
                  if (response.data === '') {
                    dispatch(
                      updateBankAccountInfo({
                        AccountHolder: data.newBankAccountHolder,
                        AccountNumber: data.newBankAccount,
                        AccountNumberLast4Digits: data.newBankAccount.substring(
                          data.newBankAccount.length - 4
                        ),
                        BankInstitution: data.newBankInstitution,
                        TransitNumber: data.newBankTransit,
                      })
                    );
                    setStep(ManagePaymentFlowStep.UpdateSuccessful);
                  } else {
                    setError('Unable to update your payment method.');
                  }
                } else {
                  setStep(ManagePaymentFlowStep.Initial);
                  return;
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (error: any) {
                DevLog.log('Error: ', error);
                DevLog.trace(error);
                setError('Unable to update your payment method.');
              }
            }}
          />
        )}
        {error ? (
          <CustomText
            onPress={() => {
              setError(null);
            }}
            weight="500"
            style={{
              position: 'absolute',
              top: '50%',
              left: 0,
              right: 0,
              backgroundColor: Colors.redOpacity50,
              zIndex: 10,
              textAlign: 'center',
              color: Colors.white,
              padding: 14,
              borderRadius: 14,
              width: '100%',
              margin: 'auto',
              fontSize: 18,
            }}
          >
            {error}
          </CustomText>
        ) : null}
      </ScrollView>
      <AppDrawer
        visible={showAddPaymentModal}
        height={350}
        onClose={() => {
          setShowAddPaymentModal(false);
        }}
        showBackDrop
      >
        <CustomText weight="500" style={{ fontSize: 24, marginBottom: 16 }}>
          Please delete card information first
        </CustomText>
        <CustomText style={{ fontSize: 16, marginTop: 16 }}>
          {isValidCardPayment
            ? 'If you would like to register bank account as a default payment. Please delete registered card information first.'
            : 'If you would like to register credit card as a default payment. Please delete registered bank account information first.'}
        </CustomText>
        <HorizontalLine thinkness={1} style={{ marginVertical: 16 }} />
        <Button
          style={{ marginTop: 16 }}
          color={Colors.backgroundGrey}
          textColor={Colors.inputTextPlaceholderColor}
          onPress={() => {
            setShowAddPaymentModal(false);
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Back
        </Button>
      </AppDrawer>
      <AppDrawer
        visible={showDeletePaymentModal}
        height={400}
        onClose={() => {
          setShowDeletePaymentModal(false);
        }}
        showBackDrop
      >
        <CustomText weight="500" style={{ fontSize: 24, marginBottom: 16 }}>
          Delete Information
        </CustomText>
        <CustomText style={{ fontSize: 16, marginTop: 16 }}>
          Once information is deleted, it can&apos;t be recovered. Also, You can not get auto
          extension benefit.
        </CustomText>
        <HorizontalLine thinkness={1} style={{ marginVertical: 16 }} />

        <Button
          style={{ marginTop: 16 }}
          color={Colors.errorRed}
          textColor={Colors.white}
          onPress={async () => {
            setShowDeletePaymentModal(false);
            const formData = new FormData();
            formData.append('PaymentMethod', 'Cash');
            try {
              const response = await accountService.changePaymentMethod(formData);
              DevLog.log(response);
              if (response.data === '') {
                dispatch(deleteCardInfo());
                dispatch(deleteBankInfo());
              }
            } catch (_error) {
              DevLog.log(_error);
            }
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Delete Information
        </Button>
        <Button
          style={{ marginTop: 16 }}
          color={Colors.backgroundGrey}
          textColor={Colors.inputTextPlaceholderColor}
          onPress={() => {
            setShowDeletePaymentModal(false);
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Cancel
        </Button>
      </AppDrawer>
    </SafeAreaView>
  );
};

export default ManagePaymentScreen;
