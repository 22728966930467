import { instance } from '../../api/axios';
import { ApiResponse } from '../../types/responses/ApiResponse';

export const billingService = {
  getIotPayStatus: async (payId: string): Promise<ApiResponse<string>> =>
    await instance.get('/Billing/GetIotPayStatus', {
      params: {
        payId,
      },
    }),
};
