import { instance } from '../../api/axios';
import { ApiRequest } from '../../types/requests/ApiRequest';
import { TopUpExtensionRequest } from '../../types/requests/MyAccount/TopUpExtensionRequest';
import { ApiResponse } from '../../types/responses/ApiResponse';
import { TopUpExtensionResponse } from '../../types/responses/MyAccount/TopUpExtensionResponse';

export const prepaidService = {
  topUpExtension: async (
    request: ApiRequest<TopUpExtensionRequest>
  ): Promise<ApiResponse<TopUpExtensionResponse>> =>
    await instance.post<ApiRequest<TopUpExtensionRequest>, ApiResponse<TopUpExtensionResponse>>(
      '/Prepaid/TopUpExtension',
      {
        amount: request.data.payload.amount,
        cnum: request.data.payload.cnum,
        currency: request.data.payload.currency,
        ex_edate: request.data.payload.extensionEndDate,
        ex_sdate: request.data.payload.extensionStartDate,
        ex_subtotal: request.data.payload.extensionSubtotal,
        newPfID: request.data.payload.newPfID,
        paymentMethod: request.data.payload.paymentMethod,
        systag: request.data.payload.systag,
        tp_capacity: request.data.payload.topUpCapacity,
        duration: request.data.payload.duration,
      },
      {
        params: {
          return_url: request.data.returnUrl,
          cancel_url: request.data.cancelUrl,
        },
      }
    ),
  prepaidUserInfo: async () => {},
  updateAutoExtensionPaymentInfo: async () => {},
  availablePlans: async () => {},
  planDetailByID: async () => {},
};
