import moment from 'moment';
import { useState } from 'react';
import { Alert, ScrollView, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { AppDrawer } from '../../components/AppDrawer';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT, DATE_FORMAT_FOR_API } from '../../constants/constants';
import { accountService } from '../../services/MyAccount/AccountService';
import { RootState } from '../../store';
import { setIsLoading, setTerminationInfo } from '../../store/app';
import { RootStackScreenProps } from '../../types';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';
import { formatPhoneNumber } from '../../utilities/utilities';

const TerminationDetailScreen = ({ navigation }: RootStackScreenProps<'TerminationDetail'>) => {
  const PhoneNumber = useSelector(
    (state: RootState) => state.selfServeCA.accountProfileCA.PhoneNumber
  );
  const CurrentBalance = useSelector(
    (state: RootState) => state.selfServeCA.accountInfoCA.CurrentBalance
  );

  const terminationInfo = useSelector((state: RootState) => state.app.terminationInfo);

  const [otp, setOtp] = useState<string>('');
  const [error, setError] = useState<string>();

  const dispatch = useDispatch();

  const [showClearBalanceModal, setShowClearBalanceModal] = useState<boolean>(false);

  return (
    <>
      <ScrollView
        style={[
          { flex: 1, paddingHorizontal: 20, marginBottom: 120 },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <CustomText weight="500" style={{ fontSize: 20, color: Colors.TextBlack, marginTop: 32 }}>
          Are you sure want to terminate your account?
        </CustomText>
        <CustomText style={{ fontSize: 14, marginTop: 8 }}>
          Your number will be terminated and you will no longer be able to use our services with the
          same phone number.
        </CustomText>

        <CustomText weight="600" style={{ fontSize: 14, marginTop: 32 }}>
          Termination Information
        </CustomText>
        <View
          style={{
            backgroundColor: Colors.backgroundGrey,
            padding: 16,
            borderRadius: 16,
            marginTop: 8,
            gap: 8,
          }}
        >
          <View style={styles.infoContainer}>
            <CustomText weight="500" style={styles.infoTitle}>
              Termination Date:
            </CustomText>
            <CustomText weight="500" style={styles.infoText}>
              {moment(terminationInfo.terminationDate).format(DATE_FORMAT)}
            </CustomText>
          </View>
          <View style={styles.infoContainer}>
            <CustomText weight="500" style={styles.infoTitle}>
              Phone Number:
            </CustomText>
            <CustomText weight="500" style={styles.infoText}>
              {formatPhoneNumber(PhoneNumber)}
            </CustomText>
          </View>
        </View>
        <InputWithTitle inputValue={otp} onInputChange={setOtp} title="One Time Password (OTP)" />
        {error ? (
          <CustomText
            style={{
              color: Colors.errorRed,
              marginTop: 4,
              textAlign: 'center',
            }}
          >
            {error}
          </CustomText>
        ) : null}
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            paddingBottom: 40,
            paddingHorizontal: 20,
            gap: 8,
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          onPress={async () => {
            DevLog.log(terminationInfo);
            DevLog.log(navigation.getState());

            if (CurrentBalance) {
              setShowClearBalanceModal(true);
            } else {
              try {
                Alert.alert('OTP Not Available', 'Need to Send OTP Verification.');

                return;
                dispatch(setIsLoading(true));
                const response = await accountService.postTermination({
                  reasonCode: terminationInfo.reason,
                  note: terminationInfo.reasonDesc,
                  terDate: moment(terminationInfo.terminationDate).format(DATE_FORMAT_FOR_API),
                });
                if (response.data[1] === 'E') {
                  setError(response.data);
                  return;
                }
                dispatch(setTerminationInfo(null));
                navigation.navigate('TerminationSuccess');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (error: any) {
                setError(error.message);
              } finally {
                dispatch(setIsLoading(false));
              }
            }
          }}
          color={Colors.red}
        >
          Request Termination
        </Button>
        <Button
          onPress={() => {
            dispatch(setTerminationInfo(null));
            navigation.navigate('Root');
          }}
          color={Colors.backgroundGrey}
          textColor={Colors.greyColor}
        >
          Cancel Request
        </Button>
      </View>
      <AppDrawer
        onClose={() => {
          setShowClearBalanceModal(false);
        }}
        height={400}
        visible={showClearBalanceModal}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24 }}>
          There is a remaining balance for this line.
        </CustomText>
        <HorizontalLine style={{ marginVertical: 16 }} />
        <CustomText style={{ fontSize: 16, marginBottom: 32 }}>
          Outstanding balance payment is required to move forward.
        </CustomText>
        <Button
          onPress={() => {
            navigation.navigate('ManagePayment');
          }}
          style={{
            marginBottom: 8,
          }}
        >
          Clear Balance
        </Button>
        <Button
          onPress={() => {
            setShowClearBalanceModal(false);
          }}
          color={Colors.backgroundGrey}
          textColor={Colors.greyColor}
        >
          Cancel
        </Button>
      </AppDrawer>
    </>
  );
};

const styles = StyleSheet.create({
  infoContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  infoText: { color: Colors.TextBlack, fontSize: 12, textAlign: 'right' },
  infoTitle: { color: Colors.greyColor, fontSize: 12 },
});
export default TerminationDetailScreen;
