import React from 'react';
import { View } from 'react-native';
import Svg, { Circle, Defs, LinearGradient, Stop } from 'react-native-svg';

import { Colors } from '../../constants/Colors';
import CustomText from '../utils/CustomText';

type Props = {
  radius?: number | undefined;
  strokeWidth?: number | undefined;
  // strokeColor?: string | undefined;
  // trailColor?: string | undefined;
  progress?: number | undefined;
  totalData: number | string;
  usedData: number | string;
  unit: string;
};

const Donut = ({
  radius = 50,
  strokeWidth = 10,
  // strokeColor = Colors.canadaButtonColor,
  // trailColor = Colors.DonutEmptyColor,
  progress = 0,
  ...props
}: Props) => {
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference * (1 - progress);
  // strokeColor = progress < 1 ? Colors.canadaButtonColor : Colors.errorRed;

  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <View
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: 120,
          height: 120,
          transform: [
            { translateX: -60 }, // half of the width
            { translateY: -60 }, // half of the height
          ],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{ alignItems: 'baseline', display: 'flex', flexDirection: 'row', marginTop: 0 }}
        >
          <CustomText
            weight="600"
            style={{
              fontSize: 26,
              fontWeight: '600',
              lineHeight: 28,
              color: progress < 1 ? Colors.TextBlack : Colors.red,
            }}
          >
            {props.usedData}
          </CustomText>
          <CustomText
            weight="600"
            style={{
              fontSize: 10,
              fontWeight: '600',
              color: progress < 1 ? Colors.TextBlack : Colors.red,
            }}
          >
            {props.unit}
          </CustomText>
        </View>
        <CustomText
          weight="500"
          style={{
            fontSize: 10,
            fontWeight: '500',
          }}
        >
          /{props.totalData} {props.unit}
        </CustomText>
      </View>
      <Svg
        // width={2 * radius}
        width={2 * radius + strokeWidth}
        // height={2 * radius}
        height={2 * radius + strokeWidth}
        viewBox={`0 0 ${2 * (radius + strokeWidth)} ${2 * (radius + strokeWidth)}`}
      >
        <Defs>
          {/* Trail Gradient */}
          <LinearGradient id="trailGradient" x1="0%" y1="100%" x2="100%" y2="0%">
            <Stop offset="0%" stopColor={Colors.gradientDarkBlue} stopOpacity="1" />
            <Stop offset="100%" stopColor={Colors.gradientLightBlue} stopOpacity="0.5" />
          </LinearGradient>

          {/* Progress Gradient */}
          <LinearGradient id="progressGradient" x1="0%" y1="100%" x2="100%" y2="0%">
            <Stop
              offset="0%"
              stopColor={progress < 1 ? Colors.gradientDarkBlue : Colors.errorRed}
              stopOpacity="1"
            />
            <Stop
              offset="100%"
              stopColor={progress < 1 ? Colors.gradientLightBlue : Colors.errorRed40Percent}
              stopOpacity="1"
            />
          </LinearGradient>
        </Defs>

        {/* Trail circle */}
        <Circle
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius}
          // stroke={trailColor}
          stroke="url(#trailGradient)"
          strokeWidth={strokeWidth}
          fill="none"
          opacity={0.2}
        />
        {/* Progress circle */}
        <Circle
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius}
          // stroke={strokeColor}
          stroke="url(#progressGradient)"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          fill="none"
          strokeLinecap={'round'}
        />
      </Svg>
    </View>
  );
};

export default Donut;
