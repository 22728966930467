import { useEffect, useRef } from 'react';
import { Animated, Pressable, View } from 'react-native';

import { Colors } from '../../constants/Colors';
import { isWebsite } from '../../utilities/functions';
import CustomText from '../utils/CustomText';

type Props = {
  value: boolean;
  onValueChange?: (value: boolean) => void;
};

const CheckboxToggle = (props: Props) => {
  const animatedValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: props.value ? 1 : 0,
      duration: 200,
      useNativeDriver: true, // Set to true if you don't need to animate layout
    }).start();
  }, [props.value]);

  const translateX = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [4, 23],
  });

  return (
    <Pressable
      style={{ position: 'relative' }}
      onPress={() => (props.onValueChange ? props.onValueChange(!props.value) : null)}
    >
      <View
        style={{
          width: 70,
          height: 28,
          backgroundColor: props.value ? Colors.canadaButtonColor : Colors.activePlanBorderColor,
          borderRadius: 25,
          justifyContent: 'center',
        }}
      >
        <Animated.View
          style={{
            position: 'absolute',
            transform: [{ translateX }],
          }}
        >
          <CustomText
            weight="500"
            style={{
              fontSize: 14,
              backgroundColor: Colors.white,
              borderRadius: isWebsite() ? 25 : 10,
              height: 20,
              width: 43,
              textAlign: 'center',
              overflow: 'hidden',
            }}
          >
            {props.value ? 'Yes' : 'No'}
          </CustomText>
        </Animated.View>
      </View>
    </Pressable>
  );
};

export default CheckboxToggle;
