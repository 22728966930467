import { SafeAreaView, StatusBar } from 'react-native';
import { useSelector } from 'react-redux';

import ActiveUserView from './ActiveUserView';
import HomePageDesktopView from './DesktopView';
import HomePageGuestDesktopView from './GuestDesktopView';
import GuestView from './GuestView';
import HomePageNoPlanDesktopView from './NoPlanDesktopView';
import NoPlanView from './NoPlanView';
import PopUpBannerImage from '../../assets/offer/before-friday-pop-up.png';
import PopUpBanner from '../../components/UI/PopUpBanner';
import { Colors } from '../../constants/Colors';
import useIsMobile from '../../hooks/useIsMobile';
import { RootState } from '../../store';

export default function HomeScreen() {
  const isMobile = useIsMobile();
  const { loggedIn, accounts } = useSelector((state: RootState) => state.user);

  return (
    <>
      <PopUpBanner image={PopUpBannerImage} />
      {!isMobile ? (
        <>
          {!loggedIn ? (
            <HomePageGuestDesktopView />
          ) : accounts.length === 0 ? (
            <HomePageNoPlanDesktopView />
          ) : (
            <HomePageDesktopView />
          )}
        </>
      ) : (
        <SafeAreaView
          style={{ flex: 1, paddingTop: StatusBar.currentHeight, backgroundColor: Colors.white }}
        >
          {!loggedIn ? <GuestView /> : accounts.length === 0 ? <NoPlanView /> : <ActiveUserView />}
        </SafeAreaView>
      )}
    </>
  );
}
