import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Dimensions, Image, Linking, Pressable, ScrollView, View } from 'react-native';

import ChatImage from '../../assets/icons/action/chat.png';
import ProfileImage from '../../assets/icons/action/profile.png';
import ReferralImage from '../../assets/icons/action/referral.png';
import CanadaPlansBanner from '../../assets/images/canada-plans.png';
import USAPlansBanner from '../../assets/images/usa-plans.png';
import LogoImage from '../../assets/logo.png';
import { AppGap } from '../../components/AppGap';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import QuickAction, { Action } from '../../components/QuickAction/QuickAction';
import Offering from '../../components/Section/Offering';
import Donut from '../../components/UI/Donut';
import NoPlan from '../../components/UI/Plans/NoPlan';
import SectionHeading from '../../components/UI/SectionHeading';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import DevLog from '../../utilities/debug-error';
import { crispChatAction } from '../../utilities/functions';
import { getProfileImage } from '../../utilities/utilities';

const { width } = Dimensions.get('window');

const HomePageGuestDesktopView = () => {
  const navigation = useNavigation();

  const guestQuickActions: Action[] = [
    {
      image: ProfileImage,
      title: 'Profile Change',
      tag: 'PROFILE_CHANGE',
    },
    {
      image: ChatImage,
      title: 'Support Chat',
      tag: 'SUPPORT_CHAT',
    },
    {
      image: ReferralImage,
      title: 'Friend Referral',
      tag: 'FRIEND_REFERRAL',
    },
  ];

  // useEffect(() => {
  //   const unsubscribeBlur = navigation.addListener('blur', () => {
  //     dispatch(setDrawerVisible(false));
  //   });
  //   const unsubscribeFocus = navigation.addListener('focus', () => {
  //     dispatch(setDrawerVisible(true));
  //   });

  //   // Cleanup the listener on unmount
  //   return () => {
  //     unsubscribeBlur();
  //     unsubscribeFocus();
  //   };
  // });

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: Colors.white,
          paddingVertical: 14,
          shadowColor: Colors.black,
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.3,
          shadowRadius: 4,
          zIndex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Image
          source={LogoImage}
          resizeMode="contain"
          style={{
            width: 230,
            height: 40,
          }}
        />
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {/* <Pressable style={{ position: 'relative' }}>
            <View
              style={{
                position: 'absolute',
                width: 8,
                height: 8,
                backgroundColor: Colors.red,
                borderRadius: 4,
                right: 2,
                top: 2,
                zIndex: 1,
              }}
            ></View>
            <MaterialCommunityIcons name="bell-outline" color={Colors.greyColor} size={25} />
          </Pressable>
          <VerticalLine style={{ marginHorizontal: 20 }} height={24} /> */}
          <View
            style={{
              justifyContent: 'center',
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: 24,
            }}
          >
            <Image
              source={getProfileImage(-1)}
              style={{ width: 40, height: 40 }}
              resizeMode="cover"
            />
            <View style={{ marginHorizontal: 8 }}>
              <CustomText
                style={{ fontSize: 14, fontWeight: '400', color: Colors.TextBlack, maxWidth: 200 }}
              >
                Welcome
              </CustomText>
              <AppGap direction="vertical" size={4} />
              <CustomText
                weight="500"
                style={{ fontSize: 14, fontWeight: '500', color: Colors.grey }}
              >
                Guest
              </CustomText>
            </View>
            <Button
              style={{ paddingHorizontal: 10, paddingVertical: 6, width: 'auto', marginLeft: 20 }}
              textStyle={{ fontSize: 12, fontWeight: '600' }}
              onPress={() => {
                navigation.navigate('Join');
              }}
            >
              Sign Up
            </Button>
          </View>
        </View>
      </View>
      <ScrollView
        style={{ backgroundColor: Colors.desktop.background }}
        contentContainerStyle={{ flexDirection: 'row', width: '100%' }}
      >
        <View
          style={{
            maxWidth: 330,
            width: 330,
            marginHorizontal: 24,
            marginVertical: 32,
            backgroundColor: Colors.white,
            borderRadius: 16,
          }}
        >
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 30,
              paddingHorizontal: 20,
            }}
          >
            <Image
              source={getProfileImage(-1)}
              style={{ width: 100, height: 100 }}
              resizeMode="cover"
            />
            <CustomText weight="500" style={{ fontSize: 24, textAlign: 'center', marginTop: 16 }}>
              Welcome
            </CustomText>
            <CustomText style={{ fontSize: 20, textAlign: 'center' }}>Guest</CustomText>
          </View>
          <HorizontalLine style={{ marginVertical: 32 }} />
          <View>
            <CustomText weight="500" style={{ fontSize: 14, paddingHorizontal: 20 }}>
              Quick Menu
            </CustomText>
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: 14,
                justifyContent: 'center',
              }}
            >
              {guestQuickActions.map((action, i) => (
                <QuickAction
                  action={action}
                  key={i}
                  onActionSelected={(tag) => {
                    switch (tag) {
                      case 'PROFILE_CHANGE':
                        navigation.navigate('Account');
                        break;

                      case 'SUPPORT_CHAT':
                        crispChatAction(false, '', '', '', '', '');
                        break;
                      case 'FRIEND_REFERRAL':
                        break;
                      default:
                        break;
                    }
                    DevLog.log(tag);
                  }}
                />
              ))}
            </View>
          </View>
          <HorizontalLine style={{ marginVertical: 32 }} />
          <View style={{ marginBottom: 20 }}>
            <CustomText weight="500" style={{ fontSize: 14, paddingHorizontal: 20 }}>
              Shop
            </CustomText>
            <Pressable
              style={{ marginTop: 14, paddingHorizontal: 20 }}
              onPress={() => {
                navigation.navigate('Plans');
              }}
            >
              <Image
                source={CanadaPlansBanner}
                style={{ width: '100%', height: 100, borderRadius: 8 }}
                resizeMode="cover"
              />
            </Pressable>
            <Pressable
              style={{ marginTop: 14, paddingHorizontal: 20 }}
              onPress={() => {
                Linking.openURL('http://gophonebox.com/plans');
              }}
            >
              <Image
                source={USAPlansBanner}
                style={{ width: '100%', height: 100, borderRadius: 8 }}
                resizeMode="cover"
              />
            </Pressable>
          </View>
        </View>
        <View style={{ flex: 1, marginTop: 50, marginRight: 25, marginLeft: 1 }}>
          <View>
            <CustomText
              weight="500"
              style={{ fontSize: 48, textAlign: 'center', color: Colors.canadaButtonColor }}
            >
              Welcome, Guest
            </CustomText>
            <CustomText
              weight="500"
              style={{ fontSize: 24, textAlign: 'center', color: Colors.TextBlack }}
            >
              How can we help you today?
            </CustomText>
          </View>

          <View>
            <SectionHeading style={{ marginLeft: 0 }}>Best Offering</SectionHeading>
            <Offering />
          </View>
          <View style={{ gap: 24, marginVertical: 34, flexDirection: 'row', flexWrap: 'wrap' }}>
            <View
              style={{
                backgroundColor: Colors.white,
                borderRadius: 8,
                padding: 24,
                minWidth: 500,
                width: (width - 500) / 2,
              }}
            >
              <SectionHeading>Data Usage</SectionHeading>
              <View
                style={{
                  borderColor: Colors.HorizonalLineDefaultColor,
                  borderWidth: 1,
                  marginHorizontal: 20,
                  borderRadius: 8,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: 16,
                }}
              >
                <Donut totalData={'No Plan'} unit="" usedData={'0.0'} />
                <View style={{ alignItems: 'center', width: 120 }}>
                  <CustomText style={{ textAlign: 'center', marginBottom: 24 }}>
                    Explore Perfect plan for you!
                  </CustomText>
                  <Button
                    isChildNonText
                    size="NORMAL"
                    style={{ width: 110, paddingVertical: 8, paddingHorizontal: 12 }}
                    onPress={() => {
                      navigation.navigate('Shop');
                    }}
                  >
                    <AntDesign name="shoppingcart" color={Colors.white} size={15} />
                    <CustomText
                      weight="600"
                      style={{
                        color: Colors.white,
                        fontWeight: '600',
                        fontSize: 12,
                        marginLeft: 4,
                      }}
                    >
                      Shop Now
                    </CustomText>
                  </Button>
                </View>
              </View>
            </View>
            <View
              style={{
                backgroundColor: Colors.white,
                borderRadius: 8,
                padding: 24,
                minWidth: 500,
                width: (width - 500) / 2,
              }}
            >
              <CustomText
                weight="600"
                style={{
                  fontWeight: '600',
                  fontSize: 10,
                  color: Colors.inputTextPlaceholderColor,
                  marginBottom: 8,
                }}
              >
                Account Balance
              </CustomText>
              <View
                style={{
                  borderColor: Colors.HorizonalLineDefaultColor,
                  borderWidth: 1,
                  marginHorizontal: 20,
                  borderRadius: 8,
                  padding: 16,
                }}
              >
                <CustomText
                  weight="600"
                  style={{
                    fontSize: 10,
                    fontWeight: '600',
                    marginBottom: 12,
                    color: Colors.inputTextPlaceholderColor,
                  }}
                >
                  Account Balance
                </CustomText>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <CustomText
                    weight="600"
                    style={{
                      fontSize: 24,
                      fontWeight: '600',
                    }}
                  >
                    $0.00
                  </CustomText>
                  <Button
                    isChildNonText
                    size="NORMAL"
                    style={{ width: 110, paddingVertical: 8, paddingHorizontal: 12 }}
                  >
                    <MaterialCommunityIcons name="plus" color={Colors.white} size={15} />
                    <CustomText
                      weight="600"
                      style={{
                        color: Colors.white,
                        fontWeight: '600',
                        fontSize: 12,
                        marginLeft: 4,
                      }}
                    >
                      Pay a Bill
                    </CustomText>
                  </Button>
                </View>
              </View>
            </View>
            <View
              style={{
                backgroundColor: Colors.white,
                borderRadius: 8,
                padding: 24,
                minWidth: 500,
                width: (width - 500) / 2,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginHorizontal: 20,
                  marginBottom: 16,
                }}
              >
                <SectionHeading>My Plans</SectionHeading>
                <Pressable
                  style={{}}
                  onPress={() => {
                    navigation.navigate('Plans');
                  }}
                >
                  <CustomText
                    weight="600"
                    style={{ color: Colors.ActionLinkColor, fontWeight: '600', fontSize: 12 }}
                  >
                    See All Plans
                  </CustomText>
                </Pressable>
              </View>

              <NoPlan />
            </View>
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default HomePageGuestDesktopView;
