import { FontAwesome } from '@expo/vector-icons';
import * as Font from 'expo-font';
import { useEffect, useState } from 'react';

import PoppinsBlack from '../assets/fonts/poppins/Poppins-Black.ttf';
import PoppinsBlackItalic from '../assets/fonts/poppins/Poppins-BlackItalic.ttf';
import PoppinsBold from '../assets/fonts/poppins/Poppins-Bold.ttf';
import PoppinsBoldItalic from '../assets/fonts/poppins/Poppins-BoldItalic.ttf';
import PoppinsExtraBold from '../assets/fonts/poppins/Poppins-ExtraBold.ttf';
import PoppinsExtraBoldItalic from '../assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf';
import PoppinsExtraLight from '../assets/fonts/poppins/Poppins-ExtraLight.ttf';
import PoppinsExtraLightItalic from '../assets/fonts/poppins/Poppins-ExtraLightItalic.ttf';
import PoppinsItalic from '../assets/fonts/poppins/Poppins-Italic.ttf';
import PoppinsLight from '../assets/fonts/poppins/Poppins-Light.ttf';
import PoppinsLightItalic from '../assets/fonts/poppins/Poppins-LightItalic.ttf';
import PoppinsMedium from '../assets/fonts/poppins/Poppins-Medium.ttf';
import PoppinsMediumItalic from '../assets/fonts/poppins/Poppins-MediumItalic.ttf';
import PoppinsRegular from '../assets/fonts/poppins/Poppins-Regular.ttf';
import PoppinsSemiBold from '../assets/fonts/poppins/Poppins-SemiBold.ttf';
import PoppinsSemiBoldItalic from '../assets/fonts/poppins/Poppins-SemiBoldItalic.ttf';
import PoppinsThin from '../assets/fonts/poppins/Poppins-Thin.ttf';
import PoppinsThinItalic from '../assets/fonts/poppins/Poppins-ThinItalic.ttf';

// SplashScreen.preventAutoHideAsync();

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  const [fontsLoaded] = Font.useFonts({
    ...FontAwesome.font,
    // Poppins
    // 100
    'Poppins-Thin': PoppinsThin, // 100
    'Poppins-ThinItalic': PoppinsThinItalic, // 100
    // 200
    'Poppins-ExtraLight': PoppinsExtraLight, // 200
    'Poppins-ExtraLightItalic': PoppinsExtraLightItalic, // 200
    // 300
    'Poppins-Light': PoppinsLight, // 300
    'Poppins-LightItalic': PoppinsLightItalic, // 300
    // 400
    'Poppins-Regular': PoppinsRegular, // 400
    'Poppins-Italic': PoppinsItalic, // 400
    // 500
    'Poppins-Medium': PoppinsMedium, // 500
    'Poppins-MediumItalic': PoppinsMediumItalic, // 500
    // 600
    'Poppins-SemiBold': PoppinsSemiBold, // 600
    'Poppins-SemiBoldItalic': PoppinsSemiBoldItalic, // 600
    // 700
    'Poppins-Bold': PoppinsBold, // 700
    'Poppins-BoldItalic': PoppinsBoldItalic, // 700
    // 800
    'Poppins-ExtraBold': PoppinsExtraBold, // 800
    'Poppins-ExtraBoldItalic': PoppinsExtraBoldItalic, // 800
    // 900
    'Poppins-Black': PoppinsBlack, // 900
    'Poppins-BlackItalic': PoppinsBlackItalic, // 900
  });

  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      if (fontsLoaded) {
        setLoadingComplete(true);
      }

      if (!fontsLoaded) {
        return null;
      }
    }

    loadResourcesAndDataAsync();
  }, [fontsLoaded]);

  return isLoadingComplete;
}
