import { useState } from 'react';
import { View, SafeAreaView, Image, Pressable, Linking } from 'react-native';
import { useSelector } from 'react-redux';

import CanadaFlagBanner from '../../assets/images/canada-flag.png';
import USAFlagBanner from '../../assets/images/usa-flag.png';
import { AppDrawer } from '../../components/AppDrawer';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { RootState } from '../../store';
import { RootStackScreenProps } from '../../types';
import { isWebsite } from '../../utilities/functions';

export default function OnboardingScreen({ navigation }: RootStackScreenProps<'Onboarding'>) {
  const { firstName } = useSelector((state: RootState) => state.user);

  const [showModel, setShowModel] = useState<boolean>(false);

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <View
        style={{
          backgroundColor: Colors.white,
          padding: 20,
          flex: 1,
        }}
      >
        <View
          style={[
            {
              justifyContent: 'space-between',
              flex: 1,
            },
            isWebsite() ? { maxWidth: 400, width: '100%', marginHorizontal: 'auto' } : {},
          ]}
        >
          <View>
            <CustomText
              weight="600"
              style={{ fontSize: 24, textAlign: 'center', color: Colors.canadaButtonColor }}
            >
              Welcome, {firstName}!
            </CustomText>
            <CustomText
              weight="500"
              style={{ fontSize: 14, textAlign: 'center', color: Colors.TextBlack }}
            >
              Select your Destination!
            </CustomText>

            <View style={{ marginTop: 20 }}>
              <Image
                source={CanadaFlagBanner}
                style={{
                  width: '100%',
                  height: 160,
                  marginBottom: 16,
                }}
                resizeMode="cover"
              />
              <Pressable
                onPress={() => {
                  setShowModel(true);
                }}
              >
                <Image
                  source={USAFlagBanner}
                  style={{
                    width: '100%',
                    height: 160,
                    marginBottom: 16,
                  }}
                  resizeMode="cover"
                />
              </Pressable>
            </View>
          </View>
          <Button
            style={{ marginTop: 32 }}
            onPress={() => {
              navigation.navigate('Plans');
            }}
            textStyle={{ fontSize: 14 }}
          >
            Continue
          </Button>
        </View>
      </View>
      <AppDrawer
        onClose={() => {
          setShowModel(false);
        }}
        height={500}
        visible={showModel}
        showBackDrop={true}
      >
        <CustomText
          weight="500"
          style={{ fontSize: 24, marginBottom: 16, textAlign: isWebsite() ? 'center' : 'auto' }}
        >
          Visit PhoneBox website to Purchage US Plan
        </CustomText>
        <HorizontalLine thinkness={1} />
        <CustomText
          weight="400"
          style={{
            fontSize: 14,
            textAlign: isWebsite() ? 'center' : 'justify',
            marginVertical: 16,
          }}
        >
          Sorry, we are currently unable to proceed with the US plan activation here. Please visit
          the PhoneBox website to proceed with activation.
        </CustomText>
        <Button
          onPress={() => {
            setShowModel(false);
            Linking.openURL('https://linktr.ee/phoneboxusa');
          }}
          style={{
            marginHorizontal: 'auto',
          }}
          textStyle={{ fontSize: 14 }}
        >
          Visit Website
        </Button>
        <Button
          style={{ marginTop: 16, marginHorizontal: 'auto' }}
          color={Colors.backgroundGrey}
          textColor={Colors.inputTextPlaceholderColor}
          onPress={() => {
            setShowModel(false);
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Choose Canadian Plan
        </Button>
      </AppDrawer>
    </SafeAreaView>
  );
}
