import { useEffect, useState } from 'react';
import { SafeAreaView, ScrollView, View } from 'react-native';

import { AppDrawer } from '../../components/AppDrawer';
import Button from '../../components/Button';
import InputWithSelectOption from '../../components/UI/Form/InputForSelectOption';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { carriers, invalidPortCarriers } from '../../constants/constants';
import { PortCarrier } from '../../types/types';
import { isWebsite } from '../../utilities/functions';
import { numbersOnly, validate } from '../../utilities/utilities';

export type PortInInfo = {
  currentCarrier: PortCarrier | null;
  accountNumber: string;
  phoneNumber: string;
};

type Props = {
  portInInfo: PortInInfo;
  onNext(portInInfo: PortInInfo | null): void;
};

export default function PortInView(props: Props) {
  const [currentCarrier, setCurrentCarrier] = useState<PortCarrier | null>(
    props.portInInfo.currentCarrier
  );
  const [accountNumber, setAccountNumber] = useState<string>(props.portInInfo.accountNumber);
  const [phoneNumber, setPhoneNumber] = useState<string>(props.portInInfo.phoneNumber);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [showCarrierModel, setShowCarrierModel] = useState<boolean>(false);

  useEffect(() => {
    if (!currentCarrier) {
      return setIsValid(false);
    } else {
      if (invalidPortCarriers.includes(currentCarrier)) {
        setError(`Unfortunately, we can not port number from ${currentCarrier}.`);
      } else {
        setError(null);
      }
    }
    if (!accountNumber) {
      return setIsValid(false);
    }
    if (!validate('phone-number', phoneNumber)) {
      return setIsValid(false);
    }
    return setIsValid(true);
  }, [currentCarrier, accountNumber, phoneNumber]);

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          padding: 20,
          backgroundColor: Colors.white,
          flex: 1,
          height: '100%',
        }}
      >
        <InputWithSelectOption
          title="Current Carrier"
          value={currentCarrier ?? 'Select One'}
          onPress={() => {
            setShowCarrierModel(true);
          }}
        />
        {error ? (
          <View style={{ marginVertical: 14 }}>
            <CustomText
              weight="500"
              style={{ fontSize: 14, color: Colors.errorRed, textAlign: 'center' }}
            >
              {error}
            </CustomText>
          </View>
        ) : null}
        <InputWithTitle
          title={currentCarrier === 'Freedom' ? 'IMEI Number' : 'Account Number'}
          keyboardType="numeric"
          inputValue={accountNumber}
          onInputChange={(val: string) => setAccountNumber(numbersOnly(val))}
          moreInfoIconText={
            currentCarrier === 'Freedom'
              ? "Please provide the IMEI (15 digits) to port in your number. Dial *#06# to get your phone's IMEI number or find it in your phone's settings."
              : undefined
          }
        />
        <InputWithTitle
          title="Phone Number"
          keyboardType="numeric"
          inputValue={phoneNumber}
          onInputChange={(val: string) => setPhoneNumber(numbersOnly(val))}
        />
      </ScrollView>
      <AppDrawer
        onClose={() => {
          setShowCarrierModel(false);
        }}
        height={500}
        visible={showCarrierModel}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24, textAlign: 'center', marginBottom: 32 }}>
          Select Carrier
        </CustomText>
        <ScrollView showsVerticalScrollIndicator={false}>
          {carriers.map((carrier) => (
            <CustomText
              onPress={() => {
                setCurrentCarrier(carrier);
                setShowCarrierModel(false);
              }}
              style={{
                fontSize: 15,
                textAlign: 'center',
                marginBottom: 8,
              }}
              key={carrier}
            >
              {carrier}
            </CustomText>
          ))}
        </ScrollView>
      </AppDrawer>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          style={{ marginTop: 32 }}
          disabled={!isValid}
          onPress={() => {
            props.onNext({
              currentCarrier,
              accountNumber,
              phoneNumber,
            });
          }}
          textStyle={{ fontSize: 14 }}
        >
          Submit Port In
        </Button>
        <Button
          style={{ marginTop: 16 }}
          color={Colors.backgroundGrey}
          textColor={Colors.inputTextPlaceholderColor}
          onPress={() => {
            props.onNext(null);
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Continue Without Port In
        </Button>
      </View>
    </SafeAreaView>
  );
}
