import {
  Pressable,
  View,
  GestureResponderEvent,
  ActivityIndicator,
  ViewStyle,
  TextStyle,
} from 'react-native';

import { AppGap } from './AppGap';
import { Colors } from '../constants/Colors';
import CustomText from './utils/CustomText';
import { isWebsite } from '../utilities/functions';

type ButtonProps = {
  children: React.ReactNode;
  isChildNonText?: boolean;
  loadingStatus?: boolean;
  disabled?: boolean;
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
  size?: 'FULL' | 'NORMAL';
  color?: string;
  textColor?: string;
  style?: ViewStyle;
  textStyle?: TextStyle;
};

const Button = ({
  size = 'NORMAL',
  color = Colors.canadaButtonColor,
  textColor = Colors.white,
  ...props
}: ButtonProps) => {
  return (
    <Pressable
      disabled={props.disabled}
      onPress={props.onPress}
      style={[
        {
          padding: 15,
          backgroundColor: props.disabled ? Colors.grey : color,
          width: size === 'FULL' ? '100%' : 'auto',
          borderRadius: 8,
          alignItems: 'center',
        },
        isWebsite()
          ? {
              maxWidth: 400,
              width: '100%',
            }
          : null,
        { ...props.style },
      ]}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {props.loadingStatus && (
          <>
            <ActivityIndicator color={Colors.white} size={'small'}></ActivityIndicator>
            <AppGap size={10}></AppGap>
          </>
        )}
        {props.isChildNonText ||
        (typeof props.children !== 'string' && typeof props.children !== 'number') ? (
          props.children
        ) : (
          // ToDo: Fix this, even though I am setting text here, I am still sending children as <Text></Text >
          // ! Update 1: May be fixed!
          <CustomText
            weight={props.textStyle?.fontWeight ?? '600'}
            style={{ color: textColor, fontWeight: '600', fontSize: 14, ...props.textStyle }}
          >
            {props.children}
          </CustomText>
        )}
      </View>
    </Pressable>
  );
};

export default Button;
