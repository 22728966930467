import { AntDesign, Entypo } from '@expo/vector-icons';
import { Alert, Pressable, View, ViewStyle } from 'react-native';

import { Colors } from '../../../constants/Colors';
import { isWebsite } from '../../../utilities/functions';
import CustomText from '../../utils/CustomText';

const InputWithSelectOption = ({
  onPress,
  title,
  value,
  icon = <Entypo name="chevron-down" size={14} color={Colors.inputTextPlaceholderColor} />,
  containerStyle,
  moreInfoIconText,
}: {
  title: string;
  onPress(): void;
  value: string;
  icon?: React.ReactElement;
  containerStyle?: ViewStyle;
  moreInfoIconText?: string;
}) => {
  return (
    <View
      style={[
        { marginTop: 16 },
        isWebsite()
          ? {
              maxWidth: 400,
              marginHorizontal: 'auto',
              width: '100%',
            }
          : null,
        containerStyle,
      ]}
    >
      <View style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
        <CustomText style={{ fontSize: 12, color: Colors.greyColor }} weight="500">
          {title}
        </CustomText>
        {moreInfoIconText ? (
          <AntDesign
            name="questioncircle"
            size={18}
            color={Colors.ActionLinkColor}
            onPress={() => {
              if (isWebsite()) {
                alert(moreInfoIconText);
              } else {
                Alert.alert('Info:', moreInfoIconText);
              }
            }}
          />
        ) : null}
      </View>
      <Pressable
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 10,
          paddingVertical: 8,
          borderRadius: 8,
          height: 48,
          paddingHorizontal: 16,
          marginTop: 6,
          backgroundColor: Colors.backgroundGrey,
        }}
        onPress={onPress}
      >
        <CustomText style={{ fontSize: 12, color: Colors.inputTextPlaceholderColor }} weight="500">
          {value}
        </CustomText>
        {icon}
      </Pressable>
    </View>
  );
};

export default InputWithSelectOption;
