import { Alert, Image, Pressable, ScrollView } from 'react-native';

import PortInImage from '../../assets/images/port-in.png';
import PortOutImage from '../../assets/images/port-out.png';
import HorizontalLine from '../../components/HorizontalLine';
import InfoDescriptionToggle from '../../components/UI/InfoDescriptionToggle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { isWebsite } from '../../utilities/functions';

// TODO: Remove no empty pattern disable eslint after finishing port code
// eslint-disable-next-line no-empty-pattern
const InitialView = ({}: { onPress(option: string): void }) => {
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={[
        {
          padding: 20,
          backgroundColor: Colors.white,
        },
        isWebsite()
          ? {
              maxWidth: 400,
              marginHorizontal: 'auto',
              width: '100%',
            }
          : null,
      ]}
    >
      <CustomText weight="600" style={{ fontSize: 14, color: Colors.TextBlack, marginBottom: 24 }}>
        Which Service you would like to proceed?
      </CustomText>
      <Pressable
        onPress={() => {
          if (isWebsite()) {
            alert(
              'Porting is not available in the app right now. Please contact our customer service team to complete the porting process.'
            );
          } else {
            Alert.alert(
              'Porting Unavailable',
              'Porting is not available in the app right now. Please contact our customer service team to complete the porting process.'
            );
          }
          // onPress('PORT_IN');
        }}
      >
        <Image
          source={PortInImage}
          style={{
            width: 335,
            height: 160,
            marginBottom: 16,
          }}
          resizeMode="cover"
        />
      </Pressable>
      <Pressable
        onPress={() => {
          if (isWebsite()) {
            alert(
              'Porting is not available in the app right now. Please contact our customer service team to complete the porting process.'
            );
          } else {
            Alert.alert(
              'Porting Unavailable',
              'Porting is not available in the app right now. Please contact our customer service team to complete the porting process.'
            );
          }
          // onPress('PORT_OUT');
        }}
      >
        <Image
          source={PortOutImage}
          style={{
            width: 335,
            height: 62,
            marginBottom: 16,
          }}
          resizeMode="cover"
        />
      </Pressable>
      <HorizontalLine
        thinkness={1}
        style={{
          marginBottom: 24,
          marginTop: 32,
        }}
      />
      <InfoDescriptionToggle
        style={{
          marginBottom: 20,
        }}
        height={208}
        title="What is Port in?"
        desc="Porting in allows you to transfer your phone number from another provider to PhoneBox. To keep your current number, ensure your line remains active with your previous provider until the process is complete. You'll need your account number, IMEI, or PIN from your previous provider. Note: Only Canadian phone numbers are eligible."
      />
      <InfoDescriptionToggle
        height={208}
        title="What is Port out?"
        desc="Porting out allows you to transfer your phone number from PhoneBox to another provider. To keep your current number, ensure your line remains active with PhoneBox until the process is complete. You may contact us to request your account number, IMEI, or PIN. Note: Only Canadian phone numbers are eligible."
      />
    </ScrollView>
  );
};

export default InitialView;
