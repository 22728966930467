import { Image, Platform, StatusBar, View } from 'react-native';
import { useSelector } from 'react-redux';

import ScrollLeft from '../../../assets/icons/scroll-left.png';
import ScrollRight from '../../../assets/icons/scroll-right.png';
import { Colors } from '../../../constants/Colors';
import { RootState } from '../../../store';
import DevLog from '../../../utilities/debug-error';
import { isWebsite } from '../../../utilities/functions';
import { getProfileImage } from '../../../utilities/utilities';
import { AppGap } from '../../AppGap';
import AppGradientContainer from '../../AppGradientContainer';
import CustomText from '../../utils/CustomText';

const NoPlanHeader = () => {
  const { activeUserFirstName } = useSelector((state: RootState) => state.user);
  DevLog.log('Test: ', activeUserFirstName);

  return (
    <AppGradientContainer
      autoHeight
      colorLeft={Colors.gradientDarkBlue}
      colorRight={Colors.gradientLightBlue}
      direction="LEFT-RIGHT"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        height: isWebsite() ? 'auto' : StatusBar.currentHeight ? StatusBar.currentHeight + 80 : 120,
        paddingTop: Platform.select({ default: StatusBar.currentHeight, ios: 48 }),
        // shadowOffset: { width: 0, height: -6 }, // vertical offset
        // shadowOpacity: 0.5, // adjust as needed
        // shadowRadius: 10, // blur radius
        // elevation: 5, // This also applies shadow for Android
        overflow: 'visible',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 20,
        }}
      >
        <View style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
          <Image
            source={getProfileImage(-1)}
            style={{ width: 40, height: 40 }}
            resizeMode="cover"
          />
          <View style={{ marginHorizontal: 8 }}>
            <CustomText
              weight="500"
              style={{ fontSize: 14, fontWeight: '500', color: Colors.white }}
            >
              Welcome!
            </CustomText>
            <AppGap direction="vertical" size={4} />
            <CustomText style={{ fontSize: 14, fontWeight: '400', color: Colors.white }}>
              {activeUserFirstName}
            </CustomText>
          </View>
        </View>

        {/* <View style={{ flexDirection: 'row' }}> */}
        {/* <Pressable style={{ position: 'relative' }}>
            <View
              style={{
                position: 'absolute',
                width: 8,
                height: 8,
                backgroundColor: Colors.red,
                borderRadius: 4,
                right: -2,
                top: 2,
                zIndex: 1,
              }}
            ></View>
            <AntDesign name="shoppingcart" color={Colors.white} size={25} />
          </Pressable> */}
        {/* <AppGap direction="horizontal" size={6} /> */}
        {/* <Pressable style={{ position: 'relative' }}>
            <View
              style={{
                position: 'absolute',
                width: 8,
                height: 8,
                backgroundColor: Colors.red,
                borderRadius: 4,
                right: 2,
                top: 2,
                zIndex: 1,
              }}
            ></View>
            <MaterialCommunityIcons name="bell-outline" color={Colors.white} size={25} />
          </Pressable> */}
        {/* </View> */}
      </View>
      <Image
        source={ScrollLeft}
        style={{
          width: 15,
          height: 15,
          position: 'absolute',
          bottom: -15,
          left: 0,
        }}
      />
      <Image
        source={ScrollRight}
        style={{
          width: 15,
          height: 15,
          position: 'absolute',
          bottom: -15,
          right: 0,
        }}
      />
    </AppGradientContainer>
  );
};

export default NoPlanHeader;
