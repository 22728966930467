import { AntDesign } from '@expo/vector-icons';
import { Moment } from 'moment';
import { View } from 'react-native';

import { Colors } from '../../constants/Colors';
import CustomText from '../utils/CustomText';

const PaymentError = ({
  currentDate,
  paymentError,
}: {
  currentDate: Moment;
  paymentError: false | '< 3 Days Left' | 'Due Today' | 'Past Due Date' | 'Pay Before 9';
}) => {
  const _3DaysLeft = (
    <CustomText
      weight="500"
      style={{
        marginLeft: 8,
        marginTop: 2,
        fontWeight: '500',
        fontSize: 14,
        color: Colors.inputTextPlaceholderColor,
      }}
    >
      <CustomText style={{ color: Colors.errorRed }}>{26 - currentDate.date()} days </CustomText>
      left to pay your bill
    </CustomText>
  );

  const _dueToday = (
    <View style={{ marginLeft: 8, marginTop: 1 }}>
      <CustomText
        weight="500"
        style={{ fontWeight: '500', fontSize: 14, color: Colors.inputTextPlaceholderColor }}
      >
        Today is the <CustomText style={{ color: Colors.errorRed }}>due date.</CustomText>
      </CustomText>
      <CustomText style={{ fontSize: 12, color: Colors.inputTextPlaceholderColor, marginTop: 2 }}>
        Please complete your payment
      </CustomText>
    </View>
  );

  const _pastDueDate = (
    <View style={{ marginLeft: 8, marginTop: 2 }}>
      <CustomText weight="500" style={{ color: Colors.errorRed, fontWeight: '500', fontSize: 14 }}>
        The Payment due date has passed.
      </CustomText>
      <CustomText
        style={{
          fontSize: 12,
          color: Colors.inputTextPlaceholderColor,
          width: 250,
          marginTop: 2,
        }}
      >
        Please note that your number may be suspended in next cycle.
      </CustomText>
    </View>
  );

  const _payBefore9 = (
    <View style={{ marginLeft: 8, marginTop: 2 }}>
      <CustomText weight="500" style={{ color: Colors.errorRed, fontWeight: '500', fontSize: 14 }}>
        The Payment due date has passed.
      </CustomText>
      <CustomText
        style={{
          fontSize: 12,
          color: Colors.inputTextPlaceholderColor,
          width: 250,
          marginTop: 2,
        }}
      >
        Please pay before 9th, to save your phone&nbsp;number
      </CustomText>
    </View>
  );

  return (
    <View style={{ flexDirection: 'row', marginBottom: 8 }}>
      <AntDesign name="exclamationcircle" size={20} color={Colors.errorRed} />
      {paymentError === '< 3 Days Left'
        ? _3DaysLeft
        : paymentError === 'Due Today'
          ? _dueToday
          : paymentError === 'Past Due Date'
            ? _pastDueDate
            : paymentError === 'Pay Before 9'
              ? _payBefore9
              : null}
    </View>
  );
};

export default PaymentError;
