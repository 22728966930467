/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { Platform } from 'react-native';

export default class DevLog {
  private static isDev() {
    return (Platform.OS === 'web' && /wonderful-coast-/.test(window.location.href)) || __DEV__;
  }

  public static log(message: any, ...optionalParams: any[]) {
    if (DevLog.isDev()) {
      console.log(message, ...optionalParams);
    }
  }
  public static error(message: any, ...optionalParams: any[]) {
    if (DevLog.isDev()) {
      console.error(message, ...optionalParams);
    }
  }
  public static warn(message: any, ...optionalParams: any[]) {
    if (DevLog.isDev()) {
      console.warn(message, ...optionalParams);
    }
  }
  public static info(message: any, ...optionalParams: any[]) {
    if (DevLog.isDev()) {
      console.info(message, ...optionalParams);
    }
  }
  public static trace(message: any, ...optionalParams: any[]) {
    if (DevLog.isDev()) {
      console.trace(message, ...optionalParams);
    }
  }
}
