import { useEffect, useState } from 'react';
import { Alert, Platform, SafeAreaView, View, Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { AppGap } from '../../components/AppGap';
import AppGradientContainer from '../../components/AppGradientContainer';
import AppleGoogleSSO from '../../components/AppleGoogleSSO';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import Button from '../../components/Button';
import CountryToggle from '../../components/CountryToggle';
import HorizontalLine from '../../components/HorizontalLine';
import TextInput from '../../components/TextInput';
import AuthBackground from '../../components/utils/AuthBackgroud';
import CustomText from '../../components/utils/CustomText';
import ScrollViewWithSafeAreaContainer from '../../components/utils/ScrollViewWithSafeAreaContainer';
import { Colors } from '../../constants/Colors';
import CommonStyles from '../../constants/CommonStyles';
import { authService } from '../../services/SSO/AuthService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import { RootStackScreenProps } from '../../types';
import { DevMessage } from '../../types/enums/DevMessage';
import { StepType } from '../../types/enums/StepType';
import { SystemCodes } from '../../types/enums/SystemCodes';
import DevLog from '../../utilities/debug-error';
import { isWebsite, validateToken } from '../../utilities/functions';

export default function LogInScreen({ navigation, route }: RootStackScreenProps<'LogIn'>) {
  const { isLoading } = useSelector((state: RootState) => state.app);

  const [emailText, setEmailText] = useState<string>('');
  const [emailTextError, setEmailTextError] = useState<string>('');
  const [passwordText, setPasswordText] = useState<string>('');
  const [passwordTextError, setPasswordTextError] = useState<string>('');
  // const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [logInError, setLogInError] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    // this only happens in redirect flow from Join Success  page
    if (route.params?.email && route.params?.password) {
      setEmailText(route.params.email);
      setPasswordText(route.params.password);
    }
  }, []);

  // const saveCredentials = async (emailText: string) => {
  //   await AsyncStorage.setItem(CRED_EMAIL, emailText);
  //   await tokenService.setRememberMe();
  // };

  const onPressLogIn = async (emailText: string, passwordText: string) => {
    // if (rememberMe) {
    //   await saveCredentials(emailText);
    // }
    dispatch(setIsLoading(true));
    const loginAsync = async () => {
      const signInResponse = await authService.signIn({
        data: {
          username: emailText,
          password: passwordText,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (!signInResponse?.data?.success) {
        if (
          signInResponse?.data?.devMessage &&
          signInResponse.data.devMessage === DevMessage.ActivationPending
        ) {
          setLogInError(signInResponse.data.error.message);
          if (Platform.OS === 'web') {
            const isOkay = confirm('Press Okay to continue with verification.');
            if (isOkay) {
              navigation.navigate('Join', { email: emailText, step: StepType.Second });
            }
          } else {
            Alert.alert(
              'Need Verification',
              'Press Continue to continue with verification.',
              [
                {
                  text: 'Cancel',
                  onPress: () => DevLog.log('Cancel Pressed'),
                  style: 'cancel',
                },
                {
                  text: 'Continue',
                  onPress: () => {
                    navigation.navigate('Join', { email: emailText, step: StepType.Second });
                  },
                },
              ],
              { cancelable: false }
            );
          }
          return;
        }
        DevLog.error(signInResponse);
        setLogInError('Incorrect username or password.');
        return;
      }

      if (signInResponse && signInResponse.data && signInResponse.data.success) {
        setLogInError('');
        await validateToken(signInResponse.data.data, dispatch, navigation);
      }
    };

    loginAsync()
      .catch((err) => DevLog.error(err))
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  return (
    <ScrollViewWithSafeAreaContainer>
      <AppGradientContainer
        colorLeft={Colors.gradientDarkBlue}
        colorRight={Colors.gradientLightBlue}
        direction="LEFT-RIGHT"
      >
        <SafeAreaView style={{ display: 'flex', height: '100%' }}>
          <AuthBackground />
          <AppGap direction="vertical" size={isWebsite() ? 0 : 530} />
          <BottomWhiteSheet
            style={{ paddingLeft: 30, paddingRight: 30, ...CommonStyles.webBottomSheet }}
          >
            {/* <View
              style={{
                position: 'absolute',
                bottom: Platform.select({ android: 560, default: 540, ios: 580, web: undefined }),
                top: Platform.select({ web: -100, default: undefined }),
                zIndex: 8,
              }}
            >
              <Image
                source={require('../../assets/illustrations/Blue9.png')}
                style={{
                  width: 120,
                  height: Platform.select({ android: 180, default: 180, ios: 140 }),
                  display: isWebsite() ? 'none' : undefined,
                }}
                resizeMode="contain"
              />
            </View> */}
            <View style={{ width: '100%' }}>
              <View
                style={[
                  {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: 50,
                    marginBottom: 20,
                  },
                  isWebsite()
                    ? {
                        maxWidth: 400,
                        marginHorizontal: 'auto',
                        width: '100%',
                      }
                    : null,
                ]}
              >
                <CustomText weight="500" style={{ fontSize: 24, fontWeight: '500' }}>
                  Welcome Back!
                </CustomText>
                <CountryToggle />
              </View>
              <HorizontalLine style={{ marginBottom: 20 }} />
              <TextInput
                placeholder="Enter your email or username"
                value={emailText}
                onChangeText={(email: string) => {
                  setEmailText(email);
                  if (email) {
                    setEmailTextError('');
                  }
                }}
                keyboardType="email-address"
                error={{
                  error: emailTextError,
                }}
              />
              <TextInput
                placeholder="Password"
                eyeTopMargin={29}
                value={passwordText}
                onChangeText={(password: string) => {
                  setPasswordText(password);
                  if (password) {
                    setPasswordTextError('');
                  }
                }}
                error={{
                  error: passwordTextError,
                }}
                secureTextEntry={true}
                style={{ marginTop: 15 }}
              />
              <View
                style={[
                  {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: 15,
                  },
                  isWebsite()
                    ? {
                        maxWidth: 400,
                        marginHorizontal: 'auto',
                        width: '100%',
                      }
                    : null,
                ]}
              >
                {/* <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    value={rememberMe}
                    onValueChange={setRememberMe}
                    color={rememberMe ? Colors.canadaButtonColor : undefined}
                  />
                  <CustomText style={{ marginLeft: 5, marginTop: 4 }}>Remember me</CustomText>
                </View> */}
                <Pressable onPress={() => navigation.navigate('RecoverPassword')}>
                  <CustomText
                    weight="500"
                    style={{ color: Colors.canadaButtonColor, fontWeight: '500' }}
                  >
                    Forgot password?
                  </CustomText>
                </Pressable>
              </View>
              <View
                style={
                  isWebsite()
                    ? {
                        marginHorizontal: 'auto',
                        maxWidth: 400,
                        width: '100%',
                      }
                    : {}
                }
              >
                <Button
                  style={{ marginTop: 25 }}
                  onPress={() => {
                    let isError = false;
                    if (emailText === '') {
                      setEmailTextError('Email/Username can not be empty.');
                      isError = true;
                      // } else if (!validate('email', emailText)) {
                      //   setEmailTextError('Please Enter valid email address');
                      //   isError = true;
                    }
                    if (passwordText === '') {
                      setPasswordTextError('Password can not be empty.');
                      isError = true;
                    }
                    if (emailTextError || passwordTextError) {
                      isError = true;
                    }
                    if (isError) {
                      return;
                    }
                    onPressLogIn(emailText, passwordText);
                  }}
                  disabled={isLoading}
                  textStyle={{ fontSize: 14 }}
                >
                  Sign in
                </Button>
                {logInError !== '' && (
                  <CustomText
                    style={{
                      color: Colors.errorRed,
                      marginTop: 4,
                      textAlign: 'center',
                    }}
                  >
                    {logInError}
                  </CustomText>
                )}
                <Button
                  style={{ marginTop: 10 }}
                  color={Colors.backgroundGrey}
                  textColor={Colors.black}
                  onPress={() => navigation.replace('Join')}
                >
                  <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
                    Sign Up
                  </CustomText>
                </Button>
              </View>
              <CustomText
                style={{
                  textAlign: 'center',
                  color: Colors.inputTextPlaceholderColor,
                  marginTop: 10,
                  fontSize: 14,
                }}
              >
                or continue with
              </CustomText>
              <AppleGoogleSSO
                setIsLoadingAPI={(status) => {
                  dispatch(setIsLoading(status));
                }}
                propsKey={'sign-in'}
              />
              <View
                style={
                  isWebsite()
                    ? {
                        marginHorizontal: 'auto',
                        maxWidth: 400,
                        width: '100%',
                      }
                    : {}
                }
              >
                <Button
                  size={'FULL'}
                  color={Colors.noColor}
                  textColor={Colors.greyColor}
                  style={{
                    padding: 5,
                    marginTop: 20,
                    marginBottom: 15,
                    width: 'auto',
                  }}
                  onPress={() => {
                    navigation.navigate('Root');
                  }}
                >
                  Continue as a guest
                </Button>
              </View>
            </View>
          </BottomWhiteSheet>
        </SafeAreaView>
      </AppGradientContainer>
    </ScrollViewWithSafeAreaContainer>
  );
}
