import { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { authService } from '../../services/SSO/AuthService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import { RootStackScreenProps } from '../../types';
import { StepType } from '../../types/enums/StepType';
import { SystemCodes } from '../../types/enums/SystemCodes';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';
import { validate } from '../../utilities/utilities';

export default function EditPasswordScreen({ navigation }: RootStackScreenProps<'EditPassword'>) {
  const dispatch = useDispatch();
  const { authorizedBy } = useSelector((state: RootState) => state.user);

  const [step, setStep] = useState<StepType>(StepType.First);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newConfirmPassword, setNewConfirmPassword] = useState('');

  const [error, setError] = useState<null | string>(null);
  const [passwordMatched, setPasswordMatched] = useState<boolean>(false);

  useEffect(() => {
    setError(null);
    if (validate('password', newPassword) && validate('password', oldPassword)) {
      setPasswordMatched(newPassword === newConfirmPassword);
    }
  }, [newPassword, newConfirmPassword]);

  const changePasswordRequest = async () => {
    if (oldPassword === newPassword) {
      setError('Your new password cannot be the same as your old password.');
      return;
    }
    dispatch(setIsLoading(true));

    try {
      const updatePasswordResponse = await authService.updatePassword({
        data: {
          originalPassword: oldPassword,
          newPassword: newPassword,
          newConfirmPassword: newConfirmPassword,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (!updatePasswordResponse || !updatePasswordResponse.data?.success) {
        setError('Sorry, something went wrong.');
        DevLog.log('Something went wrong with sendUpdateEmailCode: ', updatePasswordResponse);
      }

      if (updatePasswordResponse?.data?.success) {
        DevLog.log(updatePasswordResponse.data);
        setError('');
        setStep(StepType.Success);
      }
    } catch (err) {
      DevLog.error(err);
      setError('Sorry, something went wrong.');
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  return (
    <View
      style={{
        flex: 1,
        height: '100%',
        backgroundColor: Colors.white,
        padding: 20,
      }}
    >
      {step === StepType.First ? (
        <>
          {authorizedBy === 'PhoneBox' ? (
            <InputWithTitle
              title="Old Password"
              inputValue={oldPassword}
              onInputChange={setOldPassword}
              secureInput={true}
            />
          ) : null}
          <InputWithTitle
            title="New Password"
            inputValue={newPassword}
            onInputChange={setNewPassword}
            secureInput={true}
          />
          <InputWithTitle
            title="Confirm New Password"
            inputValue={newConfirmPassword}
            onInputChange={setNewConfirmPassword}
            secureInput={true}
          />
          <View>
            <CustomText
              weight="300"
              style={{
                fontSize: 12,
                textAlign: 'center',
                color: Colors.errorRed,
                marginVertical: 16,
              }}
            >
              New Password must contain minimum 8 charcters, at least one number, special character
              and capital letter
            </CustomText>
          </View>
        </>
      ) : (
        <View>
          <CustomText
            weight="700"
            style={{ fontSize: 32, textAlign: 'center', color: Colors.greyColor }}
          >
            Your Password has been successfully changed.
          </CustomText>
        </View>
      )}
      {error ? (
        <View style={{ marginVertical: 14 }}>
          <CustomText
            weight="500"
            style={{ fontSize: 14, color: Colors.errorRed, textAlign: 'center' }}
          >
            {error}
          </CustomText>
        </View>
      ) : null}
      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          style={{ marginTop: 32 }}
          disabled={step === StepType.First ? !passwordMatched : false}
          onPress={() => {
            if (step === StepType.First) {
              changePasswordRequest();
            } else {
              navigation.goBack();
            }
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          {step === StepType.First ? 'Update Password' : 'Go Back'}
        </Button>
      </View>
    </View>
  );
}
