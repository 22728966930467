import { DimensionValue, View, ViewStyle } from 'react-native';

import { Colors } from '../constants/Colors';

type VerticalLineProps = {
  color?: string;
  thinkness?: number;
  height?: DimensionValue;
  style?: ViewStyle;
};

const VerticalLine = (props: VerticalLineProps) => {
  return (
    <View
      style={{
        width: props.thinkness ?? 3,
        height: props.height ?? '100%',
        borderRadius: props.thinkness ?? 3,
        backgroundColor: props.color ?? Colors.HorizonalLineDefaultColor,
        ...props.style,
      }}
    ></View>
  );
};

export default VerticalLine;
