import LottieView from 'lottie-react-native';
import { useEffect, useState } from 'react';
import React from 'react';
import { View, Image, SafeAreaView, Dimensions, Platform } from 'react-native';

import FireworkSVGJSON from '../../assets/illustrations/animated/Firework_3.json';
import IllustrationImage from '../../assets/illustrations/Blue14.png';
import { AppGap } from '../../components/AppGap';
import AppGradientContainer from '../../components/AppGradientContainer';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import AuthBackground from '../../components/utils/AuthBackgroud';
import CustomText from '../../components/utils/CustomText';
import ScrollViewWithSafeAreaContainer from '../../components/utils/ScrollViewWithSafeAreaContainer';
import { Colors } from '../../constants/Colors';
import CommonStyles from '../../constants/CommonStyles';
import { RootStackScreenProps } from '../../types';
import { isWebsite } from '../../utilities/functions';

const { height } = Dimensions.get('window');

export default function JoinSuccessScreen({
  navigation,
  route,
}: RootStackScreenProps<'JoinSuccess'>) {
  const [user, setUser] = useState<{ email: string; password: string }>({
    email: '',
    password: '',
  });

  useEffect(() => {
    // this only happens in redirect flow from sign up page
    const newUser = {
      email: route.params?.email ?? '',
      password: route.params?.password ?? '',
    };
    setUser(newUser);
  }, []);

  return (
    <ScrollViewWithSafeAreaContainer>
      <AppGradientContainer
        colorLeft={Colors.gradientDarkBlue}
        colorRight={Colors.gradientLightBlue}
        direction="LEFT-RIGHT"
      >
        <SafeAreaView style={{ display: 'flex', height: '100%' }}>
          <AuthBackground />
          <View
            style={{
              position: 'relative',
              marginTop: -160,
              marginLeft: 30,
              minHeight: isWebsite() ? 250 : 'auto',
              zIndex: 100,
              display: isWebsite() ? 'none' : undefined,
            }}
          >
            <Image
              source={IllustrationImage}
              style={{
                width: 300,
                height: 350,
                zIndex: 10,
                display: isWebsite() ? 'none' : undefined,
                top: 300,
              }}
              resizeMode="contain"
            />
            <View
              style={{
                width: 500,
                height: height / 2,
                position: 'absolute',
                top: Platform.select({ default: 200, web: 260 }),
                zIndex: 8,
                right: Platform.select({ default: 0, web: undefined }),
                left: Platform.select({ default: undefined, web: '33%' }),
              }}
            >
              <LottieView style={{ flex: 1 }} autoPlay loop={true} source={FireworkSVGJSON} />
            </View>
          </View>
          <BottomWhiteSheet
            style={{ paddingLeft: 30, paddingRight: 30, ...CommonStyles.webBottomSheet }}
          >
            <View
              style={[
                { width: '100%' },
                isWebsite()
                  ? {
                      maxWidth: 400,
                      width: '100%',
                      marginHorizontal: 'auto',
                    }
                  : null,
              ]}
            >
              <CustomText
                weight="500"
                style={{ fontSize: 24, fontWeight: '500', marginTop: 50, marginBottom: 10 }}
              >
                Welcome to PhoneBox!
              </CustomText>
              <View style={{ marginBottom: 20 }}>
                <CustomText style={{ fontSize: 12 }}>
                  You&apos;re verified and ready to start using the app!
                </CustomText>
              </View>
              <HorizontalLine style={{ marginBottom: 20 }} />

              <Button
                style={{ marginTop: 10 }}
                onPress={() => {
                  navigation.replace('LogIn', { email: user.email, password: user.password });
                }}
                textStyle={{ fontSize: 14 }}
              >
                Explore the app
              </Button>
              <AppGap direction="vertical" size={30} />
            </View>
          </BottomWhiteSheet>
        </SafeAreaView>
      </AppGradientContainer>
    </ScrollViewWithSafeAreaContainer>
  );
}
