import { Image, Linking, Pressable, SafeAreaView, ScrollView, StatusBar, View } from 'react-native';
import { useSelector } from 'react-redux';

import CanadaPlanBanner from '../../assets/images/canada-plans.png';
import USAPlanBanner from '../../assets/images/usa-plans.png';
import AppGradientContainer from '../../components/AppGradientContainer';
import PageHeader from '../../components/UI/Header/PageHeader';
import ExploreProductHero from '../../components/UI/Hero/ExploreProductHero';
import { Colors } from '../../constants/Colors';
import { RootState } from '../../store';
import { RootStackScreenProps } from '../../types';
import { getGradientInfo, isWebsite } from '../../utilities/functions';
import PlanView from '../PlansScreen/PlanView';

export default function ShopScreen({ navigation }: RootStackScreenProps<'Shop'>) {
  const accountProfileCA = useSelector((state: RootState) => state.selfServeCA.accountProfileCA);
  const accountInfoCA = useSelector((state: RootState) => state.selfServeCA.accountInfoCA);

  const gradientInfo = getGradientInfo(accountProfileCA, accountInfoCA);
  return isWebsite() ? (
    <PlanView />
  ) : (
    <SafeAreaView style={{ flex: 1, paddingTop: StatusBar.currentHeight }}>
      <PageHeader />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          backgroundColor: Colors.white,
        }}
      >
        <AppGradientContainer
          autoHeight
          colorStart={gradientInfo.start}
          colorEnd={gradientInfo.end}
          direction={gradientInfo.direction}
          style={{
            paddingBottom: 20,
            paddingTop: 80,
          }}
        >
          <ExploreProductHero />
        </AppGradientContainer>
        <View
          style={{
            width: '100%',
            marginTop: -20,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            backgroundColor: Colors.white,
            paddingHorizontal: 20,
            paddingVertical: 20,
            gap: 16,
          }}
        >
          <Pressable
            onPress={() => {
              navigation.navigate('Plans');
            }}
          >
            <Image
              source={CanadaPlanBanner}
              style={[
                { width: '100%', height: 130, borderRadius: 8 },
                isWebsite()
                  ? {
                      maxWidth: 400,
                      marginHorizontal: 'auto',
                      width: '100%',
                    }
                  : null,
              ]}
              resizeMode="cover"
            />
          </Pressable>
          <Pressable
            onPress={() => {
              Linking.openURL('http://gophonebox.com/plans');
            }}
          >
            <Image
              source={USAPlanBanner}
              style={[
                { width: '100%', height: 130, borderRadius: 8 },
                isWebsite()
                  ? {
                      maxWidth: 400,
                      marginHorizontal: 'auto',
                      width: '100%',
                    }
                  : null,
              ]}
              resizeMode="cover"
            />
          </Pressable>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
