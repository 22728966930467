import { Linking, View } from 'react-native';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import Button from '../Button';
import CustomText from '../utils/CustomText';

const HotelPromo = () => {
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);

  return (
    <View
      style={{
        gap: 8,
      }}
    >
      <CustomText style={{ fontSize: 16 }} weight="600">
        Get 15% Off on Hotels!
      </CustomText>
      <CustomText weight="600">How to Redeem Your Discount:</CustomText>
      <CustomText style={{ marginLeft: 8 }}>1. Visit Choice Hotels website below.</CustomText>
      <CustomText style={{ marginLeft: 8 }}>
        2. In the Special Rate drop-down menu, select &ldquo;Special Rate/Corporate ID&rdquo;.
      </CustomText>
      <CustomText style={{ marginLeft: 8 }}>
        3. Enter the code{' '}
        <CustomText weight="600">{loggedIn ? '00201790' : '(Log in to get the code)'}</CustomText>.
      </CustomText>
      <CustomText style={{ marginLeft: 8 }}>4. Start your search</CustomText>
      <Button
        style={{ alignSelf: 'center', marginBottom: 10 }}
        onPress={() => {
          Linking.openURL(
            'https://www.choicehotels.com/en-ca?gclid=Cj0KCQiA0fu5BhDQARIsAMXUBOKm71MtmKF4QmiG-YQWFp0ckBAg0jN0ffpUBMGL2_Nk6_2SgLaftroaAhPDEALw_wcB'
          );
        }}
      >
        Visit Website
      </Button>
    </View>
  );
};

export default HotelPromo;
