import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { useState } from 'react';
import { SafeAreaView, ScrollView, View } from 'react-native';

import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { RootStackScreenProps } from '../../types';
import { isWebsite } from '../../utilities/functions';

export default function AddMoreDataScreen({ navigation }: RootStackScreenProps<'AddMoreData'>) {
  const [data, setData] = useState<number>(1);

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          padding: 20,
          backgroundColor: Colors.white,
          marginBottom: 150,
        }}
      >
        <CustomText style={{ textAlign: 'center' }}>Data Top Up -- $15.00/1GB</CustomText>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <MaterialCommunityIcons
            name="minus-circle"
            size={32}
            color={data === 1 ? Colors.greyColor : Colors.ActionLinkColor}
            onPress={() => {
              if (data <= 1) {
                return;
              }
              setData((prev) => prev - 1);
            }}
            style={{ marginTop: 16 }}
          />
          <View style={{ alignItems: 'center', marginTop: 24, width: 92 }}>
            <CustomText
              weight="600"
              style={{
                fontSize: 32,
                color: Colors.canadaButtonColor,
                paddingTop: 20,
                paddingHorizontal: 40,
              }}
            >
              {data}
            </CustomText>
            <CustomText
              weight="500"
              style={{
                fontSize: 16,
                color: Colors.canadaButtonColor,
              }}
            >
              GB
            </CustomText>
          </View>
          <MaterialIcons
            name="add-circle"
            size={32}
            color={data === 5 ? Colors.greyColor : Colors.ActionLinkColor}
            onPress={() => {
              if (data >= 5) {
                return;
              }
              setData((prev) => prev + 1);
            }}
            style={{ marginTop: 16 }}
          />
        </View>
      </ScrollView>

      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={() => {
            navigation.navigate('PrepaidPlanCheckout', {
              update: 'data-top-up',
              data: data,
            });
          }}
          textStyle={{ fontSize: 14 }}
        >
          Continue
        </Button>
      </View>
    </SafeAreaView>
  );
}
