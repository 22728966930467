import moment from 'moment';
import { useState } from 'react';
import { Alert, ScrollView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { AppDrawer } from '../../components/AppDrawer';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import InfoDescriptionToggle from '../../components/UI/InfoDescriptionToggle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT, DATE_FORMAT_FOR_API } from '../../constants/constants';
import { accountService } from '../../services/MyAccount/AccountService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import { RootStackScreenProps } from '../../types';
import { isWebsite } from '../../utilities/functions';

export default function LostOrStolenScreen({ navigation }: RootStackScreenProps<'LostOrStolen'>) {
  const [modelVisible, setModalVisible] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { Suspended, SuspensionReason } = useSelector(
    (state: RootState) => state.selfServeCA.accountProfileCA
  );

  return (
    <>
      <ScrollView style={{ flex: 1, paddingHorizontal: 20, marginBottom: 130 }}>
        <CustomText
          weight="600"
          style={{
            fontSize: 24,
            textAlign: 'center',
            marginTop: 32,
          }}
        >
          Did you lost your phone?
        </CustomText>
        <CustomText style={{ fontSize: 14, textAlign: 'center', marginTop: 8 }}>
          Suspend your service and keep your number safe.
        </CustomText>

        <HorizontalLine style={{ marginVertical: 24 }} />

        <View
          style={{
            gap: 16,
          }}
        >
          <InfoDescriptionToggle
            title="Can I keep my current number?"
            desc="Yes, you can. Once you request the Lost and Stolen Hold, we will suspend your current line. To keep using the same number, you should request a new SIM card and contact us once it arrives, so we can transfer your phone number to the new SIM. Alternatively, you can contact us to get an eSIM."
            height={180}
            style={{ marginHorizontal: 0 }}
          />
          <InfoDescriptionToggle
            title="What should I do if I find my phone?"
            desc="Once you request the Lost and Stolen Hold, we will suspend your current line. To reactivate the line, contact our customer service. A new SIM Card or eSIM may be required."
            height={120}
            style={{ marginHorizontal: 0 }}
          />
        </View>
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            paddingVertical: 40,
            paddingHorizontal: 20,
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button onPress={() => setModalVisible(true)}>Suspend the line</Button>
      </View>
      <AppDrawer
        height={430}
        onClose={() => setModalVisible(false)}
        visible={modelVisible}
        showBackDrop
      >
        <CustomText weight="500" style={{ fontSize: 24, color: Colors.TextBlack }}>
          Are you sure want to suspend the line?
        </CustomText>
        <CustomText style={{ fontSize: 14, color: Colors.black }}>
          Your line will be suspended accordingly. However, please be advised that charges will
          still be billed even after the suspension.
        </CustomText>
        <HorizontalLine style={{ marginVertical: 16 }} />
        <View
          style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <CustomText
            weight="600"
            style={{ fontSize: 14, color: Colors.inputTextPlaceholderColor }}
          >
            Suspend Date
          </CustomText>
          <CustomText weight="500" style={{ fontSize: 16, color: Colors.TextBlack }}>
            {moment().format(DATE_FORMAT)}
          </CustomText>
        </View>
        <View
          style={[
            { gap: 8, marginTop: 32 },
            isWebsite()
              ? {
                  maxWidth: 400,
                  marginHorizontal: 'auto',
                  width: '100%',
                }
              : null,
          ]}
        >
          <Button
            onPress={async () => {
              try {
                dispatch(setIsLoading(true));
                if (Suspended && SuspensionReason === 'LS') {
                  if (isWebsite()) {
                    return alert(
                      'Sorry, you cannot request at this time because your plan is suspended now due to reported Lost/Stolen.'
                    );
                  }
                  Alert.alert(
                    'Error!',
                    'Sorry, you cannot request at this time because your plan is suspended now due to reported Lost/Stolen.'
                  );
                  return false;
                }
                const { data } = await accountService.getStatus();
                if (data.TerminationStatus === 'Requested') {
                  if (isWebsite()) {
                    return alert(
                      'Sorry, you cannot request at this time due to a pending termination request.'
                    );
                  }
                  Alert.alert(
                    'Error!',
                    'Sorry, you cannot request at this time due to a pending termination request.'
                  );
                  return false;
                }
                const response = await accountService.postSuspension({
                  edate: moment().format(DATE_FORMAT_FOR_API),
                  sdate: moment().format(DATE_FORMAT_FOR_API),
                  reason: 'lost',
                });
                if (response.data[1] === 'E') {
                  if (isWebsite()) {
                    return alert('You have already requested to suspended your plan!');
                  }
                  Alert.alert('Error', 'You have already requested to suspended your plan!');
                  return false;
                }
                navigation.replace('LostOrStolenSuccess');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (_error: any) {
                if (isWebsite()) {
                  return alert('Failed to suspend your plan !');
                }
                Alert.alert('Error', 'Failed to suspend your plan !');
                return false;
              } finally {
                dispatch(setIsLoading(false));
              }
            }}
          >
            Suspend The Line
          </Button>
          <Button
            color={Colors.backgroundGrey}
            textColor={Colors.greyColor}
            onPress={() => setModalVisible(false)}
          >
            Back
          </Button>
        </View>
      </AppDrawer>
    </>
  );
}
