import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Dispatch } from '@reduxjs/toolkit';
import { Crisp } from 'crisp-sdk-web';
import moment from 'moment';
import { Alert, Platform } from 'react-native';
import * as CrispChatSDK from 'react-native-crisp-chat-sdk';
import uuid from 'react-native-uuid';
import { v7 } from 'uuid';

import DevLog from './debug-error';
import {
  DATE_FORMAT_FOR_API,
  gradientCornerImages,
  HERO_GRADIENT,
  MOBILE_CANCEL_URL,
  MOBILE_RETURN_URL,
  SUSPENSION_TYPE,
} from '../constants/constants';
import { accountService } from '../services/MyAccount/AccountService';
import { authService } from '../services/SSO/AuthService';
import { tokenService } from '../services/TokenService';
import { setIsLoading } from '../store/app';
import { setAccountInfoCA, setAccountProfileCA } from '../store/selfServeCA';
import {
  setAccounts,
  setApiToken,
  setAuthorizedBy,
  setCnum,
  setLoggedIn,
  setProfile,
} from '../store/user';
import { RootStackParamList } from '../types';
import { AccountInfoCA } from '../types/models/AccountInfoCA';
import { AccountProfileCA } from '../types/models/AccountProfileCA';
import { SignInAppleResponse } from '../types/responses/SSO/SignInAppleResponse';
import { SignInGoogleResponse } from '../types/responses/SSO/SignInGoogleResponse';
import { SignInResponse } from '../types/responses/SSO/SignInResponse';
import { SuspensionReason } from '../types/types';

export const getUserProfileAsync = async (
  dispatch: Dispatch,
  navigation: NativeStackNavigationProp<RootStackParamList>,
  cnum: string
) => {
  const userProfileResponse = await authService.userProfile({});

  if (!userProfileResponse?.data?.success) {
    DevLog.log('error handling: ');
    DevLog.log('Is invalid user');
    // TODO : fix this in backend
    await tokenService.deleteAccessTokenAsync();
    await tokenService.deleteAccessTokenExpiryAsync();
    await tokenService.deleteRefreshTokenAsync();
    await tokenService.deleteRememberMe();
    await tokenService.deleteRefreshTokenExpiryAsync();
    dispatch(setLoggedIn(false));
    navigation.navigate('Landing');
    return false;
  }

  dispatch(setProfile(userProfileResponse.data.data.profile));
  dispatch(setAuthorizedBy(userProfileResponse.data.data.authorizedBy));
  if (userProfileResponse?.data?.success && userProfileResponse.data.data.profile) {
    if (userProfileResponse.data.data.accounts) {
      const accountList = userProfileResponse.data.data.accounts.CA_SELFSERVE;
      if (!accountList || accountList.length <= 0) {
        DevLog.log('no accounts: ');
        DevLog.info(
          '%cWARNING: Update to Welcome!',
          'color: red; font-size: 40px; font-weight: bold; background-color: #fdd; padding: 14px; border: 4px solid red; border-radius: 10px;'
        );

        navigation.navigate('Onboarding');

        dispatch(setLoggedIn(true));
        return;
      } else if (accountList.length > 1) {
        const accounts = await accountService.getMultiLineAccountInfo({
          cnumAcctOwner: cnum,
        });
        dispatch(setAccounts({ accounts: accounts.data, isMultiLineAccount: true }));
        DevLog.log('has accounts: ', accounts.data);
      } else if (accountList !== undefined) {
        dispatch(setAccounts({ accounts: accountList, isMultiLineAccount: false }));
      }
      const accountInfoResponse = await accountService.getAccountInfo({});
      const profileResponse = await accountService.getProfile({});
      dispatch(setAccountInfoCA(accountInfoResponse.data));
      dispatch(setAccountProfileCA(profileResponse.data));
      dispatch(setLoggedIn(true));
    }
    return true;
  } else {
    DevLog.error(userProfileResponse);
  }
  return false;
};

export const validateToken = async (
  signInResponse: SignInResponse | SignInGoogleResponse | SignInAppleResponse,
  dispatch: Dispatch,
  navigation: NativeStackNavigationProp<RootStackParamList>
) => {
  if (signInResponse.idToken && signInResponse.idRefreshToken) {
    const validateTokenResponse = await accountService.validateToken({
      authtoken: signInResponse.idToken,
    });

    if (validateTokenResponse.data?.cNum && validateTokenResponse.data?.apiToken) {
      await tokenService.setAccessTokenAsync(signInResponse.idToken);
      await tokenService.setRefreshTokenAsync(signInResponse.idRefreshToken);
      await tokenService.setCASelfServeTokenAsync(validateTokenResponse.data.apiToken);

      const millisecondsNow = new Date().getTime();

      // expiry dates are in seconds, here we convert them to milliseconds
      const accessTokenExpiry = millisecondsNow + signInResponse.idTokenExpiresIn * 1000;
      const refreshTokenExpiry = millisecondsNow + signInResponse.idRefreshTokenExpiresIn * 1000;

      await tokenService.setAccessTokenExpiryAsync(accessTokenExpiry);
      await tokenService.setRefreshTokenExpiryAsync(refreshTokenExpiry);

      dispatch(setCnum(validateTokenResponse.data.cNum));
      dispatch(setApiToken(validateTokenResponse.data.apiToken));
      dispatch(setIsLoading(true));
      try {
        const _res = await getUserProfileAsync(
          dispatch,
          navigation,
          validateTokenResponse.data.cNum
        );
        if (_res === true) {
          // navigation.navigate('SelectLine'); // ! No need to Go there yet as we don't have multi line products at the moment
        }

        // dispatch(setLoggedIn(true)); // Move this to Select Line Screen
      } catch (error) {
        DevLog.log('Error: ', error);
      } finally {
        dispatch(setIsLoading(false));
      }

      // dispatch(setIsLoading(true));
      // navigation.navigate('SelectLine');
    }
  }
};

const uniqueId = uuid.v4();
export const crispChatAction = (
  loggedIn: boolean,
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  PhoneNumber: string
) => {
  if (Platform.OS === 'web') {
    Crisp.configure(process.env.CRISP_WEBSITE_ID!);
    Crisp.chat.close();
    Crisp.session.reset();
    if (loggedIn) {
      Crisp.setTokenId(id);
      Crisp.user.setEmail(email);
      Crisp.user.setNickname(`${firstName} ${lastName}`);
      Crisp.user.setPhone(PhoneNumber);
    } else {
      let session = v7();
      const _session: string = sessionStorage.getItem('guest-user-crisp-chat') ?? '';
      if (_session === '') {
        sessionStorage.setItem('guest-user-crisp-chat', session);
      } else {
        session = _session;
      }
      Crisp.setTokenId(session);
      Crisp.user.setNickname('Guest User');
    }
    return;
  }
  CrispChatSDK.configure(process.env.CRISP_WEBSITE_ID!);
  if (loggedIn) {
    CrispChatSDK.setTokenId(id);
    CrispChatSDK.setUserEmail(email);
    CrispChatSDK.setUserNickname(`${firstName} ${lastName}`);
    CrispChatSDK.setUserPhone(PhoneNumber);
  } else {
    CrispChatSDK.setTokenId(uniqueId);
    CrispChatSDK.setUserNickname('Guest User');
  }
  CrispChatSDK.show();
};

export const changePlan = async (
  Suspended: boolean,
  SuspensionReason: SuspensionReason | null,
  effectiveDate: moment.Moment,
  planId: number
) => {
  try {
    const { data } = await accountService.getStatus();
    if (Suspended && SuspensionReason === 'VC') {
      if (isWebsite()) {
        return alert('Sorry, you cannot change your plan because you are on vacation plan now.');
      }
      Alert.alert(
        'Error!',
        'Sorry, you cannot change your plan because you are on vacation plan now.'
      );
      return false;
    }
    if (Suspended && SuspensionReason === 'LS') {
      if (isWebsite()) {
        return alert(
          'Sorry, you cannot change your plan because your plan is suspended now due to reported Lost/Stolen.'
        );
      }
      Alert.alert(
        'Error!',
        'Sorry, you cannot change your plan because your plan is suspended now due to reported Lost/Stolen.'
      );
      return false;
    }
    if (
      data.PlanChangeStatus === 'Requested' ||
      data.SuspensionStatus === 'Requested' ||
      data.TerminationStatus === 'Requested'
    ) {
      if (isWebsite()) {
        return alert(
          'Sorry, you cannot change your plan due to a pending suspension/plan change/termination request.'
        );
      }
      Alert.alert(
        'Error!',
        'Sorry, you cannot change your plan due to a pending suspension/plan change/termination request.'
      );
      return false;
    }

    const response = await accountService.changePlan({
      effDate: moment(effectiveDate).format(DATE_FORMAT_FOR_API),
      pfid: planId,
      reason: '',
    });

    if (response.data[9] === 'I') {
      if (isWebsite()) {
        return alert('Sorry, You can not change the plan today.');
      }
      Alert.alert('Error', 'Sorry, You can not change the plan today.');
      return false;
    }
    // "Failed: Request duplicating."
    if (response.data[9] === 'R') {
      if (isWebsite()) {
        return alert(
          'You have already requested to change your plan. To make changes to this request, please contact our customer service.'
        );
      }
      Alert.alert(
        'Error',
        'You have already requested to change your plan. To make changes to this request, please contact our customer service.'
      );
      return false;
    }
    return true;
  } catch (error) {
    DevLog.log(error);
    if (isWebsite()) {
      return alert('Unable to process your request, please try again.');
    }
    Alert.alert('Error', 'Unable to process your request, please try again.');
    return false;
  }
};

export const isWebsite = () => Platform.OS === 'web';

export const paymentUrl = (type: 'return' | 'cancel') => {
  if (Platform.OS !== 'web') {
    return type === 'return' ? MOBILE_RETURN_URL : MOBILE_CANCEL_URL;
  }
  return type === 'return' ? `${window.location.origin}/payment-success/` : window.location.href;
};

export const getGradientInfo = (
  accountProfileCA: AccountProfileCA,
  accountInfoCA: AccountInfoCA
) => {
  let gradientInfo = HERO_GRADIENT.default;
  let scrollLeftImage = gradientCornerImages.defaultScrollLeft;
  let scrollRightImage = gradientCornerImages.defaultScrollRight;

  if (accountProfileCA.Suspended && accountProfileCA.SuspensionReason) {
    if (accountProfileCA.SuspensionReason === 'VC') {
      scrollLeftImage = gradientCornerImages.vacationScrollLeft;
      scrollRightImage = gradientCornerImages.vacationScrollRight;
    } else if (accountProfileCA.SuspensionReason === 'UP') {
      scrollLeftImage = gradientCornerImages.unpaidScrollLeft;
      scrollRightImage = gradientCornerImages.unpaidScrollRight;
    } else if (accountProfileCA.SuspensionReason === 'LS') {
      scrollLeftImage = gradientCornerImages.lostScrollLeft;
      scrollRightImage = gradientCornerImages.lostScrollRight;
    }
    gradientInfo = HERO_GRADIENT[SUSPENSION_TYPE[accountProfileCA.SuspensionReason]];
  }

  if (
    accountProfileCA.TerminationDate &&
    accountInfoCA.Prepaid &&
    moment(accountInfoCA.EndDate).diff(moment(), 'days') + 1 < 0
  ) {
    scrollLeftImage = gradientCornerImages.terminatedScrollLeft;
    scrollRightImage = gradientCornerImages.terminatedScrollRight;
    gradientInfo = HERO_GRADIENT['terminated'];
  }
  return {
    ...gradientInfo,
    scrollLeftImage,
    scrollRightImage,
  };
};
