import LottieView from 'lottie-react-native';
import React from 'react';
import { View, Image, SafeAreaView, Dimensions, Platform } from 'react-native';

import FireworkSVGJSON from '../../assets/illustrations/animated/Firework_3.json';
import IllustrationImage from '../../assets/illustrations/Blue4.png';
import { AppGap } from '../../components/AppGap';
import AppGradientContainer from '../../components/AppGradientContainer';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import AuthBackground from '../../components/utils/AuthBackgroud';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { RootStackScreenProps } from '../../types';

const { width, height } = Dimensions.get('window');

const PaymentSuccessScreen = ({ navigation }: RootStackScreenProps<'PaymentSuccess'>) => {
  return (
    <AppGradientContainer
      colorLeft={Colors.gradientDarkBlue}
      colorRight={Colors.gradientLightBlue}
      direction="LEFT-RIGHT"
    >
      <SafeAreaView style={{ display: 'flex', height: '100%' }}>
        <AuthBackground />
        <View
          style={{
            position: 'relative',
            marginTop: Platform.select({ default: -160, web: null }),
            marginLeft: Platform.select({ default: 30, web: null }),
          }}
        >
          <Image
            source={IllustrationImage}
            style={{
              width: 500,
              height: 550,
              zIndex: 10,
              marginLeft: Platform.select({ default: 0, web: null }),
              marginTop: Platform.select({ default: 0, web: null }),
              left: Platform.select({ default: width / 2 - 300, web: width / 2 - 250 }),
              top: Platform.select({ default: 250, web: undefined }),
            }}
            resizeMode="contain"
          />
          <View
            style={{
              width: 500,
              height: height / 2,
              position: 'absolute',
              top: Platform.select({ default: 150, web: 0 }),
              zIndex: 8,
              right: Platform.select({ default: -50, web: width / 2 - 250 }),
            }}
          >
            <LottieView style={{ flex: 1 }} autoPlay loop={true} source={FireworkSVGJSON} />
          </View>
        </View>
        <BottomWhiteSheet style={{ paddingLeft: 30, paddingRight: 30 }}>
          <View style={{ width: '100%' }}>
            <CustomText
              weight="500"
              style={{ fontSize: 24, fontWeight: '500', marginTop: 50, marginBottom: 10 }}
            >
              Payment Successful!
            </CustomText>
            <View style={{ marginBottom: 20 }}>
              <CustomText style={{ fontSize: 12 }}>Your payment has been received.</CustomText>
            </View>
            <HorizontalLine style={{ marginBottom: 20 }} />

            <Button
              style={{ marginTop: 10 }}
              onPress={() => {
                navigation.navigate('Root');
              }}
              textStyle={{ fontSize: 14 }}
            >
              Back to App
            </Button>
            <AppGap direction="vertical" size={30} />
          </View>
        </BottomWhiteSheet>
      </SafeAreaView>
    </AppGradientContainer>
  );
};

export default PaymentSuccessScreen;
