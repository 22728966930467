/* eslint-disable @typescript-eslint/no-unused-expressions */
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';

import { CRED_REMEMBER_ME, REMEBER_ME } from '../constants/constants';

const TOKEN_KEY = 'SSOAccessToken';
const TOKEN_KEY_EXPIRY = 'SSOTokenExpiry';
const REFRESH_TOKEN_KEY = 'SSORefreshToken';
const REFRESH_TOKEN_KEY_EXPIRY = 'SSORefreshTokenExpiry';
const CA_SELFSERVE_TOKEN = 'CASelfServeToken';

const getRememberMe = async () => {
  if (Platform.OS !== 'web') {
    return await SecureStore.getItemAsync(CRED_REMEMBER_ME);
  }
  return await AsyncStorage.getItem(CRED_REMEMBER_ME);
};

export const tokenService = {
  //Add Remember Me
  setRememberMe: async () => {
    if (Platform.OS !== 'web') {
      await SecureStore.setItemAsync(CRED_REMEMBER_ME, REMEBER_ME);
      return;
    }
    await AsyncStorage.setItem(CRED_REMEMBER_ME, REMEBER_ME);
  },
  getRememberMe: getRememberMe,
  deleteRememberMe: async () => {
    if (Platform.OS !== 'web') {
      await SecureStore.deleteItemAsync(CRED_REMEMBER_ME);
      return;
    }
    await AsyncStorage.removeItem(CRED_REMEMBER_ME);
  },
  // SSO tokens
  setAccessTokenAsync: async (value: string) => {
    if (Platform.OS !== 'web') {
      await SecureStore.setItemAsync(TOKEN_KEY, value);
      return;
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      await AsyncStorage.setItem(TOKEN_KEY, value);
    } else {
      window && window.sessionStorage && sessionStorage.setItem(TOKEN_KEY, value);
    }
  },

  setAccessTokenExpiryAsync: async (value: number) => {
    if (Platform.OS !== 'web') {
      await SecureStore.setItemAsync(TOKEN_KEY_EXPIRY, value.toString());
      return;
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      await AsyncStorage.setItem(TOKEN_KEY_EXPIRY, value.toString());
    } else {
      window && window.sessionStorage && sessionStorage.setItem(TOKEN_KEY_EXPIRY, value.toString());
    }
  },
  setRefreshTokenAsync: async (value: string) => {
    if (!value) {
      return;
    }
    if (Platform.OS !== 'web') {
      await SecureStore.setItemAsync(REFRESH_TOKEN_KEY, value);
      return;
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      await AsyncStorage.setItem(REFRESH_TOKEN_KEY, value);
    } else {
      window && window.sessionStorage && sessionStorage.setItem(REFRESH_TOKEN_KEY, value);
    }
  },
  setRefreshTokenExpiryAsync: async (value: number) => {
    if (!value) {
      return;
    }
    if (Platform.OS !== 'web') {
      await SecureStore.setItemAsync(REFRESH_TOKEN_KEY_EXPIRY, value.toString());
      return;
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      await AsyncStorage.setItem(REFRESH_TOKEN_KEY_EXPIRY, value.toString());
    } else {
      window &&
        window.sessionStorage &&
        sessionStorage.setItem(REFRESH_TOKEN_KEY_EXPIRY, value.toString());
    }
  },
  getAccessTokenAsync: async () => {
    if (Platform.OS !== 'web') {
      return await SecureStore.getItemAsync(TOKEN_KEY);
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      return await AsyncStorage.getItem(TOKEN_KEY);
    } else {
      return window && window.sessionStorage && sessionStorage.getItem(TOKEN_KEY);
    }
  },
  getAccessTokenExpiryAsync: async () => {
    if (Platform.OS !== 'web') {
      return await SecureStore.getItemAsync(TOKEN_KEY_EXPIRY);
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      return await AsyncStorage.getItem(TOKEN_KEY_EXPIRY);
    } else {
      return window && window.sessionStorage && sessionStorage.getItem(TOKEN_KEY_EXPIRY);
    }
  },
  getRefreshTokenAsync: async () => {
    if (Platform.OS !== 'web') {
      return await SecureStore.getItemAsync(REFRESH_TOKEN_KEY);
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      return await AsyncStorage.getItem(REFRESH_TOKEN_KEY);
    } else {
      return window && window.sessionStorage && sessionStorage.getItem(REFRESH_TOKEN_KEY);
    }
  },
  getRefreshTokenExpiryAsync: async () => {
    if (Platform.OS !== 'web') {
      return await SecureStore.getItemAsync(REFRESH_TOKEN_KEY_EXPIRY);
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      return await AsyncStorage.getItem(REFRESH_TOKEN_KEY_EXPIRY);
    } else {
      return window && window.sessionStorage && sessionStorage.getItem(REFRESH_TOKEN_KEY_EXPIRY);
    }
  },
  deleteAccessTokenAsync: async () => {
    if (Platform.OS !== 'web') {
      SecureStore.deleteItemAsync(TOKEN_KEY);
      return;
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      await AsyncStorage.removeItem(TOKEN_KEY);
    } else {
      window && window.sessionStorage && sessionStorage.removeItem(TOKEN_KEY);
    }
  },
  deleteAccessTokenExpiryAsync: async () => {
    if (Platform.OS !== 'web') {
      SecureStore.deleteItemAsync(TOKEN_KEY_EXPIRY);
      return;
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      await AsyncStorage.removeItem(TOKEN_KEY_EXPIRY);
    } else {
      window && window.sessionStorage && sessionStorage.removeItem(TOKEN_KEY_EXPIRY);
    }
  },
  deleteRefreshTokenAsync: async () => {
    if (Platform.OS !== 'web') {
      await SecureStore.deleteItemAsync(REFRESH_TOKEN_KEY);
      return;
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      await AsyncStorage.removeItem(REFRESH_TOKEN_KEY);
    } else {
      window && window.sessionStorage && sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    }
  },
  deleteRefreshTokenExpiryAsync: async () => {
    if (Platform.OS !== 'web') {
      await SecureStore.deleteItemAsync(REFRESH_TOKEN_KEY_EXPIRY);
      return;
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      await AsyncStorage.removeItem(REFRESH_TOKEN_KEY_EXPIRY);
    } else {
      window && window.sessionStorage && sessionStorage.removeItem(REFRESH_TOKEN_KEY_EXPIRY);
    }
  },
  // SYSTEM SPECIFIC: CA SELFSERVE
  setCASelfServeTokenAsync: async (value: string) => {
    if (Platform.OS !== 'web') {
      await SecureStore.setItemAsync(CA_SELFSERVE_TOKEN, value);
      return;
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      await AsyncStorage.setItem(CA_SELFSERVE_TOKEN, value);
    } else {
      window && window.sessionStorage && sessionStorage.setItem(CA_SELFSERVE_TOKEN, value);
    }
  },
  getCASelfServeTokenAsync: async () => {
    if (Platform.OS !== 'web') {
      return await SecureStore.getItemAsync(CA_SELFSERVE_TOKEN);
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      return await AsyncStorage.getItem(CA_SELFSERVE_TOKEN);
    } else {
      return window && window.sessionStorage && sessionStorage.getItem(CA_SELFSERVE_TOKEN);
    }
  },
  deleteCASelfServeTokenAsync: async () => {
    if (Platform.OS !== 'web') {
      await SecureStore.deleteItemAsync(CA_SELFSERVE_TOKEN);
      return;
    }
    if ((await getRememberMe()) === REMEBER_ME) {
      await AsyncStorage.removeItem(CA_SELFSERVE_TOKEN);
    } else {
      window && window.sessionStorage && sessionStorage.removeItem(CA_SELFSERVE_TOKEN);
    }
  },
  //
};
