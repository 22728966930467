import { Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Animated, Platform, Pressable, ScrollView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import Badge from '../../components/UI/Badge';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { accountService } from '../../services/MyAccount/AccountService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import { PortOutPlansResponse } from '../../types/responses/MyAccount/PortOutPlansResponse';
import DevLog from '../../utilities/debug-error';
import { changePlan, isWebsite } from '../../utilities/functions';
import { getProratedDates } from '../../utilities/utilities';

const PromotionPlan = ({
  plan,
  selectedPlanIndex,
  setPlanSelected,
  isPrepaid = false,
}: {
  plan: PortOutPlansResponse;
  selectedPlanIndex: number;
  isPrepaid?: boolean;
  setPlanSelected: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [detailVisiable, setDetailVisiable] = useState(false);
  const heightAnim = useRef(new Animated.Value(0)).current;

  const toggleExpand = () => {
    Animated.timing(heightAnim, {
      toValue: detailVisiable ? 0 : Platform.select({ web: 125, default: 145 }),
      duration: 300,
      useNativeDriver: false,
    }).start();
    setDetailVisiable(!detailVisiable);
  };

  return (
    <Pressable
      style={{
        padding: 16,
        borderWidth: 1,
        borderColor: Colors.activePlanBorderColor,
        borderRadius: 12,
        backgroundColor:
          selectedPlanIndex && selectedPlanIndex === plan.PlanId
            ? Colors.SelectLineItemHighlightColor
            : Colors.noColor,
      }}
      onPress={() => {
        setPlanSelected(plan.PlanId);
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 14,
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Entypo name="mobile" size={24} color={Colors.canadaButtonColor} />
          <CustomText
            weight="500"
            style={{ fontSize: 12, color: Colors.QuickActionTextColor, marginLeft: 8 }}
          >
            Canadian Mobile Plan
          </CustomText>
        </View>
        <Badge title={isPrepaid ? 'Prepaid' : 'Monthly'} status="light-blue" fontweight="600" />
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <CustomText weight="500" style={{ fontSize: 20, color: Colors.canadaButtonColor }}>
          {plan.DataCap}
        </CustomText>
        <CustomText weight="600" style={{ fontSize: 20 }}>
          ${plan.Fee.toFixed(2)}
        </CustomText>
      </View>
      <HorizontalLine thinkness={1} style={{ marginTop: 16, marginBottom: 10 }} />
      <Animated.View
        style={{
          height: heightAnim,
          opacity: heightAnim.interpolate({
            inputRange: [0, 100],
            outputRange: [0, 1],
          }),
        }}
      >
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; Plan will reset on the 2nd of every month
        </CustomText>
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; UNLIMITED picture messaging
        </CustomText>
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; Supported on the {plan.Carriers === 'R' ? 'Rogers' : 'Telus'} Network
        </CustomText>
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; Call Display
        </CustomText>
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; Voicemail Up to 35 minutes of messages
        </CustomText>
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; Data Overage Rate: $15.00/1GB
        </CustomText>
      </Animated.View>
      <CustomText
        weight="600"
        onPress={toggleExpand}
        style={{
          color: Colors.ActionLinkColor,
          fontWeight: '600',
          fontSize: 12,
          textAlign: 'center',
          marginTop: Platform.select({ web: 8, default: 10 }),
        }}
      >
        {detailVisiable ? 'Hide' : 'Show'} details
      </CustomText>
    </Pressable>
  );
};

const PortOutPlanView = ({ onPress }: { onPress(option: string): void }) => {
  const [promotionalPlans, setPromotionalPlans] = useState<PortOutPlansResponse[]>();
  const [planSelected, setPlanSelected] = useState<number>(-1);

  const {
    accountInfoCA: { Prepaid: isPrepaid, EndDate },
    accountProfileCA: { Suspended, SuspensionReason },
  } = useSelector((state: RootState) => state.selfServeCA);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const { upcomingSecond } = getProratedDates(new Date());

  const effectiveDate = isPrepaid ? moment(EndDate).add(1, 'days') : upcomingSecond;

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await accountService.getRetentionPlansForCustomer();
        DevLog.log('Response:', response);
        if (response.data.length) {
          setPromotionalPlans(response.data);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (_error: any) {
        DevLog.log(_error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };
    fetchPlans();
  }, []);

  return (
    <View
      style={{
        position: 'relative',
        padding: 20,
        backgroundColor: Colors.white,
        flex: 1,
        height: '100%',
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginBottom: 150, paddingBottom: 20 }}
      >
        <CustomText weight="500" style={{ fontSize: 20 }}>
          It&apos;s sad to let you go...
        </CustomText>
        <CustomText style={{ fontSize: 16 }}>Here&apos;s some amazing deals!</CustomText>
        <HorizontalLine thinkness={1} style={{ marginVertical: 20 }} />
        <CustomText weight="500" style={{ fontSize: 14 }}>
          Promotion Plan
        </CustomText>
        <View style={{ gap: 16, marginTop: 16 }}>
          {promotionalPlans && promotionalPlans.length ? (
            promotionalPlans.map((plan) => (
              <PromotionPlan
                key={plan.PlanId}
                plan={plan}
                selectedPlanIndex={planSelected}
                isPrepaid={isPrepaid}
                setPlanSelected={setPlanSelected}
              />
            ))
          ) : (
            <CustomText>No Plans available.</CustomText>
          )}
        </View>
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={async () => {
            if (isPrepaid) {
              navigation.navigate('PrepaidPlanCheckout', {
                planId: planSelected,
              });
            } else {
              try {
                dispatch(setIsLoading(true));
                const isRequested = await changePlan(
                  Suspended,
                  SuspensionReason,
                  effectiveDate,
                  planSelected
                );
                if (isRequested) {
                  navigation.navigate('ChangePlanSuccess');
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (_error: any) {
                DevLog.log(_error);
              } finally {
                dispatch(setIsLoading(false));
              }
            }
            // onPress('OFFER');
          }}
          textStyle={{ fontSize: 14 }}
          disabled={planSelected === -1}
        >
          Take This Offer!
        </Button>
        <Button
          style={{ marginTop: 10 }}
          color={Colors.backgroundGrey}
          textColor={Colors.TextBlack}
          onPress={() => onPress('CONTINUE')}
        >
          <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
            Continue With Port Out
          </CustomText>
        </Button>
      </View>
    </View>
  );
};

export default PortOutPlanView;
