import { AntDesign } from '@expo/vector-icons';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';

import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { setTerminationInfo } from '../../store/app';
import { RootStackScreenProps } from '../../types';
import { isWebsite } from '../../utilities/functions';

const TerminationConfirmationScreen = ({
  navigation,
}: RootStackScreenProps<'TerminationConfirmation'>) => {
  const dispatch = useDispatch();

  return (
    <>
      <ScrollView
        style={[
          { flex: 1, paddingHorizontal: 20, marginBottom: 120 },
          isWebsite()
            ? {
                maxWidth: 800,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <CustomText weight="500" style={{ fontSize: 20, color: Colors.errorRed, marginTop: 24 }}>
          Wait!
        </CustomText>
        <CustomText weight="500" style={{ fontSize: 20, color: Colors.TextBlack }}>
          You will loose all these benefits!
        </CustomText>
        <CustomText style={{ fontSize: 16, marginTop: 8 }}>
          Are you sure you want to terminate and give up all these benefits?
        </CustomText>
        <View style={{ gap: 24, marginVertical: 24 }}>
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>
              <AntDesign name="staro" size={16} color={Colors.ActionLinkColor} />
            </CustomText>
            <CustomText weight="500" style={style.listItemText}>
              Up to 1,000 mins of International Calls
            </CustomText>
          </View>
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>
              <AntDesign name="staro" size={16} color={Colors.ActionLinkColor} />
            </CustomText>
            <CustomText weight="500" style={style.listItemText}>
              $30 discount for Friends referral
            </CustomText>
          </View>
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>
              <AntDesign name="staro" size={16} color={Colors.ActionLinkColor} />
            </CustomText>
            <CustomText weight="500" style={style.listItemText}>
              Unlimited Calls and Text in Canada
            </CustomText>
          </View>
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>
              <AntDesign name="staro" size={16} color={Colors.ActionLinkColor} />
            </CustomText>
            <CustomText weight="500" style={style.listItemText}>
              Tons of Partnership Benefits
            </CustomText>
          </View>
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>
              <AntDesign name="staro" size={16} color={Colors.ActionLinkColor} />
            </CustomText>
            <CustomText weight="500" style={style.listItemText}>
              Multilingual Support in 13 languages
            </CustomText>
          </View>
        </View>
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            paddingBottom: 40,
            paddingHorizontal: 20,
            gap: 8,
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          onPress={() => {
            dispatch(setTerminationInfo(null));
            navigation.navigate('Root');
          }}
        >
          Keep Current Plan
        </Button>
        <Button
          onPress={() => {
            navigation.navigate('TerminationDetail');
          }}
          color={Colors.backgroundGrey}
          textColor={Colors.greyColor}
        >
          Give Up All These Benefits and Terminate
        </Button>
      </View>
    </>
  );
};

const style = StyleSheet.create({
  listItem: {
    alignItems: 'center',
    borderColor: Colors.lightGrey,
    borderRadius: 8,
    borderWidth: 1,
    flexDirection: 'row',
    padding: 10,
  },
  listItemNumber: {
    alignItems: 'center',
    backgroundColor: Colors.ActionLinkColorBG,
    borderRadius: 54,
    color: Colors.ActionLinkColor,
    display: 'flex',
    fontSize: 16,
    height: 26,
    justifyContent: 'center',
    marginRight: 20,
    overflow: 'hidden',
    textAlign: 'center',
    textAlignVertical: 'center',
    width: 26,
  },
  listItemText: { fontSize: 14, paddingRight: 28 },
});

export default TerminationConfirmationScreen;
