import { StyleSheet } from 'react-native';

import { Colors } from '../../constants/Colors';
import { isWebsite } from '../../utilities/functions';
import CustomText from '../utils/CustomText';

type Props = {
  title: string;
  selected?: boolean;
  onPress: () => void;
};

const FilterChip = (props: Props) => {
  return (
    <CustomText
      weight="500"
      style={[
        {
          fontSize: 14,
          color: Colors.canadaButtonColor,
          borderWidth: 1,
          alignSelf: 'center',
          borderColor: Colors.canadaButtonColor,
          textTransform: 'capitalize',
          paddingVertical: 6,
          paddingHorizontal: 12,
          borderRadius: isWebsite() ? 50 : 10,
          overflow: 'hidden',
          opacity: 0.5,
        },
        props.selected ? styles.selected : {},
      ]}
      onPress={props.onPress}
    >
      {props.title}
    </CustomText>
  );
};

const styles = StyleSheet.create({
  selected: {
    backgroundColor: Colors.canadaButtonColor,
    color: Colors.white,
    opacity: 1,
  },
});

export default FilterChip;
