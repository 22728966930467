// store file for ephemeral App functionality
// this is best for information that will be temporarily used to make API calls
// and is not necessarily permanently attached to the user
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TerminationInfo } from '../types/models/AppStateModels';

const initialTerminationInfo: TerminationInfo = {
  terminationDate: null,
  reason: '',
  reasonDesc: '',
};

const appSlice = createSlice({
  name: 'app',
  initialState: {
    isLoading: false,
    drawerVisible: false,
    terminationInfo: initialTerminationInfo,
  },
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setDrawerVisible(state, action: PayloadAction<boolean>) {
      state.drawerVisible = action.payload;
    },
    setTerminationInfo(state, action: PayloadAction<TerminationInfo | null>) {
      if (action.payload === null) {
        state.terminationInfo = initialTerminationInfo;
      } else {
        state.terminationInfo = action.payload;
      }
    },
  },
});

export const { setIsLoading, setDrawerVisible, setTerminationInfo } = appSlice.actions;

export default appSlice.reducer;
