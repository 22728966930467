import { SafeAreaView, ScrollView, View } from 'react-native';

import MyProductListItem from '../../components/UI/ListItem/MyProductListItem';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { Product } from '../../types/responses/MyAccount/GetMultiLineProductResponse';

const MobileTab = ({ list }: { list: Product[] }) => {
  return (
    <SafeAreaView style={{ backgroundColor: Colors.white, height: '100%', flex: 1 }}>
      <ScrollView style={{ padding: 20 }}>
        <View style={{ gap: 10 }}>
          {list.length ? (
            list.map((product, index) => <MyProductListItem product={product} key={index} />)
          ) : (
            <CustomText
              style={{
                textAlign: 'center',
                fontSize: 24,
                marginTop: 100,
              }}
            >
              No Item Found
            </CustomText>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default MobileTab;
