import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { Platform } from 'react-native';

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);
  const dimensions = useWindowDimensions();

  useEffect(() => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      setIsMobile(true);
    } else {
      setIsMobile(dimensions.width <= 768);
    }
  }, [dimensions.width]);

  return isMobile;
}
