import moment from 'moment';
import { Pressable, View } from 'react-native';

import { Colors } from '../../../constants/Colors';
import { BADGES_COLOR, DATE_FORMAT } from '../../../constants/constants';
import { ShippingStatus } from '../../../types/types';
import CustomText from '../../utils/CustomText';
import Badge from '../Badge';

type Props = {
  title: string;
  date: Date | string;
  status: ShippingStatus;
  isEven?: boolean;
  onPress?: () => void;
};

const StatusTrackingListItem = (props: Props) => {
  return (
    <Pressable
      onPress={props.onPress}
      style={{
        flexDirection: 'row',
        paddingVertical: 24,
        paddingHorizontal: 20,
        backgroundColor: props.isEven ? Colors.white : Colors.backgroundGrey,
      }}
    >
      <View style={{ flex: 1 }}>
        <CustomText weight="600" style={{ fontSize: 16 }}>
          {props.title}
        </CustomText>
        <CustomText weight="500" style={{ fontSize: 12, color: Colors.inputTextPlaceholderColor }}>
          Order Date: {moment(props.date).format(DATE_FORMAT)}
        </CustomText>
      </View>
      <Badge title={props.status} status={BADGES_COLOR[props.status]} />
    </Pressable>
  );
};

export default StatusTrackingListItem;
