import moment from 'moment';
import { Pressable, View } from 'react-native';

import { Colors } from '../../../constants/Colors';
import { BADGES_COLOR, DATE_FORMAT } from '../../../constants/constants';
import { PortInStatus, PortOutStatus } from '../../../types/types';
import CustomText from '../../utils/CustomText';
import Badge from '../Badge';

type Props = {
  title: string;
  date: Date | string;
  activationDate?: Date | string | null;
  status: PortOutStatus | PortInStatus;
  isEven?: boolean;
  onPress?: () => void;
};

const ServiceRequestListItem = (props: Props) => {
  return (
    <Pressable
      onPress={props.onPress}
      style={{
        flexDirection: 'row',
        paddingVertical: 24,
        paddingHorizontal: 20,
        backgroundColor: props.isEven ? Colors.white : Colors.backgroundGrey,
      }}
    >
      <View style={{ flex: 1 }}>
        <CustomText weight="600" style={{ fontSize: 16, textTransform: 'capitalize' }}>
          {props.title.toLowerCase().replace('_', ' ')}
        </CustomText>
        <CustomText weight="500" style={{ fontSize: 12, color: Colors.inputTextPlaceholderColor }}>
          Request Date: {moment(props.date).format(DATE_FORMAT)}
        </CustomText>
        {props.activationDate ? (
          <CustomText
            weight="500"
            style={{ fontSize: 12, color: Colors.inputTextPlaceholderColor }}
          >
            Activation Date: {moment(props.activationDate).format(DATE_FORMAT)}
          </CustomText>
        ) : null}
      </View>
      <Badge title={props.status} status={BADGES_COLOR[props.status]} />
    </Pressable>
  );
};

export default ServiceRequestListItem;
