import { EnhancedStore } from '@reduxjs/toolkit';

import { identityInstance } from './axios';
import { instance } from './axios';
import { tokenService } from '../services/TokenService';
import { resetUser, setApiToken, setCnum, setLoggedIn } from '../store/user';
import DevLog from '../utilities/debug-error';

/**
 * Sets up API interceptors for request and responses using the store.
 * If token expired we're auto logging out customers.
 * @param store
 */

const setupAPIInterceptors = (store: EnhancedStore) => {
  const { dispatch, getState } = store;

  // myaccount / mysim
  instance.interceptors.request.use(async (config) => {
    const { user } = getState();
    try {
      DevLog.info(`REQUEST: ${config.url}:`, config.data);
      config.headers.client = 'beta';
      config.headers.apitoken = 'CPY91BBd3EjjBbAtWjVXQ6AtzKcyDLKq';
      const authToken = await tokenService.getAccessTokenAsync();
      if (authToken) {
        config.headers.AuthToken = authToken;
      }

      if (config.url !== '/Account/ValidateToken') {
        // for ValidateToken, we do not need to send cnum, cnumAcctOwner, or token.
        // in all other cases, we do.
        config.headers.cnum = user.activeCnum;

        // need to add cnumAcctOwner to validatetokenresponse
        //config.headers.cnumAcctOwner = user.signInContentCA.cnumAcctOwner;
        const _cnumAccountOwner = config.headers.get('cnumAcctOwner');
        if (_cnumAccountOwner) {
          config.headers.cnumAcctOwner = config.headers.get('cnumAcctOwner');
        } else if (user.activeCnum !== user.cnum) {
          config.headers.cnumAcctOwner = user.cnum;
        } else {
          config.headers.cnumAcctOwner = '';
        }
        config.headers.token = user.apiToken;
      }
      return config;
    } catch (e) {
      DevLog.error(e);
      return config;
    }
  });

  instance.interceptors.response.use(
    (response) => {
      if (typeof response.data === 'string') {
        let newResponse = response;
        try {
          newResponse.data = JSON.parse(response.data);
        } catch (error) {
          DevLog.log('Error: ', error);
          newResponse = response;
        }
        DevLog.info(`RESPONSE: ${response.config.url}:`, response.data, typeof response.data);
        return newResponse;
      }
      DevLog.info(`RESPONSE: ${response.config.url}:`, response.data, typeof response.data);
      return response;
    },
    async (error) => {
      if (error.response.status === 401) {
        await tokenService.deleteAccessTokenAsync();
        await tokenService.deleteRefreshTokenAsync();
        await tokenService.deleteRememberMe();
        dispatch(setCnum(''));
        dispatch(setApiToken(''));
        dispatch(setLoggedIn(false));
        dispatch(resetUser());
        DevLog.log('Unauthorized. Logging out from MyAccount interceptor...');
      } else {
        return Promise.reject(error);
      }
    }
  );

  // identity server
  identityInstance.interceptors.request.use(async (config) => {
    try {
      DevLog.info(`REQUEST: ${config.url}:`, config.data);
      let token;
      // refresh_token route uses same auth technique but a separate token.
      if (config.url === '/Auth/v1/refresh_token') {
        token = await tokenService.getRefreshTokenAsync();
      } else {
        token = await tokenService.getAccessTokenAsync();
      }
      DevLog.log('token: ', token);
      config.headers.Authorization = token ? `Bearer ${token}` : '';
      return config;
    } catch (e) {
      DevLog.error(e);
      return config;
    }
  });

  identityInstance.interceptors.response.use(
    (response) => {
      DevLog.info(`RESPONSE: ${response.config.url}:`, response.data);
      return response;
    },
    async (error) => {
      if (error.code === 'ERR_BAD_REQUEST' && error.response && error.response.status === 401) {
        await tokenService.deleteAccessTokenAsync();
        await tokenService.deleteRefreshTokenAsync();
        await tokenService.deleteRememberMe();
        dispatch(setLoggedIn(false));
        dispatch(resetUser());
        DevLog.log('Unauthorized. Logging out from identity interceptor...');
      } else {
        return Promise.reject(error);
      }
    }
  );
};

export default setupAPIInterceptors;
