import { EvilIcons } from '@expo/vector-icons';
import { useState } from 'react';
import { Alert, Image, Linking, StatusBar, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import BellIconImage from '../../assets/icons/bell.png';
import { AppDrawer } from '../../components/AppDrawer';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { tokenService } from '../../services/TokenService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import { resetUser } from '../../store/user';
import { RootStackScreenProps } from '../../types';
import { StepType } from '../../types/enums/StepType';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';

export default function DeleteAccountScreen({ navigation }: RootStackScreenProps<'DeleteAccount'>) {
  const dispatch = useDispatch();

  const { accounts } = useSelector((state: RootState) => state.user);
  const { isLoading } = useSelector((state: RootState) => state.app);

  // const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<StepType>(StepType.First);
  const [error, setError] = useState<string | null>(null);

  const [password, setPassword] = useState<string>('');
  const [balanceModelShown, showBalanceModel] = useState<boolean>(false);
  const [confirmModelShown, showConfirmModel] = useState<boolean>(false);

  const onFinalConfirmDelete = async () => {
    dispatch(setIsLoading(true));
    try {
      await new Promise((resolve) => {
        return resolve('This is Dummy');
      });
      await tokenService.deleteAccessTokenAsync();
      await tokenService.deleteAccessTokenExpiryAsync();
      await tokenService.deleteRefreshTokenAsync();
      await tokenService.deleteRefreshTokenExpiryAsync();
      await tokenService.deleteCASelfServeTokenAsync();
      await tokenService.deleteRememberMe();
      dispatch(resetUser());
      setStep(StepType.Success);
    } catch (err) {
      DevLog.error(err);
      setError('Sorry, something went wrong.');
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const stepFirst = (
    <>
      <CustomText
        style={{ fontSize: 24, textAlign: 'center', color: Colors.TextBlack }}
        weight="600"
      >
        Are you sure you want to delete your account?
      </CustomText>
      <View
        style={[
          {
            padding: 16,
            borderRadius: 8,
            borderWidth: 1,
            marginTop: 32,
            borderColor: Colors.activePlanBorderColor,
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 12 }}>
          <View
            style={{
              backgroundColor: Colors.errorRed10Percent,
              padding: 4,
              marginRight: 8,
              borderRadius: 10,
            }}
          >
            <EvilIcons name="exclamation" size={16} color={Colors.errorRed} />
          </View>
          <CustomText weight="600" style={{ fontSize: 16 }}>
            Be aware!
          </CustomText>
        </View>
        <CustomText style={{ fontSize: 14 }}>
          Account deletion means deleting the account used to log into the app, not canceling the
          plan. Even if you delete your account, the plan will remain active. To cancel the plan,
          please use the cancel plan menu.
        </CustomText>
      </View>
      <Image
        source={BellIconImage}
        style={{
          width: '100%',
          height: 200,
        }}
        resizeMode="contain"
      />
    </>
  );

  return (
    <View
      style={{
        flex: 1,
        height: '100%',
        marginTop: StatusBar.currentHeight,
        backgroundColor: Colors.white,
        padding: 20,
      }}
    >
      {step === StepType.First ? (
        stepFirst
      ) : step === StepType.Second ? (
        <InputWithTitle
          title="Confirm Password"
          inputValue={password}
          secureInput
          onInputChange={setPassword}
        />
      ) : (
        <View>
          <CustomText
            weight="600"
            style={{
              fontSize: 24,
              color: Colors.errorRed,
              textAlign: 'center',
              marginBottom: 10,
            }}
          >
            Account Deleted
          </CustomText>
          <CustomText style={{ fontSize: 14, textAlign: 'center' }}>
            So sad to let you go, See you soon!
          </CustomText>
        </View>
      )}
      {error ? (
        <View style={{ marginVertical: 14 }}>
          <CustomText
            weight="500"
            style={{ fontSize: 14, color: Colors.errorRed, textAlign: 'center' }}
          >
            {error}
          </CustomText>
        </View>
      ) : null}
      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            width: '100%',
            alignSelf: 'center',
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          color={Colors.errorRed}
          style={{ marginTop: 32 }}
          // disabled={isLoading || step === StepType.Second ? !validate('password', password) : false}
          loadingStatus={isLoading}
          onPress={() => {
            if (isWebsite()) {
              if (
                confirm(
                  'You will be redirected to our support page, where you can submit a cancellation request.'
                )
              ) {
                Linking.openURL('https://gophonebox.com/support');
              }
            } else {
              Alert.alert(
                'Contact Customer Service',
                'You will be redirected to our support page, where you can submit a cancellation request.',
                [
                  {
                    text: 'Open Link',
                    isPreferred: true,
                    onPress: () => {
                      Linking.openURL('https://gophonebox.com/support');
                    },
                  },
                  {
                    text: 'Cancel',
                  },
                ]
              );
            }
            // step === StepType.First
            //   ? setStep(StepType.Second)
            //   : step === StepType.Second
            //   ? CurrentBalance
            //     ? showBalanceModel(true)
            //     : showConfirmModel(true)
            //   : navigation.navigate('Landing');
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          {step === StepType.First
            ? 'Delete Account'
            : step === StepType.Second
              ? 'Confirm Deletion'
              : 'Explore Main Home'}
        </Button>
        {step !== StepType.Success ? (
          <Button
            style={{ marginTop: 10 }}
            color={Colors.backgroundGrey}
            textColor={Colors.black}
            onPress={() => navigation.goBack()}
          >
            <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
              Cancel
            </CustomText>
          </Button>
        ) : null}
      </View>

      <AppDrawer
        visible={balanceModelShown}
        height={385}
        onClose={() => {
          showBalanceModel(false);
        }}
        showBackDrop
      >
        <CustomText
          weight="500"
          style={{ fontSize: 24, marginBottom: 16, textAlign: isWebsite() ? 'center' : undefined }}
        >
          There is a remaining balance on your account.
        </CustomText>
        <HorizontalLine thinkness={1} />
        <CustomText
          style={{ fontSize: 16, marginTop: 16, textAlign: isWebsite() ? 'center' : undefined }}
        >
          Before you delete your account, please make sure to clear any outstanding negative
          balance.
        </CustomText>
        <View
          style={
            isWebsite()
              ? {
                  maxWidth: 400,
                  marginHorizontal: 'auto',
                  width: '100%',
                }
              : null
          }
        >
          <Button
            style={{ marginTop: 32 }}
            onPress={() => {
              showConfirmModel(true);
              showBalanceModel(false);
            }}
            textStyle={{ fontSize: 14 }}
          >
            Clear Balance
          </Button>
          <Button
            style={{ marginTop: 16 }}
            color={Colors.backgroundGrey}
            textColor={Colors.inputTextPlaceholderColor}
            onPress={() => {
              showBalanceModel(false);
            }}
            textStyle={{ fontSize: 14, fontWeight: '600' }}
          >
            Cancel
          </Button>
        </View>
      </AppDrawer>
      <AppDrawer
        visible={confirmModelShown}
        height={385}
        onClose={() => {
          showConfirmModel(false);
        }}
        showBackDrop
      >
        <CustomText
          weight="500"
          style={{ fontSize: 24, marginBottom: 16, textAlign: isWebsite() ? 'center' : undefined }}
        >
          {accounts.length
            ? 'There is an active line under your account.'
            : 'You sure you want to delete your profile'}
        </CustomText>
        <HorizontalLine thinkness={1} />
        <CustomText
          style={{ fontSize: 16, marginTop: 16, textAlign: isWebsite() ? 'center' : undefined }}
        >
          {accounts.length
            ? 'Even if you delete your account, the line will remain active and you will continue to be billed.'
            : 'You account will be delete, THIS IS NOT A REVESABLE ACTION'}
        </CustomText>
        <View
          style={
            isWebsite()
              ? {
                  maxWidth: 400,
                  marginHorizontal: 'auto',
                  width: '100%',
                }
              : null
          }
        >
          <Button
            color={Colors.errorRed}
            style={{ marginTop: 32 }}
            onPress={() => {
              onFinalConfirmDelete();
            }}
            textStyle={{ fontSize: 14 }}
          >
            {accounts.length ? 'Delete Anyway' : 'Delete Account'}
          </Button>
          <Button
            style={{ marginTop: 16 }}
            color={Colors.backgroundGrey}
            textColor={Colors.inputTextPlaceholderColor}
            onPress={() => {
              showConfirmModel(false);
            }}
            textStyle={{ fontSize: 14, fontWeight: '600' }}
          >
            Cancel
          </Button>
        </View>
      </AppDrawer>
    </View>
  );
}
