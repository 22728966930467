import { Text as ReactText, TextProps } from 'react-native';

export type FontWeight =
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | 'bold'
  | 'normal';

interface Props extends TextProps {
  weight?: FontWeight;
  italic?: boolean;
}

const CustomText = ({ weight = '400', italic = false, ...props }: Props) => {
  const fonts: Record<FontWeight, string> = {
    '100': 'Poppins-Thin',
    '200': 'Poppins-ExtraLight',
    '300': 'Poppins-Light',
    '400': 'Poppins-Regular',
    normal: 'Poppins-Regular',
    '500': 'Poppins-Medium',
    '600': 'Poppins-SemiBold',
    '700': 'Poppins-Bold',
    bold: 'Poppins-Bold',
    '800': 'Poppins-ExtraBold',
    '900': 'Poppins-Black',
  };
  const fontsItalic: Record<FontWeight, string> = {
    '100': 'Poppins-ThinItalic',
    '200': 'Poppins-ExtraLightItalic',
    '300': 'Poppins-LightItalic',
    '400': 'Poppins-Italic',
    normal: 'Poppins-Italic',
    '500': 'Poppins-MediumItalic',
    '600': 'Poppins-SemiBoldItalic',
    '700': 'Poppins-BoldItalic',
    bold: 'Poppins-BoldItalic',
    '800': 'Poppins-ExtraBoldItalic',
    '900': 'Poppins-BlackItalic',
  };

  const _weight = weight ?? '400'; // handle undefined

  const selectedFont = italic ? fontsItalic[_weight] : fonts[_weight];

  return (
    <ReactText {...props} style={[props.style, { fontFamily: selectedFont }]}>
      {props.children}
    </ReactText>
  );
};
export default CustomText;
