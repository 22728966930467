import * as WebBrowser from 'expo-web-browser';
import moment from 'moment';
import { Platform } from 'react-native';

import {
  DATAUSAGE_NOT_AVAILABLE,
  DATACAPACITY_UNLIMITED,
  emailReg,
  passwordRegex,
  phoneNumberRegex,
  profileImages,
} from '../constants/constants';
import { BoxOnePostpaidPlan } from '../types/models/BoxOnePostpaidPlan';
import { BoxOnePrepaidPlan } from '../types/models/BoxOnePrepaidPlan';
import { ParsedPlan } from '../types/models/ParsedPlan';

/**
 * Returns strings with only numbers in it.
 * @param value
 * @returns
 */
export const numbersOnly = (value: string) => value.replace(/\D/g, '');

export const moneyOnly = (value: string) => {
  // Remove any non-numeric or non-decimal characters except for a single dot
  // const cleanValue = value.replace(/[^\d.]/g, "");
  // // Ensure the cleaned value follows the money format regex
  // const moneyRegex = /^\d+(\.\d{0,2})?$/;
  // return moneyRegex.test(cleanValue) ? cleanValue : '';
  return value.replace(/[^\d.]/g, '');
};

/**
 * Turns plans from the activationService "getPostpaidPlans" into ParsedPlans
 * @param plans
 */
export const mapBoxOnePostpaidPlans = (plans: BoxOnePostpaidPlan[]): ParsedPlan[] => {
  const parsedPlans: ParsedPlan[] = plans.map((plan: BoxOnePostpaidPlan) => {
    return {
      planId: plan.pfId,
      plan_fee: plan.pfAmt,
      carrier: plan.telusplan ? 'Telus' : 'Rogers',
      pfCode: plan.pfCode,
      data: /(GB|MB|KB)/.test(plan.dataCap)
        ? plan.dataCap.replace('.00', '')
        : formatPhoneData(plan.dataCap.split('.')[0], ''),
      planTypeD: '',
      calling: plan.calling,
      message: plan.messages,
      plintronPlanId: null,
      subscriptionId: null,
      planType: 'monthly',
      dataSize: (plan.dataCap.split(' ')[1] as 'GB' | 'MB' | 'KB') ?? 'GB',
      dataValue: parseFloat(plan.dataCap.split(' ')[0]),
    };
  });
  return parsedPlans;
};

/**
 * Turns plans from the activationService "getPostpaidPlans" into ParsedPlans
 * @param plans
 */
export const mapBoxOnePrepaidPlans = (plans: BoxOnePrepaidPlan[]) => {
  const parsedPlans: ParsedPlan[] = plans.map((plan: BoxOnePrepaidPlan) => {
    return {
      planId: plan.PlanId,
      plan_fee: plan.Fee,
      carrier: getCarrierFullName(plan.Carriers),
      pfCode: plan.PlanCode,
      data: formatPhoneData((plan.Data / (1024 * 1024)).toString(), plan.PlanTypeD),
      planTypeD: plan.PlanTypeD,
      calling: plan.Calling,
      message: plan.Message,
      plintronPlanId: null,
      subscriptionId: null,
      planType: 'prepaid',
      dataValue: plan.Data / (1024 * 1024),
      dataSize: 'GB',
    };
  });
  return parsedPlans;
};

/**
 * Shows data.
 * @param data
 * @returns
 */
export const formatPhoneData = (data: string | number, planTypeD: string | number) => {
  if (data === 'Voice' || Number(data) === 0 || data === 'Voice Only') {
    return 'Voice';
  }
  if (Number(data) > 9000 || data === 'Unlimited') {
    return 'Unlimited';
  }
  if (typeof data === 'string' && (data.indexOf('MB') !== -1 || data.indexOf('GB') !== -1)) {
    return data;
  }
  if (Number(data) > 99 && Number(planTypeD) === 90) {
    return data + ' GB';
  }
  if (Number(data) > 99) {
    return data + ' MB';
  }
  return data + ' GB';
};

/**
 * Returns a formatted phone number
 * @param phoneNumberString
 * @returns phone number formatted to XXX-XXX-XXXX
 */
export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3];
  }
  return null;
};

/**
 * Gets carrier full name.
 * @param short
 * @returns
 */
export const getCarrierFullName = (short: string) => {
  if (short === 'T') {
    return 'Telus';
  } else {
    return 'Rogers';
  }
};

/**
 * Opens a link properly depending on the OS
 * @param link
 */
export const openLink = (link: string) => {
  if (Platform.OS === 'web') {
    window.open(link);
  } else {
    WebBrowser.openBrowserAsync(link);
  }
};

/**
 * Returns the proper label depending on planTypeD
 * @param days
 * @returns string indicating timespan
 */
export const getTimeFromPlanTypeD = (days: string) => {
  switch (days) {
    case '7':
      return 'week';
    case '30':
      return 'month';
    case '90':
      return '90 days';
    default:
      return 'month';
  }
};

export const getDataUsage = (
  dataUsage: number,
  dataCapacity: number,
  getPercentage = false,
  toFixed = true
) => {
  if (dataCapacity === 0) {
    // do not divide by zero
    return 0;
  }

  if (dataUsage === DATAUSAGE_NOT_AVAILABLE) dataUsage = 0;

  if (getPercentage) {
    return getUsagePercentage(dataUsage, dataCapacity);
  } else {
    return toFixed ? (dataUsage / 1024).toFixed(2) : dataUsage / 1024;
  }
};

export const getUsagePercentage = (dataUsage: number, dataCapacity: number) => {
  let usagePercentage = dataUsage / 1024 / dataCapacity;

  usagePercentage = usagePercentage > 1.0 ? 1.0 : usagePercentage;

  return Math.round(usagePercentage * 100);
};

// export const getUsedDataString = (dataUsage: number, dataCapacity: number) => {
//   if (dataUsage === DATAUSAGE_NOT_AVAILABLE) {
//     dataUsage = 0;
//   }

//   const usedData =
//     dataUsage > 1024
//       ? (dataUsage / 1024).toFixed(2) + ' GB '
//       : getFormatData(dataUsage) + '.00 MB ';

//   const capacityString =
//     dataCapacity === DATACAPACITY_UNLIMITED
//       ? 'Unlimited'
//       : (dataCapacity / 1.0).toFixed(2) + ' GB';

//   // used to be usedData + "/" + capacityString.
//   return usedData;
// };

export const getUsedDataString = (dataUsage: number) => {
  if (dataUsage === DATAUSAGE_NOT_AVAILABLE) {
    dataUsage = 0;
  }

  const usedData =
    dataUsage > 1024
      ? (dataUsage / 1024).toFixed(2) + ' GB '
      : getFormatData(dataUsage) + '.00 MB ';

  return usedData;
};

export const getDataCapacityString = (dataCapacity: number) => {
  const capacityString =
    dataCapacity === DATACAPACITY_UNLIMITED ? 'Unlimited' : (dataCapacity / 1.0).toFixed(2) + ' GB';

  // used to be usedData + "/" + capacityString.
  return capacityString;
};

export const getRemainingDataString = (
  dataUsage: number,
  dataCapacity: number,
  options: { short?: boolean } = { short: false }
) => {
  if (dataCapacity === DATACAPACITY_UNLIMITED) {
    return options.short ? '∞' : 'Unlimited';
  }

  if (dataUsage === DATAUSAGE_NOT_AVAILABLE) {
    dataUsage = 0;
  }

  const remainingData = dataCapacity - dataUsage / 1024;
  return options.short ? remainingData.toFixed(2) : remainingData.toFixed(2) + ' GB';
};

const getFormatData = (data: number) => {
  let r = data;
  let formattedData = '';
  while (r >= 1000) {
    let remains = (r % 1000) as number | string;
    remains =
      Number(remains) >= 100
        ? remains
        : Number(remains) >= 10
          ? '0' + remains
          : Number(remains) >= 0
            ? '00' + remains
            : '000';
    formattedData = formattedData + ',' + remains;
    r = Math.floor(r / 1000);
  }
  formattedData = r + '' + formattedData;
  return formattedData;
};

// There is another function in this file to return date as object of all values for better control
// function getDetailedDate
export const getDataResetDate = () => {
  const dateObject = new Date();
  let formattedDate;

  const today = new Date(dateObject.getTime() - Math.abs(dateObject.getTimezoneOffset() * 60000));

  // CHECK FOR 1st DAY OF MONTH
  if (today.getDate() === 1) {
    const month = today.getMonth() + 1;
    let monthString = month.toString();
    if (monthString.length === 1) monthString = '0' + monthString;
    formattedDate = today.getFullYear() + '-' + monthString + '-' + '02';
    return formattedDate;
  }

  // all logic for incrementing the year / month
  let newYear = today.getFullYear();
  let newMonth = today.getMonth() + 2;

  if (newMonth === 13) {
    newMonth = 1;
    newYear = newYear + 1;
  }

  let newMonthString = newMonth.toString();

  if (newMonthString.length === 1) {
    newMonthString = '0' + newMonthString;
  }

  formattedDate = `${newYear}-${newMonthString}-02`;
  return formattedDate;
};

// Validator for input fields
// const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// export const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=`~{}|[\]\\;':",./<>?])[A-Za-z\d!@#$%^&*()_+\-=`~{}|[\]\\;':",./<>?]{8,32}$/;

export const validate = (type: 'email' | 'password' | 'phone-number', value: string) => {
  let valid = false;
  switch (type) {
    case 'email':
      valid = emailReg.test(value);
      break;
    case 'password':
      valid = passwordRegex.test(value);
      break;
    case 'phone-number':
      valid = phoneNumberRegex.test(value);
      break;
    default:
      throw new Error('VALIDATE TYPE IS NOT CORRECT');
  }
  return valid;
};

export const getProfileImage = (index: number) => {
  if (index >= 0 && index < profileImages.length) {
    return profileImages[index];
  }
  return profileImages[1];
};

export const getDetailedDate = (date?: Date) => {
  // Get current date if no date is provided
  date = date ?? new Date();

  // Helper functions for formatting
  const pad = (num: number | string) => num.toString().padStart(2, '0');

  const formatDate = (date: Date, withDate?: boolean) => {
    let options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
    if (withDate) {
      options = { ...options, hour: '2-digit', minute: '2-digit', second: '2-digit' };
    }
    return date.toLocaleDateString('en-US', options);
  };

  // Define the date object
  const dateObj = {
    hour: date.getHours(),
    hour2Digit: pad(date.getHours()),
    minute: date.getMinutes(),
    minute2Digit: pad(date.getMinutes()),
    second: date.getSeconds(),
    second2Dogot: pad(date.getSeconds()),
    year: date.getFullYear(),
    day: date.getDay() + 1, // 1 for Sunday, 2 for Monday, etc.
    day2Digit: pad(date.getDay() + 1), // 01 for Sunday, 02 for Monday, etc.
    date: date.getDate(),
    month: date.getMonth() + 1, // 1 for January, 2 for February, etc.
    month2Digit: pad(date.getMonth() + 1), // 01 for January, 02 for February, etc.
    monthString: date.toLocaleString('default', { month: 'short' }), // Jan, Feb, etc.
    monthStringLong: date.toLocaleString('default', { month: 'long' }), // January, February, etc.
    yearShort: date.getFullYear().toString().slice(-2), // Last two digits of the year
    dayshort: date.toLocaleString('default', { weekday: 'short' }), // Mon, Tue, etc.
    dayLong: date.toLocaleString('default', { weekday: 'long' }), // Monday, Tuesday, etc.
    fullDate: formatDate(date), // e.g., "Aug 21, 2024"
    fullDateTime: formatDate(date, true), // e.g., "Aug 21, 2024"
    dateObj: date, // The original Date object
  };

  return dateObj;
};

export const formatNumber = (toFormat: string | number) =>
  Intl.NumberFormat('en-US').format(parseInt(toFormat.toString(), 10));

export const getProratedDates = (activationDate: Date) => {
  const activationStartDate = moment(activationDate);
  let upcomingSecond = moment(activationStartDate).startOf('month').date(2);

  if (activationStartDate.isSameOrAfter(upcomingSecond, 'days')) {
    upcomingSecond = upcomingSecond.add(1, 'month');
  }
  const prorateEndDate = moment(upcomingSecond).subtract(1, 'day');

  return {
    activationStartDate,
    upcomingSecond,
    prorateEndDate,
  };
};

export const getCreditCardType = (cc: string) => {
  const amex = new RegExp('^3[47][0-9]{13}$');
  const visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
  const mastercard = new RegExp('^5[1-5][0-9]{14}$');
  const mastercard2 = new RegExp('^2[2-7][0-9]{14}$');
  if (visa.test(cc)) {
    return 'VISA';
  }
  if (amex.test(cc)) {
    return 'American Express';
  }
  if (mastercard.test(cc) || mastercard2.test(cc)) {
    return 'MasterCard';
  }
  return 'Other';
};

export const convertDataURItoBlob = (dataURI: string) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = decodeURI(dataURI.split(',')[1]);
  }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const getNextNMonths = (n: number, format = 'MMMM, YYYY'): string[] => {
  const months: string[] = [];
  const currentDate = moment();
  for (let i = 0; i < n; i++) {
    months.push(currentDate.format(format));
    currentDate.add(1, 'months');
  }
  return months;
};
