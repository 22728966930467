import { useEffect, useState } from 'react';
import { SafeAreaView, ScrollView, View } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';

import Button from '../../components/Button';
import CanadaPostAutoCompleteAddress from '../../components/CanadaPostAutoCompleteAddress';
import GoogleAutoCompleteAddress from '../../components/GoogleAutoCompleteAddress';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { accountService } from '../../services/MyAccount/AccountService';
import { activationService } from '../../services/MyAccount/ActivationService';
import { CustomerShippingOrder } from '../../types/models/models';
import DevLog from '../../utilities/debug-error';

const UpdateAddressView = ({
  selectingShippingOrder,
  setLoading,
  loading,
  onCancel,
  onDone,
}: {
  selectingShippingOrder: CustomerShippingOrder | null;
  setLoading(status: boolean): void;
  loading: boolean;
  onCancel(): void;
  onDone(): void;
}) => {
  const [_, setCountry] = useState<string>('Canada');
  const [countryId, setCountryId] = useState<number>(42);
  const [countryIso, setCountryIso] = useState<string>('CA');
  const [street, setStreet] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [province, setProvince] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [unitNumber, setUnitNumber] = useState<string>('');
  const [isAddressValid, setIsAddressValid] = useState<boolean>(false);

  const [error, setError] = useState<null | string>(null);

  const [openCountry, setOpenCountry] = useState(false);
  const [newSelectedCountry, setNewSelectedCountry] = useState('42'); // Default Canada
  const [showAddressSearch, setShowAddressSearch] = useState(false);

  const [countryList, setCountryList] = useState<
    Array<{
      CountryCode: string;
      CountryCodeISO: string;
      CountryName: string;
    }>
  >([]);

  useEffect(() => {
    if (street === '' || city === '' || province === '' || postalCode === '') {
      setIsAddressValid(false);
    } else {
      setIsAddressValid(true);
    }
  }, [street, city, province, postalCode]);

  useEffect(() => {
    setLoading(true);
    const getCountriesAsync = async () => {
      const getCountriesResponse = await activationService.getCountries();

      if (!getCountriesResponse || !getCountriesResponse.data) {
        setError('Unable to Fetch Countries');
        return;
      }

      if (getCountriesResponse && getCountriesResponse.data) {
        setCountryList(getCountriesResponse.data);
      }
    };
    getCountriesAsync()
      .catch((err) => DevLog.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getShippingAddressAutoComplete = (full_address: string[]) => {
    setStreet(full_address[0]);
    setCity(full_address[1]);
    setPostalCode(full_address[4]);
    setProvince(full_address[6]);
  };

  const handleChangeValueFromCountryDropDown = (item: string | null) => {
    if (item) {
      setShowAddressSearch(true);
    }
    const selectedCountry = countryList.find((country) => country.CountryCode === item);
    if (selectedCountry) {
      setCountryIso(selectedCountry.CountryCodeISO);
      setCountry(selectedCountry.CountryName);
      setCountryId(Number(selectedCountry.CountryCode));
    } else {
      setCountryIso('');
      setCountry('');
      setCountryId(-1);
    }
    setStreet('');
    setCity('');
    setProvince('');
    setPostalCode('');
  };

  const AddressInput = () => {
    return (
      <View style={{ marginBottom: 10, marginTop: 16 }}>
        <CustomText style={{ fontSize: 12, color: Colors.greyColor, marginBottom: 6 }} weight="500">
          Search Address to Update
        </CustomText>
        {countryIso === 'CA' || countryIso === 'US' ? (
          <CanadaPostAutoCompleteAddress
            onAutoCompleteAddress={getShippingAddressAutoComplete}
            selectedCountry={countryIso || 'CA'}
          />
        ) : (
          <GoogleAutoCompleteAddress
            onAutoCompleteAddress={getShippingAddressAutoComplete}
            country={countryIso}
          />
        )}
      </View>
    );
  };

  return (
    <>
      <SafeAreaView style={{ flex: 1, height: '100%' }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{
            backgroundColor: Colors.white,
            flex: 1,
            height: '100%',
            marginBottom: 130,
          }}
        >
          {error ? (
            <View style={{ marginVertical: 14 }}>
              <CustomText
                weight="500"
                style={{ fontSize: 14, color: Colors.errorRed, textAlign: 'center' }}
              >
                {error}
              </CustomText>
            </View>
          ) : null}
          <View style={{ marginBottom: 10, zIndex: 998, marginTop: 16 }}>
            <CustomText
              style={{ fontSize: 12, color: Colors.greyColor, marginBottom: 6 }}
              weight="500"
            >
              Country
            </CustomText>
            <DropDownPicker
              searchable={true}
              open={openCountry}
              listMode="SCROLLVIEW"
              value={newSelectedCountry}
              setOpen={setOpenCountry}
              setValue={setNewSelectedCountry}
              onChangeValue={handleChangeValueFromCountryDropDown}
              schema={{
                label: 'CountryName',
                value: 'CountryCode',
              }}
              placeholder="Select a Country"
              // @ts-expect-error: items shounld be ItemType<string>[]
              // but since we defined schema we can use
              // {CountryCode: string;CountryCodeISO: string;CountryName: string;}[],
              // it still gives type error.
              items={countryList}
            />
          </View>
          {showAddressSearch ? <AddressInput /> : null}
          <InputWithTitle title="Street Name *" inputValue={street} onInputChange={setStreet} />
          <InputWithTitle title="City *" inputValue={city} onInputChange={setCity} />
          <InputWithTitle title="Province *" inputValue={province} onInputChange={setProvince} />
          <InputWithTitle
            title="Postal Code *"
            inputValue={postalCode}
            onInputChange={setPostalCode}
          />
          <InputWithTitle
            title="Unit # (Optional)"
            inputValue={unitNumber}
            onInputChange={setUnitNumber}
          />
        </ScrollView>
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            alignSelf: 'center',
          }}
        >
          <Button
            style={{ marginTop: 32 }}
            disabled={!isAddressValid || loading}
            onPress={async () => {
              if (!selectingShippingOrder) {
                return;
              }
              try {
                setLoading(true);
                await accountService.updateShippingOrderAddress({
                  type: selectingShippingOrder.Type,
                  shippingOrderId: selectingShippingOrder.ShippingOrderId,
                  city: city,
                  countryId: countryId,
                  postalCode: postalCode,
                  province: province,
                  streetAddress: `${unitNumber ? unitNumber + '-' : ''}${street}`,
                });
                onDone();
              } catch (_error) {
                DevLog.log(_error);
              } finally {
                setLoading(false);
              }
            }}
            textStyle={{ fontSize: 14 }}
          >
            Resubmit Shipping Address
          </Button>
          <Button
            style={{ marginTop: 10 }}
            color={Colors.backgroundGrey}
            textColor={Colors.TextBlack}
            onPress={() => {
              onCancel();
            }}
          >
            <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
              Cancel
            </CustomText>
          </Button>
        </View>
      </SafeAreaView>
    </>
  );
};

export default UpdateAddressView;
