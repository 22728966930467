import { Feather } from '@expo/vector-icons';
import { useEffect, useState } from 'react';
import { Image, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';

import CheckoutView from './CheckoutView';
import InitialView from './InitialView';
import { AppDrawer } from '../../components/AppDrawer';
import AppGradientContainer from '../../components/AppGradientContainer';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { HERO_GRADIENT } from '../../constants/constants';
import { accountService } from '../../services/MyAccount/AccountService';
import { billingService } from '../../services/MyAccount/BillingService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import { RootStackScreenProps } from '../../types';
import { PaymentMethods } from '../../types/enums/enums';
import { PaymentType } from '../../types/types';
import DevLog from '../../utilities/debug-error';
import { isWebsite, paymentUrl } from '../../utilities/functions';

const gradientInfo = HERO_GRADIENT.default;

enum FlowStep {
  Initial = 'initial',
  Checkout = 'checkout',
}

export default function BillingPayBalanceScreen({
  navigation,
}: RootStackScreenProps<'BillingPayBalance'>) {
  const [step, setStep] = useState<FlowStep>(FlowStep.Initial);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useDispatch();

  const { CurrentBalance } = useSelector((state: RootState) => state.selfServeCA.accountInfoCA);

  const [amountToPay, setAmountToPay] = useState<string>(CurrentBalance.toFixed(2));
  const [_, setAliPayWeChatPayId] = useState<string | null>(null);
  const [qrCodeURL, setQrCodeURL] = useState<string | null>(null);

  let aliPayWeChatPayCheckTimer: NodeJS.Timeout | number | null = null;

  useEffect(() => {
    return () => {
      if (aliPayWeChatPayCheckTimer !== null) {
        clearInterval(aliPayWeChatPayCheckTimer);
      }
    };
  }, []);

  const generatePaymentUrl = async (paymentInfo: {
    paymentTypeSlug: PaymentType;
    paymentType: PaymentMethods;
  }): Promise<{
    status: boolean;
    message: string;
    data: null | {
      url: string;
      paymentType: 'alipay_wap' | PaymentMethods;
    };
  }> => {
    setError(null);
    dispatch(setIsLoading(true));
    try {
      let type: 'alipay_wap' | PaymentMethods = paymentInfo.paymentType;
      if (type === 'alipay_qr') {
        type = 'alipay_wap';
      } else if (type === PaymentMethods.GOOGLE_PAY) {
        type = PaymentMethods.CREDIT_CARD;
      }

      const response = await accountService.customerBilling({
        amount: parseFloat(amountToPay),
        billtype: type,
        cancel_url: paymentUrl('cancel'),
        return_url: paymentUrl('return'),
        systag: 'mobileapp',
      });
      DevLog.log(response);
      if (response.data.substring(0, 6) === 'Failed') {
        return { status: false, message: 'Failed to complete payment', data: null };
      }
      let redirectUrl = response.data;
      if (type === 'alipay_wap') {
        redirectUrl = redirectUrl.split('^')[0];
      }
      return {
        status: true,
        data: {
          url: redirectUrl,
          paymentType: type,
        },
        message: 'Redirect URL Created!',
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return { status: false, message: error.message, data: null };
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  return (
    <>
      {error ? (
        <CustomText
          weight="500"
          style={{
            position: 'absolute',
            bottom: '25%',
            left: 0,
            right: 0,
            backgroundColor: Colors.redOpacity50,
            zIndex: 10,
            textAlign: 'center',
            color: Colors.white,
            padding: 14,
            borderRadius: 14,
            width: '100%',
            margin: 'auto',
            fontSize: 18,
          }}
        >
          {error}
        </CustomText>
      ) : null}
      <AppGradientContainer
        autoHeight
        colorStart={gradientInfo.start}
        colorEnd={gradientInfo.end}
        direction={gradientInfo.direction}
      >
        <SafeAreaView
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 16,
            paddingHorizontal: 20,
          }}
        >
          <Feather
            name="chevron-left"
            size={24}
            color={Colors.white}
            onPress={() => {
              DevLog.log('HERE');
              if (step === FlowStep.Initial) {
                navigation.goBack();
              } else {
                setStep(FlowStep.Initial);
              }
            }}
            style={isWebsite() ? { marginLeft: 20 } : {}}
          />
          <CustomText
            weight="500"
            style={{
              fontSize: 16,
              flex: 1,
              textAlign: 'center',
              color: Colors.white,
              marginLeft: -20,
              zIndex: -1,
            }}
          >
            Pay Bill
          </CustomText>
        </SafeAreaView>
      </AppGradientContainer>
      {step === FlowStep.Initial ? (
        <InitialView
          currentBalance={CurrentBalance}
          onNext={(amountToPay) => {
            if (isNaN(parseFloat(amountToPay)) || parseFloat(amountToPay) === 0) {
              return;
            }
            setAmountToPay(amountToPay);
            setStep(FlowStep.Checkout);
            setError(null);
          }}
        />
      ) : (
        <CheckoutView
          onNext={async (paymentInfo) => {
            try {
              const redirectInfo = await generatePaymentUrl(paymentInfo);
              if (!redirectInfo.status) {
                throw new Error(redirectInfo.message);
              }
              if (!redirectInfo.data) {
                throw new Error('Unable to process payment info. Please try again.');
              }
              if (
                redirectInfo.data.paymentType === PaymentMethods.ALIPAY_QR ||
                redirectInfo.data.paymentType === PaymentMethods.WECHAT_PAY
              ) {
                const data = redirectInfo.data.url.split('^');
                if (data.length === 2) {
                  setAliPayWeChatPayId(data[0]);
                  setQrCodeURL(data[1]);
                  if (aliPayWeChatPayCheckTimer !== null) {
                    clearInterval(aliPayWeChatPayCheckTimer);
                  }
                  aliPayWeChatPayCheckTimer = setInterval(async () => {
                    try {
                      const response = await billingService.getIotPayStatus(data[0]);
                      if (response.data === 'Approved') {
                        if (aliPayWeChatPayCheckTimer !== null) {
                          clearInterval(aliPayWeChatPayCheckTimer);
                        }
                        navigation.navigate('PaymentSuccess');
                        dispatch(setIsLoading(false));
                      }
                    } catch (error) {
                      DevLog.log(error);
                    }
                  }, 1000);
                } else {
                  dispatch(setIsLoading(false));
                  DevLog.log('image wrong format');
                }
              } else {
                if (Platform.OS !== 'web') {
                  navigation.navigate('PaymentWebView', {
                    url: redirectInfo.data.url,
                    paymentType: redirectInfo.data.paymentType,
                  });
                } else {
                  window.location.href = redirectInfo.data.url;
                }
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e: any) {
              setError(e.message);
            }
          }}
          amountToPay={amountToPay}
        />
      )}
      <AppDrawer
        visible={qrCodeURL !== '' && qrCodeURL !== null}
        height={485}
        onClose={() => {
          setQrCodeURL(null);
          setAliPayWeChatPayId(null);
          if (aliPayWeChatPayCheckTimer !== null) {
            clearInterval(aliPayWeChatPayCheckTimer);
          }
        }}
        showBackDrop
      >
        <CustomText weight="500" style={{ fontSize: 24, marginBottom: 16 }}>
          Please scan the QR code to finish payment
        </CustomText>
        <HorizontalLine thinkness={1} />

        {qrCodeURL ? (
          <Image
            source={{
              uri: qrCodeURL,
            }}
            width={200}
            height={200}
            style={{
              width: 200,
              height: 200,
              alignSelf: 'center',
            }}
            resizeMode="contain"
          />
        ) : null}

        <Button
          style={{ marginTop: 16 }}
          color={Colors.backgroundGrey}
          textColor={Colors.inputTextPlaceholderColor}
          onPress={() => {
            setQrCodeURL(null);
            setAliPayWeChatPayId(null);
            if (aliPayWeChatPayCheckTimer !== null) {
              clearInterval(aliPayWeChatPayCheckTimer);
            }
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Cancel
        </Button>
      </AppDrawer>
    </>
  );
}
