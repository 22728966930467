import { LinearGradient } from 'expo-linear-gradient';
import { ReactNode } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';

import { Colors } from '../constants/Colors';
import { GradientDirection } from '../types/types';

interface AppGradientContainerProps {
  children: ReactNode;
  colorLeft?: string; // start
  colorRight?: string; // end
  colorStart?: string; // start
  colorEnd?: string; // end
  direction?: GradientDirection;
  autoHeight?: boolean;
  style?: ViewStyle;
}

export default function AppGradientContainer(props: AppGradientContainerProps) {
  const direction = {
    'TOP-DOWN': { start: { x: 0, y: 0 }, end: { x: 0, y: 1 } },
    'DOWN-TOP': { start: { x: 0, y: 1 }, end: { x: 0, y: 0 } },
    'LEFT-RIGHT': { start: { x: 0, y: 0 }, end: { x: 1, y: 0 } },
    'RIGHT-LEFT': { start: { x: 1, y: 0 }, end: { x: 0, y: 0 } },
  }[props.direction ?? 'TOP-DOWN'];

  const styles = StyleSheet.create({
    background: {
      // flex: props.autoHeight ? 0 : 1,
      height: props.autoHeight ? 'auto' : '100%',
    },
  });

  return (
    <LinearGradient
      style={[styles.background, { ...props.style }]}
      colors={[
        props.colorStart ?? props.colorLeft ?? Colors.gradientDarkBlue,
        props.colorEnd ?? props.colorRight ?? Colors.gradientLightBlue,
      ]}
      start={direction.start}
      end={direction.end}
    >
      {props.children}
    </LinearGradient>
  );
}
