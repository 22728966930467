import { useEffect, useState } from 'react';
import { SafeAreaView, StatusBar } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import InitialView from './InitialView';
import MarkReceivedView from './MarkReceivedView';
import UpdateAddressView from './UpdateAddressView';
import { Colors } from '../../constants/Colors';
import { accountService } from '../../services/MyAccount/AccountService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import {
  setCustomerShippingOrders,
  setSelectedCustomerShippingOrders,
} from '../../store/selfServeCA';
import { RootStackScreenProps } from '../../types';

enum FlowStep {
  Initial = 'initial',
  MarkReceived = 'mark-received',
  ChangeAddress = 'change-address',
}

const ShippingTrackingScreen = ({
  navigation,
  route,
}: RootStackScreenProps<'ShippingTracking'>) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState<FlowStep>(FlowStep.Initial);

  const userEmail = useSelector((state: RootState) => state.user.email);
  const { isLoading } = useSelector((state: RootState) => state.app);
  const { selectingShippingOrder } = useSelector((state: RootState) => ({
    selectingShippingOrder: state.selfServeCA.selectingShippingOrder,
  }));

  useEffect(() => {
    const fetchOrders = async () => {
      const response = await accountService.getCustomerShippingOrders(userEmail);
      const orders = response.data;
      dispatch(setCustomerShippingOrders(orders));
      const findOrder = orders.find((o) => o.ShippingOrderId == route.params.tracking);
      if (!findOrder) {
        navigation.navigate('Root');
        return;
      }
      dispatch(setSelectedCustomerShippingOrders(findOrder));
    };
    if (
      !selectingShippingOrder ||
      selectingShippingOrder.ShippingOrderId !== route.params.tracking
    ) {
      fetchOrders();
    }
  }, [route.params.tracking]);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        height: '100%',
        marginTop: StatusBar.currentHeight,
        backgroundColor: Colors.white,
        paddingVertical: 24,
        paddingHorizontal: 20,
        position: 'relative',
      }}
    >
      {selectingShippingOrder ? (
        step === FlowStep.Initial ? (
          <InitialView
            selectingShippingOrder={selectingShippingOrder!}
            onMarkedReceived={() => {
              setStep(FlowStep.MarkReceived);
            }}
            onUpdateAddress={() => {
              setStep(FlowStep.ChangeAddress);
            }}
          />
        ) : step === FlowStep.MarkReceived ? (
          <MarkReceivedView
            selectingShippingOrder={selectingShippingOrder}
            onComplete={() => {
              navigation.navigate('StatusTracking');
            }}
            setLoading={(status) => {
              dispatch(setIsLoading(status));
            }}
            onCancel={() => {
              navigation.goBack();
            }}
          />
        ) : (
          <UpdateAddressView
            selectingShippingOrder={selectingShippingOrder}
            loading={isLoading}
            setLoading={(status) => {
              dispatch(setIsLoading(status));
            }}
            onCancel={() => {
              setStep(FlowStep.Initial);
            }}
            onDone={() => {
              navigation.navigate('StatusTracking');
            }}
          />
        )
      ) : null}
    </SafeAreaView>
  );
};

export default ShippingTrackingScreen;
