import { ActivityIndicator, StyleSheet, View } from 'react-native';

import { Colors } from '../../constants/Colors';

type Props = {
  loading: boolean;
};

const FullScreenLoader = ({ loading }: Props) => {
  return loading ? (
    <View style={styles.loaderContainer}>
      <ActivityIndicator color={Colors.white} size={'large'} />
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  loaderContainer: {
    alignItems: 'center',
    backgroundColor: Colors.overlay40,
    bottom: 0,
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  },
});

export default FullScreenLoader;
