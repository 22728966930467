import { Image, ScrollView, View } from 'react-native';

import CheckmarkIconImage from '../../assets/icons/checkmark.png';
import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { RootStackScreenProps } from '../../types';
import { isWebsite } from '../../utilities/functions';

const LostOrStolenSuccessScreen = ({ navigation }: RootStackScreenProps<'LostOrStolenSuccess'>) => {
  return (
    <View
      style={{
        position: 'relative',
        padding: 20,
        paddingTop: 32,
        backgroundColor: Colors.white,
        flex: 1,
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginBottom: 150, paddingBottom: 20 }}
      >
        <CustomText
          weight="600"
          style={{ fontSize: 24, textAlign: 'center', color: Colors.canadaButtonColor }}
        >
          Lost or Stolen Hold Requested!
        </CustomText>
        <CustomText
          style={{ fontSize: 14, marginTop: 16, textAlign: 'center', color: Colors.TextBlack }}
        >
          Your line is now suspended. Please request a new SIM card, and once it arrives, contact
          our customer service to reactivate your current phone number with the new SIM.
          Alternatively, you can contact our customer service directly to request an eSIM. Note that
          an invoice will still be issued, and payment will be required to maintain your service
          with us.
        </CustomText>
        <Image
          source={CheckmarkIconImage}
          style={{
            width: 220,
            height: 220,
            marginHorizontal: 'auto',
            marginTop: 100,
            alignSelf: 'center',
          }}
          resizeMode="contain"
        />
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            paddingVertical: 40,
            paddingHorizontal: 20,
            gap: 8,
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button style={{ marginTop: 32 }} onPress={() => {}} textStyle={{ fontSize: 14 }}>
          Request New SIM Card
        </Button>
        <Button
          color={Colors.backgroundGrey}
          textColor={Colors.greyColor}
          onPress={() => {
            navigation.goBack();
          }}
        >
          Back To The Main Home
        </Button>
      </View>
    </View>
  );
};

export default LostOrStolenSuccessScreen;
