import { ReactNode, useEffect, useState } from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { Gesture, GestureDetector, Directions } from 'react-native-gesture-handler';
import Animated, { Easing, useSharedValue, withTiming } from 'react-native-reanimated';
import { useDispatch } from 'react-redux';

import { Colors } from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import { setDrawerVisible } from '../store/app';

interface AppDrawerProps {
  children: ReactNode;
  height: number;
  visible: boolean;
  onClose: () => void;
  rmBottomTab?: boolean;
  showBackDrop?: boolean;
  containerStyle?: ViewStyle;
}

export function AppDrawer(props: AppDrawerProps) {
  const dispatch = useDispatch();
  const drawerHeight = useSharedValue(0);
  const drawerOpacity = useSharedValue(0);
  // "initVisible" is for the initial visible => true value set
  // it prevents glitches caused by differences in when the drawer is visible and how long the animation should last.
  const [initVisible, setInitVisible] = useState(false);

  useEffect(() => {
    if (props.visible) {
      setInitVisible(true);
      openDrawerAnimation();

      // for pages where we must hide the bottom tab
      if (props.rmBottomTab) {
        dispatch(setDrawerVisible(true));
      }
    } else if (!props.visible) {
      closeDrawerAnimation();

      // for pages where we must hide the bottom tab
      if (props.rmBottomTab) {
        dispatch(setDrawerVisible(false));
      }
    }
  }, [props.visible]);

  const closeDrawerAnimation = () => {
    drawerHeight.value = withTiming(0, {
      duration: 800,
      easing: Easing.elastic(1.1),
    });
    drawerOpacity.value = withTiming(0, {
      duration: 400,
    });
  };

  const openDrawerAnimation = () => {
    drawerHeight.value = withTiming(props.height, {
      duration: 800,
      easing: Easing.elastic(1.1),
    });
    drawerOpacity.value = withTiming(1, {
      duration: 800,
    });
  };

  const fling = Gesture.Fling()
    .direction(Directions.DOWN)
    .runOnJS(true)
    .onStart(() => {
      props.onClose();
    });

  return (
    initVisible &&
    props.visible && (
      <>
        {props.showBackDrop && (
          <View
            style={{
              ...StyleSheet.absoluteFillObject,
              // width: '100%',
              // height: '100%',
              backgroundColor: Colors.overlay40,
              zIndex: 100,
            }}
          />
        )}
        <GestureDetector gesture={fling}>
          <Animated.View
            style={[
              styles.drawer,
              { height: drawerHeight, opacity: drawerOpacity },
              props.containerStyle ? props.containerStyle : {},
            ]}
          >
            <View style={CommonStyles.flexCenter}>
              <View
                style={{
                  backgroundColor: Colors.bottomDrawerHandle,
                  width: 90,
                  marginTop: -40,
                  height: 6,
                  borderRadius: 3,
                }}
              ></View>
            </View>
            {props.children}
          </Animated.View>
        </GestureDetector>
      </>
    )
  );
}

const styles = StyleSheet.create({
  drawer: {
    position: 'absolute',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingVertical: 40,
    paddingHorizontal: 30,
    backgroundColor: Colors.white,
    // shadowColor: 'black',
    // shadowOpacity: 0.4,
    // shadowRadius: 10,
    bottom: 0,
    left: 0,
    right: 0,
    alignSelf: 'flex-start',
    zIndex: 100,
  },
});
