import { SafeAreaView, View, Text } from 'react-native';

import AppGradientContainer from '../components/AppGradientContainer';
import Button from '../components/Button';
import { Colors } from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import { RootStackScreenProps } from '../types';

export default function GenericSuccessScreen({
  navigation,
  route,
}: RootStackScreenProps<'GenericSuccess'>) {
  const onPressClose = () => {
    navigation.navigate('Root');
  };

  return (
    <AppGradientContainer>
      <SafeAreaView style={{ ...CommonStyles.container, display: 'flex', height: '100%' }}>
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={[CommonStyles.title, CommonStyles.whiteText]}>{route.params.message}</Text>
        </View>
        <View style={CommonStyles.bottomContainer}>
          <Button color={Colors.white} textColor={Colors.green} onPress={onPressClose}>
            Close
          </Button>
        </View>
      </SafeAreaView>
    </AppGradientContainer>
  );
}
