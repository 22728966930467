import { useEffect, useState } from 'react';
import { SafeAreaView, ScrollView, View } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import CanadaPostAutoCompleteAddress from '../../components/CanadaPostAutoCompleteAddress';
import GoogleAutoCompleteAddress from '../../components/GoogleAutoCompleteAddress';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { activationService } from '../../services/MyAccount/ActivationService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import { ShippingOption } from '../../types/models/ShippingOption';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';

export type ShippingAddress = {
  fullAddress: string;
  unitNumber: string;
  street: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  countryId: number;
};
type Props = {
  address: ShippingAddress | null;
  onNext(address: ShippingAddress, shippingOption: ShippingOption[]): void;
};

export default function ShippingAddressView(props: Props) {
  const [country, setCountry] = useState<string>('Canada');
  const [countryId, setCountryId] = useState<number>(42);
  const [countryIso, setCountryIso] = useState<string>('CA');
  const [street, setStreet] = useState<string>(props.address?.street ?? '');
  const [city, setCity] = useState<string>(props.address?.city ?? '');
  const [province, setProvince] = useState<string>(props.address?.province ?? '');
  const [postalCode, setPostalCode] = useState<string>(props.address?.postalCode ?? '');
  const [unitNumber, setUnitNumber] = useState<string>(props.address?.unitNumber ?? '');
  const [isAddressValid, setIsAddressValid] = useState<boolean>(props.address !== null);
  const [shippingOption, setShippingOption] = useState<ShippingOption[]>([]);

  const [error, setError] = useState<null | string>(null);

  const [openCountry, setOpenCountry] = useState(false);
  const [newSelectedCountry, setNewSelectedCountry] = useState('42'); // Default Canada
  const [showAddressSearch, setShowAddressSearch] = useState(false);

  const [countryList, setCountryList] = useState<
    Array<{
      CountryCode: string;
      CountryCodeISO: string;
      CountryName: string;
    }>
  >([]);

  const { isLoading } = useSelector((state: RootState) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    if (street === '' || city === '' || province === '' || postalCode === '') {
      setIsAddressValid(false);
    } else {
      setIsAddressValid(true);
    }
  }, [street, city, province, postalCode]);

  useEffect(() => {
    dispatch(setIsLoading(true));
    const getCountriesAsync = async () => {
      const getCountriesResponse = await activationService.getCountries();

      if (!getCountriesResponse || !getCountriesResponse.data) {
        setError('Unable to Fetch Countries');
        return;
      }

      if (getCountriesResponse && getCountriesResponse.data) {
        setCountryList(getCountriesResponse.data);
      }
    };
    getCountriesAsync()
      .catch((err) => DevLog.error(err))
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  }, []);

  const fetchShippingOption = async (countryId: number) => {
    try {
      dispatch(setIsLoading(true));
      const response = await activationService.getShippingOptions(countryId);
      setShippingOption(response.data);
      DevLog.log(response.data);
    } catch (error) {
      DevLog.log('Error: ', error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    if (countryId === -1) {
      return;
    }
    fetchShippingOption(countryId);
  }, [countryId]);

  const getShippingAddressAutoComplete = (full_address: string[]) => {
    setStreet(full_address[0]);
    setCity(full_address[1]);
    setPostalCode(full_address[4]);
    setProvince(full_address[6]);
  };

  const handleChangeValueFromCountryDropDown = (item: string | null) => {
    if (item) {
      setShowAddressSearch(true);
    }
    const selectedCountry = countryList.find((country) => country.CountryCode === item);
    if (selectedCountry) {
      setCountryIso(selectedCountry.CountryCodeISO);
      setCountry(selectedCountry.CountryName);
      setCountryId(Number(selectedCountry.CountryCode));
    } else {
      setCountryIso('');
      setCountry('');
      setCountryId(-1);
    }
    setStreet('');
    setCity('');
    setProvince('');
    setPostalCode('');
  };

  const AddressInput = () => {
    return (
      <View
        style={[
          { marginBottom: 10, marginTop: 16 },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <CustomText style={{ fontSize: 12, color: Colors.greyColor, marginBottom: 6 }} weight="500">
          Search Address to Update
        </CustomText>
        {countryIso === 'CA' || countryIso === 'US' ? (
          <CanadaPostAutoCompleteAddress
            onAutoCompleteAddress={getShippingAddressAutoComplete}
            selectedCountry={countryIso || 'CA'}
          />
        ) : (
          <GoogleAutoCompleteAddress
            onAutoCompleteAddress={getShippingAddressAutoComplete}
            country={countryIso}
          />
        )}
      </View>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          padding: 20,
          backgroundColor: Colors.white,
          flex: 1,
          height: '100%',
          marginBottom: 100,
        }}
        automaticallyAdjustKeyboardInsets={true}
      >
        {error ? (
          <View style={{ marginVertical: 14 }}>
            <CustomText
              weight="500"
              style={{ fontSize: 14, color: Colors.errorRed, textAlign: 'center' }}
            >
              {error}
            </CustomText>
          </View>
        ) : null}
        <View
          style={[
            { marginBottom: 10, zIndex: 998, marginTop: 16 },
            isWebsite()
              ? {
                  maxWidth: 400,
                  marginHorizontal: 'auto',
                  width: '100%',
                }
              : null,
          ]}
        >
          <CustomText
            style={{ fontSize: 12, color: Colors.greyColor, marginBottom: 6 }}
            weight="500"
          >
            Country
          </CustomText>
          <DropDownPicker
            searchable={true}
            open={openCountry}
            listMode="SCROLLVIEW"
            value={newSelectedCountry}
            setOpen={setOpenCountry}
            setValue={setNewSelectedCountry}
            onChangeValue={handleChangeValueFromCountryDropDown}
            schema={{
              label: 'CountryName',
              value: 'CountryCode',
            }}
            placeholder="Select a Country"
            // @ts-expect-error: items shounld be ItemType<string>[]
            // but since we defined schema we can use
            // {CountryCode: string;CountryCodeISO: string;CountryName: string;}[],
            // it still gives type error.
            items={countryList}
          />
        </View>
        {showAddressSearch ? <AddressInput /> : null}
        <InputWithTitle
          title="Street Name *"
          inputValue={street}
          onInputChange={setStreet}
          onFocus={() => setOpenCountry(false)}
        />
        <InputWithTitle
          title="City *"
          inputValue={city}
          onInputChange={setCity}
          onFocus={() => setOpenCountry(false)}
        />
        <InputWithTitle
          title="Province *"
          inputValue={province}
          onInputChange={setProvince}
          onFocus={() => setOpenCountry(false)}
        />
        <InputWithTitle
          title="Postal Code *"
          inputValue={postalCode}
          onInputChange={setPostalCode}
          onFocus={() => setOpenCountry(false)}
        />
        <InputWithTitle
          title="Unit # (Optional)"
          inputValue={unitNumber}
          onInputChange={setUnitNumber}
          onFocus={() => setOpenCountry(false)}
        />
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          style={{ marginTop: 32 }}
          disabled={!isAddressValid || isLoading}
          onPress={() => {
            const fullAddress = `${
              unitNumber ? unitNumber + '-' : ''
            }${street}, ${city}, ${province} ${postalCode}, ${country}`;
            props.onNext(
              {
                fullAddress,
                unitNumber,
                street,
                city,
                province,
                postalCode,
                country,
                countryId,
              },
              shippingOption
            );
          }}
          textStyle={{ fontSize: 14 }}
        >
          Continue
        </Button>
      </View>
    </SafeAreaView>
  );
}
