import { StyleSheet } from 'react-native';

import { Colors } from './Colors';
import { isWebsite } from '../utilities/functions';

const CommonStyles = StyleSheet.create({
  blackText: {
    color: Colors.black,
  },
  boldText: {
    fontFamily: 'FuturaCyrillicBold',
  },
  bottomContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 25,
  },
  card: {
    borderRadius: 10,
    padding: 15,
  },
  container: {
    marginHorizontal: 20,
  },
  errorText: {
    color: Colors.red,
  },
  flexCenter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  futuraFont: {
    fontFamily: 'Futura',
  },
  greenText: {
    color: Colors.green,
  },
  input: {
    backgroundColor: Colors.lightGrey,
    borderRadius: 10,
    fontSize: 15,
    fontWeight: '300',
    height: 50,
    paddingHorizontal: 16,
    width: '100%',
  },
  inputLabel: {
    fontSize: 20,
    fontWeight: '600',
  },
  justifyRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  subtitle: {
    fontFamily: 'Futura',
    fontSize: 20,
  },
  title: {
    fontFamily: 'Futura',
    fontSize: 30,
  },
  topRow: {
    flexDirection: 'row',
  },
  webBottomSheet: isWebsite()
    ? {
        position: 'relative',
        borderRadius: 40,
        marginTop: 100,
        paddingBottom: 40,
      }
    : {},

  whiteText: {
    color: Colors.white,
    fontFamily: 'Futura',
  },
});

export default CommonStyles;
