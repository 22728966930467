import { Feather, FontAwesome } from '@expo/vector-icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { BackHandler, Image, Platform } from 'react-native';
import { useDispatch } from 'react-redux';

import ActivateSimCardView from './ActivateSimCardView';
import ActivationInfoView, { ActivationInfo } from './ActivationInfoView';
import CheckoutView from './CheckoutView';
import PortInView, { PortInInfo } from './PortInView';
import ProratedPlanView from './ProratedPlanView';
import ReviewAndPayView from './ReviewAndPayView';
import ShippingAddressView, { ShippingAddress } from './ShippingAddressView';
import ShippingOptionsView from './ShippingOptionsView';
import SimTypeView from './SimTypeView';
import { AppDrawer } from '../../components/AppDrawer';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT_FOR_API } from '../../constants/constants';
import { activationService } from '../../services/MyAccount/ActivationService';
import { billingService } from '../../services/MyAccount/BillingService';
import { setIsLoading } from '../../store/app';
import { RootStackScreenProps } from '../../types';
import { PaymentMethods } from '../../types/enums/enums';
import { ParsedPlan } from '../../types/models/ParsedPlan';
import { ShippingOption } from '../../types/models/ShippingOption';
import { PostActivationInfoPostpaidRequest } from '../../types/requests/MyAccount/PostActivationInfoPostpaidRequest';
import { PostActivationInfoPrepaidRequest } from '../../types/requests/MyAccount/PostActivationInfoPrepaidRequest';
import { GetTotalPostpaidResponse } from '../../types/responses/MyAccount/GetTotalPostpaidResponse';
import { GetTotalPrepaidResponse } from '../../types/responses/MyAccount/GetTotalPrepaidResponse';
import { PaymentType } from '../../types/types';
import DevLog from '../../utilities/debug-error';
import { isWebsite, paymentUrl } from '../../utilities/functions';

enum FlowStep {
  SelectSim = 'select-sim',
  ActivateSim = 'activate-sim',
  // ESimConpatibility = 'esim-compatibility',
  ShippingInfo = 'shipping-info',
  ShippingOptions = 'shipping-options',
  ActivationInfo = 'activation-information',
  PortIn = 'port-in',
  ProratedPlan = 'prorated-plan',
  ReviewAndPay = 'review & pay',
  Checkout = 'checkout',
  // ScanCard = 'scan-card',
}

export type SIMType = 'ESIM' | 'PhysicalSIM';

type StepOption = {
  title: string;
  previousStep: FlowStep | null;
  nextStep: FlowStep | null;
};

const stepOption: Record<FlowStep, StepOption> = {
  [FlowStep.SelectSim]: {
    title: 'SIM type',
    previousStep: null,
    nextStep: FlowStep.ActivateSim,
  },
  [FlowStep.ActivateSim]: {
    title: 'Activate SIM card',
    previousStep: FlowStep.SelectSim,
    nextStep: FlowStep.ShippingInfo,
  },
  [FlowStep.ShippingInfo]: {
    title: 'Shipping Address',
    previousStep: FlowStep.ActivateSim,
    nextStep: FlowStep.ShippingOptions,
  },
  [FlowStep.ShippingOptions]: {
    title: 'Shipping Options',
    previousStep: FlowStep.ShippingInfo,
    nextStep: FlowStep.ActivationInfo,
  },
  [FlowStep.ActivationInfo]: {
    title: 'Activation Information',
    previousStep: FlowStep.ShippingOptions,
    nextStep: FlowStep.PortIn,
  },
  [FlowStep.PortIn]: {
    title: 'Port In',
    previousStep: FlowStep.ActivationInfo,
    nextStep: FlowStep.ProratedPlan,
  },
  [FlowStep.ProratedPlan]: {
    title: 'Prorated Plan',
    previousStep: FlowStep.PortIn,
    nextStep: FlowStep.ReviewAndPay,
  },
  [FlowStep.ReviewAndPay]: {
    title: 'Review & Pay',
    previousStep: FlowStep.ProratedPlan,
    nextStep: FlowStep.Checkout,
  },
  [FlowStep.Checkout]: {
    title: 'Checkout',
    previousStep: FlowStep.ReviewAndPay,
    nextStep: null,
  },
};

export default function PurchasePlanFLowScreen({
  navigation,
  route,
}: RootStackScreenProps<'PurchasePlanFlow'>) {
  const dispatch = useDispatch();
  const plan: ParsedPlan = route.params.plan;

  const [step, setStep] = useState<FlowStep>(FlowStep.SelectSim);
  const [selectedSimType, setSelectedSimType] = useState<SIMType | null>(null);
  const [simCardNumber, setSimCardNumber] = useState<string | null>(null);
  const [shippingAddress, setShippingAddress] = useState<ShippingAddress | null>(null);
  const [activationInfo, setActivationInfo] = useState<ActivationInfo | null>(null);
  const [selectedShippingOption, setSelectedShippingOption] = useState<ShippingOption | null>(null);
  const [shippingOption, setShippingOption] = useState<ShippingOption[] | null>(null);
  const [portInInfo, setPortInInfo] = useState<PortInInfo | null>(null);
  const [totalPaymentInfo, setTotalPaymentInfo] = useState<
    GetTotalPrepaidResponse | GetTotalPostpaidResponse | null
  >(null);

  const [error, setError] = useState<string | null>(null);
  const [process, setProcess] = useState<string | null>(null);
  const [_, setAliPayWeChatPayId] = useState<string | null>(null);
  const [qrCodeURL, setQrCodeURL] = useState<string | null>(null);

  let aliPayWeChatPayCheckTimer: NodeJS.Timeout | number | null = null;

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <FontAwesome
          name="times"
          size={24}
          color={Colors.black}
          style={{ marginRight: 16 }}
          onPress={() => {
            navigation.goBack();
          }}
        />
      ),
    });
    return () => {
      if (aliPayWeChatPayCheckTimer !== null) {
        clearInterval(aliPayWeChatPayCheckTimer);
      }
    };
  }, []);

  // DevLog.log('this: ', { plan });

  useEffect(() => {
    // @ts-expect-error: Suppress error: This comparison appears to be unintentional because the types 'ParsedPlan' and 'string' have no overlap.
    if (plan === '[object Object]' || !plan) {
      navigation.replace('Plans');
      return;
    }

    if (Platform.OS === 'android') {
      const backAction = () => {
        if (stepOption[step].previousStep !== null) {
          setStep(stepOption[step].previousStep);
          return false;
        }
        return true;
      };

      const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

      return () => backHandler.remove();
    }
  }, []);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Feather
          name="chevron-left"
          size={24}
          color="black"
          onPress={() => {
            setError(null);
            if (stepOption[step].previousStep === null) {
              navigation.goBack();
            } else {
              setStep(stepOption[step].previousStep);
            }
          }}
          style={isWebsite() ? { marginLeft: 20 } : {}}
        />
      ),
      headerTitleAlign: 'center',
      title: stepOption[step].title,
    });
  }, [step]);

  useEffect(() => {
    stepOption[FlowStep.PortIn].nextStep =
      plan.planType === 'monthly' ? FlowStep.ProratedPlan : FlowStep.ReviewAndPay;
    stepOption[FlowStep.ReviewAndPay].previousStep =
      plan.planType === 'monthly' ? FlowStep.ProratedPlan : FlowStep.PortIn;
  }, [plan]);

  useEffect(() => {
    stepOption[FlowStep.ActivationInfo].previousStep =
      selectedSimType === 'ESIM'
        ? FlowStep.SelectSim
        : simCardNumber
          ? FlowStep.ActivateSim
          : FlowStep.ShippingOptions;

    stepOption[FlowStep.ActivateSim].nextStep = simCardNumber
      ? FlowStep.ActivationInfo
      : FlowStep.ShippingInfo;
  }, [selectedSimType, simCardNumber]);

  const postActivationInfo = async () => {
    if (!totalPaymentInfo || !activationInfo) {
      return 'Unable to Process your request. Please Fill all previous required info before proceeding.';
    }
    try {
      const prepaidRequest: PostActivationInfoPrepaidRequest = {
        simcardOrder: shippingAddress !== null,
        simcard_no: simCardNumber ?? '',
        startDate: moment(activationInfo?.activationDate).format(DATE_FORMAT_FOR_API),
        endDate: moment(activationInfo?.endDate).format(DATE_FORMAT_FOR_API),
        planId: plan.planId,
        firstName: activationInfo.firstname,
        lastName: activationInfo.lastname,
        email: activationInfo.emailAddress,
        serviceType: '',
        portin_carrier: portInInfo && portInInfo.currentCarrier ? portInInfo.currentCarrier : '',
        portin_accountNo: portInInfo ? portInInfo.accountNumber : '',
        portin_phoneNo: Number(portInInfo?.phoneNumber)!,
        portin_other: '',
        service_countryId: 42,
        service_province: activationInfo.province.code,
        service_city: activationInfo.city,
        delivery_countryId: shippingAddress?.countryId ?? 0,
        delivery_province: shippingAddress?.province ?? '',
        delivery_address:
          shippingAddress !== null
            ? (shippingAddress.unitNumber ? shippingAddress.unitNumber + '-' : '') +
              shippingAddress.street +
              ', ' +
              shippingAddress.city
            : '',
        delivery_postal: shippingAddress?.postalCode ?? '',
        currency: 'CAD',
        simcard_fee: totalPaymentInfo.simcard_amt,
        prorate_fee: totalPaymentInfo.prorate_Amt,
        charge_duration: totalPaymentInfo.charge_Duration,
        plan_fee: totalPaymentInfo.plan_Amt,
        gst_rate: totalPaymentInfo.gst_rate,
        pst_rate: totalPaymentInfo.pst_rate,
        gst_amt: totalPaymentInfo.gst_Amt,
        pst_amt: totalPaymentInfo.pst_Amt,
        subtotal: totalPaymentInfo.subtotal,
        promocode: '',
        promocredit: totalPaymentInfo.promo_Amt,
        total: totalPaymentInfo.total,
        bizId: 0,
        sfID: selectedShippingOption?.sfID ?? 0,
        referral_cnum: '',
        consent_cem: true,
        shipping_contact_number: '',
        esim: selectedSimType === 'ESIM',
      };

      const postpaidRequest: PostActivationInfoPostpaidRequest = {
        ...prepaidRequest,
        prorate_duration: totalPaymentInfo.prorate_Duration,
        prorateDataCap: totalPaymentInfo.prorate_DataCap,
        prorateEndDate: totalPaymentInfo.prorate_End,
        proratePct: totalPaymentInfo.prorate_Pct,
        endDate: null,
      };

      const response = await (plan.planType === 'monthly'
        ? activationService.postActivationInfoPostpaid({
            data: postpaidRequest,
          })
        : activationService.postActivationInfoPrepaid({
            data: prepaidRequest,
          }));
      return response.data;
    } catch (_e) {
      return 'Unable to Process your request. Please Try Again.';
    }
  };

  const generatePaymentLink = async (
    paymentInfo: {
      paymentTypeSlug: PaymentType;
      paymentType: PaymentMethods;
    },
    insertedRow: number
  ): Promise<{
    status: boolean;
    message: string;
    data: null | {
      url: string;
      paymentType: 'alipay_wap' | PaymentMethods;
    };
  }> => {
    let type: 'alipay_wap' | PaymentMethods = paymentInfo.paymentType;
    if (type === 'alipay_qr') {
      type = 'alipay_wap';
    } else if (type === PaymentMethods.GOOGLE_PAY) {
      type = PaymentMethods.CREDIT_CARD;
    }
    const payload = {
      billtype: type,
      cancel_url: paymentUrl('cancel'),
      return_url: paymentUrl('return'),
      headers: {
        [plan.planType === 'monthly' ? 'oaid' : 'paid']: insertedRow,
      },
    };
    try {
      const response = await (plan.planType === 'monthly'
        ? activationService.postpaidActivationBilling({
            data: payload,
          })
        : activationService.prepaidActivationBilling({
            data: { ...payload, currency: 'CAD' },
          }));
      if (response.data.substring(0, 6) === 'Failed') {
        return { status: false, message: 'Failed to complete payment', data: null };
      }
      let redirectUrl = response.data;
      if (type === 'alipay_wap') {
        redirectUrl = redirectUrl.split('^')[0];
      }
      return {
        status: true,
        data: {
          url: redirectUrl,
          paymentType: type,
        },
        message: 'Redirect URL Created!',
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return { status: false, message: e.message, data: null };
    }
  };

  const getStepBasedView = () => {
    switch (step) {
      case FlowStep.SelectSim:
        return (
          <SimTypeView
            onNext={(simType: SIMType) => {
              setSelectedSimType(simType);
              if (simType === 'ESIM') {
                setStep(FlowStep.ActivationInfo);
                return;
              }
              setStep(stepOption[step].nextStep!);
            }}
          />
        );
      case FlowStep.ActivateSim:
        return (
          <ActivateSimCardView
            simcardNumber={simCardNumber || ''}
            planType={plan.planType!}
            onNext={(simCard) => {
              setSimCardNumber(simCard);
              setStep(simCard ? FlowStep.ActivationInfo : FlowStep.ShippingInfo);
            }}
          />
        );
      case FlowStep.ShippingInfo:
        return (
          <ShippingAddressView
            address={shippingAddress}
            onNext={(address: ShippingAddress, shippingOption: ShippingOption[]) => {
              setShippingAddress(address);
              setShippingOption(shippingOption);
              setStep(stepOption[step].nextStep!);
            }}
          />
        );
      case FlowStep.ShippingOptions:
        return shippingAddress ? (
          <ShippingOptionsView
            shippingOption={shippingOption!}
            shippingCountry={shippingAddress.country}
            selectedShippingOption={selectedShippingOption}
            onNext={(shippingOption: ShippingOption) => {
              setSelectedShippingOption(shippingOption);
              setStep(stepOption[step].nextStep!);
            }}
          />
        ) : null;
      case FlowStep.ActivationInfo:
        return (
          <ActivationInfoView
            plan={plan}
            shippingCountry={shippingAddress?.country}
            selectedShippingOption={selectedShippingOption}
            activationInfo={
              activationInfo ?? {
                lastname: '',
                emailAddress: '',
                firstname: '',
                province: {
                  code: 'BC',
                  name: 'British Columbia',
                },
                city: '',
              }
            }
            onNext={(activationInfo: ActivationInfo) => {
              setActivationInfo(activationInfo);
              setStep(stepOption[step].nextStep!);
            }}
          />
        );
      case FlowStep.PortIn:
        return (
          <PortInView
            portInInfo={
              portInInfo ?? {
                accountNumber: '',
                currentCarrier: null,
                phoneNumber: '',
              }
            }
            onNext={(portInInfo: PortInInfo) => {
              setPortInInfo(portInInfo);
              setStep(stepOption[step].nextStep!);
            }}
          />
        );
      case FlowStep.ProratedPlan:
        return activationInfo ? (
          <ProratedPlanView
            plan={plan}
            simCardNumber={simCardNumber}
            province={activationInfo.province}
            selectedSimType={selectedSimType!}
            activationDate={activationInfo?.activationDate ?? new Date()}
            onNext={() => {
              setStep(stepOption[step].nextStep!);
            }}
          />
        ) : null;
      case FlowStep.ReviewAndPay:
        return (
          <ReviewAndPayView
            plan={plan}
            selectedSimType={selectedSimType}
            simCardNumber={simCardNumber}
            shippingAddress={shippingAddress}
            activationInfo={activationInfo}
            selectedShippingOption={selectedShippingOption}
            portInInfo={portInInfo}
            onNext={(totalPaymentInfo) => {
              setTotalPaymentInfo(totalPaymentInfo);
              setStep(stepOption[step].nextStep!);
            }}
          />
        );
      case FlowStep.Checkout:
        return (
          <CheckoutView
            totalPaymentInfo={totalPaymentInfo!}
            onNext={async (paymentInfo: {
              paymentTypeSlug: PaymentType;
              paymentType: PaymentMethods;
            }) => {
              DevLog.log(paymentInfo);
              try {
                dispatch(setIsLoading(true));
                setError(null);
                setProcess('Processing Plan Request');
                const insertedRowId: number | string = await postActivationInfo();
                if (isNaN(+insertedRowId)) {
                  throw new Error('Unable to generate your plan, please try again.');
                }
                setProcess('Generating Payment link.');
                const redirectInfo = await generatePaymentLink(paymentInfo, Number(insertedRowId));
                if (!redirectInfo.status) {
                  throw new Error(redirectInfo.message);
                }
                if (!redirectInfo.data) {
                  throw new Error('Unable to process payment info. Please try again.');
                }
                if (
                  redirectInfo.data.paymentType === PaymentMethods.ALIPAY_QR ||
                  redirectInfo.data.paymentType === PaymentMethods.WECHAT_PAY
                ) {
                  const data = redirectInfo.data.url.split('^');
                  if (data.length === 2) {
                    setAliPayWeChatPayId(data[0]);
                    setQrCodeURL(data[1]);
                    aliPayWeChatPayCheckTimer = setInterval(async () => {
                      const response = await billingService.getIotPayStatus(data[0]!);
                      if (response.data === 'Approved') {
                        navigation.navigate('PaymentSuccess');
                        dispatch(setIsLoading(false));
                      }
                    }, 1000);
                  } else {
                    DevLog.log('image wrong format');
                  }
                  dispatch(setIsLoading(false));
                } else {
                  if (Platform.OS !== 'web') {
                    navigation.navigate('PaymentWebView', {
                      url: redirectInfo.data.url,
                      paymentType: redirectInfo.data.paymentType,
                    });
                  } else {
                    window.location.href = redirectInfo.data.url;
                  }
                  dispatch(setIsLoading(false));
                }
                setProcess('');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (e: any) {
                setError(e.message);
                setProcess('');
                dispatch(setIsLoading(false));
              }
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {error ? (
        <CustomText
          weight="500"
          style={{
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
            backgroundColor: Colors.redOpacity50,
            zIndex: 10,
            textAlign: 'center',
            color: Colors.white,
            padding: 14,
            borderRadius: 14,
            width: '100%',
            margin: 'auto',
            fontSize: 18,
          }}
        >
          {error}
        </CustomText>
      ) : null}
      {process ? (
        <CustomText
          weight="500"
          style={{
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
            backgroundColor: Colors.overlay40,
            zIndex: 10,
            textAlign: 'center',
            color: Colors.white,
            padding: 14,
            borderRadius: 14,
            width: '100%',
            margin: 'auto',
            fontSize: 18,
          }}
        >
          {process}
        </CustomText>
      ) : null}
      {getStepBasedView()}
      <AppDrawer
        visible={qrCodeURL !== '' && qrCodeURL !== null}
        height={485}
        onClose={() => {
          setQrCodeURL(null);
          setAliPayWeChatPayId(null);
          if (aliPayWeChatPayCheckTimer !== null) {
            clearInterval(aliPayWeChatPayCheckTimer);
          }
        }}
        showBackDrop
      >
        <CustomText weight="500" style={{ fontSize: 24, marginBottom: 16 }}>
          Please scan the QR code to finish payment
        </CustomText>
        <HorizontalLine thinkness={1} />

        {qrCodeURL ? (
          <Image
            source={{
              uri: qrCodeURL,
            }}
            width={200}
            height={200}
            style={{
              width: 200,
              height: 200,
              alignSelf: 'center',
            }}
            resizeMode="contain"
          />
        ) : null}

        <Button
          style={{ marginTop: 16 }}
          color={Colors.backgroundGrey}
          textColor={Colors.inputTextPlaceholderColor}
          onPress={() => {
            setQrCodeURL(null);
            setAliPayWeChatPayId(null);
            if (aliPayWeChatPayCheckTimer !== null) {
              clearInterval(aliPayWeChatPayCheckTimer);
            }
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Cancel
        </Button>
      </AppDrawer>
    </>
  );
}
