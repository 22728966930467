// store file for all the original Canadian self serve information and API calls

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AccountInfoCA } from '../types/models/AccountInfoCA';
import { AccountProfileCA } from '../types/models/AccountProfileCA';
import { AccountStatusCA } from '../types/models/AccountStatusCA';
import { InvoiceInfo } from '../types/models/InvoiceInfo';
import { CustomerShippingOrder } from '../types/models/models';
import { RecentTransaction } from '../types/models/RecentTransaction';
import {
  GetCustomerServiceRequestsResponse,
  UnifiedCustomerServiceRequests,
} from '../types/responses/MyAccount/GetCustomerServiceRequestsResponse';
import { PortOutStatus, ShippingStatus } from '../types/types';

const initialState = {
  accountInfoCA: {
    FirstName: '',
    LastName: '',
    DataUsage: 0,
    DataCapacity: 0,
    DataCapacityName: '',
    Calling: 0,
    Message: 0,
    CurrentBalance: 0,
    StartDate: '',
    EndDate: '',
    CurrentPlan: '',
    CurrentPlan_pfId: 0,
    DataOverage: '',
    Prepaid: false,
    Carrier: '',
    DeviceBalance: 0,
    isDataBlocked: false,
    DataUsageStartDate: null, // TODO: double check this
    PlanChangeable: false,
    PlanChangedText: '',
    CurrentPlan_Days: 0,
    TopupCapable: false,
    TopupRate: 0,
    TopupOverage: '',
    CurrentGst: 0,
    CurrentPst: 0,
    isRogers: undefined,
  } as AccountInfoCA,
  accountProfileCA: {
    AccountHolder: null,
    AccountNumber: '',
    AccountNumberLast4Digits: null,
    ActDate: '',
    Address: '',
    AutoPayment: false,
    BankInstitution: null,
    BillingType: '',
    CreditCardExpirationMonth: '',
    CreditCardExpirationYear: '',
    CreditCardHolderName: '',
    CreditCardLast4Digits: '',
    CreditCardType: '',
    Email: '',
    FirstName: '',
    LastName: '',
    PhoneNumber: '',
    Suspended: false,
    SuspensionDate: null,
    SuspensionReason: null,
    TerminationDate: '',
    TransitNumber: null,
    VacationEndDate: null,
  } as AccountProfileCA,
  nonActivatedAccountInfo: {
    ExpressShippingPhoneNumber: null,
    Mop: null,
    PlanName: null,
    ShippingCity: '',
    ShippingProvince: '',
    ShippingStreetAddress: '',
    addr: null,
    appPath: '',
    apvdDate: null,
    charge_duration: '',
    chgDate: null,
    chgHistory: null,
    city: null,
    cnum: null,
    complete: null,
    completed: false,
    consentEmail: null,
    consentName: null,
    country: '',
    crHolder: null,
    crNum: null,
    currency: '',
    deleted: false,
    deletedempId: null,
    destcity: '',
    destprov: '',
    eSim: false,
    email: '',
    fName: '',
    feature: null,
    hst: 0,
    hstrate: 0,
    http_referrer: null,
    isFraud: null,
    lName: '',
    maddress: '',
    mcountry: '',
    memo: null,
    minorStauts: false,
    monthfee: 28,
    mpostal: '',
    naoFee: 0,
    naoId: 0,
    oaId: 0,
    paidAmt: null,
    paidResult: null,
    paylog: null,
    pfid: 0,
    phonetype: null,
    planType: '',
    portin_acctNo: null,
    portin_carrier: null,
    portin_chkNote: null,
    portin_msgNote: null,
    portin_oapnsId: null,
    portin_other: null,
    portin_phoneNo: null,
    portin_submitNo: null,
    postalcode: null,
    promoAmt: 0,
    promocode: '',
    prorateDataCap: '',
    prorateEndDate: '',
    proratePct: 0,
    prorate_duration: '',
    proratefee: 0,
    pst: 0,
    pstrate: 0,
    ptId: null,
    refId: 0,
    remSent: null,
    sTo: '',
    service_countryId: 0,
    sfFee: 0,
    sfID: 0,
    sfName: '',
    sfrom: '',
    sidekick: null,
    sidekickfee: null,
    simcard_order: false,
    simcardfee: 0,
    simnum: '',
    simtype: null,
    subtotal: 0,
    sysregdate: '',
    total: 0,
  },
  accountStatusCA: {
    SuspensionStatus: '',
    SuspensionDate: null,
    TerminationStatus: '',
    TerminationDate: null,
    NumberChangeStatus: '',
    NumberChangeEffectiveDate: null,
    PlanChangeStatus: '',
    PlanChangeEffectiveDate: null,
  } as AccountStatusCA,
  shippingOrder: [] as CustomerShippingOrder[],
  selectingShippingOrder: null as CustomerShippingOrder | null,
  serviceRequests: [] as UnifiedCustomerServiceRequests[],
  selectingServiceRequests: null as UnifiedCustomerServiceRequests | null,
  recentTransaction: [] as RecentTransaction[],
  invoices: [] as InvoiceInfo[],
};

const selfServeCASlice = createSlice({
  name: 'selfServeCA',
  initialState: initialState,
  reducers: {
    setAccountInfoCA(state, action: PayloadAction<AccountInfoCA>) {
      state.accountInfoCA = action.payload;
      state.accountInfoCA.isRogers =
        ['rogers', 'rogers-high data', 'rogers-voice only', 'rogers-data only'].indexOf(
          action.payload.Carrier.toLowerCase()
        ) !== -1;
    },
    setAccountProfileCA(state, action: PayloadAction<AccountProfileCA>) {
      state.accountProfileCA = action.payload;
    },
    setAccountStatusCA(state, action: PayloadAction<AccountStatusCA>) {
      state.accountStatusCA = action.payload;
    },
    setCustomerShippingOrders(state, action: PayloadAction<CustomerShippingOrder[]>) {
      const mappedPayload = action.payload.map((p) => ({
        ...p,
        StatusDescription: p.StatusDescription.toLowerCase() as ShippingStatus,
      }));
      state.shippingOrder = mappedPayload;
    },
    setSelectedCustomerShippingOrders(state, action: PayloadAction<CustomerShippingOrder>) {
      state.selectingShippingOrder = action.payload;
    },
    setCustomerServiceRequests(state, action: PayloadAction<GetCustomerServiceRequestsResponse>) {
      const _createdArray = [
        ...action.payload.ActivationRequests.map((a) => ({
          ...a,
          Status: '',
          requestId: a.ActivationRequestId,
          requestType: 'ACTIVATION' as const,
        })),
        ...action.payload.PortinRequests.map((p) => ({
          ...p,
          requestId: p.PortinRequestId,
          requestType: 'PORT_IN' as const,
        })),
        ...action.payload.PortoutRequests.map((p) => ({
          ...p,
          Type: '',
          requestId: p.PortoutRequestId,
          requestType: 'PORT_OUT' as const,
        })),
      ];

      const mappedPayload = _createdArray
        .map((p) => ({
          ...p,
          requestId: p.requestId.toString(),
          status: p.Status.toLowerCase() as PortOutStatus,
        }))
        .sort((p1, p2) => (p1.RequestTime > p2.RequestTime ? -1 : 1));
      state.serviceRequests = mappedPayload;
    },
    setSelectedCustomerServiceRequests(
      state,
      action: PayloadAction<UnifiedCustomerServiceRequests>
    ) {
      state.selectingServiceRequests = action.payload;
    },
    updateAddress(state, action: PayloadAction<string>) {
      state.accountProfileCA.Address = action.payload;
    },
    setRecentTransaction(state, action: PayloadAction<RecentTransaction[]>) {
      state.recentTransaction = action.payload;
    },
    setInvoices(state, action: PayloadAction<InvoiceInfo[]>) {
      state.invoices = action.payload;
    },
    updateInvoices(state, action: PayloadAction<InvoiceInfo[]>) {
      state.invoices = [...state.invoices, ...action.payload];
    },
    updateBankAccountInfo(
      state,
      action: PayloadAction<{
        AccountNumberLast4Digits: string;
        BankInstitution: string;
        TransitNumber: string;
        AccountHolder: string;
        AccountNumber: string;
      }>
    ) {
      state.accountProfileCA.AccountNumberLast4Digits = action.payload.AccountNumberLast4Digits;
      state.accountProfileCA.BankInstitution = action.payload.BankInstitution;
      state.accountProfileCA.TransitNumber = action.payload.TransitNumber;
      state.accountProfileCA.AccountHolder = action.payload.AccountHolder;
      state.accountProfileCA.AccountNumber = action.payload.AccountNumber;
      state.accountProfileCA.BillingType = 'Bank Account';
    },
    deleteBankInfo(state) {
      state.accountProfileCA.AccountNumberLast4Digits = null;
      state.accountProfileCA.BankInstitution = null;
      state.accountProfileCA.TransitNumber = null;
      state.accountProfileCA.AccountHolder = null;
      state.accountProfileCA.AccountNumber = '';
      state.accountProfileCA.BillingType = 'Cash';
    },
    updateCardInfo(
      state,
      action: PayloadAction<{
        CreditCardExpirationMonth: string;
        CreditCardExpirationYear: string;
        CreditCardHolderName: string;
        CreditCardLast4Digits: string;
        CreditCardType: string;
      }>
    ) {
      state.accountProfileCA.CreditCardExpirationMonth = action.payload.CreditCardExpirationMonth;
      state.accountProfileCA.CreditCardExpirationYear = action.payload.CreditCardExpirationYear;
      state.accountProfileCA.CreditCardHolderName = action.payload.CreditCardHolderName;
      state.accountProfileCA.CreditCardLast4Digits = action.payload.CreditCardLast4Digits;
      state.accountProfileCA.CreditCardType = action.payload.CreditCardType;
      state.accountProfileCA.BillingType = 'Credit Card';
    },
    deleteCardInfo(state) {
      state.accountProfileCA.CreditCardExpirationMonth = '';
      state.accountProfileCA.CreditCardExpirationYear = '';
      state.accountProfileCA.CreditCardHolderName = '';
      state.accountProfileCA.CreditCardLast4Digits = '';
      state.accountProfileCA.CreditCardType = '';
      state.accountProfileCA.BillingType = 'Cash';
    },
  },
});

export const {
  setAccountInfoCA,
  setAccountProfileCA,
  setAccountStatusCA,
  setCustomerShippingOrders,
  setSelectedCustomerShippingOrders,
  updateAddress,
  setCustomerServiceRequests,
  setSelectedCustomerServiceRequests,
  setRecentTransaction,
  setInvoices,
  updateInvoices,
  updateBankAccountInfo,
  deleteBankInfo,
  updateCardInfo,
  deleteCardInfo,
} = selfServeCASlice.actions;

export default selfServeCASlice.reducer;
