import { AntDesign } from '@expo/vector-icons';
import { View } from 'react-native';

import { Colors } from '../../constants/Colors';
import CustomText from '../utils/CustomText';

const InfoMessage = ({
  normalText = '',
  boldText = '',
}: {
  normalText?: string;
  boldText?: string;
}) => {
  return (
    <View style={{ flexDirection: 'row', marginBottom: 8 }}>
      <AntDesign name="exclamationcircle" size={20} color={Colors.errorRed} />
      <CustomText
        weight="500"
        style={{
          marginLeft: 8,
          fontWeight: '500',
          fontSize: 14,
          color: Colors.inputTextPlaceholderColor,
        }}
      >
        {normalText}{' '}
        <CustomText weight="600" style={{ color: Colors.black }}>
          {boldText}
        </CustomText>
      </CustomText>
    </View>
  );
};

export default InfoMessage;
