import { identityInstance } from '../../api/axios';
import { SystemCodes } from '../../types/enums/SystemCodes';
import { ApiRequest } from '../../types/requests/ApiRequest';
import { ForgotPasswordRequest } from '../../types/requests/SSO/ForgotPasswordRequest';
import { RefreshTokenRequest } from '../../types/requests/SSO/RefreshTokenRequest';
import { ResetPasswordRequest } from '../../types/requests/SSO/ResetPasswordRequest';
import { SendUpdateEmailCodeRequest } from '../../types/requests/SSO/SendUpdateEmailCodeRequest';
import { SignInAppleRequest } from '../../types/requests/SSO/SignInAppleRequest';
import { SignInGoogleRequest } from '../../types/requests/SSO/SignInGoogleRequest';
import { SignInRequest } from '../../types/requests/SSO/SignInRequest';
import { UpdateEmailRequest } from '../../types/requests/SSO/UpdateEmailRequest';
import { UpdatePasswordRequest } from '../../types/requests/SSO/UpdatePasswordRequest';
import { UpdateProfileRequest } from '../../types/requests/SSO/UpdateProfileRequest';
import { UserProfileRequest } from '../../types/requests/SSO/UserProfileRequest';
import { ValidateOtpForResetPasswordRequest } from '../../types/requests/SSO/ValidateOtpForResetPasswordRequest';
import { IdentityApiResponse } from '../../types/responses/ApiResponse';
import { ForgotPasswordResponse } from '../../types/responses/SSO/ForgotPasswordResponse';
import { SendOTPForPortoutResponse } from '../../types/responses/SSO/OTPForPortoutResponses';
import { RefreshTokenResponse } from '../../types/responses/SSO/RefreshTokenResponse';
import { ResetPasswordResponse } from '../../types/responses/SSO/ResetPasswordResponse';
import { SendUpdateEmailCodeResponse } from '../../types/responses/SSO/SendUpdateEmailCodeResponse';
import { SignInResponse } from '../../types/responses/SSO/SignInResponse';
import { UpdateEmailResponse } from '../../types/responses/SSO/UpdateEmailResponse';
import { UpdatePasswordResponse } from '../../types/responses/SSO/UpdatePasswordResponse';
import { UpdateProfileResponse } from '../../types/responses/SSO/UpdateProfileResponse';
import { UserProfileResponse } from '../../types/responses/SSO/UserProfileResponse';
import { ValidateOtpForResetPasswordResponse } from '../../types/responses/SSO/ValidateOtpForResetPasswordResponse';

export const authService = {
  signIn: async (
    request: ApiRequest<SignInRequest>
  ): Promise<IdentityApiResponse<SignInResponse>> =>
    await identityInstance.post<ApiRequest<SignInRequest>, IdentityApiResponse<SignInResponse>>(
      '/Auth/v1/signin',
      request
    ),
  forgotPassword: async (
    request: ApiRequest<ForgotPasswordRequest>
  ): Promise<IdentityApiResponse<ForgotPasswordResponse>> =>
    await identityInstance.post<
      ApiRequest<ForgotPasswordRequest>,
      IdentityApiResponse<ForgotPasswordResponse>
    >('/Auth/v1/forgot_password', request),
  // updating this for OTP efforts:
  // was previously: reset_password
  // now: ResetPassword
  resetPassword: async (
    request: ApiRequest<ResetPasswordRequest>
  ): Promise<IdentityApiResponse<ResetPasswordResponse>> =>
    await identityInstance.post<
      ApiRequest<ResetPasswordRequest>,
      IdentityApiResponse<ResetPasswordResponse>
    >('/Auth/v1/ResetPassword', request),
  userProfile: async (
    request: UserProfileRequest
  ): Promise<IdentityApiResponse<UserProfileResponse>> =>
    await identityInstance.get<UserProfileRequest, IdentityApiResponse<UserProfileResponse>>(
      '/Auth/v1/user_profile',
      request
    ),
  updatePassword: async (
    request: ApiRequest<UpdatePasswordRequest>
  ): Promise<IdentityApiResponse<UpdatePasswordResponse>> =>
    await identityInstance.put<
      ApiRequest<UpdatePasswordRequest>,
      IdentityApiResponse<UpdatePasswordResponse>
    >('/Auth/v1/update_password', request),
  updateProfile: async (
    request: ApiRequest<UpdateProfileRequest>
  ): Promise<IdentityApiResponse<UpdateProfileResponse>> =>
    await identityInstance.put<
      ApiRequest<UpdateProfileRequest>,
      IdentityApiResponse<UpdateProfileResponse>
    >('/Auth/v1/update_profile', request),
  sendUpdateEmailCode: async (
    request: ApiRequest<SendUpdateEmailCodeRequest>
  ): Promise<IdentityApiResponse<SendUpdateEmailCodeResponse>> =>
    await identityInstance.post<
      ApiRequest<SendUpdateEmailCodeRequest>,
      IdentityApiResponse<SendUpdateEmailCodeResponse>
    >('/Auth/v1/send_update_email_code', request),
  updateEmail: async (
    request: ApiRequest<UpdateEmailRequest>
  ): Promise<IdentityApiResponse<UpdateEmailResponse>> =>
    await identityInstance.put<
      ApiRequest<UpdateEmailRequest>,
      IdentityApiResponse<UpdateEmailResponse>
    >('/Auth/v2/update_email', request),
  refreshToken: async (
    request: RefreshTokenRequest
  ): Promise<IdentityApiResponse<RefreshTokenResponse>> =>
    identityInstance.get<RefreshTokenRequest, IdentityApiResponse<RefreshTokenResponse>>(
      '/Auth/v1/refresh_token',
      request
    ),
  signInGoogle: async (
    request: ApiRequest<SignInGoogleRequest>
  ): Promise<IdentityApiResponse<SignInResponse>> =>
    await identityInstance.post<
      ApiRequest<SignInGoogleRequest>,
      IdentityApiResponse<SignInResponse>
    >('/Auth/v1/signin_google', request),
  signInApple: async (
    request: ApiRequest<SignInAppleRequest>
  ): Promise<IdentityApiResponse<SignInResponse>> =>
    await identityInstance.post<
      ApiRequest<SignInAppleRequest>,
      IdentityApiResponse<SignInResponse>
    >('/Auth/v1/signin_apple', request),
  validateOtpForResetPassword: async (
    request: ApiRequest<ValidateOtpForResetPasswordRequest>
  ): Promise<IdentityApiResponse<ValidateOtpForResetPasswordResponse>> =>
    await identityInstance.post<
      ApiRequest<ValidateOtpForResetPasswordRequest>,
      IdentityApiResponse<ValidateOtpForResetPasswordResponse>
    >('/Auth/v1/ValidateOtpForResetPassword', request),
  sendOtpForPortout: async (
    email: string
  ): Promise<IdentityApiResponse<SendOTPForPortoutResponse>> =>
    await identityInstance.post('/Auth/v1/send_otp_for_portout', {
      data: {
        email: email,
        systemCode: SystemCodes.CA_SELFSERVE,
      },
    }),
  validateOtpForPortout: async (
    userId: string,
    otp: string
  ): Promise<IdentityApiResponse<SendOTPForPortoutResponse>> =>
    await identityInstance.post('/Auth/v1/validate_otp_for_portout', {
      data: {
        userId: userId,
        otpCode: otp,
        systemCode: SystemCodes.CA_SELFSERVE,
      },
    }),
};
