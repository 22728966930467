import { useEffect, useState } from 'react';
import { Platform, SafeAreaView, ScrollView, StatusBar, View } from 'react-native';

import AppGradientContainer from '../../components/AppGradientContainer';
import Offering from '../../components/Section/Offering';
import PageHeader from '../../components/UI/Header/PageHeader';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { OfferList } from '../../constants/constants';
import { RootStackScreenProps } from '../../types';

export default function BenefitsScreen({ route }: RootStackScreenProps<'Benefits'>) {
  const benefit = route.params?.benefit ?? '';

  const offerListKeys = Object.keys(OfferList);
  const [selected, setSelected] = useState<string>(
    offerListKeys.includes(benefit) ? benefit : offerListKeys[0]
  );

  useEffect(() => {
    setSelected(offerListKeys.includes(benefit) ? benefit : offerListKeys[0]);
  }, [benefit]);

  const SelectedComponent = OfferList[selected].component;

  return (
    <SafeAreaView
      style={{ flex: 1, paddingTop: StatusBar.currentHeight, backgroundColor: Colors.white }}
    >
      <PageHeader />
      <ScrollView showsVerticalScrollIndicator={false}>
        <AppGradientContainer
          autoHeight
          colorLeft={Colors.gradientDarkBlue}
          colorRight={Colors.gradientLightBlue}
          direction="LEFT-RIGHT"
          style={{ paddingBottom: 15, paddingTop: Platform.OS === 'web' ? 50 : 30 }}
        >
          <View
            style={{
              paddingVertical: 40,
            }}
          >
            <CustomText
              style={{
                fontSize: 24,
                color: Colors.white,
                textAlign: 'center',
                marginBottom: 16,
                marginTop: 10,
              }}
              weight="600"
            >
              Available Offers
            </CustomText>
            <Offering
              selectedOffer={selected}
              onOfferSelect={(selectedOffer: string) => {
                setSelected(selectedOffer);
              }}
            />
          </View>
        </AppGradientContainer>
        <View
          style={{
            width: '100%',
            marginTop: -20,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            backgroundColor: Colors.white,
            paddingHorizontal: 16,
            paddingVertical: 24,
            // height: '100%',
            position: 'relative',
          }}
        >
          {SelectedComponent ? <SelectedComponent /> : null}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
