import { useEffect, useState } from 'react';
import { View, KeyboardAvoidingView, Platform, SafeAreaView, Image } from 'react-native';

import IllustrationImage1 from '../../assets/illustrations/blue21.png';
import IllustrationImage2 from '../../assets/illustrations/Blue30.png';
import AppGradientContainer from '../../components/AppGradientContainer';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import OTPInput from '../../components/OTPInput';
import TextInput from '../../components/TextInput';
import AuthBackground from '../../components/utils/AuthBackgroud';
import CustomText from '../../components/utils/CustomText';
import ScrollViewWithSafeAreaContainer from '../../components/utils/ScrollViewWithSafeAreaContainer';
import { Colors } from '../../constants/Colors';
import CommonStyles from '../../constants/CommonStyles';
import { authService } from '../../services/SSO/AuthService';
import { RootStackScreenProps } from '../../types';
import { DataErrorType } from '../../types/enums/DataErrorType';
import { DevMessage } from '../../types/enums/DevMessage';
import { StepType } from '../../types/enums/StepType';
import { SystemCodes } from '../../types/enums/SystemCodes';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';
import { validate } from '../../utilities/utilities';

export default function RecoverYourPasswordScreen({
  navigation,
}: RootStackScreenProps<'RecoverPassword'>) {
  const [userId, setUserId] = useState<string | null>(null);
  const [email, setEmail] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newConfirmPassword, setNewConfirmPassword] = useState<string>('');

  const [emailError, setEmailError] = useState<string>('');
  const [newPasswordError, setNewPasswordError] = useState<string>('');
  const [newConfirmPasswordError, setNewConfirmPasswordError] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>('');

  const [step, setStep] = useState<StepType>(StepType.First);
  const [otpShowError, setOtpShowError] = useState<boolean>(false);

  useEffect(() => {
    setOtpShowError(false);
  }, [otp]);

  const sendPasswordResetOTP = async () => {
    if (!validate('email', email)) {
      return setEmailError('Please enter valid email address');
    }
    setLoading(true);
    try {
      const response = await authService.forgotPassword({
        data: {
          email: email,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });
      if (!response || !response.data.success) {
        if (response.data && response.data.error && response.data.error.message) {
          setEmailError(response.data.error.message);
        }
      }

      if (response && response.data.success) {
        setEmailError('');
        setStep(StepType.Second);
        setUserId(response.data.data.userId);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error && error.response && error.response.data && error.response.data.errors) {
        if (error.response.data.errors[DataErrorType.Email]) {
          setEmailError(error.response.data.errors[DataErrorType.Email]);
        }
      }
      DevLog.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const verifyOTPForResetPassword = async () => {
    if (otp.length !== 6) {
      return setOtpShowError(true);
    }
    if (!validate('password', newPassword)) {
      return setNewPasswordError(
        'Password must contain minimum 8 charcters, at least one number, special character and capital letter'
      );
    }
    if (newPassword !== newConfirmPassword) {
      return setNewPasswordError('Confirm Password does not match.');
    }
    try {
      setLoading(true);
      const response = await authService.validateOtpForResetPassword({
        data: {
          userId: userId,
          otpCode: otp,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (!response || !response.data?.success) {
        if (
          response?.data?.devMessage &&
          (response.data.devMessage === DevMessage.InvalidOTP ||
            response?.data?.devMessage === DevMessage.InCorrectOTP ||
            /expired/.test(response?.data?.error?.message))
        ) {
          setOtpShowError(true);
        }

        return;
      }

      if (response.data.success) {
        setOtpShowError(false);
        resetPassword();
      }
    } catch (error) {
      DevLog.error(error);
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async () => {
    try {
      setLoading(true);
      const response = await authService.resetPassword({
        data: {
          userId: userId,
          newPassword: newPassword,
          otpCode: otp,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (!response || !response.data?.success) {
        DevLog.log('Something went wrong with resetPasswordAsync(): ', response);
        return;
      }

      if (response?.data?.success) {
        navigation.pop();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      DevLog.error(error);
      if (error && error.response && error.response.data && error.response.data.errors) {
        if (error.response.data.errors[DataErrorType.NewPassword]) {
          setNewPasswordError(error.response.data.errors[DataErrorType.NewPassword]);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScrollViewWithSafeAreaContainer minHeight={812}>
      <AppGradientContainer
        colorLeft={Colors.gradientDarkBlue}
        colorRight={Colors.gradientLightBlue}
        direction="LEFT-RIGHT"
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          keyboardVerticalOffset={-120}
          style={{ flex: 1 }}
        >
          <SafeAreaView style={{ flex: 1 }}>
            <AuthBackground />
            <BottomWhiteSheet
              style={{ paddingLeft: 30, paddingRight: 30, ...CommonStyles.webBottomSheet }}
            >
              {step === StepType.First ? (
                <>
                  <View
                    style={{
                      position: 'absolute',
                      top: Platform.select({ android: -190, default: -190, ios: -80 }),
                      zIndex: 8,
                    }}
                  >
                    <Image
                      source={IllustrationImage1}
                      style={{
                        width: 150,
                        height: Platform.select({ android: 300, default: 300, ios: 120 }),
                        display: isWebsite() ? 'none' : undefined,
                      }}
                      resizeMode="contain"
                    />
                  </View>
                  <View
                    style={[
                      { width: '100%' },
                      isWebsite()
                        ? {
                            maxWidth: 400,
                            marginHorizontal: 'auto',
                            width: '100%',
                          }
                        : null,
                    ]}
                  >
                    <CustomText
                      weight="500"
                      style={{ fontSize: 24, fontWeight: '500', marginTop: 50, marginBottom: 10 }}
                    >
                      Recover Your Account
                    </CustomText>
                    <HorizontalLine style={{ marginBottom: 20 }} />
                    <TextInput
                      placeholder="Email"
                      value={email}
                      onChangeText={(email: string) => {
                        setEmail(email);
                        if (validate('email', email)) {
                          setEmailError('');
                        }
                      }}
                      error={{ error: emailError }}
                    />
                    <Button
                      style={{ marginTop: 25 }}
                      onPress={async () => {
                        sendPasswordResetOTP();
                      }}
                      loadingStatus={loading}
                      disabled={loading}
                      textStyle={{ fontSize: 14 }}
                    >
                      Recover Password
                    </Button>
                    <Button
                      size={'FULL'}
                      color={Colors.noColor}
                      textColor={Colors.greyColor}
                      onPress={() => {
                        navigation.pop();
                      }}
                      style={{
                        padding: 5,
                        marginTop: 10,
                        width: 'auto',
                      }}
                      textStyle={{ fontSize: 14 }}
                    >
                      Cancel
                    </Button>
                  </View>
                </>
              ) : step === StepType.Second ? (
                <>
                  <View
                    style={{
                      position: 'absolute',
                      bottom: Platform.select({ android: 460, default: 460 }),
                      zIndex: 8,
                    }}
                  >
                    <Image
                      source={IllustrationImage2}
                      style={{
                        width: 150,
                        height: 250,
                        display: isWebsite() ? 'none' : undefined,
                      }}
                      resizeMode="contain"
                    />
                  </View>
                  <View
                    style={[
                      { width: '100%' },
                      isWebsite()
                        ? {
                            maxWidth: 400,
                            marginHorizontal: 'auto',
                            width: '100%',
                          }
                        : null,
                    ]}
                  >
                    <CustomText
                      weight="500"
                      style={{ fontSize: 24, fontWeight: '500', marginTop: 50, marginBottom: 10 }}
                    >
                      Verification Code
                    </CustomText>
                    <View style={{ marginBottom: 20 }}>
                      <CustomText style={{ fontSize: 12 }}>
                        A verification email has been sent to you
                      </CustomText>
                    </View>
                    <HorizontalLine style={{ marginBottom: 20 }} />
                    <OTPInput onChangeText={setOtp} isError={otpShowError} />
                    <CustomText
                      style={{
                        marginVertical: 15,
                        textAlign: isWebsite() ? 'center' : undefined,
                      }}
                    >
                      Didn&apos;t receive the code?{' '}
                      <CustomText
                        onPress={() => {
                          sendPasswordResetOTP();
                        }}
                        weight="600"
                        style={{
                          color: Colors.canadaButtonColor,
                          fontWeight: '600',
                          textDecorationLine: 'underline',
                        }}
                      >
                        Resend Code
                      </CustomText>
                    </CustomText>
                    <TextInput
                      placeholder="New Password"
                      value={newPassword}
                      onChangeText={(password: string) => {
                        setNewPassword(password);
                        if (validate('password', password)) {
                          setNewPasswordError('');
                        }
                      }}
                      eyeTopMargin={29}
                      secureTextEntry={true}
                      style={{ marginTop: 15 }}
                      error={{ error: newPasswordError }}
                    />
                    <TextInput
                      placeholder="Confirm New Password"
                      value={newConfirmPassword}
                      onChangeText={(password: string) => {
                        setNewConfirmPassword(password);
                        if (validate('password', password)) {
                          setNewConfirmPasswordError('');
                        }
                      }}
                      eyeTopMargin={29}
                      secureTextEntry={true}
                      style={{ marginTop: 15 }}
                      error={{ error: newConfirmPasswordError }}
                    />
                    <Button
                      style={{ marginTop: 25 }}
                      loadingStatus={loading}
                      disabled={loading}
                      onPress={() => {
                        verifyOTPForResetPassword();
                      }}
                      textStyle={{ fontSize: 14 }}
                    >
                      Change Password
                    </Button>
                    <Button
                      size={'FULL'}
                      color={Colors.noColor}
                      textColor={Colors.greyColor}
                      onPress={() => {
                        navigation.pop();
                      }}
                      style={{
                        padding: 5,
                        marginTop: 10,
                        width: 'auto',
                      }}
                      textStyle={{ fontSize: 14 }}
                    >
                      Cancel
                    </Button>
                  </View>
                </>
              ) : null}
            </BottomWhiteSheet>
          </SafeAreaView>
        </KeyboardAvoidingView>
      </AppGradientContainer>
    </ScrollViewWithSafeAreaContainer>
  );
}
