import * as Clipboard from 'expo-clipboard';
import { useState } from 'react';
import { View, Text, Linking, Alert, StyleSheet, Pressable, SafeAreaView } from 'react-native';
import { useSelector } from 'react-redux';

import { AppDrawer } from '../components/AppDrawer';
import Button from '../components/Button';
import { Colors } from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import { RootState } from '../store';

export default function FriendsReferralScreen() {
  const horizontalContainerMargin = useHorizontalContainerMargin();
  const user = useSelector((state: RootState) => state.user);
  const fullName = user.profile.firstName + ' ' + user.profile.lastName;
  const referralLink = `https://gophonebox.com/plans/?referral=${user.cnum}`;
  const [_, setLinkCopied] = useState(false);
  const [visible, setVisible] = useState(false);

  const onPressSendInvites = () => {
    setVisible(true);
  };

  const onPressReferByEmail = () => {
    if (!user.cnum) {
      return;
    }
    const emailContents = encodeURI(
      `mailto:?to=&subject=Congrats! You've received your Referral from ${fullName}!&body=Your friend has invited you to join PhoneBox! Complete your Referral using the link below:\n${referralLink}`
    );
    handleEmailContents(emailContents);
  };

  const onPressCopy = async () => {
    setLinkCopied(true);
    await Clipboard.setStringAsync(referralLink);
  };

  const handleEmailContents = async (emailURI: string) => {
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(emailURI);

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(emailURI);
    } else {
      Alert.alert(`Don't know how to open this URL: ${emailURI}`);
    }
  };

  return (
    <>
      <SafeAreaView style={{ flex: 1, height: '100%' }}>
        <View style={{ marginHorizontal: horizontalContainerMargin, flex: 1 }}>
          <View style={[CommonStyles.container, { flex: 1 }]}>
            <View style={styles.referralContainer}>
              <View style={styles.marginTop}>
                <Text style={[CommonStyles.title, { paddingRight: 20 }]}>
                  Earn $30 when you refer a friend
                </Text>
              </View>
              <View style={styles.marginTop}>
                <Text style={CommonStyles.futuraFont}>
                  Invite a friend to use PhoneBox and get $30 in credits when your friend has signed
                  up for a plan.
                </Text>
              </View>
              <View style={styles.stepContainer}>
                <View style={styles.stepCircle}>
                  <Text style={styles.stepNumber}>1</Text>
                </View>
                <View style={styles.stepTextContainer}>
                  <Text style={styles.stepTextLabel}>Invite a friend</Text>
                  <Text style={styles.stepText}>
                    Send invites to your friends, via text or as a post on social media
                  </Text>
                </View>
              </View>
              <View style={styles.stepContainer}>
                <View style={styles.stepCircle}>
                  <Text style={styles.stepNumber}>2</Text>
                </View>
                <View style={styles.stepTextContainer}>
                  <Text style={styles.stepTextLabel}>Friends sign up for a plan</Text>
                  <Text style={styles.stepText}>
                    We&apos;ll track when your friends have signed on for one of our plans
                  </Text>
                </View>
              </View>
              <View style={styles.stepContainer}>
                <View style={styles.stepCircle}>
                  <Text style={styles.stepNumber}>3</Text>
                </View>
                <View style={styles.stepTextContainer}>
                  <Text style={styles.stepTextLabel}>Get paid in credits</Text>
                  <Text style={styles.stepText}>
                    Once your friend has made their first payment, a $30 credit is added to your
                    balance
                  </Text>
                </View>
              </View>
            </View>
            <View style={CommonStyles.bottomContainer}>
              <Button onPress={onPressSendInvites}>Send Invites</Button>
            </View>
          </View>
        </View>
      </SafeAreaView>
      <AppDrawer onClose={() => setVisible(false)} visible={visible} height={400}>
        <View style={styles.referralHeader}>
          <Text style={CommonStyles.title}>Send an invite</Text>
        </View>
        <Pressable style={styles.referralLink} onPress={onPressCopy}>
          <Text>Copy link</Text>
        </Pressable>
        <Pressable style={styles.referralLink} onPress={onPressReferByEmail}>
          <Text>Refer by email</Text>
        </Pressable>
      </AppDrawer>
    </>
  );
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 25,
  },
  referralContainer: {
    marginHorizontal: 10,
  },
  referralHeader: {
    marginVertical: 15,
  },
  referralLink: {
    borderBottomColor: Colors.mediumGrey,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    paddingVertical: 10,
  },
  stepCircle: {
    borderRadius: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    height: 40,
    paddingHorizontal: 13,
    paddingTop: 6,
    width: 40,
  },
  stepContainer: {
    flexDirection: 'row',
    marginVertical: 20,
  },
  stepNumber: {
    fontSize: 20,
  },
  stepText: {
    fontSize: 16,
  },
  stepTextContainer: {
    marginLeft: 20,
    width: 230,
  },
  stepTextLabel: {
    fontSize: 16,
  },
});
