import { Pressable, View } from 'react-native';

import { Colors } from '../../../constants/Colors';
import CustomText from '../../utils/CustomText';

type Props = {
  title: string;
  frontIcon: React.JSX.Element;
  backIcon: React.JSX.Element;
  onPress?: () => void;
  disabled?: boolean;
};

const MenuItem = ({ title, backIcon, frontIcon, onPress, disabled }: Props) => {
  return (
    <Pressable
      onPress={onPress}
      style={{
        backgroundColor: disabled ? Colors.grey : Colors.backgroundGrey,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 8,
        borderRadius: 8,
      }}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View
          style={{
            backgroundColor: disabled ? Colors.grey : Colors.ActionLinkColorBG,
            padding: 4,
            marginRight: 8,
            borderRadius: 10,
          }}
        >
          {frontIcon}
        </View>
        <CustomText style={{ color: disabled ? Colors.white : Colors.TextBlack }}>
          {title}
        </CustomText>
      </View>
      {backIcon}
    </Pressable>
  );
};

export default MenuItem;
