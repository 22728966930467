import { Image, StyleSheet, View } from 'react-native';

import AuthBackgroundImage from '../../assets/backgrounds/auth-bg.png';
import LogoWhiteImage from '../../assets/images/logo_white.png';
import { isWebsite } from '../../utilities/functions';

const AuthBackground = () => {
  return (
    <>
      <View
        style={{
          alignItems: 'center',
          height: 100,
          display: 'flex',
          position: isWebsite() ? 'relative' : 'absolute',
          top: 40,
          left: 0,
          right: 0,
          minHeight: isWebsite() ? 'auto' : 300,
        }}
      >
        {!isWebsite() && (
          <Image source={AuthBackgroundImage} style={styles.authBg} resizeMode="cover" />
        )}
        <Image source={LogoWhiteImage} style={styles.logo} resizeMode="contain" />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  authBg: {
    aspectRatio: 1.4,
    position: 'absolute',
    top: -60,
    width: '100%',
    zIndex: 0,
  },
  logo: {
    height: 75,
    marginTop: 20,
    width: 150,
  },
});

export default AuthBackground;
