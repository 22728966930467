import { useEffect, useState } from 'react';
import { View, SafeAreaView, StatusBar, ScrollView, Platform, Alert } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { useDispatch, useSelector } from 'react-redux';

import { AppGap } from '../../components/AppGap';
import Button from '../../components/Button';
import CanadaPostAutoCompleteAddress from '../../components/CanadaPostAutoCompleteAddress';
import GoogleAutoCompleteAddress from '../../components/GoogleAutoCompleteAddress';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import TitleWithSupTitle from '../../components/UI/TitleWithSupTitle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { accountService } from '../../services/MyAccount/AccountService';
import { activationService } from '../../services/MyAccount/ActivationService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import { updateAddress } from '../../store/selfServeCA';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';

export default function EditAddressScreen() {
  const dispatch = useDispatch();

  const { Address } = useSelector((store: RootState) => store.selfServeCA.accountProfileCA);
  const { isLoading } = useSelector((store: RootState) => store.app);

  const [country, setCountry] = useState('Canada');
  const [countryIso, setCountryIso] = useState('CA');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [unitNumber, setUnitNumber] = useState('');
  const [isAddressValid, setIsAddressValid] = useState<boolean>(false);

  const [error, setError] = useState<null | string>(null);

  const [openCountry, setOpenCountry] = useState(false);
  const [newSelectedCountry, setNewSelectedCountry] = useState('42'); // Default Canada
  const [showAddressSearch, setShowAddressSearch] = useState(false);

  const [countryList, setCountryList] = useState<
    Array<{
      CountryCode: string;
      CountryCodeISO: string;
      CountryName: string;
    }>
  >([]);

  useEffect(() => {
    if (street === '' || city === '' || province === '' || postalCode === '') {
      setIsAddressValid(false);
    } else {
      setIsAddressValid(true);
    }
  }, [street, city, province, postalCode]);

  useEffect(() => {
    dispatch(setIsLoading(true));
    const getCountriesAsync = async () => {
      const getCountriesResponse = await activationService.getCountries();

      if (!getCountriesResponse || !getCountriesResponse.data) {
        setError('Unable to Fetch Countries');
        return;
      }

      if (getCountriesResponse && getCountriesResponse.data) {
        setCountryList(getCountriesResponse.data);
      }
    };
    getCountriesAsync()
      .catch((err) => DevLog.error(err))
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  }, []);

  const getShippingAddressAutoComplete = (full_address: string[]) => {
    setStreet(full_address[0]);
    setCity(full_address[1]);
    setPostalCode(full_address[4]);
    setProvince(full_address[6]);
  };

  const handleChangeValueFromCountryDropDown = (item: string | null) => {
    if (item) {
      setShowAddressSearch(true);
    }
    const selectedCountry = countryList.find((country) => country.CountryCode === item);
    if (selectedCountry) {
      setCountryIso(selectedCountry.CountryCodeISO);
      setCountry(selectedCountry.CountryName);
    } else {
      setCountryIso('');
      setCountry('');
    }
    setStreet('');
    setCity('');
    setProvince('');
    setPostalCode('');
  };

  const updateAddressInfo = async () => {
    const fullAddress = `${
      unitNumber ? unitNumber + '-' : ''
    } ${street}, ${city}, ${province} ${postalCode}, ${country}`;
    dispatch(setIsLoading(true));
    try {
      await accountService.changeAddress(fullAddress);
      dispatch(updateAddress(fullAddress));
      if (isWebsite()) {
        alert('Address is updated successfully.');
      } else {
        Alert.alert('Success', 'Address is updated successfully.');
      }
    } catch (err) {
      DevLog.error(err);
      setError('Sorry, something went wrong.');
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const AddressInput = () => {
    return (
      <View
        style={[
          { marginBottom: 10, marginTop: 16 },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <CustomText style={{ fontSize: 12, color: Colors.greyColor, marginBottom: 6 }} weight="500">
          Search Address to Update
        </CustomText>
        {countryIso === 'CA' || countryIso === 'US' ? (
          <CanadaPostAutoCompleteAddress
            onAutoCompleteAddress={getShippingAddressAutoComplete}
            selectedCountry={countryIso || 'CA'}
          />
        ) : (
          <GoogleAutoCompleteAddress
            onAutoCompleteAddress={getShippingAddressAutoComplete}
            country={countryIso}
          />
        )}
      </View>
    );
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        height: '100%',
        marginTop: StatusBar.currentHeight,
        backgroundColor: Colors.white,
      }}
    >
      <ScrollView
        automaticallyAdjustKeyboardInsets={true}
        style={{ padding: 20, marginBottom: Platform.select({ default: 110, ios: 80 }), flex: 1 }}
      >
        <TitleWithSupTitle title={Address || 'No Address Added to Profile'} supTitle="Address" />
        {error ? (
          <View style={{ marginVertical: 14 }}>
            <CustomText
              weight="500"
              style={{ fontSize: 14, color: Colors.errorRed, textAlign: 'center' }}
            >
              {error}
            </CustomText>
          </View>
        ) : null}
        <View
          style={[
            { marginBottom: 10, zIndex: 998, marginTop: 16 },
            isWebsite()
              ? {
                  maxWidth: 400,
                  marginHorizontal: 'auto',
                  width: '100%',
                }
              : null,
          ]}
        >
          <CustomText
            style={{ fontSize: 12, color: Colors.greyColor, marginBottom: 6 }}
            weight="500"
          >
            Country
          </CustomText>
          <DropDownPicker
            searchable={true}
            open={openCountry}
            listMode="SCROLLVIEW"
            value={newSelectedCountry}
            setOpen={setOpenCountry}
            setValue={setNewSelectedCountry}
            onChangeValue={handleChangeValueFromCountryDropDown}
            schema={{
              label: 'CountryName',
              value: 'CountryCode',
            }}
            placeholder="Select a Country"
            // @ts-expect-error: items shounld be ItemType<string>[]
            // but since we defined schema we can use
            // {CountryCode: string;CountryCodeISO: string;CountryName: string;}[],
            // it still gives type error.
            items={countryList}
          />
        </View>
        {showAddressSearch ? <AddressInput /> : null}
        <InputWithTitle
          // editable={false}
          title="Street Name"
          inputValue={street}
          onInputChange={setStreet}
          onFocus={() => setOpenCountry(false)}
        />
        <InputWithTitle
          // editable={false}
          title="City"
          inputValue={city}
          onInputChange={setCity}
          onFocus={() => setOpenCountry(false)}
        />
        <InputWithTitle
          // editable={false}
          title="Province"
          inputValue={province}
          onInputChange={setProvince}
          onFocus={() => setOpenCountry(false)}
        />
        <InputWithTitle
          // editable={false}
          title="Postal Code"
          inputValue={postalCode}
          onInputChange={setPostalCode}
          onFocus={() => setOpenCountry(false)}
        />
        <InputWithTitle
          title="Unit # (Optional)"
          inputValue={unitNumber}
          onInputChange={setUnitNumber}
          onFocus={() => setOpenCountry(false)}
        />
        <AppGap size={40} direction="vertical" />
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          style={{ marginTop: 32 }}
          disabled={!isAddressValid || isLoading}
          loadingStatus={isLoading}
          onPress={() => {
            updateAddressInfo();
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Update Address
        </Button>
      </View>
    </SafeAreaView>
  );
}
