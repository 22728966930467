import { AntDesign } from '@expo/vector-icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  SafeAreaView,
  Image,
  View,
  Pressable,
  ScrollView,
  ImageSourcePropType,
  Platform,
  Alert,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import AlipayImage from '../../assets/icons/alipay.jpg';
import ApplePayImage from '../../assets/icons/apple-pay.png';
import CreditCardImage from '../../assets/icons/credit-card.png';
import GooglePayImage from '../../assets/icons/google-pay.png';
import PaypalImage from '../../assets/icons/paypal.png';
import WechatPayImage from '../../assets/icons/wechat-pay.png';
import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT, DATE_FORMAT_FOR_API } from '../../constants/constants';
import { activationService } from '../../services/MyAccount/ActivationService';
import { prepaidService } from '../../services/MyAccount/PrepaidService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import { RootStackScreenProps } from '../../types';
import { PaymentMethods } from '../../types/enums/enums';
import { BoxOnePrepaidPlan } from '../../types/models/BoxOnePrepaidPlan';
import { PaymentType } from '../../types/types';
import DevLog from '../../utilities/debug-error';
import { isWebsite, paymentUrl } from '../../utilities/functions';

const CheckMarkIcon = (
  <View
    style={{
      position: 'absolute',
      top: -10,
      right: -8,
      backgroundColor: Colors.white,
      borderRadius: 50,
    }}
  >
    <AntDesign name="checkcircle" size={24} color={Colors.canadaButtonColor} />
  </View>
);

type PaymentOptionProps = {
  selected: boolean;
  onPress(): void;
  image: ImageSourcePropType;
  title: string;
};

const PaymentOption = ({ selected, onPress, image, title }: PaymentOptionProps) => {
  return (
    <Pressable
      style={{
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 14,
        marginHorizontal: 10,
        position: 'relative',
        backgroundColor: selected ? Colors.SelectLineItemHighlightColor : Colors.white,
        borderRadius: 8,
      }}
      onPress={onPress}
    >
      {selected ? CheckMarkIcon : null}
      <Image source={image} resizeMode="contain" style={{ width: 44, height: 44 }} />
      <CustomText
        weight="500"
        style={{
          textAlign: 'center',
          marginTop: 5,
          fontSize: 14,
          fontWeight: '500',
          color: Colors.QuickActionTextColor,
          width: 60,
        }}
      >
        {title}
      </CustomText>
    </Pressable>
  );
};

export default function PrepaidPlanCheckoutScreen({
  navigation,
  route,
}: RootStackScreenProps<'PrepaidPlanCheckout'>) {
  const dispatch = useDispatch();
  const { planId, update, data } = route.params;

  const { CurrentGst, CurrentPst, CurrentPlan_pfId, CurrentBalance, EndDate } = useSelector(
    (state: RootState) => state.selfServeCA.accountInfoCA
  );
  const { activeCnum } = useSelector((state: RootState) => state.user);

  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [plan, setPlan] = useState<BoxOnePrepaidPlan>();
  const [error, setError] = useState<null | string>(null);
  const actionType: 'PLAN CHANGE' | 'PLAN EXTENSION' | 'DATA TOP UP' =
    update === 'extension'
      ? 'PLAN EXTENSION'
      : update === 'data-top-up'
        ? 'DATA TOP UP'
        : 'PLAN CHANGE';

  //   CurrentGst
  // CurrentPst
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<{
    paymentTypeSlug: PaymentType;
    paymentType: PaymentMethods;
  } | null>(null);

  const effectiveDate = moment(EndDate).add(1, 'days').format(DATE_FORMAT);
  const endDate = moment(effectiveDate)
    .add(Number(plan?.PlanTypeD) - 1, 'days')
    .format(DATE_FORMAT); // -1 because date is inclusive

  const PaymentOptionList = [
    {
      title: 'Credit Card',
      image: CreditCardImage,
      onPress: () => {
        setSelectedPaymentMethod({
          paymentTypeSlug: 'CREDIT_CARD',
          paymentType: PaymentMethods.CREDIT_CARD,
        });
      },
      selected: selectedPaymentMethod?.paymentTypeSlug === 'CREDIT_CARD',
    },
    {
      title: 'PayPal',
      image: PaypalImage,
      onPress: () => {
        setSelectedPaymentMethod({
          paymentTypeSlug: 'PAYPAL',
          paymentType: PaymentMethods.PAYPAL,
        });
      },
      selected: selectedPaymentMethod?.paymentTypeSlug === 'PAYPAL',
    },
  ];

  if (Platform.OS === 'android') {
    PaymentOptionList.push({
      title: 'Google Pay',
      image: GooglePayImage,
      onPress: () => {
        setSelectedPaymentMethod({
          paymentTypeSlug: 'GOOGLE_PAY',
          paymentType: PaymentMethods.GOOGLE_PAY,
        });
      },
      selected: selectedPaymentMethod?.paymentTypeSlug === 'GOOGLE_PAY',
    });
  }
  if (Platform.OS === 'ios') {
    PaymentOptionList.push({
      title: 'Apple Pay',
      image: ApplePayImage,
      onPress: () => {
        setSelectedPaymentMethod({
          paymentTypeSlug: 'APPLE_PAY',
          paymentType: PaymentMethods.APPLE_PAY,
        });
      },
      selected: selectedPaymentMethod?.paymentTypeSlug === 'APPLE_PAY',
    });
  }
  if (isWebsite()) {
    PaymentOptionList.push({
      title: 'WeChat Pay',
      image: WechatPayImage,
      onPress: () => {
        setSelectedPaymentMethod({
          paymentTypeSlug: 'WECHAT_PAY',
          paymentType: PaymentMethods.WECHAT_PAY,
        });
      },
      selected: selectedPaymentMethod?.paymentTypeSlug === 'WECHAT_PAY',
    });
    PaymentOptionList.push({
      title: 'AliPay',
      image: AlipayImage,
      onPress: () => {
        setSelectedPaymentMethod({
          paymentTypeSlug: 'ALIPAY',
          paymentType: PaymentMethods.ALIPAY_QR,
        });
      },
      selected: selectedPaymentMethod?.paymentTypeSlug === 'ALIPAY',
    });
  }

  useEffect(() => {
    if (
      !planId &&
      (!update ||
        !['extension', 'data-top-up'].includes(update) ||
        (update === 'data-top-up' && (Number(data) <= 0 || Number(data) > 5)))
    ) {
      navigation.navigate('Root');
      return;
    }
    let _planId = planId ?? 0;
    if (actionType === 'PLAN EXTENSION') {
      _planId = CurrentPlan_pfId;
    }
    const getPlan = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await activationService.prepaidPlanById({
          data: {
            PlanId: _planId,
          },
        });
        if (response.data.length === 0) {
          if (isWebsite()) {
            alert('Please select a valid plan.');
          } else {
            Alert.alert('Invalid Plan', 'Please select a valid plan.');
          }
          navigation.navigate('Root');
          return;
        }
        const plan = response.data[0];
        const price = plan.Fee * (1 + CurrentGst + CurrentPst) + CurrentBalance;
        setTotalPrice(price);
        setPlan(plan);
      } catch (_error) {
        DevLog.log(_error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };
    if (actionType === 'DATA TOP UP') {
      const price = data! * 15 * (1 + CurrentGst + CurrentPst);
      setTotalPrice(price);
      dispatch(setIsLoading(false));
    } else {
      getPlan().catch((err) => DevLog.error(err));
    }
  }, [planId, update]);

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          padding: 20,
          backgroundColor: Colors.white,
          marginBottom: 150,
        }}
      >
        <CustomText
          weight="600"
          style={{ fontSize: 14, color: Colors.greyColor, marginBottom: 16 }}
        >
          Payment Info
        </CustomText>
        {actionType !== 'DATA TOP UP' ? (
          <View style={{ marginBottom: 30 }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <CustomText weight="500" style={{}}>
                Effective Date:{' '}
              </CustomText>
              <CustomText> {effectiveDate}</CustomText>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <CustomText weight="500" style={{}}>
                New Expiration Date:{' '}
              </CustomText>
              <CustomText> {endDate}</CustomText>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <CustomText weight="500" style={{}}>
                Plan Duration:{' '}
              </CustomText>
              <CustomText> {plan?.PlanTypeD} days </CustomText>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <CustomText weight="500" style={{}}>
                Plan Fee:{' '}
              </CustomText>
              <CustomText> ${plan?.Fee.toFixed(2)}</CustomText>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <CustomText weight="500" style={{}}>
                HST/GST:{' '}
              </CustomText>
              <CustomText>
                {(CurrentGst * 100) % 1 === 0 ? CurrentGst * 100 : (CurrentGst * 100).toFixed()}%
              </CustomText>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <CustomText weight="500" style={{}}>
                PST/QST:{' '}
              </CustomText>
              <CustomText>
                {(CurrentPst * 100) % 1 === 0 ? CurrentPst * 100 : (CurrentPst * 100).toFixed()}%
              </CustomText>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <CustomText weight="500" style={{}}>
                Current Balance:{' '}
              </CustomText>
              <CustomText> ${CurrentBalance.toFixed(2)}</CustomText>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <CustomText weight="500" style={{}}>
                Subtotal:{' '}
              </CustomText>
              <CustomText> ${(plan?.Fee ?? 0 + CurrentBalance).toFixed(2)}</CustomText>
            </View>
          </View>
        ) : (
          <View style={{ marginBottom: 30 }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <CustomText weight="500" style={{}}>
                HST/GST:{' '}
              </CustomText>
              <CustomText> {CurrentGst * 100}%</CustomText>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <CustomText weight="500" style={{}}>
                PST/QST:{' '}
              </CustomText>
              <CustomText> {CurrentPst * 100}%</CustomText>
            </View>

            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <CustomText weight="500" style={{}}>
                Subtotal:{' '}
              </CustomText>
              <CustomText> ${(Number(data) * 15).toFixed(2)}</CustomText>
            </View>
          </View>
        )}
        <CustomText
          weight="600"
          style={{ fontSize: 14, color: Colors.greyColor, marginBottom: 16 }}
        >
          Payment Methods
        </CustomText>

        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 5,
          }}
        >
          {PaymentOptionList.map((paymentOption) => (
            <PaymentOption {...paymentOption} key={paymentOption.title} />
          ))}
        </View>
        {error ? (
          <CustomText
            weight="500"
            style={{
              backgroundColor: Colors.redOpacity50,
              textAlign: 'center',
              color: Colors.white,
              padding: 14,
              borderRadius: 14,
              margin: 'auto',
              fontSize: 18,
              marginTop: 20,
            }}
          >
            {error}
          </CustomText>
        ) : null}
      </ScrollView>

      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
        }}
      >
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <CustomText weight="600" style={{ fontSize: 16, color: Colors.TextBlack }}>
            Total Amount:
          </CustomText>
          <CustomText weight="600" style={{ fontSize: 16, color: Colors.canadaButtonColor }}>
            ${totalPrice.toFixed(2)}
          </CustomText>
        </View>
        <Button
          style={{ marginTop: 32, marginHorizontal: isWebsite() ? 'auto' : undefined }}
          onPress={async () => {
            if (!selectedPaymentMethod) {
              return;
            }
            try {
              dispatch(setIsLoading(true));
              setError(null);
              const response = await prepaidService.topUpExtension({
                data: {
                  returnUrl: paymentUrl('return'),
                  cancelUrl: paymentUrl('cancel'),
                  payload: {
                    amount: totalPrice.toFixed(2),
                    cnum: activeCnum,
                    currency: 'CAD',
                    extensionStartDate:
                      actionType === 'DATA TOP UP'
                        ? ''
                        : moment(effectiveDate).format(DATE_FORMAT_FOR_API),
                    extensionEndDate:
                      actionType === 'DATA TOP UP'
                        ? ''
                        : moment(endDate).format(DATE_FORMAT_FOR_API),
                    extensionSubtotal:
                      actionType === 'DATA TOP UP' ? 0 : plan!.Fee + CurrentBalance,
                    paymentMethod:
                      selectedPaymentMethod?.paymentType === PaymentMethods.GOOGLE_PAY
                        ? PaymentMethods.CREDIT_CARD
                        : selectedPaymentMethod.paymentType,
                    systag: 'mobileapp/topup',
                    topUpCapacity: actionType === 'DATA TOP UP' ? data! : 0,
                    duration: actionType === 'PLAN EXTENSION' ? Number(plan?.PlanTypeD) : null,
                    newPfID: actionType === 'PLAN CHANGE' ? plan?.PlanId : null,
                  },
                },
              });
              if (response.data.substring(0, 6) === 'Failed') {
                return setError('Failed to complete payment');
              }

              if (Platform.OS !== 'web') {
                navigation.navigate('PaymentWebView', {
                  url: response.data,
                  paymentType: selectedPaymentMethod.paymentType,
                });
              } else {
                window.location.href = response.data;
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (_e: any) {
              DevLog.log(_e);
            } finally {
              dispatch(setIsLoading(false));
            }
          }}
          textStyle={{ fontSize: 14 }}
          disabled={selectedPaymentMethod === null}
        >
          Pay
        </Button>
      </View>
    </SafeAreaView>
  );
}
