import moment from 'moment';
import { View } from 'react-native';

import { Colors } from '../../../constants/Colors';
import { DATE_FORMAT } from '../../../constants/constants';
import { Product } from '../../../types/responses/MyAccount/GetMultiLineProductResponse';
import { formatPhoneNumber } from '../../../utilities/utilities';
import HorizontalLine from '../../HorizontalLine';
import CustomText from '../../utils/CustomText';
import Badge from '../Badge';

const MyProductListItem = ({
  product,
  isChildAccount = false,
}: {
  product: Product;
  isChildAccount?: boolean;
}) => {
  return (
    <>
      <View
        style={{
          borderRadius: 8,
          borderWidth: 1,
          borderColor: Colors.lightGrey,
          padding: 10,
          backgroundColor: product.TerminationDate === '' ? Colors.white : Colors.lightGrey,
          borderLeftWidth: isChildAccount ? 10 : 1,
        }}
      >
        <View style={{ flexDirection: 'row', gap: 8, marginBottom: 8, flexWrap: 'wrap' }}>
          {/* <Badge title="Canada" status={product.TerminationDate === '' ? 'blue' : 'grey'} /> */}
          {isChildAccount ? <Badge title="Child Account" status="green" /> : null}
          {product.TerminationDate === '' ? (
            <Badge title="Active" status="orange" />
          ) : (
            <Badge title="Terminated" status="grey" />
          )}
          <Badge
            title={product.AccountType.toLowerCase() === 'Postpaid' ? 'Monthly' : 'Prepaid'}
            status="grey"
          />
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <CustomText weight="500" style={{ fontSize: 20 }}>
            {product.PlanCapacity}
          </CustomText>
          <CustomText weight="600" style={{ fontSize: 20 }}>
            ${product.PlanFee.toFixed(2)}
          </CustomText>
        </View>
        <HorizontalLine thinkness={1} style={{ marginVertical: 8 }} />
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8 }}>
          <CustomText weight="500" style={{ fontSize: 12, color: Colors.greyColor }}>
            Activation
          </CustomText>
          <CustomText weight="500" style={{ fontSize: 12, color: Colors.TextBlack }}>
            {moment(product.ActivationDate).format(DATE_FORMAT)}
          </CustomText>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <CustomText weight="500" style={{ fontSize: 12, color: Colors.greyColor }}>
            Phone Number
          </CustomText>
          <CustomText weight="500" style={{ fontSize: 12, color: Colors.TextBlack }}>
            {formatPhoneNumber(product.phoneNum)}
          </CustomText>
        </View>
      </View>
      {product.isMultilineAccount && product.MultilineAccount && product.MultilineAccount.length > 0
        ? product.MultilineAccount.map((product, index) => (
            <MyProductListItem product={product} isChildAccount={true} key={index} />
          ))
        : null}
    </>
  );
};

export default MyProductListItem;
