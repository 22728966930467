import { useEffect, useState } from 'react';
import { SafeAreaView, ScrollView, View } from 'react-native';
import { useDispatch } from 'react-redux';

import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT } from '../../constants/constants';
import { activationService } from '../../services/MyAccount/ActivationService';
import { setIsLoading } from '../../store/app';
import { ParsedPlan } from '../../types/models/ParsedPlan';
import { ProvinceInfo } from '../../types/types';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';
import { getProratedDates } from '../../utilities/utilities';

import { SIMType } from '.';

type Props = {
  plan: ParsedPlan;
  activationDate: Date;
  selectedSimType: SIMType;
  simCardNumber: string | null;
  province: ProvinceInfo;
  onNext(): void;
};

export default function ProratedPlanView(props: Props) {
  const { activationStartDate, upcomingSecond, prorateEndDate } = getProratedDates(
    props.activationDate
  );

  const [dataAvailable, setDataAvailable] = useState<string>('');
  const [proratedFee, setProratedFee] = useState<number>(0.0);

  const dispatch = useDispatch();

  useEffect(() => {
    const proratedInfo = async () => {
      dispatch(setIsLoading(true));
      try {
        const response = await activationService.getTotalPostpaid({
          data: {
            orderSimCard:
              (props.simCardNumber === '' || props.simCardNumber === null) &&
              props.selectedSimType !== 'ESIM',
            planID: props.plan.planId,
            countryId: 42, // Canada
            provinceCode: props.province.code,
            start: activationStartDate.format('YYYY-MM-DD'),
            esim: props.selectedSimType === 'ESIM',
          },
        });
        setDataAvailable(response.data[0].prorate_DataCap);
        setProratedFee(response.data[0].prorate_Amt);
      } catch (_error) {
        DevLog.log(_error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };
    proratedInfo();
  }, [props.plan, props.activationDate]);

  return (
    <SafeAreaView
      style={[
        { flex: 1, height: '100%' },
        isWebsite() ? { maxWidth: 700, width: '100%', marginHorizontal: 'auto' } : {},
      ]}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          padding: 20,
          backgroundColor: Colors.white,
          flex: 1,
          height: '100%',
        }}
      >
        <CustomText weight="600" style={{ fontSize: 16, marginBottom: 12 }}>
          Why Prorated Plan?
        </CustomText>
        <CustomText style={{ fontSize: 14 }}>
          This is because you are activating your plan during our billing cycle. Your full billing
          cycle will start on{' '}
          <CustomText weight="600" style={{ color: Colors.ActionLinkColor }}>
            {upcomingSecond.format(DATE_FORMAT)}
          </CustomText>
          .
        </CustomText>

        <CustomText weight="600" style={{ fontSize: 14, marginTop: 32 }}>
          Prorated Plan Details
        </CustomText>
        <View
          style={{
            backgroundColor: Colors.backgroundGrey,
            padding: 16,
            borderRadius: 16,
            marginTop: 8,
            gap: 8,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <CustomText weight="500" style={{ fontSize: 12, color: Colors.greyColor }}>
              Prorated Plan Cycle
            </CustomText>
            <CustomText
              weight="500"
              style={{ fontSize: 12, color: Colors.TextBlack, textAlign: 'right' }}
            >
              {activationStartDate.format(DATE_FORMAT)} ~ {prorateEndDate.format(DATE_FORMAT)}
            </CustomText>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <CustomText weight="500" style={{ fontSize: 12, color: Colors.greyColor }}>
              Prorated Data
            </CustomText>
            <CustomText
              weight="500"
              style={{ fontSize: 12, color: Colors.TextBlack, textAlign: 'right' }}
            >
              {dataAvailable}
            </CustomText>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <CustomText weight="500" style={{ fontSize: 12, color: Colors.greyColor }}>
              Prorated Fee
            </CustomText>
            <CustomText
              weight="500"
              style={{ fontSize: 12, color: Colors.TextBlack, textAlign: 'right' }}
            >
              ${proratedFee.toFixed(2)}
            </CustomText>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <CustomText weight="500" style={{ fontSize: 12, color: Colors.greyColor }}>
              Full Billing Cycle Start Date
            </CustomText>
            <CustomText
              weight="500"
              style={{ fontSize: 12, color: Colors.TextBlack, textAlign: 'right' }}
            >
              {upcomingSecond.format(DATE_FORMAT)}
            </CustomText>
          </View>
        </View>

        <CustomText weight="600" style={{ fontSize: 14, marginTop: 16 }}>
          Prorated Plan Features
        </CustomText>
        <View
          style={{
            backgroundColor: Colors.backgroundGrey,
            padding: 16,
            borderRadius: 16,
            marginTop: 8,
            gap: 8,
          }}
        >
          <CustomText weight="500" style={{ fontSize: 12, color: Colors.greyColor }}>
            &bull; Unlimited Canada-wide calling
          </CustomText>
          <CustomText weight="500" style={{ fontSize: 12, color: Colors.greyColor }}>
            &bull; Up to 1000 minutes of International Calls
          </CustomText>
          <CustomText weight="500" style={{ fontSize: 12, color: Colors.greyColor }}>
            &bull; Unlimited Messages
          </CustomText>
        </View>
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={() => {
            props.onNext();
          }}
          textStyle={{ fontSize: 14 }}
        >
          Confirm and Proceed
        </Button>
      </View>
    </SafeAreaView>
  );
}
