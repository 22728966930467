import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';

export default function useHorizontalContainerMargin() {
  const [width, setWidth] = useState(0);
  const dimensions = useWindowDimensions();

  useEffect(() => {
    if (dimensions.width < 768) {
      setWidth(0);
      return;
    }
    if (dimensions.width < 992) {
      setWidth(50);
      return;
    }
    if (dimensions.width < 1200) {
      setWidth(130);
      return;
    }
    if (dimensions.width < 1400) {
      setWidth(220);
      return;
    }
    setWidth(280);
  }, [dimensions.width]);

  return width;
}
