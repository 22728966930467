import { Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import {
  Pressable,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import { useDispatch } from 'react-redux';

import { AppDrawer } from '../../components/AppDrawer';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import Badge from '../../components/UI/Badge';
import PlanTypeBadges, { PlanType } from '../../components/UI/PlanTypeBadges';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { activationService } from '../../services/MyAccount/ActivationService';
import { setIsLoading } from '../../store/app';
import { ParsedPlan } from '../../types/models/ParsedPlan';
import { GetPostpaidPlansResponse } from '../../types/responses/MyAccount/GetPostpaidPlansResponse';
import { GetPrepaidPlansResponse } from '../../types/responses/MyAccount/GetPrepaidPlansResponse';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';
import { mapBoxOnePostpaidPlans, mapBoxOnePrepaidPlans } from '../../utilities/utilities';

const PlanView = () => {
  const navigation = useNavigation();

  const [prepaidPlans, setPrepaidPlans] = useState<ParsedPlan[]>([]);
  const [postpaidPlans, setPostpaidPlans] = useState<ParsedPlan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<ParsedPlan | null>(null);
  const [selectedPlansTyle, setSelectedPlansType] = useState<PlanType>('monthly');
  const [planDetailModelShown, setPlanDetailModelShown] = useState(false);

  const [_, setError] = useState<string | null>(null);

  const dispatch = useDispatch();

  const getPlansAsync = async () => {
    dispatch(setIsLoading(true));
    try {
      const planApiCalls = [];
      planApiCalls.push(
        activationService.getPostpaidPlans({
          data: { carrier: '', promo_code: '', currency: 'CAD' },
        })
      );
      planApiCalls.push(
        activationService.getPrepaidPlans({
          data: { carrier: '', promo_code: '', currency: 'CAD' },
        })
      );
      const [{ data: postpaidPlans }, { data: prepaidPlans }] = await Promise.all(planApiCalls);
      setPostpaidPlans(mapBoxOnePostpaidPlans(postpaidPlans as GetPostpaidPlansResponse));
      setPrepaidPlans(mapBoxOnePrepaidPlans(prepaidPlans as GetPrepaidPlansResponse));
    } catch (_err) {
      setError('Unable to fetch plans, please try again.');
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    getPlansAsync().catch((err) => DevLog.error(err));
  }, []);

  useEffect(() => {
    setSelectedPlan(null);
  }, [selectedPlansTyle]);

  const Plan = ({ plan }: { plan: ParsedPlan }) => {
    return (
      <Pressable
        style={{
          padding: 16,
          borderWidth: 1,
          borderColor: Colors.activePlanBorderColor,
          borderRadius: 12,
          backgroundColor:
            selectedPlan && selectedPlan.planId === plan.planId
              ? Colors.ActionLinkColorBG
              : Colors.noColor,
        }}
        onPress={() => {
          setSelectedPlan((selectedPlan) => (selectedPlan?.planId === plan.planId ? null : plan));
          setPlanDetailModelShown(true);
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 14,
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Entypo name="mobile" size={24} color={Colors.canadaButtonColor} />
            <CustomText
              weight="500"
              style={{ fontSize: 12, color: Colors.QuickActionTextColor, marginLeft: 8 }}
            >
              Canadian Mobile Plan
            </CustomText>
          </View>
          <Badge
            title={plan.planType === 'monthly' ? 'Monthly' : `${plan.planTypeD} Days`}
            status="light-blue"
            fontweight="600"
          />
        </View>
        <View
          style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <CustomText weight="500" style={{ fontSize: 20, color: Colors.canadaButtonColor }}>
            {plan.data}
          </CustomText>
          <CustomText weight="600" style={{ fontSize: 20 }}>
            ${plan.plan_fee.toFixed(2)}
          </CustomText>
        </View>
      </Pressable>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          padding: 20,
          backgroundColor: Colors.white,
        }}
        refreshControl={<RefreshControl refreshing={false} onRefresh={getPlansAsync} />}
      >
        <PlanTypeBadges selected={selectedPlansTyle} setSelected={setSelectedPlansType} />
        <View style={{ marginVertical: 16, gap: 16 }}>
          {selectedPlansTyle === 'monthly'
            ? postpaidPlans.map((plan) => <Plan plan={plan} key={plan.planId} />)
            : null}
          {selectedPlansTyle === 'prepaid'
            ? prepaidPlans.map((plan) => <Plan plan={plan} key={plan.planId} />)
            : null}
        </View>
      </ScrollView>
      <AppDrawer
        visible={selectedPlan !== null && planDetailModelShown}
        height={620}
        onClose={() => {
          setPlanDetailModelShown(false);
          setSelectedPlan(null);
        }}
        showBackDrop
      >
        <CustomText weight="500" style={{ fontSize: 24, marginBottom: 10 }}>
          Plan Details
        </CustomText>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 16,
          }}
        >
          <CustomText weight="600" style={{ fontSize: 24, color: Colors.canadaButtonColor }}>
            {selectedPlan?.data}
          </CustomText>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Badge title="CAD" status="light-blue" textTransform="uppercase" />
            <CustomText weight="500" style={{ fontSize: 16, marginLeft: 8 }}>
              ${selectedPlan?.plan_fee.toFixed(2)}
            </CustomText>
            <CustomText style={{ fontSize: 12 }}>
              /{selectedPlan?.planType === 'monthly' ? 'Month' : selectedPlan?.planTypeD + ' Days'}
            </CustomText>
          </View>
        </View>
        <HorizontalLine thinkness={1} />
        <View>
          <CustomText weight="500" style={styles.planDetailTitle}>
            Call & Text
          </CustomText>
          <CustomText style={styles.planDetailInfo}>Unlimited Canada wide Call & Text</CustomText>
          <CustomText style={styles.planDetailInfo}>Unlimited International Text</CustomText>
          <CustomText style={styles.planDetailInfo}>
            Up to 1,000 mins of International Calls
          </CustomText>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View style={{ width: 150 }}>
            <CustomText weight="500" style={styles.planDetailTitle}>
              Data
            </CustomText>
            <CustomText style={styles.planDetailInfo}>{selectedPlan?.data}</CustomText>
            <CustomText style={styles.planDetailInfo}>4G LTE/5G Speed</CustomText>
          </View>
          <View style={{ width: 150 }}>
            <CustomText weight="500" style={styles.planDetailTitle}>
              Additional Feature
            </CustomText>
            <CustomText style={styles.planDetailInfo}>Regular SIM</CustomText>
            <CustomText style={styles.planDetailInfo}>Voicemail (35 mins)</CustomText>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View style={{ width: 150 }}>
            <CustomText weight="500" style={styles.planDetailTitle}>
              Network
            </CustomText>
            <CustomText style={styles.planDetailInfo}>{selectedPlan?.carrier}</CustomText>
          </View>
          <View style={{ width: 150 }}>
            <CustomText weight="500" style={styles.planDetailTitle}>
              Data Overage Fee
            </CustomText>
            <CustomText style={styles.planDetailInfo}>$15.00 per 1GB</CustomText>
          </View>
        </View>

        <View
          style={
            isWebsite()
              ? {
                  maxWidth: 400,
                  marginHorizontal: 'auto',
                  width: '100%',
                }
              : null
          }
        >
          <Button
            style={{ marginTop: 32 }}
            onPress={async () => {
              navigation.navigate('PurchasePlanFlow', {
                plan: selectedPlan!,
              });
            }}
            textStyle={{ fontSize: 14 }}
          >
            Select this plan
          </Button>
          <Button
            style={{ marginTop: 16 }}
            color={Colors.backgroundGrey}
            textColor={Colors.inputTextPlaceholderColor}
            onPress={() => {
              setPlanDetailModelShown(false);
              setSelectedPlan(null);
            }}
            textStyle={{ fontSize: 14, fontWeight: '600' }}
          >
            Select other plan
          </Button>
        </View>
      </AppDrawer>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  planDetailInfo: { fontSize: 14 },
  planDetailTitle: {
    color: Colors.inputTextPlaceholderColor,
    fontSize: 12,
    marginBottom: 8,
    marginTop: 16,
  },
});

export default PlanView;
