import { useState } from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  StatusBar,
  ScrollView,
  Image,
  Pressable,
  Alert,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { profileImages } from '../../constants/constants';
import { authService } from '../../services/SSO/AuthService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import { updateProfile } from '../../store/user';
import { SystemCodes } from '../../types/enums/SystemCodes';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';

export default function EditNameScreen() {
  const dispatch = useDispatch();
  const { firstName, lastName, profileImage } = useSelector((state: RootState) => state.user);
  const { isLoading } = useSelector((state: RootState) => state.app);

  const [newFirstname, setNewFirstname] = useState<string>(firstName);

  const [newLastname, setNewLastname] = useState<string>(lastName);
  const [newProfileImage, setNewProfileImage] = useState<number>(profileImage);

  const [error, setError] = useState<null | string>(null);

  const updateProfileInfo = async () => {
    dispatch(setIsLoading(true));
    try {
      const updateProfileResponse = await authService.updateProfile({
        data: {
          firstName: newFirstname,
          lastName: newLastname,
          systemCode: SystemCodes.CA_SELFSERVE,
          profileImage: newProfileImage,
        },
      });
      if (!updateProfileResponse?.data?.success) {
        DevLog.error(
          'Something went wrong with resendActivationCodeAsync():',
          updateProfileResponse
        );
        setError('Sorry, something went wrong.');
      } else {
        dispatch(updateProfile(updateProfileResponse.data.data.profile));
        if (isWebsite()) {
          alert('Address is updated successfully.');
        } else {
          Alert.alert('Success', 'Name is updated successfully.');
        }
        setError('');
      }
    } catch (err) {
      DevLog.error(err);
      setError('Sorry, something went wrong.');
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        height: '100%',
        marginTop: StatusBar.currentHeight,
        backgroundColor: Colors.white,
      }}
    >
      <ScrollView style={{ padding: 20, marginBottom: 100 }}>
        <CustomText
          style={[
            { color: Colors.greyColor, fontSize: 12, fontWeight: '500' },
            isWebsite()
              ? {
                  maxWidth: 400,
                  marginHorizontal: 'auto',
                  width: '100%',
                }
              : null,
          ]}
          weight="500"
        >
          Profile Image
        </CustomText>
        <View
          style={[
            { flexDirection: 'row', flexWrap: 'wrap', gap: 18, marginTop: 16 },
            isWebsite()
              ? {
                  maxWidth: 400,
                  marginHorizontal: 'auto',
                  width: '100%',
                }
              : null,
          ]}
        >
          {profileImages.map((image, index) => (
            <Pressable onPress={() => setNewProfileImage(index)} key={index}>
              <Image
                source={image}
                style={[
                  styles.profileImage,
                  newProfileImage === index
                    ? { borderColor: Colors.MainOrange }
                    : { borderColor: Colors.noColor },
                ]}
                resizeMode="contain"
              />
            </Pressable>
          ))}
        </View>
        <InputWithTitle
          title="First Name"
          inputValue={newFirstname}
          onInputChange={setNewFirstname}
        />
        <InputWithTitle title="Last Name" inputValue={newLastname} onInputChange={setNewLastname} />
        {error ? (
          <View style={{ marginVertical: 14 }}>
            <CustomText
              weight="500"
              style={{ fontSize: 14, color: Colors.errorRed, textAlign: 'center' }}
            >
              {error}
            </CustomText>
          </View>
        ) : null}
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          style={{ marginTop: 32 }}
          disabled={isLoading}
          loadingStatus={isLoading}
          onPress={() => {
            updateProfileInfo();
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Update Name
        </Button>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  profileImage: {
    borderRadius: 70,
    borderWidth: 3,
    height: 70,
    width: 70,
  },
});
