import { AntDesign } from '@expo/vector-icons';
import moment from 'moment';
import { Platform, ScrollView, View } from 'react-native';

import CancelledTimeline from './CancelledTimeline';
import CompletedTimeline from './CompletedTimeline';
import InBetweenTimeline from './InBetweenTimeline';
import ProcessingTimeline from './ProcessingTimeline';
import ReceivedTimeline from './ReceivedTimeline';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import Badge from '../../components/UI/Badge';
import TitleWithSupTitle from '../../components/UI/TitleWithSupTitle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { BADGES_COLOR, DATE_FORMAT } from '../../constants/constants';
import { UnifiedCustomerServiceRequests } from '../../types/responses/MyAccount/GetCustomerServiceRequestsResponse';
import { PortInStatus, PortOutStatus } from '../../types/types';
import { formatPhoneNumber } from '../../utilities/utilities';

const InitialView = ({
  selectingServiceRequests,
  onUpdateInfo,
}: {
  selectingServiceRequests: UnifiedCustomerServiceRequests;
  onUpdateInfo(): void;
}) => {
  // While using separate timeline views for each status isn't ideal, we have opted for this approach due to time constraints.
  const timeline: Record<PortInStatus | PortOutStatus, React.JSX.Element> = {
    received: <ReceivedTimeline date={new Date(selectingServiceRequests.RequestTime)} />,
    processing: <ProcessingTimeline date={new Date(selectingServiceRequests.RequestTime)} />,
    cancelled: <CancelledTimeline date={new Date(selectingServiceRequests.RequestTime)} />,
    telus: (
      <InBetweenTimeline title={'telus'} date={new Date(selectingServiceRequests.RequestTime)} />
    ),
    rogers: (
      <InBetweenTimeline title={'rogers'} date={new Date(selectingServiceRequests.RequestTime)} />
    ),
    'ownership transfer': (
      <InBetweenTimeline
        title={'ownership transfer'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'china telecom': (
      <InBetweenTimeline
        title={'china telecom'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    '1st pending port out': (
      <InBetweenTimeline
        title={'1st pending port out'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    '2st pending port out': (
      <InBetweenTimeline
        title={'2st pending port out'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'revoke completion': (
      <InBetweenTimeline
        title={'revoke completion'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'notified: 90 mins': (
      <InBetweenTimeline
        title={'notified: 90 mins'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'notified: invalid info': (
      <InBetweenTimeline
        title={'notified: invalid info'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'notified: device balance': (
      <InBetweenTimeline
        title={'notified: device balance'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'notified: dependent line': (
      <InBetweenTimeline
        title={'notified: dependent line'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'notified: inactive line': (
      <InBetweenTimeline
        title={'notified: inactive line'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'notified: authorization note': (
      <InBetweenTimeline
        title={'notified: authorization note'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'notified: incompleted': (
      <InBetweenTimeline
        title={'notified: incompleted'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'notified: land line': (
      <InBetweenTimeline
        title={'notified: land line'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    submition: (
      <InBetweenTimeline
        title={'submition'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    incompleted: (
      <InBetweenTimeline
        title={'incompleted'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'notified: cancelled': (
      <InBetweenTimeline
        title={'notified: cancelled'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'notified: others': (
      <InBetweenTimeline
        title={'notified: others'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'notified: invalid sim card': (
      <InBetweenTimeline
        title={'notified: invalid sim card'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'notified: interaction id (t)': (
      <InBetweenTimeline
        title={'notified: interaction id (t)'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    'notified: completion (t)': (
      <InBetweenTimeline
        title={'notified: completion (t)'}
        date={new Date(selectingServiceRequests.RequestTime)}
      />
    ),
    completed: <CompletedTimeline date={new Date(selectingServiceRequests.RequestTime)} />,
  };

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ ...Platform.select({ ios: { padding: 20 } }) }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 12,
          }}
        >
          <CustomText weight="500" style={{ fontSize: 20, textTransform: 'capitalize' }}>
            {selectingServiceRequests.requestType.toLowerCase().replace('_', ' ')}
          </CustomText>
          {selectingServiceRequests ? (
            <Badge
              title={selectingServiceRequests.status!}
              status={BADGES_COLOR[selectingServiceRequests.status!]}
            />
          ) : null}
        </View>
        <CustomText
          weight="500"
          style={{ color: Colors.inputTextPlaceholderColor, marginBottom: 4 }}
        >
          Request Date:{' '}
          <CustomText style={{ color: Colors.TextBlack }}>
            {moment(selectingServiceRequests.RequestTime).format(DATE_FORMAT)}
          </CustomText>
        </CustomText>
        {selectingServiceRequests.requestType === 'PORT_OUT' ||
        selectingServiceRequests.requestType === 'PORT_IN' ? (
          <CustomText weight="500" style={{ color: Colors.inputTextPlaceholderColor }}>
            Phone Number:{' '}
            <CustomText style={{ color: Colors.TextBlack }}>
              {formatPhoneNumber(
                selectingServiceRequests.PhoneNumber ??
                  selectingServiceRequests.PortinPhoneNumber ??
                  ''
              )}
            </CustomText>
          </CustomText>
        ) : null}
        <HorizontalLine thinkness={1} style={{ marginVertical: 16 }} />
        {Number(selectingServiceRequests.SubmissionCount) >= 5 ? (
          <View
            style={{
              borderColor: Colors.activePlanBorderColor,
              borderWidth: 1,
              borderRadius: 8,
              padding: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>
              <View
                style={{
                  backgroundColor: Colors.badge.red.bg,
                  padding: 4,
                  marginRight: 8,
                  borderRadius: 10,
                }}
              >
                <AntDesign name="exclamationcircleo" size={16} color={Colors.badge.red.color} />
              </View>
              <CustomText weight="600" style={{ fontSize: 16 }}>
                Resubmission Limit Exceeded
              </CustomText>
            </View>
            <CustomText>
              Please contact our Customer Support team to update your information or resubmit port
              in.
            </CustomText>
          </View>
        ) : null}
        {['notified: invalid info'].includes(selectingServiceRequests.status!) ? (
          <View
            style={{
              borderColor: Colors.activePlanBorderColor,
              borderWidth: 1,
              borderRadius: 8,
              padding: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>
              <View
                style={{
                  backgroundColor: Colors.badge.red.bg,
                  padding: 4,
                  marginRight: 8,
                  borderRadius: 10,
                }}
              >
                <AntDesign name="exclamationcircleo" size={16} color={Colors.badge.red.color} />
              </View>
              <CustomText weight="600" style={{ fontSize: 16 }}>
                Incorrect Information
              </CustomText>
            </View>
            <CustomText>
              We are unable to proceed with the port in due to incorrect port in information. Please
              provide correct information clicking the button below.
            </CustomText>
          </View>
        ) : null}
        {['notified: 90 mins'].includes(selectingServiceRequests.status!) ? (
          <View
            style={{
              borderColor: Colors.activePlanBorderColor,
              borderWidth: 1,
              borderRadius: 8,
              padding: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>
              <View
                style={{
                  backgroundColor: Colors.badge['light-blue'].bg,
                  padding: 4,
                  marginRight: 8,
                  borderRadius: 10,
                }}
              >
                <AntDesign
                  name="questioncircleo"
                  size={16}
                  color={Colors.badge['light-blue'].color}
                />
              </View>
              <CustomText weight="600" style={{ fontSize: 16, color: Colors.canadaButtonColor }}>
                What is 90 MIN SMS?
              </CustomText>
            </View>
            <CustomText>
              To complete the port-in process, you need to reply to the 90-minute SMS by responding
              with &ldquo;Yes&rdquo; within 90 minutes. This message is sent by your previous
              carrier. If you missed the SMS, you will need to resubmit your port-in request.
            </CustomText>
          </View>
        ) : null}
        {timeline[selectingServiceRequests.status ?? 'received']}
        {selectingServiceRequests.requestType === 'PORT_IN' ? (
          <View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 28,
              }}
            >
              <CustomText
                style={{ color: Colors.greyColor, fontSize: 12, fontWeight: '500' }}
                weight="500"
              >
                Port In Info
              </CustomText>
            </View>
            <View
              style={{
                backgroundColor: Colors.backgroundGrey,
                padding: 16,
                borderRadius: 16,
                marginTop: 8,
              }}
            >
              <TitleWithSupTitle
                title={`${selectingServiceRequests.FirstName} ${selectingServiceRequests.LastName}`}
                supTitle="Name"
              />
              <TitleWithSupTitle
                title={formatPhoneNumber(selectingServiceRequests.PortinPhoneNumber!)!}
                supTitle="Phone Number"
              />
              <TitleWithSupTitle title={selectingServiceRequests.Email!} supTitle="Email Address" />
            </View>
          </View>
        ) : null}
      </ScrollView>
      {['notified: invalid info'].includes(selectingServiceRequests.status!) ? (
        <View
          style={{
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          }}
        >
          <Button
            style={{ marginTop: 32 }}
            onPress={() => {
              onUpdateInfo();
            }}
            textStyle={{ fontSize: 14, fontWeight: '600' }}
          >
            Update Information
          </Button>
        </View>
      ) : null}
    </>
  );
};

export default InitialView;
