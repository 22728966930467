import { Feather } from '@expo/vector-icons';
import { DateTimePickerAndroid, DateTimePickerEvent } from '@react-native-community/datetimepicker';
import moment from 'moment';
import React, { useState } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { DatePickerModal } from 'react-native-paper-dates';
import { CalendarDate } from 'react-native-paper-dates/lib/typescript/Date/Calendar';
import { useDispatch } from 'react-redux';

import { AppDrawer } from '../../components/AppDrawer';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import InputWithSelectOption from '../../components/UI/Form/InputForSelectOption';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import InfoDescriptionToggle from '../../components/UI/InfoDescriptionToggle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT, TerminationReasons } from '../../constants/constants';
import { setTerminationInfo } from '../../store/app';
import { RootStackScreenProps } from '../../types';
import { TerminationInfo } from '../../types/models/AppStateModels';
import { isWebsite } from '../../utilities/functions';

export default function TerminatePlanScreen({ navigation }: RootStackScreenProps<'TerminatePlan'>) {
  const [terminationDate, setTerminationDate] = useState<Date>();
  const [showDateModal, setShowDateModal] = useState(false);

  const [reasonDesc, setReasonDesc] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [showReasonModal, setShowReasonModal] = useState(false);

  const dispatch = useDispatch();

  const onConfirmDate = (params: { date: CalendarDate }) => {
    setShowDateModal(false);
    if (params.date) {
      setTerminationDate(params.date);
    }
  };

  const onDismissDate = () => {
    setShowDateModal(false);
  };

  return (
    <>
      <View
        style={{
          ...StyleSheet.absoluteFillObject,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: Colors.overlay75,
          zIndex: 10,
        }}
      >
        <View
          style={{
            backgroundColor: Colors.white,
            padding: 20,
            marginHorizontal: 20,
            borderRadius: 10,
            gap: 10,
            maxWidth: 500,
          }}
        >
          <CustomText style={{ fontSize: 16 }} weight="600">
            Feature Unavailable
          </CustomText>
          <HorizontalLine />
          <CustomText>
            We apologize, but this feature is currently unavailable. For assistance with terminating
            your mobile plan, please contact our customer service team.
          </CustomText>
        </View>
      </View>
      <ScrollView style={{ flex: 1, paddingHorizontal: 20, marginBottom: 120 }}>
        <CustomText
          weight="600"
          style={{ fontSize: 24, color: Colors.errorRed, marginTop: 32, textAlign: 'center' }}
        >
          Terminate mobile plan
        </CustomText>
        <CustomText
          style={{
            fontSize: 14,
            textAlign: 'center',
            marginTop: 8,
            color: Colors.TextBlack,
            paddingHorizontal: 20,
          }}
        >
          Terminating your number does not delete your account. If you would like to delete your
          account, please go to the &lsquo;My Profile&rsquo; menu after terminating the service.
        </CustomText>
        <CustomText
          style={{
            fontSize: 14,
            textAlign: 'center',
            marginTop: 8,
            color: Colors.TextBlack,
          }}
        >
          Also, please note that once your number is terminated, it cannot be retrieved.
        </CustomText>
        <InputWithSelectOption
          value={terminationDate ? moment(terminationDate).format(DATE_FORMAT) : 'Select Date'}
          title="Termination Date"
          onPress={() => {
            if (Platform.OS === 'android') {
              DateTimePickerAndroid.open({
                value: terminationDate ?? new Date(),
                onChange: (_: DateTimePickerEvent, selectedDate?: Date) => {
                  if (selectedDate) {
                    setTerminationDate(selectedDate);
                  }
                },
                mode: 'date',
                is24Hour: true,
                minimumDate: new Date(),
              });
            } else {
              setShowDateModal(true);
            }
          }}
          icon={<Feather name="calendar" size={20} color={Colors.canadaButtonColor} />}
        />

        <InputWithSelectOption
          title="Reason for Termination"
          value={
            TerminationReasons.find((p) => p.code === reason)?.description ?? 'Select from the list'
          }
          onPress={() => {
            setShowReasonModal(true);
          }}
        />

        <InputWithTitle
          multiline={true}
          inputValue={reasonDesc}
          title="Tell us more about the reason"
          numberOfLines={8}
          textAlignVertical="top"
          onInputChange={setReasonDesc}
          inputStyle={{
            height: 150,
          }}
        />

        <HorizontalLine style={{ marginVertical: 24 }} />
        <InfoDescriptionToggle
          title="Can I retrieve my number?"
          desc="Unfortunately, we won't be able to retrieve your number once it is terminated. Please make sure that you terminate the right number"
          height={120}
          style={{
            marginHorizontal: 0,
            marginBottom: 16,
          }}
        />
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            paddingBottom: 40,
            paddingHorizontal: 20,
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          onPress={() => {
            dispatch(
              setTerminationInfo({
                reasonDesc,
                reason,
                terminationDate,
              } as TerminationInfo)
            );
            navigation.navigate('RetentionPlan', {
              reason: 'TERMINATE',
            });
          }}
          disabled={!terminationDate || reason === '' || reasonDesc === ''}
          color={Colors.red}
        >
          Request Termination
        </Button>
      </View>
      <DatePickerModal
        validRange={{
          startDate: new Date(),
        }}
        locale="en"
        mode="single"
        visible={showDateModal && Platform.OS !== 'android'}
        date={terminationDate}
        onConfirm={onConfirmDate}
        onDismiss={onDismissDate}
      />
      <AppDrawer
        onClose={() => {
          setShowReasonModal(false);
        }}
        height={300}
        visible={showReasonModal}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24, textAlign: 'center', marginBottom: 32 }}>
          Reason of Termination
        </CustomText>
        <ScrollView showsVerticalScrollIndicator={false}>
          {TerminationReasons.map((terminationReason, index) => (
            <React.Fragment key={terminationReason.code}>
              <CustomText
                onPress={() => {
                  setReason(terminationReason.code);
                  setShowReasonModal(false);
                }}
                style={{
                  fontSize: 15,
                  marginVertical: 8,
                  borderBottomColor: Colors.red,
                  borderBottomWidth: 2,
                }}
                key={terminationReason.code}
              >
                {terminationReason.description}
              </CustomText>
              {TerminationReasons.length - 1 > index ? <HorizontalLine /> : null}
            </React.Fragment>
          ))}
        </ScrollView>
      </AppDrawer>
    </>
  );
}
