import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { RootStackScreenProps } from '../../types';
import { isWebsite } from '../../utilities/functions';

export default function VacationHoldScreen({ navigation }: RootStackScreenProps<'VacationHold'>) {
  return (
    <SafeAreaView style={{ flex: 1, paddingHorizontal: 20 }}>
      <CustomText
        weight="600"
        style={{
          fontSize: 24,
          color: Colors.canadaButtonColor,
          textAlign: 'center',
          width: 250,
          alignSelf: 'center',
        }}
      >
        Up to 3 months of Free vacation Plan
      </CustomText>
      <CustomText
        style={{ fontSize: 14, textAlign: 'center', color: Colors.TextBlack, marginTop: 8 }}
      >
        Your services will be paused temporarily and your current phone plan will resume once your
        vacation plan ends.
      </CustomText>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            paddingBottom: 40,
            paddingHorizontal: 20,
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          onPress={() => {
            navigation.navigate('VacationHoldDetail');
          }}
        >
          Set up vacation plan
        </Button>
      </View>
    </SafeAreaView>
  );
}
