import { identityInstance } from '../../api/axios';
import { ApiRequest } from '../../types/requests/ApiRequest';
import { ActivateUserByEmailIdRequest } from '../../types/requests/SSO/ActivateUserByEmailIdRequest';
import { ActivateUserRequest } from '../../types/requests/SSO/ActivateUserRequest';
import { ResendActivationCodeRequest } from '../../types/requests/SSO/ResendActivationCodeRequest';
import { SignUpRequest } from '../../types/requests/SSO/SignUpRequest';
import { ValidateEmailRequest } from '../../types/requests/SSO/ValidateEmailRequest';
import { IdentityApiResponse } from '../../types/responses/ApiResponse';
import { ActivateUserByEmailIdResponse } from '../../types/responses/SSO/ActivateUserByEmailIdResponse';
import { ActivateUserResponse } from '../../types/responses/SSO/ActivateUserResponse';
import { ResendActivationCodeResponse } from '../../types/responses/SSO/ResendActivationCodeResponse';
import { SignUpResponse } from '../../types/responses/SSO/SignUpResponse';
import { ValidateEmailResponse } from '../../types/responses/SSO/ValidateEmailResponse';

export const signUpService = {
  signUp: async (
    request: ApiRequest<SignUpRequest>
  ): Promise<IdentityApiResponse<SignUpResponse>> =>
    await identityInstance.post<ApiRequest<SignUpRequest>, IdentityApiResponse<SignUpResponse>>(
      '/SignUp/v1/signup',
      request
    ),
  validateEmail: async (
    request: ApiRequest<ValidateEmailRequest>
  ): Promise<IdentityApiResponse<ValidateEmailResponse>> =>
    await identityInstance.post<
      ApiRequest<ValidateEmailRequest>,
      IdentityApiResponse<ValidateEmailResponse>
    >('/SignUp/v1/validate_email', request),
  activateUser: async (
    request: ApiRequest<ActivateUserRequest>
  ): Promise<IdentityApiResponse<ActivateUserResponse>> =>
    await identityInstance.post<
      ApiRequest<ActivateUserRequest>,
      IdentityApiResponse<ActivateUserResponse>
    >('/SignUp/v1/activate_user', request),
  resendActivationCode: async (
    request: ApiRequest<ResendActivationCodeRequest>
  ): Promise<IdentityApiResponse<ResendActivationCodeResponse>> =>
    await identityInstance.post<
      ApiRequest<ResendActivationCodeRequest>,
      IdentityApiResponse<ResendActivationCodeResponse>
    >('/SignUp/v1/ResendActivationCode', request),
  activateUserByEmailId: async (
    request: ApiRequest<ActivateUserByEmailIdRequest>
  ): Promise<IdentityApiResponse<ActivateUserByEmailIdResponse>> =>
    await identityInstance.post<
      ApiRequest<ActivateUserByEmailIdRequest>,
      IdentityApiResponse<ActivateUserByEmailIdResponse>
    >('/SignUp/v1/ActivateUserByEmailId', request),
};
