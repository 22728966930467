import { MaterialIcons } from '@expo/vector-icons';
import moment from 'moment';
import { View } from 'react-native';

import { Colors } from '../../../constants/Colors';
import { DATE_FORMAT } from '../../../constants/constants';
import { RecentTransaction } from '../../../types/models/RecentTransaction';
import CustomText from '../../utils/CustomText';

const RecentTransactionListItem = ({ transaction }: { transaction: RecentTransaction }) => (
  <View
    style={{
      flexDirection: 'row',
      alignItems: 'center',
      gap: 16,
      padding: 10,
    }}
  >
    <View
      style={{
        backgroundColor: Colors.backgroundGrey,
        padding: 12,
        borderRadius: 50,
      }}
    >
      <MaterialIcons name="currency-exchange" size={20} color={Colors.green} />
    </View>
    <View style={{ flex: 1 }}>
      <CustomText weight="500" style={{ fontSize: 16 }}>
        {transaction.ItemName}
      </CustomText>
      <CustomText weight="300" style={{ fontSize: 12 }}>
        {moment(transaction.Timestamp).format(DATE_FORMAT)}
      </CustomText>
    </View>
    <CustomText weight="500" style={{ fontSize: 14, color: Colors.canadaButtonColor }}>
      ${transaction.ItemAmount.toFixed(2)}
    </CustomText>
  </View>
);

export default RecentTransactionListItem;
