import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';

import { activationService } from '../../services/MyAccount/ActivationService';
import { setIsLoading } from '../../store/app';
import { ParsedPlan } from '../../types/models/ParsedPlan';
import { GetPostpaidPlansResponse } from '../../types/responses/MyAccount/GetPostpaidPlansResponse';
import DevLog from '../../utilities/debug-error';
import { mapBoxOnePostpaidPlans } from '../../utilities/utilities';
import Button from '../Button';
import CustomText from '../utils/CustomText';

const BeforeBlackFridayPromo = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const [plans, setPlans] = useState<ParsedPlan[]>([]);

  const fetchPlans = async () => {
    try {
      dispatch(setIsLoading(true));
      const { data: postpaidPlans } = await activationService.getPostpaidPlans({
        data: { carrier: '', promo_code: '', currency: 'CAD' },
      });
      setPlans(mapBoxOnePostpaidPlans(postpaidPlans as GetPostpaidPlansResponse));
    } catch (error) {
      DevLog.log(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchPlans().catch((e) => DevLog.log(e));
  }, []);

  return (
    <View
      style={{
        gap: 8,
      }}
    >
      <CustomText weight="600" style={{ fontSize: 16 }}>
        🎉 Black Friday Deals Alert! 🎉
      </CustomText>
      <CustomText>Start enjoying our Black Friday deals today! Upgrade your plan!</CustomText>
      {plans.map((plan) => (
        <CustomText key={plan.planId}>
          &bull; ${plan.plan_fee} for {plan.data}
        </CustomText>
      ))}
      <Button
        style={{ alignSelf: 'center', marginVertical: 20 }}
        onPress={() => {
          navigation.navigate('Plans');
        }}
      >
        Unlock Deal
      </Button>
      <CustomText>
        <CustomText weight="600">Prepaid users:</CustomText> Contact our Customer Service team to
        make the switch to monthly.
      </CustomText>
    </View>
  );
};

export default BeforeBlackFridayPromo;
