import { Image, SafeAreaView, ScrollView, View } from 'react-native';

import SIMCardWhiteImage from '../../assets/images/sim-card-white.png';
import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { isWebsite } from '../../utilities/functions';

import { SIMType } from '.';

type Props = {
  onNext(simType: SIMType): void;
};

export default function SimTypeView(props: Props) {
  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          padding: 20,
          backgroundColor: Colors.white,
          flex: 1,
          height: '100%',
        }}
      >
        <CustomText weight="600" style={{ fontSize: 24, textAlign: 'center', marginBottom: 10 }}>
          Activate with eSIM
        </CustomText>
        <CustomText style={{ fontSize: 14, textAlign: 'center', marginBottom: 32 }}>
          We&apos;re providing you with an eSIM by default. No need to wait for shipping—just scan
          it, and you&apos;re good to go!
        </CustomText>
        {/* <MenuItem
          title="Check eSIM compatibility"
          frontIcon={<EvilIcons name="question" size={16} color={Colors.ActionLinkColor} />}
          backIcon={<Entypo name="chevron-thin-right" size={16} color={Colors.greyColor} />}
        /> */}
        <Image
          source={SIMCardWhiteImage}
          style={{ width: 'auto', height: 200, marginTop: 55 }}
          resizeMode="contain"
        />
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={() => {
            props.onNext('ESIM');
          }}
          textStyle={{ fontSize: 14 }}
        >
          Continue With ESIM
        </Button>
        <Button
          style={{ marginTop: 16 }}
          color={Colors.backgroundGrey}
          textColor={Colors.inputTextPlaceholderColor}
          onPress={() => {
            props.onNext('PhysicalSIM');
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Continue With Physical SIM
        </Button>
      </View>
    </SafeAreaView>
  );
}
