import { Ionicons } from '@expo/vector-icons';
import Checkbox from 'expo-checkbox';
import * as Clipboard from 'expo-clipboard';
import { useState } from 'react';
import { Alert, Dimensions, ScrollView, Share, View } from 'react-native';
import { useSelector } from 'react-redux';

import { Colors } from '../../constants/Colors';
import { NON_ACTIVATED_CNUM } from '../../constants/constants';
import { RootState } from '../../store';
import { isWebsite } from '../../utilities/functions';
import Button from '../Button';
import CustomText from '../utils/CustomText';

const { height, width } = Dimensions.get('window');

const ReferAFriend = () => {
  const cnum = useSelector((state: RootState) => state.user.cnum);
  const _referralLink =
    !cnum || cnum === NON_ACTIVATED_CNUM
      ? 'Please Log in to get your referral code'
      : (isWebsite() && window.location.href.includes('account-area-testing')) || __DEV__
        ? `testhp2021.gophonebox.com/plans/?referral=${cnum}`
        : `gophonebox.com/plans/?referral=${cnum}`;
  const [referralLink, setReferralLink] = useState(_referralLink);
  const [agree, setAgree] = useState(false);
  const [share, setShare] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const onShare = async () => {
    try {
      await Share.share({
        message: referralLink,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      Alert.alert(error.message);
    }
  };

  return (
    <>
      <View
        style={{
          gap: 8,
          height: '100%',
        }}
      >
        <CustomText weight="600" style={{ textAlign: 'center' }}>
          Best friends, Best Perks!
        </CustomText>
        <View
          style={{
            gap: 8,
          }}
        >
          <CustomText>There are only 3 steps to enjoy your reward!</CustomText>
          <CustomText style={{ marginLeft: 8 }}>
            1. Share the link below to invite friends to PhoneBox.
          </CustomText>
          <CustomText style={{ marginLeft: 8 }}>
            2. Your friend activates a plan using the link.
          </CustomText>
          <CustomText style={{ marginLeft: 8 }}>
            3. Enjoy credits on both accounts in 30 days!
          </CustomText>
          {!share ? (
            <>
              <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                <Checkbox
                  value={agree}
                  onValueChange={setAgree}
                  color={agree ? Colors.canadaButtonColor : undefined}
                />
                <CustomText>
                  I agree to the{' '}
                  <CustomText
                    weight="500"
                    style={{ color: Colors.ActionLinkColor, textDecorationLine: 'underline' }}
                    onPress={() => {
                      setShowTerms(true);
                    }}
                  >
                    Terms & Conditions and Privacy Statement.
                  </CustomText>
                </CustomText>
              </View>
              <Button
                disabled={!agree}
                style={{ alignSelf: 'center', marginVertical: 20 }}
                onPress={() => {
                  if (!cnum || cnum === NON_ACTIVATED_CNUM) {
                    return isWebsite()
                      ? alert(referralLink)
                      : Alert.alert('Login Required', referralLink);
                  }
                  if (isWebsite()) {
                    setShare(true);
                  } else {
                    onShare();
                  }
                }}
              >
                Share
              </Button>
            </>
          ) : null}
          {agree && share ? (
            <>
              <CustomText
                weight="500"
                style={{
                  textAlign: 'center',
                  marginTop: 16,
                }}
              >
                Share this link with your friends and you will both get up to $30 credit.
              </CustomText>
              <View
                style={{
                  alignSelf: 'center',
                  backgroundColor: Colors.lightGrey,
                  borderRadius: 8,
                  paddingVertical: 8,
                  paddingHorizontal: 16,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 16,
                }}
              >
                <CustomText>{referralLink}</CustomText>
                <Ionicons
                  name="copy-outline"
                  size={24}
                  color={Colors.ActionLinkColor}
                  onPress={async () => {
                    await Clipboard.setStringAsync(referralLink);
                    setReferralLink('Link Copied!');
                    setTimeout(() => {
                      setReferralLink(_referralLink);
                    }, 2500);
                  }}
                />
              </View>
            </>
          ) : null}
        </View>
      </View>
      {showTerms ? (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 100,
            backgroundColor: Colors.overlay75,
            padding: 10,
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 200,
          }}
        >
          <ScrollView
            contentContainerStyle={{
              gap: 8,
              padding: 20,
            }}
            style={{
              width: width - 20,
              backgroundColor: Colors.white,
              borderRadius: 8,
              maxHeight: height - 470,
            }}
          >
            <CustomText weight="600" style={{ textAlign: 'center', marginTop: 24 }}>
              PhoneBox Friends Referral Reward Terms & Conditions
            </CustomText>
            <CustomText weight="600" style={{ color: Colors.canadaButtonColor }}>
              | THE PROGRAM
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              Friends Referral Reward is a PhoneBox program to reward new and existing customers who
              invite their friends and family to become a PhoneBox client by giving both accounts a
              credit. If eligibility is confirmed, the Referrer and Referee will receive a certain
              amount of credits on their bills. To earn the Friends Referral Reward, the Referee
              must activate a plan through the Referrer activation link.
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              The Referrer is a PhoneBox&apos;s existing customer inviting friends to start using
              our services. They must have a current active account and it must be in good standing.
              It is not allowed to refer a new line to the same account holder and request a
              referral credit. You must only send your link to friends and family who genuinely want
              to receive it.
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              The Referee is a new customer joining PhoneBox after being invited by an existing
              PhoneBox client. The Referee can be anyone with whom the Referrer has had direct and
              voluntary two-way communications and agreed upon signing up.
            </CustomText>
            <CustomText weight="600" style={{ color: Colors.canadaButtonColor }}>
              | TERMS AND CONDITIONS
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              To be eligible for the PhoneBox Friends Referral Reward, the Referrer must be an
              active customer on a PhoneBox plan, and the Referee must become a new PhoneBox
              customer using the activation link shared by the Referrer.
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              For the referral to be valid, the service starting dates must be different. If the
              Referrer activation date is on January 1st, then the Referee activation date must be
              exactly 1 month after the Referrer&apos;s activation date, which in this case would be
              February 1st, in order for the referral to be deemed eligible. If a new client is
              registered under a partner, which includes but is not limited to, the plan being
              offered, purchased, or provided by one of our partners, it will not be eligible for
              the Friends Referral Reward.
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              The amount of credit to be applied depends on the new activated plan:
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              - For Monthly Plans below $40 value, the referral is eligible for a $20 Referral
              Credit.
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              - For Monthly Plans above $40 value, the referral is eligible for a $30 Referral
              Credit.
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              - For Prepaid Plans excluding 7-day short-term plans, the referral is eligible for a
              $5 Referral Credit.
            </CustomText>
            <CustomText weight="600" style={{ color: Colors.canadaButtonColor }}>
              | ELIGIBILITY AND LIMITATIONS
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              All eligible referrals will be limited to 15 referrals per calendar year. A calendar
              year is defined as starting on the first day of the year and ending on the last day of
              the year, regardless of the date the account was activated. This means that referrals
              are counted for the entire year, and the referral count will reset on the first day of
              the new year.
            </CustomText>
            <CustomText weight="600" style={{ color: Colors.canadaButtonColor }}>
              | CREDIT REVOKE RULES
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              &bull; The service start dates must be different, and the Referee&apos;s activation
              date must be exactly 1 month after the Referrer&apos;s activation date for the
              referral to be valid.
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              &bull; Referees under partner registration will not be eligible. The partner
              registration indicates that the new client&apos;s plan was offered, purchased, or
              provided by one of our partners.
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              &bull; If any of the accounts are under terminated or suspended status anytime during
              the credit application process, the request will be declined. Referral credits are
              non-refundable and non-transferrable.
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              &bull; Referrals from the same account holder will not be eligible for the program.
            </CustomText>
            <CustomText style={{ textAlign: 'justify' }}>
              &bull; PhoneBox reserves the right to revoke any or all Friends Referral credits from
              the end customer if we determine that the program is being misused, manipulated, or
              leveraged on a large scale for activities that are not in line with acceptable use.
            </CustomText>
            <CustomText weight="500" style={{ textAlign: 'center', marginVertical: 18 }}>
              By Pressing the button, you agree to our Terms & Conditions and Privacy Statement.
            </CustomText>
            <Button style={{ alignSelf: 'center' }} onPress={() => setShowTerms(false)}>
              Yes, I Agree
            </Button>
          </ScrollView>
        </View>
      ) : null}
    </>
  );
};

export default ReferAFriend;
