import { Entypo, Feather } from '@expo/vector-icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Platform, SafeAreaView, ScrollView, View } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import { useDispatch } from 'react-redux';

import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT, MONTHS } from '../../constants/constants';
import { accountService } from '../../services/MyAccount/AccountService';
import { setIsLoading } from '../../store/app';
import { InvoiceInfo } from '../../types/models/InvoiceInfo';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';

const InvoicesScreen = () => {
  const date = moment();
  const dispatch = useDispatch();
  const [selectedMonth, setSelectedMonth] = useState(
    date.month() + (date.isSameOrAfter(moment(date).date(15)) ? 1 : 0)
  );
  const [month, setMonth] = useState<number>();
  const [year, setYear] = useState<number>();
  const [selectedYear, setSelectedYear] = useState(date.year());
  const [error, setError] = useState<string | null>(null);
  const [invoice, setInvoice] = useState<InvoiceInfo | null>(null);

  const years = Array.from({ length: date.year() - 2013 + 1 }, (_, index) => 2013 + index);

  const fetchInvoice = async () => {
    try {
      dispatch(setIsLoading(true));
      const response = await accountService.invoiceInfoByYearMonth(selectedMonth, selectedYear);
      if (response.data.InvoiceDetail.length === 0) {
        setInvoice(null);
        return setError('No Invoice Found');
      }
      setError(null);
      setMonth(selectedMonth);
      setYear(selectedYear);
      setInvoice(response.data);
    } catch (_error) {
      DevLog.log(_error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchInvoice();
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        height: '100%',
        backgroundColor: Colors.white,
        // padding: 20,
      }}
    >
      <ScrollView>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 8,
            marginHorizontal: 10,
          }}
        >
          <RNPickerSelect
            items={years.map((y) => ({ label: y.toString(), value: y }))}
            onValueChange={(itemValue) => setSelectedYear(itemValue)}
            value={selectedYear}
            placeholder={{}}
            Icon={() =>
              Platform.select({
                ios: (
                  <Entypo name="chevron-down" size={14} color={Colors.inputTextPlaceholderColor} />
                ),
              })
            }
            style={{
              viewContainer: {
                ...Platform.select({
                  ios: { alignItems: 'center', justifyContent: 'center' },
                  android: {
                    width: 105,
                  },
                }),
              },
              inputIOSContainer: {
                paddingRight: 20,
              },
              iconContainer: {
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          />
          <RNPickerSelect
            items={MONTHS.map((m, i) => ({
              label: m,
              value: i + 1,
            }))}
            onValueChange={(itemValue) => setSelectedMonth(itemValue)}
            value={selectedMonth}
            placeholder={{}}
            Icon={() =>
              Platform.select({
                ios: (
                  <Entypo name="chevron-down" size={14} color={Colors.inputTextPlaceholderColor} />
                ),
              })
            }
            style={{
              viewContainer: {
                ...Platform.select({
                  ios: { alignItems: 'center', justifyContent: 'center' },
                  android: {
                    width: 130,
                  },
                }),
              },
              inputIOSContainer: {
                paddingRight: 20,
              },
              iconContainer: {
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          />
          <Button
            style={{
              paddingVertical: 3,
              paddingHorizontal: 6,
              borderRadius: 4,
              width: isWebsite() ? 'auto' : undefined,
            }}
            textStyle={{
              fontSize: 14,
            }}
            onPress={fetchInvoice}
          >
            Search
          </Button>
        </View>
        {error ? (
          <CustomText
            weight="500"
            style={{
              color: Colors.errorRed,
              fontSize: 24,
              textAlign: 'center',
              marginTop: 100,
            }}
          >
            {error}
          </CustomText>
        ) : invoice ? (
          <View
            style={{
              marginTop: 20,
            }}
          >
            <CustomText
              weight="500"
              style={{
                fontSize: 12,
                backgroundColor: Colors.backgroundGrey,
                color: Colors.TextBlack,
                paddingVertical: 10,
                paddingHorizontal: 20,
              }}
            >
              {year}
            </CustomText>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: 16,
                marginTop: 16,
                marginHorizontal: 20,
              }}
            >
              <View
                style={{
                  backgroundColor: Colors.backgroundGrey,
                  padding: 12,
                  borderRadius: 50,
                }}
              >
                <Feather name="file-text" size={20} color={Colors.ActionLinkColor} />
              </View>
              <View style={{ flex: 1 }}>
                <CustomText weight="500" style={{ fontSize: 16 }}>
                  {MONTHS[month! - 1]} Invoice
                </CustomText>
                <CustomText weight="300" style={{ fontSize: 12 }}>
                  {moment(`${year}-${month}-15`).format(DATE_FORMAT)} Issued
                </CustomText>
              </View>
              <CustomText weight="500" style={{ fontSize: 14, color: Colors.canadaButtonColor }}>
                ${invoice.TotalAmount.toFixed(2)}
              </CustomText>
            </View>
            <View
              style={{
                backgroundColor: Colors.backgroundGrey,
                paddingVertical: 12,
                paddingHorizontal: 20,
                gap: 12,
                borderRadius: 16,
                marginTop: 16,
                marginHorizontal: 20,
              }}
            >
              {invoice.InvoiceDetail.map((id, i) => (
                <View
                  key={i}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <CustomText
                    weight="500"
                    style={{ fontSize: 12, textTransform: 'capitalize', color: Colors.greyColor }}
                  >
                    {id.ItemName}
                  </CustomText>
                  <CustomText weight="500" style={{ fontSize: 14, color: Colors.TextBlack }}>
                    ${id.ItemAmount.toFixed(2)}
                  </CustomText>
                </View>
              ))}
              <View
                style={
                  isWebsite()
                    ? {
                        maxWidth: 400,
                        marginHorizontal: 'auto',
                        width: '100%',
                      }
                    : null
                }
              >
                {/* <Button
                  style={{
                    paddingVertical: 8,
                  }}
                  textStyle={{
                    fontWeight: '600',
                    fontSize: 14,
                  }}
                >
                  Download Full PDF Invoice
                </Button> */}
              </View>
            </View>
          </View>
        ) : null}
      </ScrollView>
    </SafeAreaView>
  );
};

export default InvoicesScreen;
