import axios from 'axios';
import { Platform } from 'react-native';

// TODO: Figure out a way to type this from the dotenv library
// import { BASE_API_URL, IDENTITY_API_URL } from '@env';

let BASE_API_URL = 'https://testmyaccount.azurewebsites.net/api/'; // 'https://phoneboxapi.azurewebsites.net';
if ((Platform.OS === 'web' && /wonderful-coast-/.test(window.location.href)) || __DEV__) {
  BASE_API_URL = 'https://testmyaccount.azurewebsites.net/api/';
}

// const BASE_API_URL = "http://localhost:8086/api/"

// Update URL to Live one before making it live
let IDENTITY_API_URL = 'https://phonebox-sso.gophonebox.com/api/';
if ((Platform.OS === 'web' && /wonderful-coast-/.test(window.location.href)) || __DEV__) {
  IDENTITY_API_URL = 'https://test-phonebox-sso.gophonebox.com/api/';
}

export const instance = axios.create({ baseURL: BASE_API_URL });
export const identityInstance = axios.create({ baseURL: IDENTITY_API_URL });
