import * as WebBrowser from 'expo-web-browser';
import { View, Image, Platform, Linking } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import IllustrationImage from '../../assets/illustrations/Blue9.png';
import { AppGap } from '../../components/AppGap';
import AppGradientContainer from '../../components/AppGradientContainer';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import AuthBackground from '../../components/utils/AuthBackgroud';
import CustomText from '../../components/utils/CustomText';
import ScrollViewWithSafeAreaContainer from '../../components/utils/ScrollViewWithSafeAreaContainer';
import { Colors } from '../../constants/Colors';
import CommonStyles from '../../constants/CommonStyles';
import { RootStackScreenProps } from '../../types';
import { isWebsite } from '../../utilities/functions';

WebBrowser.maybeCompleteAuthSession();

export default function LandingScreen({ navigation }: RootStackScreenProps<'Landing'>) {
  const onButtonPress = (type: 'CANADA' | 'USA' | 'GUEST') => {
    switch (type) {
      case 'USA':
        Linking.openURL('https://us.myaccount.gophonebox.com/');
        break;
      case 'GUEST':
        navigation.navigate('Root');
        break;
      default:
        navigation.navigate('LogIn');
        break;
    }
  };

  return (
    <ScrollViewWithSafeAreaContainer>
      <AppGradientContainer
        colorLeft={Colors.gradientDarkBlue}
        colorRight={Colors.gradientLightBlue}
        direction="LEFT-RIGHT"
      >
        <SafeAreaView style={{ display: 'flex', height: '100%', position: 'relative' }}>
          <AuthBackground />
          <View
            style={{
              marginTop: Platform.select({ web: 140, android: 150, default: 100 }),
              marginLeft: Platform.select({ web: 0, android: 110, default: 110 }),
              zIndex: 100,
              position: 'relative',
              display: isWebsite() ? 'none' : undefined,
            }}
          >
            <Image
              source={IllustrationImage}
              style={[
                {
                  width: 200,
                  height: 300,
                },
                isWebsite() ? { margin: 'auto' } : null,
              ]}
              resizeMode="contain"
            />
          </View>

          <BottomWhiteSheet
            style={{ paddingLeft: 30, paddingRight: 30, ...CommonStyles.webBottomSheet }}
          >
            {/*  // ? May be add Separator (AppGap) instead of MarginBottom */}
            <CustomText
              weight="500"
              style={{ fontSize: 24, marginTop: 50, textAlign: 'center', zIndex: 10 }}
            >
              Welcome to PhoneBox!
            </CustomText>
            <HorizontalLine thinkness={1} style={{ marginVertical: 24 }} />
            <Button
              size={'FULL'}
              color={Colors.canadaButtonColor}
              style={{ marginBottom: 10 }}
              onPress={() => onButtonPress('CANADA')}
            >
              Canadian Account
            </Button>
            <Button
              size={'FULL'}
              color={Colors.usaButtonColor}
              onPress={() => onButtonPress('USA')}
            >
              USA Account
            </Button>
            <Button
              size={'FULL'}
              color={Colors.noColor}
              textColor={Colors.greyColor}
              style={{
                padding: 5,
                marginTop: 20,
                width: 'auto',
              }}
              onPress={() => onButtonPress('GUEST')}
            >
              Continue as a guest
            </Button>
            <AppGap size={30} direction="vertical" />
          </BottomWhiteSheet>
        </SafeAreaView>
      </AppGradientContainer>
    </ScrollViewWithSafeAreaContainer>
  );
}
