import { StyleSheet, Text, SafeAreaView, ActivityIndicator } from 'react-native';

import { AppGap } from '../components/AppGap';
import AppGradientContainer from '../components/AppGradientContainer';
import { AppLogoSvgIcon } from '../components/AppLogoSvgIcon';
import { Colors } from '../constants/Colors';

export default function LoadingScreen() {
  return (
    <>
      <AppGradientContainer>
        <SafeAreaView style={styles.loadingWrapper}>
          <AppLogoSvgIcon />
          <AppGap direction="vertical" size={20} />
          <ActivityIndicator size="large" color={Colors.white} />
          <Text style={styles.loadingText}>
            Hang tight.. We&apos;re getting everything ready for you.
          </Text>
        </SafeAreaView>
      </AppGradientContainer>
    </>
  );
}

const styles = StyleSheet.create({
  loadingText: {
    color: Colors.white,
    fontFamily: 'Poppins-Regular',
    marginTop: 20,
    textAlign: 'center',
  },
  loadingWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
});
