import moment from 'moment';
import { View } from 'react-native';

import CompletedTimeline from './CompletedTimeline';
import InBetweenTimeline from './InBetweenTimeline';
import InQueueTimeline from './InQueueTimeline';
import ReadyTimeline from './ReadyTimeline';
import SentInTransitTimeline from './SentInTransitTimeline';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import Badge from '../../components/UI/Badge';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { BADGES_COLOR, DATE_FORMAT } from '../../constants/constants';
import { CustomerShippingOrder } from '../../types/models/models';
import { ShippingStatus } from '../../types/types';

const InitialView = ({
  selectingShippingOrder,
  onMarkedReceived,
  onUpdateAddress,
}: {
  selectingShippingOrder: CustomerShippingOrder;
  onMarkedReceived(): void;
  onUpdateAddress(): void;
}) => {
  // While using separate timeline views for each status isn't ideal, we have opted for this approach due to time constraints.
  const timeline: Record<ShippingStatus, React.JSX.Element> = {
    queued: <InQueueTimeline date={new Date(selectingShippingOrder?.OrderDate)} />,
    'sent/in-transit': <SentInTransitTimeline date={new Date(selectingShippingOrder?.OrderDate)} />,
    ready: <ReadyTimeline date={new Date(selectingShippingOrder?.OrderDate)} />,
    received: <CompletedTimeline date={new Date(selectingShippingOrder?.OrderDate)} />,
    incomplete: (
      <InBetweenTimeline title={'In Complete'} date={new Date(selectingShippingOrder.OrderDate)} />
    ),
    'invalid address': (
      <InBetweenTimeline
        title={'Invalid Address'}
        date={new Date(selectingShippingOrder.OrderDate)}
      />
    ),
    returned: (
      <InBetweenTimeline title={'Returned'} date={new Date(selectingShippingOrder.OrderDate)} />
    ),
  };

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 12,
        }}
      >
        <CustomText weight="500" style={{ fontSize: 20 }}>
          Canadian SIM Card
        </CustomText>
        {selectingShippingOrder ? (
          <Badge
            title={selectingShippingOrder?.StatusDescription}
            status={BADGES_COLOR[selectingShippingOrder.StatusDescription]}
          />
        ) : null}
      </View>
      <CustomText weight="500" style={{ color: Colors.inputTextPlaceholderColor, marginBottom: 4 }}>
        Order Date:{' '}
        <CustomText style={{ color: Colors.TextBlack }}>
          {moment(selectingShippingOrder?.OrderDate).format(DATE_FORMAT)}
        </CustomText>
      </CustomText>
      <CustomText weight="500" style={{ color: Colors.inputTextPlaceholderColor }}>
        Shipping Address:{' '}
        <CustomText style={{ color: Colors.TextBlack }}>
          {selectingShippingOrder?.ShippingAddress.StreetAddress}
        </CustomText>
      </CustomText>
      <HorizontalLine thinkness={1} style={{ marginVertical: 16 }} />
      {timeline[selectingShippingOrder?.StatusDescription ?? 'queued']}
      {selectingShippingOrder?.StatusDescription === 'sent/in-transit' ? (
        <View
          style={{
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          }}
        >
          <Button
            style={{ marginTop: 32 }}
            onPress={() => {
              onMarkedReceived();
            }}
            textStyle={{ fontSize: 14, fontWeight: '600' }}
          >
            Mark as Received
          </Button>
        </View>
      ) : selectingShippingOrder?.StatusDescription &&
        ['queued', 'incomplete', 'invalid address'].includes(
          selectingShippingOrder?.StatusDescription
        ) ? (
        <View
          style={{
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          }}
        >
          <Button
            style={{ marginTop: 32 }}
            onPress={() => {
              onUpdateAddress();
            }}
            textStyle={{ fontSize: 14, fontWeight: '600' }}
          >
            Update Address
          </Button>
        </View>
      ) : null}
    </>
  );
};

export default InitialView;
