import { useEffect, useState } from 'react';
import { Image, Platform, Pressable, SafeAreaView, View, KeyboardAvoidingView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import IllustrationImage from '../../assets/illustrations/Blue30.png';
import { AppGap } from '../../components/AppGap';
import AppGradientContainer from '../../components/AppGradientContainer';
import AppleGoogleSSO from '../../components/AppleGoogleSSO';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import OTPInput from '../../components/OTPInput';
import TextInput from '../../components/TextInput';
import AuthBackground from '../../components/utils/AuthBackgroud';
import CustomText from '../../components/utils/CustomText';
import ScrollViewWithSafeAreaContainer from '../../components/utils/ScrollViewWithSafeAreaContainer';
import { Colors } from '../../constants/Colors';
import CommonStyles from '../../constants/CommonStyles';
import { signUpService } from '../../services/SSO/SignUpService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import { RootStackScreenProps } from '../../types';
import { DataErrorType } from '../../types/enums/DataErrorType';
import { DevMessage } from '../../types/enums/DevMessage';
import { StepType } from '../../types/enums/StepType';
import { SystemCodes } from '../../types/enums/SystemCodes';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';
import { validate } from '../../utilities/utilities';

type ValidationError = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export default function RegisterScreen({ navigation, route }: RootStackScreenProps<'Join'>) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState<ValidationError>({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
  });
  const [step, setStep] = useState<StepType>(StepType.First);
  const [_, setUserId] = useState('');
  const [otp, setOtp] = useState<string>('');
  const [otpShowError, setOtpShowError] = useState<string | null>(null);

  useEffect(() => {
    setOtpShowError(null);
    if (otp.length === 6) {
      onPressConfirm(otp);
    }
  }, [otp]);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state: RootState) => state.app);

  useEffect(() => {
    // this only happens in redirect flow from login page
    if (route.params?.email && route.params?.step) {
      setStep(route.params.step);
      setEmail(route.params.email);
    }
  }, []);

  const validateOnPressCreateAccount = async () => {
    const _validationError: ValidationError = {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
    };
    let isError = false;
    if (firstName === '') {
      _validationError.firstName = 'First Name required';
      isError = true;
    }
    if (lastName === '') {
      _validationError.lastName = 'Last name required';
      isError = true;
    }
    if (!validate('email', email)) {
      _validationError.email = 'Please enter a valid email address.';
      isError = true;
    } else {
      const errorExists = await validateEmail();
      if (errorExists) {
        _validationError.email = errorExists;
        isError = true;
      }
    }
    if (!validate('password', password)) {
      _validationError.password =
        'Password must contain minimum 8 charcters, at least one number, special character and capital letter';
      isError = true;
    }
    setValidationErrors(_validationError);
    if (isError) {
      dispatch(setIsLoading(false));
      return;
    }
    onPressCreateAnAccount();
  };

  const validateEmail = async () => {
    try {
      dispatch(setIsLoading(true));
      const validateEmailResponse = await signUpService.validateEmail({
        data: {
          systemCode: SystemCodes.CA_SELFSERVE,
          email: email,
        },
      });
      if (validateEmailResponse.data.devMessage === DevMessage.AccountAlreadyExists) {
        return 'Email already exists, Please sign-in.';
      }
      if (
        validateEmailResponse.data.devMessage ===
        DevMessage.AccountAlreadyExistsAndActivationPending
      ) {
        const isOkay = confirm(
          'Email already exists but account is not verified, Please Okay to Verify account.'
        );
        DevLog.log('Test:', isOkay, 'User want to Verify Account, Move to Next Step');
        if (isOkay) {
          setStep(StepType.Second);
          onPressResendNow();
        }
        return 'Email already exists but account is not verified, Please Verify';
      }
      if (validateEmailResponse.data.devMessage === DevMessage.NoRecordFound) {
        return '';
      }
    } catch (error) {
      return `${error}`;
    }
  };

  const onPressCreateAnAccount = () => {
    dispatch(setIsLoading(true));
    const signUpAsync = async () => {
      const signUpResponse = await signUpService.signUp({
        data: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (!signUpResponse || !signUpResponse?.data?.success) {
        DevLog.error('Something went wrong in signUp(): ', signUpResponse);
        if (
          signUpResponse?.data?.devMessage &&
          signUpResponse?.data?.devMessage === DevMessage.AccountExistsAndNotActivated
        ) {
          // setPasswordErrors(["Account has not been verified. Please click here to proceed to account verification."])
          // skip to next step if they already exist.
          // onPressResendNow();
          setStep(StepType.Second);
        }
        return;
      }

      if (signUpResponse && signUpResponse.data && signUpResponse.data.success) {
        setStep(StepType.Second);
        setUserId(signUpResponse.data.data.userId);
      }
    };

    signUpAsync()
      .catch((err) => {
        if (err && err.response && err.response.data && err.response.data.errors) {
          if (err.response.data.errors[DataErrorType.Password]) {
            setValidationErrors({
              ...validationErrors,
              password: err.response.data.errors[DataErrorType.Password],
            });
          }
        }
        DevLog.error(err);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  const onPressResendNow = () => {
    const resendActivationCodeAsync = async () => {
      const resendActivationCodeResponse = await signUpService.resendActivationCode({
        data: {
          email: email,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (!resendActivationCodeResponse?.data?.success) {
        DevLog.error(
          'Something went wrong with resendActivationCodeAsync():',
          resendActivationCodeResponse
        );
        return;
      }
    };

    resendActivationCodeAsync().catch((err) => DevLog.error(err));
  };

  const onPressConfirm = (otp: string) => {
    if (!otp) {
      return;
    }

    const activateUserByEmailIdAsync = async () => {
      dispatch(setIsLoading(true));

      const activateUserResponse = await signUpService.activateUserByEmailId({
        data: {
          email: email,
          code: otp,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (!activateUserResponse?.data?.success) {
        DevLog.error('Something went wrong with activateUserByEmailId(): ', activateUserResponse);
        if (
          activateUserResponse?.data?.devMessage === DevMessage.InvalidOTP ||
          activateUserResponse?.data?.devMessage === DevMessage.InCorrectOTP
        ) {
          setOtpShowError('The verification code is incorrect.');
        } else if (/expired/.test(activateUserResponse?.data?.error?.message)) {
          setOtpShowError('Activation Code has expired.');
        }
        return;
      }

      if (activateUserResponse && activateUserResponse.data.success) {
        navigation.navigate('JoinSuccess', { email, password });
        setStep(StepType.First);
      }
    };

    activateUserByEmailIdAsync()
      .catch((err) => {
        setOtpShowError('Unexpected error occured!');
        DevLog.error(err);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  return (
    <ScrollViewWithSafeAreaContainer minHeight={812}>
      {step === StepType.First ? (
        <AppGradientContainer
          colorLeft={Colors.gradientDarkBlue}
          colorRight={Colors.gradientLightBlue}
          direction="LEFT-RIGHT"
        >
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            keyboardVerticalOffset={-150}
            style={{ flex: 1 }}
          >
            <SafeAreaView style={{ flex: 1 }}>
              <AuthBackground />
              <BottomWhiteSheet
                style={{ paddingLeft: 30, paddingRight: 30, ...CommonStyles.webBottomSheet }}
              >
                {/* <View
                  style={{
                    position: 'absolute',
                    top: Platform.select({ android: -190, default: -190, ios: -80 }),
                    zIndex: 8,
                  }}
                >
                  <Image
                    source={require('../../assets/illustrations/blue21.png')}
                    style={{
                      width: 150,
                      height: Platform.select({ android: 300, default: 300, ios: 120 }),
                      display: isWebsite() ? 'none' : undefined,
                    }}
                    resizeMode="contain"
                  />
                </View> */}
                <View
                  style={[
                    { width: '100%' },
                    isWebsite()
                      ? {
                          maxWidth: 400,
                          marginHorizontal: 'auto',
                          width: '100%',
                        }
                      : null,
                  ]}
                >
                  <CustomText
                    weight="500"
                    style={{ fontSize: 24, fontWeight: '500', marginTop: 50, marginBottom: 10 }}
                  >
                    Create Your Account
                  </CustomText>
                  <View
                    style={{
                      marginBottom: 20,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <CustomText>Already a user? </CustomText>
                    <Pressable onPress={() => navigation.replace('LogIn')}>
                      <CustomText
                        weight="600"
                        style={{
                          color: Colors.canadaButtonColor,
                          fontWeight: '600',
                          textDecorationLine: 'underline',
                        }}
                      >
                        Sign in
                      </CustomText>
                    </Pressable>
                  </View>
                  <HorizontalLine style={{ marginBottom: 20 }} />
                  <TextInput
                    placeholder="First Name"
                    value={firstName}
                    onChangeText={(firstName: string) => {
                      setFirstName(firstName);
                      if (firstName) {
                        setValidationErrors({ ...validationErrors, firstName: '' });
                      }
                    }}
                    error={{ error: validationErrors.firstName }}
                  />
                  <TextInput
                    placeholder="Last Name"
                    value={lastName}
                    onChangeText={(lastName: string) => {
                      setLastName(lastName);
                      if (lastName) {
                        setValidationErrors({ ...validationErrors, lastName: '' });
                      }
                    }}
                    style={{ marginTop: 15 }}
                    error={{ error: validationErrors.lastName }}
                  />
                  <TextInput
                    placeholder="Enter your Email"
                    keyboardType="email-address"
                    value={email}
                    onChangeText={(email: string) => {
                      setEmail(email);
                      if (email) {
                        setValidationErrors({ ...validationErrors, email: '' });
                      }
                    }}
                    style={{ marginTop: 15 }}
                    error={{ error: validationErrors.email }}
                  />
                  <TextInput
                    placeholder="Password"
                    value={password}
                    eyeTopMargin={29}
                    onChangeText={(password: string) => {
                      setPassword(password);
                      if (password) {
                        setValidationErrors({ ...validationErrors, password: '' });
                      }
                    }}
                    style={{ marginTop: 15 }}
                    secureTextEntry={true}
                    error={{ error: validationErrors.password }}
                  />
                  <Button
                    style={{ marginTop: 25 }}
                    onPress={validateOnPressCreateAccount}
                    loadingStatus={isLoading}
                    disabled={isLoading}
                    textStyle={{ fontSize: 14 }}
                  >
                    Create Account
                  </Button>
                  <CustomText
                    style={{
                      textAlign: 'center',
                      color: Colors.inputTextPlaceholderColor,
                      marginTop: 10,
                      fontSize: 14,
                    }}
                  >
                    or continue with
                  </CustomText>
                  <AppleGoogleSSO
                    setIsLoadingAPI={(status: boolean) => {
                      dispatch(setIsLoading(status));
                    }}
                    propsKey={'sign-up'}
                  />
                  <AppGap direction="vertical" size={20} />
                </View>
              </BottomWhiteSheet>
            </SafeAreaView>
          </KeyboardAvoidingView>
        </AppGradientContainer>
      ) : (
        <AppGradientContainer
          colorLeft={Colors.gradientDarkBlue}
          colorRight={Colors.gradientLightBlue}
          direction="LEFT-RIGHT"
        >
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            keyboardVerticalOffset={-150}
            style={{ flex: 1 }}
          >
            <SafeAreaView style={{ display: 'flex', height: '100%' }}>
              <AuthBackground />
              <BottomWhiteSheet
                style={{ paddingLeft: 30, paddingRight: 30, ...CommonStyles.webBottomSheet }}
              >
                <View
                  style={{
                    position: 'absolute',
                    bottom: Platform.select({ android: 380, default: 360 }),
                    zIndex: 8,
                  }}
                >
                  <Image
                    source={IllustrationImage}
                    style={{
                      width: 250,
                      height: 350,
                      display: isWebsite() ? 'none' : undefined,
                    }}
                    resizeMode="contain"
                  />
                </View>
                <View
                  style={[
                    { width: '100%' },
                    isWebsite()
                      ? {
                          maxWidth: 400,
                          marginHorizontal: 'auto',
                          width: '100%',
                        }
                      : null,
                  ]}
                >
                  <CustomText
                    weight="500"
                    style={{ fontSize: 24, fontWeight: '500', marginTop: 50, marginBottom: 10 }}
                  >
                    Verification Code
                  </CustomText>
                  <View style={{ marginBottom: 20 }}>
                    <CustomText style={{ fontSize: 12 }}>
                      A verification email has been sent to you
                    </CustomText>
                  </View>
                  <HorizontalLine style={{ marginBottom: 20 }} />
                  <OTPInput onChangeText={setOtp} isError={otpShowError !== null} />
                  <CustomText
                    style={{
                      marginVertical: 15,
                      textAlign: isWebsite() ? 'center' : undefined,
                    }}
                  >
                    Didn&apos;t receive the code?{' '}
                    <CustomText
                      onPress={onPressResendNow}
                      weight="600"
                      style={{
                        color: Colors.canadaButtonColor,
                        fontWeight: '600',
                        textDecorationLine: 'underline',
                      }}
                    >
                      Resend Code
                    </CustomText>
                  </CustomText>
                  {otpShowError ? (
                    <CustomText
                      weight="500"
                      style={{ fontSize: 14, color: Colors.errorRed, textAlign: 'center' }}
                    >
                      {otpShowError}
                    </CustomText>
                  ) : null}
                  <Button
                    style={{ marginTop: 25 }}
                    loadingStatus={isLoading}
                    disabled={isLoading}
                    onPress={() => {
                      if (otp.length !== 6) {
                        setOtpShowError('The verification code is incorrect.');
                        return;
                      }
                      onPressConfirm(otp);
                    }}
                    textStyle={{ fontSize: 14 }}
                  >
                    Continue
                  </Button>
                  <Button
                    size={'FULL'}
                    color={Colors.noColor}
                    textColor={Colors.greyColor}
                    onPress={() => {
                      setStep(StepType.First);
                    }}
                    style={{
                      padding: 5,
                      marginTop: 10,
                      width: 'auto',
                    }}
                    textStyle={{ fontSize: 14 }}
                  >
                    Cancel
                  </Button>
                  <AppGap direction="vertical" size={20} />
                </View>
              </BottomWhiteSheet>
            </SafeAreaView>
          </KeyboardAvoidingView>
        </AppGradientContainer>
      )}
    </ScrollViewWithSafeAreaContainer>
  );
}
