import { useEffect, useState } from 'react';
import { SafeAreaView, StatusBar } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import InitialView from './InitialView';
import UpdateInformationView from './UpdateInformationView';
import { Colors } from '../../constants/Colors';
import { accountService } from '../../services/MyAccount/AccountService';
import { RootState } from '../../store';
import {
  setCustomerServiceRequests,
  setSelectedCustomerServiceRequests,
} from '../../store/selfServeCA';
import { RootStackScreenProps } from '../../types';
import { UnifiedCustomerServiceRequests } from '../../types/responses/MyAccount/GetCustomerServiceRequestsResponse';

enum FlowStep {
  Initial = 'initial',
  ChangeInfo = 'change-info',
}

const ServiceRequestTrackingScreen = ({
  navigation,
  route,
}: RootStackScreenProps<'ServiceRequestTracking'>) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState<FlowStep>(FlowStep.Initial);

  const userEmail = useSelector((state: RootState) => state.user.email);
  const { selectingServiceRequests } = useSelector((state: RootState) => ({
    selectingServiceRequests: state.selfServeCA.selectingServiceRequests,
  }));

  useEffect(() => {
    const fetchOrders = async () => {
      const response = await accountService.getCustomerServiceRequests(userEmail);
      dispatch(setCustomerServiceRequests(response.data));

      const findOrder = response.data.ActivationRequests.find(
        (r) => r.ActivationRequestId == route.params.tracking
      );
      if (!findOrder) {
        navigation.navigate('Root');
        return;
      }
      const _findOrder = {
        ...findOrder,
        requestType: route.params.type,
      } as UnifiedCustomerServiceRequests;
      dispatch(setSelectedCustomerServiceRequests(_findOrder));
    };
    if (!selectingServiceRequests || selectingServiceRequests.requestId !== route.params.tracking) {
      fetchOrders();
    }
  }, [route.params.tracking]);

  return (
    <SafeAreaView
      style={[
        {
          flex: 1,
          height: '100%',
          marginTop: StatusBar.currentHeight,
          backgroundColor: Colors.white,
          position: 'relative',
        },
        step === FlowStep.Initial
          ? { paddingVertical: 24, paddingHorizontal: 20, paddingBottom: 150 }
          : {},
      ]}
    >
      {selectingServiceRequests ? (
        step === FlowStep.Initial ? (
          <InitialView
            selectingServiceRequests={selectingServiceRequests!}
            onUpdateInfo={() => {
              setStep(FlowStep.ChangeInfo);
            }}
          />
        ) : (
          <UpdateInformationView
            onPress={() => {
              navigation.pop();
            }}
          />
        )
      ) : null}
    </SafeAreaView>
  );
};

export default ServiceRequestTrackingScreen;
