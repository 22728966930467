import {
  AntDesign,
  Entypo,
  Feather,
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
} from '@expo/vector-icons';
import { useState } from 'react';
import { Alert, Linking, Platform, SafeAreaView, ScrollView, StatusBar, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import BlogImage from '../../assets/icons/action/blog.png';
import ChatImage from '../../assets/icons/action/chat.png';
import { AppDrawer } from '../../components/AppDrawer';
import { AppGap } from '../../components/AppGap';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import QuickAction, { Action } from '../../components/QuickAction/QuickAction';
import PageHeader from '../../components/UI/Header/PageHeader';
import MenuItem from '../../components/UI/ListItem/MenuItem';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { tokenService } from '../../services/TokenService';
import { RootState } from '../../store';
import { resetUser } from '../../store/user';
import { RootStackScreenProps } from '../../types';
import DevLog from '../../utilities/debug-error';
import { crispChatAction, isWebsite } from '../../utilities/functions';

// import HelpCenterImage from '../../assets/icons/action/help-center.png';
// import TicketImage from '../../assets/icons/action/ticket.png';

const manageTabActions: Action[] = [
  {
    image: ChatImage,
    title: 'Chat with Agent',
    tag: 'CHAT_WITH_AGENT',
  },
  {
    image: BlogImage,
    title: 'Blog',
    tag: 'BLOG_ACTION',
  },
  // {
  //   image: HelpCenterImage,
  //   title: 'Help Center',
  //   tag: 'HELP_CENTER',
  // },
  // {
  //   image: TicketImage,
  //   title: 'Support Ticket',
  //   tag: 'SUPPORT_TICKET',
  // },
];

export default function SettingsScreen({ navigation }: RootStackScreenProps<'Settings'>) {
  const [logoutModelShown, setLogoutModelShown] = useState(false);

  const dispatch = useDispatch();

  const { loggedIn, id, email, firstName, lastName, accounts } = useSelector(
    (state: RootState) => state.user
  );
  const accountProfileCA = useSelector((state: RootState) => state.selfServeCA.accountProfileCA);
  const accountInfo = useSelector((state: RootState) => state.selfServeCA.accountInfoCA);

  const backIcon = <Entypo name="chevron-thin-right" size={16} color={Colors.greyColor} />;
  const dummyIcon = (
    <AntDesign name="exclamationcircleo" size={16} color={Colors.ActionLinkColor} />
  );
  const backIconDisabled = <Entypo name="chevron-thin-right" size={16} color={Colors.white} />;
  const dummyIconDisabled = <AntDesign name="exclamationcircleo" size={16} color={Colors.white} />;
  const logOutIcon = <Feather name="log-out" size={16} color={Colors.ActionLinkColor} />;
  const termsAndConditionIcon = (
    <Ionicons name="document-text-outline" size={16} color={Colors.ActionLinkColor} />
  );

  const planManagementDiabled = accounts.length === 0 || accountInfo.Prepaid;

  return (
    <>
      <SafeAreaView
        style={{ flex: 1, paddingTop: StatusBar.currentHeight, backgroundColor: Colors.white }}
      >
        <PageHeader />
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{
            paddingTop: Platform.select({ ios: 60, default: 80 }),
            paddingBottom: Platform.select({ ios: 40, default: 30 }),
            backgroundColor: Colors.white,
            paddingHorizontal: 20,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              // justifyContent: 'space-evenly',
              gap: 16,
              width: '100%',
              marginTop: 16,
            }}
          >
            {manageTabActions.map((action, index) => {
              return (
                <QuickAction
                  action={action}
                  key={index}
                  onActionSelected={(tag) => {
                    switch (tag) {
                      case 'HELP_CENTER':
                        break;
                      case 'CHAT_WITH_AGENT':
                        crispChatAction(
                          loggedIn,
                          id,
                          email,
                          firstName,
                          lastName,
                          accountProfileCA.PhoneNumber
                        );
                        break;
                      case 'SUPPORT_TICKET':
                        break;
                      case 'BLOG_ACTION':
                        Linking.openURL('https://blog.gophonebox.com/');
                        break;
                    }
                    DevLog.log(tag);
                  }}
                />
              );
            })}
          </View>
          <View>
            <CustomText
              weight="600"
              style={{ fontSize: 14, color: Colors.greyColor, marginBottom: 8, marginTop: 32 }}
            >
              Account
            </CustomText>
            <View
              style={{
                padding: 8,
                gap: 4,
                backgroundColor: Colors.backgroundGrey,
                borderRadius: 16,
              }}
            >
              <MenuItem
                frontIcon={
                  <FontAwesome5 name="user-circle" size={16} color={Colors.ActionLinkColor} />
                }
                title="My Account"
                backIcon={backIcon}
                onPress={() => navigation.navigate('Account')}
              />
              <MenuItem
                frontIcon={<Entypo name="mobile" size={16} color={Colors.ActionLinkColor} />}
                title="My Orders"
                backIcon={backIcon}
                onPress={() => navigation.navigate('MyOrders')}
              />
              <MenuItem
                frontIcon={
                  <MaterialCommunityIcons name="target" size={16} color={Colors.ActionLinkColor} />
                }
                title="Activity Tracking"
                backIcon={backIcon}
                onPress={() => navigation.navigate('StatusTracking')}
              />
            </View>
          </View>

          <View>
            <CustomText
              weight="600"
              style={{ fontSize: 14, color: Colors.greyColor, marginBottom: 8, marginTop: 32 }}
            >
              Plan Management
            </CustomText>
            <View
              style={{
                padding: 8,
                gap: 4,
                backgroundColor: Colors.backgroundGrey,
                borderRadius: 16,
              }}
            >
              <MenuItem
                disabled={planManagementDiabled}
                frontIcon={planManagementDiabled ? dummyIconDisabled : dummyIcon}
                backIcon={planManagementDiabled ? backIconDisabled : backIcon}
                title="Change Plan"
                onPress={() => {
                  if (planManagementDiabled) {
                    Platform.select({
                      web: alert('No plan is currently active.'),
                      default: Alert.alert('No plan is currently active.'),
                    });
                  } else {
                    navigation.navigate('ChangePlan');
                  }
                }}
              />
              <MenuItem
                disabled={planManagementDiabled}
                frontIcon={planManagementDiabled ? dummyIconDisabled : dummyIcon}
                backIcon={planManagementDiabled ? backIconDisabled : backIcon}
                title="Vacation Hold"
                onPress={() => {
                  if (planManagementDiabled) {
                    Platform.select({
                      web: alert('No plan is currently active.'),
                      default: Alert.alert('No plan is currently active.'),
                    });
                  } else {
                    navigation.navigate('VacationHold');
                  }
                }}
              />
              <MenuItem
                disabled={planManagementDiabled}
                frontIcon={planManagementDiabled ? dummyIconDisabled : dummyIcon}
                backIcon={planManagementDiabled ? backIconDisabled : backIcon}
                title="SIM Lost/Stolen Hold"
                onPress={() => {
                  if (planManagementDiabled) {
                    Platform.select({
                      web: alert('No plan is currently active.'),
                      default: Alert.alert('No plan is currently active.'),
                    });
                  } else {
                    navigation.navigate('LostOrStolen');
                  }
                }}
              />
              <MenuItem
                disabled={planManagementDiabled}
                frontIcon={planManagementDiabled ? dummyIconDisabled : dummyIcon}
                backIcon={planManagementDiabled ? backIconDisabled : backIcon}
                title="Porting"
                onPress={() => {
                  if (planManagementDiabled) {
                    Platform.select({
                      web: alert('No plan is currently active.'),
                      default: Alert.alert('No plan is currently active.'),
                    });
                  } else {
                    navigation.navigate('PortOption');
                  }
                }}
              />
              <MenuItem
                disabled={planManagementDiabled}
                frontIcon={planManagementDiabled ? dummyIconDisabled : dummyIcon}
                backIcon={planManagementDiabled ? backIconDisabled : backIcon}
                title="Terminate Plan"
                onPress={() => {
                  if (planManagementDiabled) {
                    Platform.select({
                      web: alert('No plan is currently active.'),
                      default: Alert.alert('No plan is currently active.'),
                    });
                  } else {
                    navigation.navigate('TerminatePlan');
                  }
                }}
              />
            </View>
          </View>
          <View>
            <CustomText
              weight="600"
              style={{ fontSize: 14, color: Colors.greyColor, marginBottom: 8, marginTop: 32 }}
            >
              Action
            </CustomText>
            <View
              style={{
                padding: 8,
                gap: 4,
                backgroundColor: Colors.backgroundGrey,
                borderRadius: 16,
              }}
            >
              <MenuItem
                frontIcon={termsAndConditionIcon}
                title="Term & Condition"
                backIcon={backIcon}
                onPress={() => Linking.openURL('https://gophonebox.com/terms-of-service/')}
              />
              <MenuItem
                frontIcon={logOutIcon}
                title="Sign Out"
                backIcon={backIcon}
                onPress={() => setLogoutModelShown(true)}
              />
            </View>
          </View>
          {isWebsite() ? null : <AppGap size={120} direction="vertical" />}
        </ScrollView>
      </SafeAreaView>
      <AppDrawer
        visible={logoutModelShown}
        height={385}
        onClose={() => {
          setLogoutModelShown(false);
        }}
        showBackDrop
      >
        <CustomText
          weight="500"
          style={{ fontSize: 24, marginBottom: 16, textAlign: isWebsite() ? 'center' : undefined }}
        >
          Are you sure you want to sign out?
        </CustomText>
        <HorizontalLine thinkness={1} />
        <CustomText
          style={{ fontSize: 16, marginTop: 16, textAlign: isWebsite() ? 'center' : undefined }}
        >
          Don&apos;t worry, you can easily log back in anytime to access your account!
        </CustomText>
        <View
          style={
            isWebsite()
              ? {
                  maxWidth: 400,
                  marginHorizontal: 'auto',
                  width: '100%',
                }
              : null
          }
        >
          <Button
            style={{ marginTop: 32 }}
            onPress={async () => {
              DevLog.log('Logout');
              await tokenService.deleteAccessTokenAsync();
              await tokenService.deleteAccessTokenExpiryAsync();
              await tokenService.deleteRefreshTokenAsync();
              await tokenService.deleteRefreshTokenExpiryAsync();
              await tokenService.deleteCASelfServeTokenAsync();
              await tokenService.deleteRememberMe();
              dispatch(resetUser());
            }}
            textStyle={{ fontSize: 14 }}
          >
            Sign Out
          </Button>
          <Button
            style={{ marginTop: 16 }}
            color={Colors.backgroundGrey}
            textColor={Colors.inputTextPlaceholderColor}
            onPress={() => {
              setLogoutModelShown(false);
            }}
            textStyle={{ fontSize: 14, fontWeight: '600' }}
          >
            Keep Sign In
          </Button>
        </View>
      </AppDrawer>
    </>
  );
}
