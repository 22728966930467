import { useState } from 'react';
import {
  Dimensions,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  ScrollView,
  View,
} from 'react-native';

import QuickAction, { Action } from './QuickAction';
import { Colors } from '../../constants/Colors';

type Props = {
  quickActions: Action[][];
  onActionSelected: (tag: string) => void;
};

const QuickActions = ({ quickActions, onActionSelected }: Props) => {
  const { width } = Dimensions.get('window');
  const [currentPage, setCurrentPage] = useState(0);

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const xOffset = event.nativeEvent.contentOffset.x;
    const newPage = Math.round(xOffset / width);
    // DevLog.log('Here', xOffset, newPage, currentPage, width, xOffset / width);
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <ScrollView
        style={{ flex: 1, paddingVertical: 10, height: 120 }}
        contentContainerStyle={Platform.select({
          web: { alignItems: 'center', width: '100%' },
          default: null,
        })}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={16}
        pagingEnabled={true}
        onScroll={handleScroll}
      >
        {quickActions.map((actions, index) => {
          return (
            <View
              style={{
                display: 'flex',
                paddingHorizontal: 10,
                width: actions.length < 4 ? width : width, //'100%',
                flexDirection: 'row',
                justifyContent: actions.length < 4 ? 'space-evenly' : 'space-evenly',
              }}
              key={index}
            >
              {actions.map((action, index) => (
                <QuickAction action={action} key={index} onActionSelected={onActionSelected} />
              ))}
            </View>
          );
        })}
      </ScrollView>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          marginBottom: 20,
        }}
      >
        {quickActions.map((_, v) =>
          currentPage === v ? (
            <View
              style={{
                height: 6,
                width: 16,
                backgroundColor: Colors.canadaButtonColor,
                borderRadius: 5,
                marginLeft: 8,
              }}
              key={v}
            />
          ) : (
            <View
              style={{
                height: 6,
                width: 6,
                backgroundColor: Colors.PaginationDotColor,
                borderRadius: 5,
                marginLeft: 8,
              }}
              key={v}
            />
          )
        )}
      </View>
    </>
  );
};

export default QuickActions;
