/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Root: {
        // path: 'dashboard',
        screens: {
          Home: 'home',
          Shop: 'shop',
          Billing: 'billing',
          Settings: 'settings',
          Benefits: 'benefits',
        },
      },
      LogIn: 'login',
      Join: 'join',
      JoinSuccess: 'join-success',
      SelectLine: 'select-line',
      Landing: '',
      StatusTracking: {
        path: 'status-tracking',
        screens: {
          Shipping: 'shipping',
          'Service Request': 'service-request',
        },
      },
      Plans: 'plans',
      PurchasePlanFlow: 'purchase-plan-flow',
      PaymentWebView: 'payment-view-page',
      ShippingTracking: 'status-tracking/shipping-tracking',
      ServiceRequestTracking: 'status-tracking/service-request-tracking',
      RecoverPassword: 'recover-password',
      Loading: 'loading',
      Invoices: 'billing/invoices',
      BillingPayBalance: 'billing/billing-pay-balance',
      ManagePayment: 'billing/manage-payment',
      PortOption: 'port-option',
      Account: 'settings/account',
      EditName: 'settings/account/edit-name',
      EditAddress: 'settings/account/edit-address',
      EditEmail: 'settings/account/edit-email',
      EditPassword: 'settings/account/edit-password',
      DeleteAccount: 'settings/account/delete-account',
      ChangePlan: 'change-plan',
      PrepaidPlanCheckout: 'change-plan-prepaid-checkout',
      AddMoreData: 'add-more-data',
      ChangePlanSuccess: 'change-plan-success',
      TemporaryHolds: 'temporary-holds',
      LostOrStolen: 'lost-or-stolen',
      LostOrStolenSuccess: 'lost-or-stolen/success',
      VacationHold: 'vacation-hold',
      TerminatePlan: 'terminate-plan',
      TerminationConfirmation: 'terminate-plan/confirmation',
      TerminationDetail: 'terminate-plan/confirmation/detail',
      TerminationSuccess: 'terminate-plan/success',
      GenericSuccess: 'success',
      FriendsReferral: 'friends-referral',
      PaymentSuccess: 'payment-success',
      MyOrders: {
        path: 'my-orders',
        screens: {
          All: 'all',
          Mobile: 'mobile',
          'Home Internet': 'home-internet',
        },
      },
      NotFound: '*',
    },
  },
};

export default linking;
