import { Image, ScrollView, View } from 'react-native';

import CheckmarkIconImage from '../../assets/icons/checkmark.png';
import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { isWebsite } from '../../utilities/functions';

const SuccessView = ({ onPress }: { onPress(): void }) => {
  return (
    <View
      style={{
        position: 'relative',
        padding: 20,
        backgroundColor: Colors.white,
        flex: 1,
        height: '100%',
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginBottom: 150, paddingBottom: 20 }}
      >
        <CustomText
          weight="600"
          style={{ fontSize: 24, textAlign: 'center', color: Colors.canadaButtonColor }}
        >
          Registered Successfully!
        </CustomText>
        <CustomText
          style={{ fontSize: 14, marginTop: 16, textAlign: 'center', color: Colors.TextBlack }}
        >
          Your payment method has been successfully register.
        </CustomText>
        <Image
          source={CheckmarkIconImage}
          style={{
            width: 220,
            height: 220,
            marginHorizontal: 'auto',
            marginTop: 60,
            alignSelf: 'center',
          }}
          resizeMode="contain"
        />
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
          alignItems: isWebsite() ? 'center' : undefined,
        }}
      >
        <Button style={{ marginTop: 32 }} onPress={() => onPress()} textStyle={{ fontSize: 14 }}>
          Back to Main Page
        </Button>
      </View>
    </View>
  );
};

export default SuccessView;
