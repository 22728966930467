import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { useRef, useState } from 'react';
import { Alert, Animated, Linking, Platform, View } from 'react-native';
import { useSelector } from 'react-redux';

import BlogImage from '../../assets/icons/action/4x/blog.png';
import CardImage from '../../assets/icons/action/4x/card.png';
import ChatImage from '../../assets/icons/action/4x/chat.png';
import DataTopUpImage from '../../assets/icons/action/4x/data-top-up.png';
import InvoiceImage from '../../assets/icons/action/4x/invoice.png';
import LostImage from '../../assets/icons/action/4x/lost.png';
import PhoneImage from '../../assets/icons/action/4x/phone.png';
import PortInImage from '../../assets/icons/action/4x/port-in.png';
import PortOutImage from '../../assets/icons/action/4x/port-out.png';
import ProfileImage from '../../assets/icons/action/4x/profile.png';
import ReferralImage from '../../assets/icons/action/4x/referral.png';
import TrackingImage from '../../assets/icons/action/4x/tracking.png';
import VacationImage from '../../assets/icons/action/4x/vacation.png';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT } from '../../constants/constants';
import { RootState } from '../../store';
import DevLog from '../../utilities/debug-error';
import { crispChatAction, isWebsite } from '../../utilities/functions';
import QuickAction, { Action } from '../QuickAction/QuickAction';
import CustomText from '../utils/CustomText';

// import HelpCenterImage from  '../../assets/icons/action/4x/help-center.png'

const prePaidActions: Action[] = [
  {
    image: ProfileImage,
    title: 'Profile Change',
    tag: 'PROFILE_CHANGE',
  },
  {
    image: PhoneImage,
    title: 'Plan Change',
    tag: 'PLAN_CHANGE',
  },
  {
    image: DataTopUpImage,
    title: 'Data Top Up',
    tag: 'DATA_TOP_UP',
  },
  {
    image: CardImage,
    title: 'Auto Payment',
    tag: 'AUTO_PAYMENT',
  },

  // {
  //   image: HelpCenterImage,
  //   title: 'Help Center',
  //   tag: 'HELP_CENTER',
  // },
  {
    image: ReferralImage,
    title: 'Friends Referral',
    tag: 'FRIENDS_REFERRAL',
  },
  {
    image: InvoiceImage,
    title: 'View Invoice',
    tag: 'VIEW_INVOICE',
  },
  {
    image: TrackingImage,
    title: 'Status Tracking',
    tag: 'STATUS_TRACKING',
  },
  {
    image: PortInImage,
    title: 'Port In',
    tag: 'PORT_IN',
  },
  {
    image: PortOutImage,
    title: 'Port Out',
    tag: 'PORT_OUT',
  },
];

const postPaidActions: Action[] = [
  {
    image: ProfileImage,
    title: 'Profile Change',
    tag: 'PROFILE_CHANGE',
  },
  {
    image: PhoneImage,
    title: 'Plan Change',
    tag: 'PLAN_CHANGE',
  },
  {
    image: InvoiceImage,
    title: 'View Invoice',
    tag: 'VIEW_INVOICE',
  },
  {
    image: CardImage,
    title: 'Auto Payment',
    tag: 'AUTO_PAYMENT',
  },

  {
    image: TrackingImage,
    title: 'Status Tracking',
    tag: 'STATUS_TRACKING',
  },
  {
    image: PortInImage,
    title: 'Port In',
    tag: 'PORT_IN',
  },
  {
    image: ReferralImage,
    title: 'Friends Referral',
    tag: 'FRIENDS_REFERRAL',
  },
  {
    image: VacationImage,
    title: 'Vacation Hold',
    tag: 'VACATION_HOLD',
  },
  {
    image: ChatImage,
    title: 'Support Chat',
    tag: 'SUPPORT_CHAT',
  },
  {
    image: PortOutImage,
    title: 'Port Out',
    tag: 'PORT_OUT',
  },
  {
    image: LostImage,
    title: 'Lost or Stolen',
    tag: 'LOST_OR_STOLEN',
  },
  {
    image: BlogImage,
    title: 'Blog',
    tag: 'BLOG_ACTION',
  },
];

const QuickActionDesktop = () => {
  const navigation = useNavigation();

  const { loggedIn, id, email, firstName, lastName } = useSelector(
    (state: RootState) => state.user
  );

  const { accountProfileCA } = useSelector((state: RootState) => state.selfServeCA);
  const { Prepaid: isPrepaid, EndDate } = useSelector(
    (state: RootState) => state.selfServeCA.accountInfoCA
  );

  const [detailVisiable, setDetailVisiable] = useState(false);
  const heightAnim = useRef(new Animated.Value(120)).current;

  const toggleExpand = () => {
    Animated.timing(heightAnim, {
      toValue: detailVisiable ? 120 : 440,
      duration: 300,
      useNativeDriver: false,
    }).start();
    setDetailVisiable(!detailVisiable);
  };

  return (
    <View
      style={{
        marginTop: 14,
      }}
    >
      <Animated.View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          overflow: 'hidden',
          gap: 5,
          height: heightAnim,
          opacity: heightAnim.interpolate({
            inputRange: [0, 100],
            outputRange: [0, 1],
          }),
        }}
      >
        {(isPrepaid ? prePaidActions : postPaidActions).map((action, i) => (
          <QuickAction
            action={action}
            key={i}
            onActionSelected={(tag) => {
              switch (tag) {
                case 'PROFILE_CHANGE':
                  navigation.navigate('Account');
                  break;
                case 'PLAN_CHANGE':
                  navigation.navigate('ChangePlan');
                  break;
                case 'STATUS_TRACKING':
                  navigation.navigate('StatusTracking');
                  break;
                case 'DATA_TOP_UP':
                  {
                    const endDateString = moment(EndDate).format(DATE_FORMAT);
                    if (
                      confirm(
                        `Your Current term end date is ${endDateString}. Do you still want to Top Up`
                      )
                    ) {
                      navigation.navigate('AddMoreData');
                    }
                  }
                  break;
                case 'PORT_OUT':
                case 'PORT_IN':
                  if (isWebsite()) {
                    alert(
                      'Porting is not available in the app right now. Please contact our customer service team to complete the porting process.'
                    );
                  } else {
                    Alert.alert(
                      'Porting Unavailable',
                      'Porting is not available in the app right now. Please contact our customer service team to complete the porting process.'
                    );
                  }
                  break;
                case 'VIEW_INVOICE':
                  navigation.navigate('Invoices');
                  break;
                case 'SUPPORT_CHAT':
                  crispChatAction(
                    loggedIn,
                    id,
                    email,
                    firstName,
                    lastName,
                    accountProfileCA.PhoneNumber
                  );
                  break;
                case 'AUTO_PAYMENT':
                  navigation.navigate('ManagePayment');
                  break;
                case 'LOST_OR_STOLEN':
                  navigation.navigate('LostOrStolen');
                  break;
                case 'FRIENDS_REFERRAL':
                  navigation.navigate('Benefits', { benefit: 'refer_a_friend_promo' });
                  break;
                case 'VACATION_HOLD':
                  navigation.navigate('VacationHold');
                  break;
                case 'SUPPORT_TICKET':
                  break;
                case 'BLOG_ACTION':
                  Linking.openURL('https://blog.gophonebox.com/');
                  break;
                default:
                  break;
              }
              DevLog.log(tag);
            }}
          />
        ))}
      </Animated.View>
      <CustomText
        weight="600"
        onPress={toggleExpand}
        style={{
          color: Colors.ActionLinkColor,
          fontWeight: '600',
          fontSize: 12,
          textAlign: 'center',
          marginTop: Platform.select({ web: 8, default: 10 }),
        }}
      >
        {detailVisiable ? 'Hide' : 'Show'} More Actions
      </CustomText>
    </View>
  );
};

export default QuickActionDesktop;
