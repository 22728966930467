import { Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useState } from 'react';
import {
  DimensionValue,
  Image,
  ImageSourcePropType,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';

import { Colors } from '../../constants/Colors';

type Props = {
  image: ImageSourcePropType;
  width?: DimensionValue;
  height?: DimensionValue;
  visible?: boolean;
};

const PopUpBanner = (props: Props) => {
  const navigation = useNavigation();

  const [visible, setVisible] = useState(props.visible !== false);

  return visible ? (
    <View
      style={{
        ...StyleSheet.absoluteFillObject,
        backgroundColor: Colors.overlay75,
        width: '100%',
        height: '100%',
        zIndex: 999999,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View style={{ position: 'relative' }}>
        <Pressable
          onPress={() => {
            navigation.navigate('Plans');
          }}
        >
          <Image
            source={props.image}
            style={{
              width: props.width ? props.width : 300,
              height: props.height ? props.height : 419,
              borderRadius: 8,
            }}
            resizeMode="contain"
          />
        </Pressable>
        <Entypo
          name="circle-with-cross"
          size={28}
          color={Colors.white}
          style={{
            position: 'absolute',
            top: -12,
            right: -12,
          }}
          onPress={() => {
            setVisible(false);
          }}
        />
      </View>
    </View>
  ) : null;
};

export default PopUpBanner;
