import { Ionicons, MaterialCommunityIcons, AntDesign, FontAwesome6 } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';

import { Colors } from '../constants/Colors';
import BenefitsScreen from '../screens/BenefitsScreen';
import BillingScreen from '../screens/BillingScreen';
import HomeScreen from '../screens/HomeScreen';
import SettingsScreen from '../screens/SettingsScreen';
import ShopScreen from '../screens/ShopScreen';
import { RootState } from '../store';
import { RootTabParamList } from '../types';

const BottomTab = createBottomTabNavigator<RootTabParamList>();

export function BottomTabNavigator() {
  const { drawerVisible } = useSelector((state: RootState) => state.app);
  const { loggedIn, accounts } = useSelector((state: RootState) => state.user);

  const isPrepaid = useSelector((state: RootState) => state.selfServeCA.accountInfoCA.Prepaid);

  return (
    <BottomTab.Navigator
      screenOptions={{
        tabBarActiveTintColor: Colors.white, // Selected Button
        tabBarInactiveTintColor: Colors.greyColor, // In Active Button
        // tabBarShowLabel: true,
        headerShown: false,
        tabBarLabelStyle: { fontSize: 10 },
        tabBarStyle: {
          height: Platform.OS === 'ios' ? 80 : 60,
          display: drawerVisible ? 'none' : 'flex',
          backgroundColor: Colors.black,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          paddingBottom: Platform.OS === 'ios' ? 28 : 8,
        },
        tabBarIconStyle: { marginTop: 4 },
      }}
      initialRouteName="Home"
    >
      <BottomTab.Screen
        name="Benefits"
        component={BenefitsScreen}
        options={{
          title: 'Benefits',
          tabBarIcon: ({ color, size }) => (
            <FontAwesome6 name="splotch" size={size} color={color} />
          ),
        }}
      />
      {!loggedIn ? null : (
        <BottomTab.Screen
          name="Shop"
          component={ShopScreen}
          options={{
            title: 'Shop',
            tabBarIcon: ({ color, size }) => (
              <AntDesign name="shoppingcart" size={size} color={color} />
            ),
          }}
        />
      )}
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: 'Home',
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons name="home" color={color} size={size} />
          ),
        }}
      />
      {loggedIn ? null : (
        <BottomTab.Screen
          name="Shop"
          component={ShopScreen}
          options={{
            title: 'Shop',
            tabBarIcon: ({ color, size }) => (
              <AntDesign name="shoppingcart" size={size} color={color} />
            ),
          }}
        />
      )}
      {!loggedIn || isPrepaid || accounts.length === 0 ? null : (
        <BottomTab.Screen
          name="Billing"
          component={BillingScreen}
          options={{
            title: 'Billing',
            tabBarIcon: ({ color, size }) => <Ionicons name="receipt" size={size} color={color} />,
          }}
        />
      )}
      {!loggedIn ? null : (
        <BottomTab.Screen
          name="Settings"
          component={SettingsScreen}
          options={{
            title: 'Settings',
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons name="account" color={color} size={size} />
            ),
          }}
        />
      )}
    </BottomTab.Navigator>
  );
}
