import { Dimensions, ScrollView, View } from 'react-native';
import WebView, { WebViewNavigation } from 'react-native-webview';

import { Colors } from '../../constants/Colors';
import { RootStackScreenProps } from '../../types';

const { width, height } = Dimensions.get('window');

export default function PaymentWebViewScreen({
  navigation,
  route,
}: RootStackScreenProps<'PaymentWebView'>) {
  const { url, paymentType } = route.params;

  const returnFromWebview = (e: WebViewNavigation) => {
    if (e === null) {
      return false;
    }

    if (hasError(e)) {
      navigation.goBack();
      return false;
    }

    if (isPaymentCancel(e)) {
      navigation.goBack();
      return false;
    }
    if (isPaymentSuccess(e)) {
      // its Working Here
      navigation.navigate('PaymentSuccess');
      return true;
    }
  };

  const isPaymentSuccess = (e: WebViewNavigation) => {
    if (
      ['creditcard', 'paypal', 'alipay_wap'].includes(paymentType) &&
      e.url.includes('mobilereturn')
    ) {
      return true;
    }
    return false;
  };

  const isPaymentCancel = (e: WebViewNavigation) => {
    if (['paypal', 'creditcard'].includes(paymentType) && e.url.includes('mobilecancel')) {
      return true;
    }
    return false;
  };

  const hasError = (e: WebViewNavigation) => {
    if (
      e.url.includes('https://www.gophonebox.com/en/Error/404') ||
      e.url.includes('en/Error/404')
    ) {
      return true;
    }
    return false;
  };

  return (
    <View style={{ width: '100%', height: '100%' }}>
      <ScrollView>
        <View
          style={{
            flex: 1,
            backgroundColor: Colors.canadaButtonColor,
            width: width,
            height: height,
          }}
        >
          <WebView
            source={{ uri: url }}
            onNavigationStateChange={(e) => {
              returnFromWebview(e);
            }}
          />
        </View>
      </ScrollView>
    </View>
  );
}
