/* eslint-disable @typescript-eslint/no-explicit-any */
import { Octicons } from '@expo/vector-icons';
import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import React from 'react';

import CustomText from '../components/utils/CustomText';
import { BottomTabNavigator } from '../navigation/BottomTabNavigator';
import AccountScreen from '../screens/AccountScreen';
import BillingPayBalanceScreen from '../screens/BillingPayBalanceScreen';
import ChangePlanScreen from '../screens/ChangePlanScreen';
import AddMoreDataScreen from '../screens/ChangePlanScreen/AddMoreDataScreen';
import ChangePlanSuccessScreen from '../screens/ChangePlanScreen/ChangePlanSuccessScreen';
import PrepaidPlanCheckoutScreen from '../screens/ChangePlanScreen/PrepaidPlanCheckoutScreen';
import DeleteAccountScreen from '../screens/CloseAccount';
import EditAddressScreen from '../screens/EditAddressScreen';
import EditEmailScreen from '../screens/EditEmailScreen';
import EditNameScreen from '../screens/EditNameScreen';
import EditPasswordScreen from '../screens/EditPasswordScreen';
import FriendsReferralScreen from '../screens/FriendsReferralScreen';
import GenericSuccessScreen from '../screens/GenericSuccessScreen';
import HomeScreen from '../screens/HomeScreen';
import IntroSliderScreen from '../screens/IntroSliderScreen';
import InvoicesScreen from '../screens/InvoicesScreen';
import RegisterScreen from '../screens/JoinScreen';
import JoinSuccessScreen from '../screens/JoinSuccessScreen';
import LandingScreen from '../screens/LandingScreen';
import LoadingScreen from '../screens/LoadingScreen';
import LogInScreen from '../screens/LoginScreen';
import LostOrStolenScreen from '../screens/LostOrStolenScreen';
import LostOrStolenSuccessScreen from '../screens/LostOrStolenScreen/LostOrStolenSuccessScreen';
import ManagePaymentScreen from '../screens/ManagePaymentScreen';
import MyOrderScreen from '../screens/MyOrderScreen';
import OnboardingScreen from '../screens/OnboardingScreen';
import PaymentSuccessScreen from '../screens/Payment/PaymentSuccessScreen';
import PaymentWebViewScreen from '../screens/Payment/PaymentWebViewScreen';
import PlansScreen from '../screens/PlansScreen';
import PortOptionScreen from '../screens/PortOptionScreen';
import PurchasePlanFLowScreen from '../screens/PurchasePlanFlowScreen';
import RecoverYourPasswordScreen from '../screens/RecoverYourPasswordScreen';
import RetentionPlanScreen from '../screens/RetentionPlanScreen';
import SelectLineScreen from '../screens/SelectLineScreen';
import ServiceRequestTrackingScreen from '../screens/ServiceRequestTrackingScreen';
import SettingsScreen from '../screens/SettingsScreen';
import ShippingTrackingScreen from '../screens/ShippingTrackingScreen';
import ShopScreen from '../screens/ShopScreen';
import StatusTrackingScreen from '../screens/StatusTrackingScreen';
import TemporaryHoldsScreen from '../screens/TemporaryHoldsScreen';
import TerminatePlanScreen from '../screens/TerminatePlanScreen';
import TerminationConfirmationScreen from '../screens/TerminatePlanScreen/TerminationConfirmationScreen';
import TerminationDetailScreen from '../screens/TerminatePlanScreen/TerminationDetailScreen';
import TerminationSuccessScreen from '../screens/TerminatePlanScreen/TerminationSuccessScreen';
import VacationHoldScreen from '../screens/VacationHoldScreen';
import VacationHoldDetailScreen from '../screens/VacationHoldScreen/VacationHoldDetailScreen';
import VacationHoldSuccessScreen from '../screens/VacationHoldScreen/VacationHoldSuccessScreen';
import { RootStackParamList } from '../types';
import { isWebsite } from '../utilities/functions';

export const UnAuthenticatedUserScreen: {
  name: keyof RootStackParamList;
  component: any;
  options: NativeStackNavigationOptions;
}[] = [
  {
    name: 'IntroSlider',
    component: IntroSliderScreen,
    options: {
      headerShown: false,
      headerBackVisible: true,
    },
  },
  {
    name: 'Landing',
    component: LandingScreen,
    options: {
      headerShown: false,
      headerBackVisible: true,
    },
  },
  {
    name: 'LogIn',
    component: LogInScreen,
    options: {
      headerShown: false,
      headerBackVisible: true,
    },
  },
  {
    name: 'Join',
    component: RegisterScreen,
    options: {
      headerShown: false,
      headerBackVisible: true,
    },
  },
  {
    name: 'RecoverPassword',
    component: RecoverYourPasswordScreen,
    options: {
      headerShown: false,
      headerBackVisible: true,
    },
  },
  {
    name: 'JoinSuccess',
    component: JoinSuccessScreen,
    options: {
      headerShown: false,
      headerBackVisible: true,
    },
  },
  {
    name: 'SelectLine',
    component: SelectLineScreen,
    options: {
      headerShown: false,
      headerBackVisible: true,
    },
  },
];

export const AuthenticatedUserScreen: {
  name: keyof RootStackParamList;
  component: any;
  options: NativeStackNavigationOptions;
}[] = [
  {
    name: 'Loading',
    component: LoadingScreen,
    options: {
      headerShown: false,
    },
  },
];

export const StackScreens: {
  name: keyof RootStackParamList;
  component: any;
  options:
    | NativeStackNavigationOptions
    | ((props: {
        route: RouteProp<RootStackParamList, keyof RootStackParamList>;
        navigation: any;
      }) => NativeStackNavigationOptions);
}[] = [
  {
    name: 'Root',
    component: BottomTabNavigator,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'Home',
    component: HomeScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'Settings',
    component: SettingsScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'Shop',
    component: ShopScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: 'Plans',
    component: PlansScreen,
    options: {
      title: 'Plans',
    },
  },
  {
    name: 'PurchasePlanFlow',
    component: PurchasePlanFLowScreen,
    options: {
      title: 'SIM Type',
    },
  },
  {
    name: 'PaymentWebView',
    component: PaymentWebViewScreen,
    options: {
      title: 'Payment',
      headerShown: false,
    },
  },
  {
    name: 'StatusTracking',
    component: StatusTrackingScreen,
    options: {
      title: 'Activity Tracking',
      headerRight: () =>
        React.createElement(Octicons, {
          name: 'sort-desc',
          size: 24,
          color: 'black',
          style: { marginRight: 6 },
        }),
    },
  },
  {
    name: 'ShippingTracking',
    component: ShippingTrackingScreen,
    options: {
      title: 'Shipping Tracking',
    },
  },
  {
    name: 'ServiceRequestTracking',
    component: ServiceRequestTrackingScreen,
    options: {
      title: 'Service Request Tracking',
    },
  },
  {
    name: 'FriendsReferral',
    component: FriendsReferralScreen,
    options: {
      title: 'Friends Referral',
    },
  },
  {
    name: 'PortOption',
    component: PortOptionScreen,
    options: {
      title: 'Porting',
    },
  },
  {
    name: 'MyOrders',
    component: MyOrderScreen,
    options: {
      title: 'My Orders',
    },
  },
  {
    name: 'Account',
    component: AccountScreen,
    options: {
      title: 'Profile',
    },
  },
  {
    name: 'EditName',
    component: EditNameScreen,
    options: {
      title: 'Edit Name',
    },
  },
  {
    name: 'EditAddress',
    component: EditAddressScreen,
    options: {
      title: 'Edit Profile',
    },
  },
  {
    name: 'EditEmail',
    component: EditEmailScreen,
    options: {
      title: 'Change email',
    },
  },
  {
    name: 'EditPassword',
    component: EditPasswordScreen,
    options: {
      title: 'Update Password',
    },
  },
  {
    name: 'DeleteAccount',
    component: DeleteAccountScreen,
    options: {
      title: 'Delete Account',
    },
  },
  {
    name: 'Invoices',
    component: InvoicesScreen,
    options: {
      title: 'Invoice',
    },
  },
  {
    name: 'ManagePayment',
    component: ManagePaymentScreen,
    options: {
      title: 'Manage Payment',
    },
  },
  {
    name: 'BillingPayBalance',
    component: BillingPayBalanceScreen,
    options: {
      title: 'Pay Bill',
      headerShown: false,
    },
  },
  {
    name: 'PaymentSuccess',
    component: PaymentSuccessScreen,
    options: {
      title: '',
      headerShown: false,
    },
  },
  {
    name: 'ChangePlan',
    component: ChangePlanScreen,
    options: {
      title: 'Change Plan',
    },
  },
  {
    name: 'PrepaidPlanCheckout',
    component: PrepaidPlanCheckoutScreen,
    options: {
      title: 'Change Plan Checkout',
    },
  },
  {
    name: 'AddMoreData',
    component: AddMoreDataScreen,
    options: {
      title: 'Add More Data',
    },
  },
  {
    name: 'ChangePlanSuccess',
    component: ChangePlanSuccessScreen,
    options: {
      title: 'Plan Change Success',
    },
  },
  {
    name: 'TemporaryHolds',
    component: TemporaryHoldsScreen,
    options: {
      title: 'Temporary Holds',
      headerShown: false,
    },
  },
  {
    name: 'LostOrStolen',
    component: LostOrStolenScreen,
    options: {
      title: 'Lost or Stolen',
    },
  },
  {
    name: 'LostOrStolenSuccess',
    component: LostOrStolenSuccessScreen,
    options: {
      title: 'Lost or Stolen',
    },
  },
  {
    name: 'TerminatePlan',
    component: TerminatePlanScreen,
    options: {
      title: 'Termination',
    },
  },
  {
    name: 'TerminationConfirmation',
    component: TerminationConfirmationScreen,
    options: {
      title: 'Termination',
    },
  },
  {
    name: 'TerminationDetail',
    component: TerminationDetailScreen,
    options: {
      title: 'Termination',
    },
  },
  {
    name: 'TerminationSuccess',
    component: TerminationSuccessScreen,
    options: {
      title: 'Termination',
    },
  },
  {
    name: 'RetentionPlan',
    component: RetentionPlanScreen,
    options: {
      title: 'Promotional Plan',
    },
  },
  {
    name: 'GenericSuccess',
    component: GenericSuccessScreen,
    options: {
      title: '',
    },
  },
  {
    name: 'Onboarding',
    component: OnboardingScreen,
    options: ({ navigation }) => ({
      title: 'Activation',
      headerRight: () =>
        React.createElement(
          CustomText,
          {
            style: isWebsite()
              ? {
                  marginRight: 20,
                }
              : {},
            onPress: () => {
              navigation.navigate('Root');
            },
          },
          'Skip'
        ),
    }),
  },
  {
    name: 'VacationHold',
    component: VacationHoldScreen,
    options: {
      title: 'Vacation Hold',
    },
  },
  {
    name: 'VacationHoldDetail',
    component: VacationHoldDetailScreen,
    options: {
      title: 'Vacation Hold',
    },
  },
  {
    name: 'VacationHoldSuccess',
    component: VacationHoldSuccessScreen,
    options: {
      title: 'Vacation Hold',
    },
  },
];
