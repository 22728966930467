import { EvilIcons } from '@expo/vector-icons';
import moment from 'moment';
import { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT_FOR_API } from '../../constants/constants';
import { accountService } from '../../services/MyAccount/AccountService';
import { authService } from '../../services/SSO/AuthService';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';
import { formatPhoneNumber } from '../../utilities/utilities';

import { PortOutData } from '.';

const PortOutConfirmationView = ({
  onPress,
  portOutData,
}: {
  onPress(option: string): void;
  portOutData: PortOutData;
}) => {
  const [otp, setOtp] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const { PhoneNumber } = useSelector((state: RootState) => state.selfServeCA.accountProfileCA);

  return (
    <View
      style={[
        {
          position: 'relative',
          padding: 20,
          backgroundColor: Colors.white,
          flex: 1,
          height: '100%',
        },
        isWebsite()
          ? {
              maxWidth: 400,
              marginHorizontal: 'auto',
              width: '100%',
            }
          : null,
      ]}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginBottom: 150, paddingBottom: 20 }}
      >
        <View
          style={{
            backgroundColor: Colors.errorRed10Percent,
            padding: 16,
            borderRadius: 16,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 16,
            }}
          >
            <View
              style={{
                backgroundColor: Colors.errorRed20Percent,
                padding: 4,
                marginRight: 8,
                borderRadius: 10,
              }}
            >
              <EvilIcons name="question" size={16} color={Colors.errorRed} />
            </View>
            <CustomText weight="500" style={{ fontSize: 14, color: Colors.red }}>
              What will happen next?
            </CustomText>
          </View>
          <View style={{ gap: 10 }}>
            <CustomText style={{ fontSize: 12 }}>
              Once you submit your port-out request, your data service will be paused to calculate
              your usage.
            </CustomText>
            <CustomText style={{ fontSize: 12 }}>
              We will also send you a port-out number (account number) via email.
            </CustomText>
            <CustomText style={{ fontSize: 12 }}>
              Please notify us once the port-in is successfully completed so we can terminate your
              line.
            </CustomText>
            <CustomText style={{ fontSize: 12 }}>
              If we are not informed, we will be unable to terminate your account, and you may be
              charged for the next billing period.
            </CustomText>
          </View>
        </View>
        <CustomText weight="600" style={{ fontSize: 14, marginTop: 24 }}>
          Port out information
        </CustomText>
        <View
          style={{
            backgroundColor: Colors.backgroundGrey,
            padding: 16,
            borderRadius: 16,
            marginTop: 8,
            gap: 8,
          }}
        >
          <View style={styles.infoContainer}>
            <CustomText weight="500" style={styles.infoTitle}>
              Phone Number:
            </CustomText>
            <CustomText weight="500" style={styles.infoText}>
              {formatPhoneNumber(PhoneNumber)}
            </CustomText>
          </View>
          <View style={styles.infoContainer}>
            <CustomText weight="500" style={styles.infoTitle}>
              Carrier:
            </CustomText>
            <CustomText weight="500" style={styles.infoText}>
              {portOutData.carrier}
            </CustomText>
          </View>
        </View>

        <InputWithTitle inputValue={otp} onInputChange={setOtp} title="One Time Password (OTP)" />
        {error ? (
          <CustomText
            style={{
              color: Colors.errorRed,
              marginTop: 4,
              textAlign: 'center',
            }}
          >
            {error}
          </CustomText>
        ) : null}
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
        }}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={async () => {
            try {
              dispatch(setIsLoading(true));
              const response = await authService.validateOtpForPortout(portOutData.otpUserId, otp);
              DevLog.log(response.data);
              if (!response.data.success) {
                return setError(response.data.error.message);
              }
              const request = await accountService.createCustomerPortoutRequest({
                Note: portOutData.portOutReasonDesc,
                PortoutDate: moment().format(DATE_FORMAT_FOR_API),
                PortoutReasonId: portOutData.portOutReason,
                PortTo: portOutData.carrier,
              });
              if (!request.data) {
                return setError(
                  'Unable to process your request at this time. Please contact customer service.'
                );
              }
              onPress('DONE');
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
              DevLog.log(error);
              setError(error.message);
            } finally {
              dispatch(setIsLoading(false));
            }
          }}
          textStyle={{ fontSize: 14 }}
          color={Colors.errorRed}
          disabled={!otp || otp.length !== 6}
        >
          Request Port Out
        </Button>
        <Button
          style={{ marginTop: 10 }}
          color={Colors.backgroundGrey}
          textColor={Colors.black}
          onPress={() => onPress('CANCEL')}
        >
          <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
            Cancel
          </CustomText>
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  infoContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  infoText: { color: Colors.TextBlack, fontSize: 12, textAlign: 'right' },
  infoTitle: { color: Colors.greyColor, fontSize: 12 },
});

export default PortOutConfirmationView;
