import { Feather } from '@expo/vector-icons';
import { DateTimePickerAndroid, DateTimePickerEvent } from '@react-native-community/datetimepicker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Platform, SafeAreaView, ScrollView, View } from 'react-native';
import { DatePickerModal, enGB, registerTranslation } from 'react-native-paper-dates';
import { CalendarDate } from 'react-native-paper-dates/lib/typescript/Date/Calendar';
import { useSelector } from 'react-redux';

import { AppDrawer } from '../../components/AppDrawer';
import Button from '../../components/Button';
import InputWithSelectOption from '../../components/UI/Form/InputForSelectOption';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT, provinces } from '../../constants/constants';
import { RootState } from '../../store';
import { ParsedPlan } from '../../types/models/ParsedPlan';
import { ShippingOption } from '../../types/models/ShippingOption';
import { ProvinceInfo } from '../../types/types';
import { isWebsite } from '../../utilities/functions';
import { validate } from '../../utilities/utilities';

export type ActivationInfo = {
  firstname: string;
  lastname: string;
  activationDate?: Date;
  endDate?: Date;
  emailAddress: string;
  province: ProvinceInfo;
  city: string;
};

type Props = {
  plan: ParsedPlan;
  activationInfo: ActivationInfo;
  onNext(activationInfo: ActivationInfo): void;
  shippingCountry?: string;
  selectedShippingOption?: ShippingOption | null;
};

export default function ActivationInfoView(props: Props) {
  registerTranslation('en', enGB);

  const [firstname, setFirstname] = useState<string>(props.activationInfo.firstname ?? '');
  const [lastname, setLastname] = useState<string>(props.activationInfo.lastname ?? '');
  const [endDate, setEndDate] = useState<Date>(props.activationInfo.endDate ?? new Date());
  const { loggedIn, email } = useSelector((state: RootState) => state.user);
  const [emailAddress, setEmailAddress] = useState<string>(
    loggedIn ? email : (props.activationInfo.emailAddress ?? '')
  );
  const [confirmEmailAddress, setConfirmEmailAddress] = useState<string>(
    loggedIn ? email : (props.activationInfo.emailAddress ?? '')
  );
  const [province, setProvince] = useState<ProvinceInfo>(props.activationInfo.province);
  const [city, setCity] = useState<string>(props.activationInfo.city);

  const [showDateModal, setShowDateModal] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showProvinceModel, setShowProvinceModel] = useState<boolean>(false);

  let dayDelay = 0;
  if (props.shippingCountry && props.selectedShippingOption) {
    dayDelay = props.selectedShippingOption.deliLongest;
  }
  const [activationDate, setActivationDate] = useState<Date>(
    props.activationInfo.activationDate ?? moment().add(dayDelay, 'days').toDate()
  );

  useEffect(() => {
    if (!firstname) {
      return setIsValid(false);
    }
    if (!lastname) {
      return setIsValid(false);
    }
    if (!validate('email', emailAddress) || emailAddress !== confirmEmailAddress) {
      return setIsValid(false);
    }
    return setIsValid(true);
  }, [firstname, lastname, emailAddress, confirmEmailAddress]);

  const onConfirmDate = (params: { date: CalendarDate }) => {
    setShowDateModal(false);
    if (params.date) {
      setActivationDate(params.date);
    }
    if (props.plan.planType === 'prepaid') {
      setEndDate(
        moment(params.date)
          .add(Number(props.plan.planTypeD) - 1)
          .toDate()
      );
    }
  };

  const onDismissDate = () => {
    setShowDateModal(false);
  };

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          padding: 20,
          backgroundColor: Colors.white,
          flex: 1,
          height: '100%',
          marginBottom: 115,
        }}
        automaticallyAdjustKeyboardInsets={true}
      >
        <InputWithTitle title="First Name*" inputValue={firstname} onInputChange={setFirstname} />
        <InputWithTitle title="Last Name*" inputValue={lastname} onInputChange={setLastname} />
        <InputWithSelectOption
          title="Activation Date*"
          value={moment(activationDate).format(DATE_FORMAT)}
          icon={<Feather name="calendar" size={20} color={Colors.canadaButtonColor} />}
          onPress={() => {
            if (Platform.OS === 'android') {
              DateTimePickerAndroid.open({
                value: activationDate,
                onChange: (_: DateTimePickerEvent, selectedDate?: Date) => {
                  if (selectedDate) {
                    setActivationDate(selectedDate);
                    if (props.plan.planType === 'prepaid') {
                      setEndDate(
                        moment(selectedDate)
                          .add(Number(props.plan.planTypeD) - 1)
                          .toDate()
                      );
                    }
                  }
                },
                mode: 'date',
                is24Hour: true,
                minimumDate: moment().add(dayDelay, 'days').toDate(),
              });
            } else {
              setShowDateModal(true);
            }
          }}
        />
        <InputWithSelectOption
          title="Where will you be using our service?*"
          value={province.name ?? 'Select One*'}
          onPress={() => {
            setShowProvinceModel(true);
          }}
        />
        <InputWithTitle title="City*" inputValue={city} onInputChange={setCity} />
        <InputWithTitle
          title="Email Address*"
          inputValue={emailAddress}
          onInputChange={setEmailAddress}
          inputMode="email"
          keyboardType="email-address"
          editable={!loggedIn}
        />
        <InputWithTitle
          title="Confirm Email Address*"
          inputValue={confirmEmailAddress}
          onInputChange={setConfirmEmailAddress}
          inputMode="email"
          keyboardType="email-address"
          editable={!loggedIn}
        />
        <DatePickerModal
          validRange={{
            startDate: moment().add(dayDelay, 'days').toDate(),
          }}
          locale="en"
          mode="single"
          visible={showDateModal && Platform.OS !== 'android'}
          date={activationDate}
          onConfirm={onConfirmDate}
          onDismiss={onDismissDate}
        />
      </ScrollView>
      <AppDrawer
        onClose={() => {
          setShowProvinceModel(false);
        }}
        height={350}
        visible={showProvinceModel}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24, textAlign: 'center', marginBottom: 32 }}>
          Select Province
        </CustomText>
        <ScrollView showsVerticalScrollIndicator={false}>
          {provinces.map((p) => (
            <CustomText
              onPress={() => {
                setProvince({ code: p.code, name: p.name });
                setShowProvinceModel(false);
              }}
              style={{
                fontSize: 15,
                textAlign: 'center',
                marginBottom: 8,
              }}
              key={p.key}
            >
              {p.name}
            </CustomText>
          ))}
        </ScrollView>
      </AppDrawer>

      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
            backgroundColor: Colors.white,
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        {emailAddress !== '' && emailAddress !== confirmEmailAddress ? (
          <CustomText
            weight="500"
            style={{
              color: Colors.errorRed,
              fontSize: 12,
              textAlign: 'center',
              marginTop: 12,
            }}
          >
            Email does not match
          </CustomText>
        ) : null}
        <Button
          style={{ marginTop: 12 }}
          disabled={!isValid}
          onPress={() => {
            props.onNext({
              firstname,
              lastname,
              activationDate,
              endDate,
              emailAddress,
              province,
              city,
            });
          }}
          textStyle={{ fontSize: 14 }}
        >
          Continue
        </Button>
      </View>
    </SafeAreaView>
  );
}
