import { useState } from 'react';
import { Image, Pressable, SafeAreaView, ScrollView, View } from 'react-native';

import ExpressShippingDark from '../../assets/images/express-shipping-dark.png';
import ExpressShippingLight from '../../assets/images/express-shipping-light.png';
import RegularShippingDark from '../../assets/images/regular-shipping-dark.png';
import RegularShippingLight from '../../assets/images/regular-shipping-light.png';
import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { ShippingOption } from '../../types/models/ShippingOption';
import { isWebsite } from '../../utilities/functions';

type Props = {
  shippingOption: ShippingOption[];
  selectedShippingOption: ShippingOption | null;
  onNext(shippingOption: ShippingOption): void;
  shippingCountry: string;
};

const shippingImage = {
  express: {
    light: ExpressShippingLight,
    dark: ExpressShippingDark,
  },
  regular: {
    light: RegularShippingLight,
    dark: RegularShippingDark,
  },
};

export default function ShippingOptionsView(props: Props) {
  const [shippingOption, setShippingOption] = useState<ShippingOption | null>(
    props.selectedShippingOption ?? props.shippingOption[0]
  );

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={[
          {
            padding: 20,
            backgroundColor: Colors.white,
            flex: 1,
            height: '100%',
            marginBottom: 100,
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <CustomText weight="500" style={{ fontSize: 14, marginBottom: 16 }}>
          Select your shipping option!
        </CustomText>
        <Pressable
          onPress={() => {
            const selectedShippingOption = props.shippingOption.find(
              (s) => s.regularOrExpress.toLowerCase() === 'express'
            );
            setShippingOption(selectedShippingOption ?? null);
          }}
        >
          <Image
            source={
              shippingOption?.regularOrExpress.toLowerCase() === 'express'
                ? shippingImage.express.light
                : shippingImage.express.dark
            }
            style={{
              width: '100%',
              height: 160,
              marginBottom: 16,
            }}
            resizeMode="contain"
          />
        </Pressable>
        <Pressable
          onPress={() => {
            const selectedShippingOption = props.shippingOption.find(
              (s) => s.regularOrExpress.toLowerCase() === 'regular'
            );
            setShippingOption(selectedShippingOption ?? null);
          }}
        >
          <Image
            source={
              shippingOption?.regularOrExpress.toLowerCase() === 'regular'
                ? shippingImage.regular.light
                : shippingImage.regular.dark
            }
            style={{
              width: '100%',
              height: 160,
              marginBottom: 32,
            }}
            resizeMode="contain"
          />
        </Pressable>
        {props.shippingOption.map((shipping) => (
          <View
            key={shipping.sfID}
            style={{
              display:
                shippingOption?.regularOrExpress.toLowerCase() ===
                shipping.regularOrExpress.toLowerCase()
                  ? 'flex'
                  : 'none',
            }}
          >
            <CustomText weight="600" style={{ fontSize: 16, marginBottom: 8 }}>
              {shipping.sfName}
            </CustomText>
            <CustomText style={{ fontSize: 14, marginLeft: 4 }}>
              &bull;{' '}
              {shipping.sfFee === 0
                ? 'Free Shipping'
                : `Shipping Fee: $${shipping.sfFee.toFixed(2)} + tax`}
            </CustomText>
            <CustomText style={{ fontSize: 14, marginLeft: 4 }}>
              &bull; {shipping.sfName} - {shipping.deliEarliest} ~ {shipping.deliLongest} Business
              Days
            </CustomText>
          </View>
        ))}
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={() => {
            props.onNext(shippingOption!);
          }}
          disabled={!shippingOption}
          textStyle={{ fontSize: 14 }}
        >
          Continue
        </Button>
      </View>
    </SafeAreaView>
  );
}
