import { Image, Linking } from 'react-native';

import Button from './Button';
import FlagCanadaImage from '../assets/images/flag-canada.png';
import FlagUsImage from '../assets/images/flag-us.png';
import { Colors } from '../constants/Colors';

const CountryToggle = () => {
  return (
    <Button
      isChildNonText={true}
      onPress={() => Linking.openURL('https://us.myaccount.gophonebox.com/')}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.countryToggleBackgroundColor,
        borderRadius: 10,
        padding: 10,
        width: 90,
        position: 'relative',
        marginHorizontal: 0,
      }}
    >
      <Image
        source={FlagCanadaImage}
        style={{
          width: 30,
          height: 20,
          borderRadius: 2,
        }}
        resizeMode="cover"
      />
      <Image
        source={FlagUsImage}
        style={{
          width: 30,
          height: 20,
          borderRadius: 2,
          marginLeft: 10,
        }}
        resizeMode="cover"
      />
    </Button>
  );
};

export default CountryToggle;
