import { ReactNode } from 'react';
import { View, ScrollView, Dimensions } from 'react-native';

import { isWebsite } from '../../utilities/functions';

interface AppContainerProps {
  children: ReactNode;
  minHeight?: number;
}

export default function ScrollViewWithSafeAreaContainer(props: AppContainerProps) {
  const { width } = Dimensions.get('window');

  return (
    <ScrollView
      style={{ width: width, height: '100%', flex: 1 }}
      contentContainerStyle={
        isWebsite()
          ? {
              flex: 1,
            }
          : {}
      }
    >
      <View style={{ minHeight: props.minHeight ?? 812, flex: 1 }}>{props.children}</View>
    </ScrollView>
  );
}
