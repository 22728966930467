import { useEffect, useState } from 'react';
import { SafeAreaView } from 'react-native';

import CancelPlanView from './CancelPlanView';
import InitialView from './InitialView';
import PortInDetailView from './PortInDetailView';
import PortInDoneView from './PortInDoneView';
import PortOutConfirmationView from './PortOutConfirmationView';
import PortOutDetailView from './PortOutDetailView';
import PortOutDoneView from './PortOutDoneView';
import PortOutPlanView from './PortOutPlanView';
import { RootStackScreenProps } from '../../types';
import { PortCarrier } from '../../types/types';
import DevLog from '../../utilities/debug-error';

enum FlowStep {
  Initial = 'initial',
  CancelPlan = 'cancel-plan',
  PortOutDetails = 'port-out-details',
  PortOutPlans = 'port-out-plans',
  PortOutConfirmation = 'port-out-confirmation',
  PortOutDone = 'port-out-done',
  PortInDetails = 'port-in-details',
  PortInDone = 'port-in-done',
}

export type PortOutData = {
  carrier: PortCarrier;
  portOutReason: number;
  portOutReasonDesc: string;
  otpUserId: string;
};

const PortOptionScreen = ({ navigation }: RootStackScreenProps<'PortOption'>) => {
  const [step, setStep] = useState<FlowStep>(FlowStep.Initial);
  const [portOutData, setPortOutData] = useState<PortOutData>();

  useEffect(() => {
    switch (step) {
      case FlowStep.CancelPlan:
        navigation.setOptions({
          headerTitle: 'Cancel Plan',
        });
        break;
      case FlowStep.PortOutPlans:
      case FlowStep.PortOutDetails:
      case FlowStep.PortOutConfirmation:
      case FlowStep.PortOutDone:
        navigation.setOptions({
          headerTitle: 'Port Out',
        });
        break;
      case FlowStep.PortInDetails:
      case FlowStep.PortInDone:
        navigation.setOptions({
          headerTitle: 'Port In',
        });
        break;
      case FlowStep.Initial:
      default:
        navigation.setOptions({
          headerTitle: 'Porting',
        });
    }
  }, []);

  const getView = () => {
    switch (step) {
      case FlowStep.Initial:
        return (
          <InitialView
            onPress={(option) => {
              setStep(option === 'PORT_IN' ? FlowStep.PortInDetails : FlowStep.CancelPlan);
            }}
          />
        );
      case FlowStep.CancelPlan:
        return (
          <CancelPlanView
            onPress={() => {
              setStep(FlowStep.PortOutPlans);
            }}
          />
        );
      case FlowStep.PortOutPlans:
        return (
          <PortOutPlanView
            onPress={(option) => {
              if (option === 'CONTINUE') {
                setStep(FlowStep.PortOutDetails);
              } else {
                DevLog.log(option);
              }
            }}
          />
        );
      case FlowStep.PortOutDetails:
        return (
          <PortOutDetailView
            onPress={async (data) => {
              setPortOutData(data);
              setStep(FlowStep.PortOutConfirmation);
            }}
          />
        );
      case FlowStep.PortOutConfirmation:
        return (
          <PortOutConfirmationView
            portOutData={portOutData!}
            onPress={(option) => {
              if (option === 'CANCEL') {
                setStep(FlowStep.Initial);
                navigation.goBack();
              } else {
                setStep(FlowStep.PortOutDone);
              }
            }}
          />
        );
      case FlowStep.PortOutDone:
        return (
          <PortOutDoneView
            onPress={(option) => {
              setStep(FlowStep.Initial);
              if (option === 'PLANS') {
                navigation.navigate('Plans');
              } else {
                navigation.goBack();
              }
            }}
          />
        );
      case FlowStep.PortInDetails:
        return (
          <PortInDetailView
            onPress={() => {
              setStep(FlowStep.PortInDone);
            }}
          />
        );
      case FlowStep.PortInDone:
        return (
          <PortInDoneView
            onPress={(option) => {
              setStep(FlowStep.Initial);
              if (option === 'HOME') {
                navigation.goBack();
              } else {
                navigation.navigate('StatusTracking');
              }
            }}
          />
        );
    }
  };

  return <SafeAreaView style={{ flex: 1, height: '100%' }}>{getView()}</SafeAreaView>;
};

export default PortOptionScreen;
