import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { useState } from 'react';
import { Dimensions, Image, Linking, Pressable, ScrollView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import CanadaPlansBanner from '../../assets/images/canada-plans.png';
import USAPlansBanner from '../../assets/images/usa-plans.png';
import LogoImage from '../../assets/logo.png';
import { AppDrawer } from '../../components/AppDrawer';
import { AppGap } from '../../components/AppGap';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import Offering from '../../components/Section/Offering';
import DataUsage from '../../components/UI/DataUsage';
import InfoMessage from '../../components/UI/InfoMessage';
import PaymentError from '../../components/UI/PaymentError';
import Plan from '../../components/UI/Plans/Plan';
import PlanDetails from '../../components/UI/Plans/PlanDetails';
import QuickActionDesktop from '../../components/UI/QuickActionDesktop';
import SectionHeading from '../../components/UI/SectionHeading';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT } from '../../constants/constants';
import { accountService } from '../../services/MyAccount/AccountService';
import { RootState } from '../../store';
import { setDrawerVisible, setIsLoading } from '../../store/app';
import { setAccountInfoCA, setAccountProfileCA } from '../../store/selfServeCA';
import { setActiveUser } from '../../store/user';
import { MultiLineAccountInfo } from '../../types/models/MultiLineAccountInfo';
import DevLog from '../../utilities/debug-error';
import { formatNumber, formatPhoneNumber, getProfileImage } from '../../utilities/utilities';

const { width } = Dimensions.get('window');

const HomePageDesktopView = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [showMultiLineSwitch, setShowMultiLineSwitch] = useState<boolean>(false);

  const { activeUserFirstName, activeUserLastName, accounts, activeCnum, isMultiLineAccount } =
    useSelector((state: RootState) => state.user);

  const { accountProfileCA, accountInfoCA } = useSelector((state: RootState) => state.selfServeCA);
  const { PhoneNumber } = useSelector((state: RootState) => state.selfServeCA.accountProfileCA);
  const {
    Prepaid: isPrepaid,
    CurrentBalance,
    EndDate,
    CurrentPlan_pfId,
  } = useSelector((state: RootState) => state.selfServeCA.accountInfoCA);
  const [paymentDollar, paymentCent]: string[] = CurrentBalance.toString().split('.');

  const selectedAccount = isMultiLineAccount
    ? (accounts as MultiLineAccountInfo[]).find((a) => a.Cnum === activeCnum)
    : null;

  const _currentDate = moment();
  const _resetDateData = moment();
  if (_currentDate.date() !== 1) {
    _resetDateData.add(1, 'months');
  }

  const _resetDate = isPrepaid
    ? moment(EndDate).format(DATE_FORMAT)
    : _resetDateData.format('MMM [02], YYYY');
  const remainingDays = isPrepaid ? moment(EndDate).diff(moment(), 'days') + 1 : 30;
  let _paymentError: false | '< 3 Days Left' | 'Due Today' | 'Past Due Date' | 'Pay Before 9' =
    false;

  const _paymentString = { paymentDate: '', paymentInfo: 'Pay Before: ' };

  if (isPrepaid) {
    _paymentString.paymentInfo = 'Expires on:';
    _paymentString.paymentDate = _resetDate;
  } else {
    if (CurrentBalance) {
      if (_currentDate.date() > 14 && _currentDate.date() < 26) {
        _paymentString.paymentDate = moment().format('MMM [25], YYYY');
        if (_currentDate.date() + 2 >= 25) {
          _paymentError = '< 3 Days Left';
        }
        if (_currentDate.date() === 25) {
          _paymentError = 'Due Today';
        }
      } else if (
        _currentDate.date() > 25 &&
        (_currentDate.date() <= 31 || _currentDate.date() === 1)
      ) {
        _paymentError = 'Past Due Date';
        _paymentString.paymentDate = moment().add(1, 'months').format('MMM [01], YYYY');
        if (_currentDate.date() === 1) {
          _paymentString.paymentDate = moment().format('MMM [01], YYYY');
        }
      } else {
        _paymentError = 'Pay Before 9';
        _paymentString.paymentInfo = 'Account Suspended!';
      }
    } else {
      _paymentString.paymentInfo = '';
    }
  }

  let sideBGColor = Colors.white;
  if (accountProfileCA.Suspended && accountProfileCA.SuspensionReason) {
    if (accountProfileCA.SuspensionReason === 'VC') {
      sideBGColor = Colors.gradient.vacation.start;
    } else if (accountProfileCA.SuspensionReason === 'UP') {
      sideBGColor = Colors.gradient.unpaid.start;
    } else if (accountProfileCA.SuspensionReason === 'LS') {
      sideBGColor = Colors.gradient.lostStolen.start;
    }
  }

  if (
    accountProfileCA.TerminationDate &&
    accountInfoCA.Prepaid &&
    moment(accountInfoCA.EndDate).diff(moment(), 'days') + 1 < 0
  ) {
    sideBGColor = Colors.gradient.terminated.start;
  }

  // useEffect(() => {
  //   const unsubscribeBlur = navigation.addListener('blur', () => {
  //     dispatch(setDrawerVisible(false));
  //   });
  //   const unsubscribeFocus = navigation.addListener('focus', () => {
  //     dispatch(setDrawerVisible(true));
  //   });

  //   // Cleanup the listener on unmount
  //   return () => {
  //     unsubscribeBlur();
  //     unsubscribeFocus();
  //   };
  // });

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: Colors.white,
          paddingVertical: 14,
          shadowColor: Colors.black,
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.3,
          shadowRadius: 4,
          zIndex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Image
          source={LogoImage}
          resizeMode="contain"
          style={{
            width: 230,
            height: 40,
          }}
        />
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {/* <Pressable style={{ position: 'relative' }}>
            <View
              style={{
                position: 'absolute',
                width: 8,
                height: 8,
                backgroundColor: Colors.red,
                borderRadius: 4,
                right: 2,
                top: 2,
                zIndex: 1,
              }}
            ></View>
            <MaterialCommunityIcons name="bell-outline" color={Colors.greyColor} size={25} />
          </Pressable>
          <VerticalLine style={{ marginHorizontal: 20 }} height={24} /> */}
          <View
            style={{
              justifyContent: 'center',
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: 24,
            }}
          >
            <Image
              source={getProfileImage(-1)}
              style={{ width: 40, height: 40 }}
              resizeMode="cover"
            />
            <View style={{ marginHorizontal: 8 }}>
              <CustomText
                style={{ fontSize: 14, fontWeight: '400', color: Colors.TextBlack, maxWidth: 200 }}
              >
                {selectedAccount ? selectedAccount.FirstName : activeUserFirstName}{' '}
                {selectedAccount ? selectedAccount.LastName : activeUserLastName}
              </CustomText>
              <AppGap direction="vertical" size={4} />
              <CustomText
                weight="500"
                style={{ fontSize: 14, fontWeight: '500', color: Colors.grey }}
              >
                {formatPhoneNumber(
                  isMultiLineAccount && selectedAccount && typeof selectedAccount !== 'string'
                    ? selectedAccount!.Pnum
                    : accountProfileCA.PhoneNumber
                )}
              </CustomText>
            </View>
            {isMultiLineAccount ? (
              <Pressable
                onPress={() => {
                  setShowMultiLineSwitch(true);
                  dispatch(setDrawerVisible(true));
                }}
              >
                <MaterialCommunityIcons name="chevron-down" size={28} color={Colors.grey} />
              </Pressable>
            ) : null}
          </View>
        </View>
      </View>
      <ScrollView
        style={{ backgroundColor: Colors.desktop.background }}
        contentContainerStyle={{ flexDirection: 'row', width: '100%' }}
      >
        <View
          style={{
            maxWidth: 330,
            width: 330,
            marginHorizontal: 24,
            marginVertical: 32,
            backgroundColor: sideBGColor,
            borderRadius: 16,
          }}
        >
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 30,
              paddingHorizontal: 20,
            }}
          >
            <Image
              source={getProfileImage(-1)}
              style={{ width: 100, height: 100 }}
              resizeMode="cover"
            />
            <CustomText weight="500" style={{ fontSize: 24, textAlign: 'center', marginTop: 16 }}>
              {selectedAccount ? selectedAccount.FirstName : activeUserFirstName}{' '}
              {selectedAccount ? selectedAccount.LastName : activeUserLastName}
            </CustomText>
            <CustomText style={{ fontSize: 20, textAlign: 'center' }}>
              {formatPhoneNumber(PhoneNumber)}
            </CustomText>
          </View>

          <View style={{ padding: 10, alignItems: 'center' }}>
            {/* Payment Error */}
            {_paymentError ? (
              <PaymentError currentDate={_currentDate} paymentError={_paymentError} />
            ) : null}

            {/* Vacation Info  */}
            {accountProfileCA.Suspended && accountProfileCA.SuspensionReason === 'VC' ? (
              <View style={{ alignItems: 'center' }}>
                <InfoMessage
                  normalText="Your account is currently on the"
                  boldText="Vacation Plan."
                />
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <CustomText
                    weight="500"
                    style={{
                      fontSize: 12,
                      fontWeight: '500',
                    }}
                  >
                    <CustomText style={{ color: Colors.inputTextPlaceholderColor }}>
                      From:{' '}
                    </CustomText>
                    {moment(accountProfileCA.SuspensionDate).format(DATE_FORMAT)}
                  </CustomText>
                  <CustomText
                    weight="500"
                    style={{
                      fontSize: 12,
                      fontWeight: '500',
                    }}
                  >
                    <CustomText style={{ color: Colors.inputTextPlaceholderColor, marginLeft: 10 }}>
                      Resume on:{' '}
                    </CustomText>
                    {moment(accountProfileCA.VacationEndDate).format(DATE_FORMAT)}
                  </CustomText>
                </View>
              </View>
            ) : null}

            {/* Lost and Stolen Info  */}
            {accountProfileCA.Suspended && accountProfileCA.SuspensionReason === 'LS' ? (
              <>
                <InfoMessage normalText="Suspended for" boldText="Lost/Stolen." />
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <CustomText
                    weight="500"
                    style={{
                      fontSize: 12,
                      fontWeight: '500',
                    }}
                  >
                    <CustomText style={{ color: Colors.inputTextPlaceholderColor }}>
                      From:{' '}
                    </CustomText>
                    {moment(accountProfileCA.SuspensionDate).format(DATE_FORMAT)}
                  </CustomText>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: 10,
                    gap: 10,
                  }}
                >
                  <Button
                    textStyle={{ fontSize: 12 }}
                    color={Colors.backgroundGrey}
                    textColor={Colors.greyColor}
                    style={{ paddingVertical: 8, width: 142 }}
                    onPress={() => {
                      DevLog.log('Press Pause Data');
                    }}
                  >
                    Request new SIM
                  </Button>
                  <Button
                    textStyle={{ fontSize: 12 }}
                    style={{ paddingVertical: 8, width: 142 }}
                    onPress={() => {
                      DevLog.log('Press Add more data');
                    }}
                  >
                    Resume My Plan
                  </Button>
                </View>
              </>
            ) : null}

            {/* Unpaid Info  */}
            {accountProfileCA.Suspended && accountProfileCA.SuspensionReason === 'UP' ? (
              <>
                <InfoMessage normalText="Suspended for" boldText="unpaid." />
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: 4,
                    gap: 10,
                  }}
                >
                  <Button
                    textStyle={{ fontSize: 12 }}
                    color={Colors.backgroundGrey}
                    textColor={Colors.greyColor}
                    style={{ paddingVertical: 8, width: 142 }}
                    onPress={() => {
                      DevLog.log('Press Pause Data');
                    }}
                  >
                    Request new SIM
                  </Button>
                  <Button
                    textStyle={{ fontSize: 12 }}
                    style={{ paddingVertical: 8, width: 142 }}
                    onPress={() => {
                      DevLog.log('Press Add more data');
                    }}
                  >
                    Retrieve Number
                  </Button>
                </View>
              </>
            ) : null}
          </View>

          <HorizontalLine style={{ marginVertical: 32 }} />
          <View>
            <CustomText weight="500" style={{ fontSize: 14, paddingHorizontal: 20 }}>
              Quick Menu
            </CustomText>
            <QuickActionDesktop />
          </View>
          <HorizontalLine style={{ marginVertical: 32 }} />
          <View style={{ marginBottom: 20 }}>
            <CustomText weight="500" style={{ fontSize: 14, paddingHorizontal: 20 }}>
              Shop
            </CustomText>
            <Pressable
              style={{ marginTop: 14, paddingHorizontal: 20 }}
              onPress={() => {
                navigation.navigate('Plans');
              }}
            >
              <Image
                source={CanadaPlansBanner}
                style={{ width: '100%', height: 100, borderRadius: 8 }}
                resizeMode="cover"
              />
            </Pressable>
            <Pressable
              style={{ marginTop: 14, paddingHorizontal: 20 }}
              onPress={() => {
                Linking.openURL('http://gophonebox.com/plans');
              }}
            >
              <Image
                source={USAPlansBanner}
                style={{ width: '100%', height: 100, borderRadius: 8 }}
                resizeMode="cover"
              />
            </Pressable>
          </View>
        </View>
        <View style={{ flex: 1, marginTop: 50, marginRight: 25, marginLeft: 1 }}>
          <View>
            <CustomText
              weight="500"
              style={{ fontSize: 48, textAlign: 'center', color: Colors.canadaButtonColor }}
            >
              Welcome, {selectedAccount ? selectedAccount.FirstName : activeUserFirstName}
            </CustomText>
            <CustomText
              weight="500"
              style={{ fontSize: 24, textAlign: 'center', color: Colors.TextBlack }}
            >
              How can we help you today?
            </CustomText>
          </View>

          <View>
            <SectionHeading style={{ marginLeft: 0 }}>Best Offering</SectionHeading>
            <Offering />
          </View>
          <View style={{ gap: 24, marginVertical: 34, flexDirection: 'row', flexWrap: 'wrap' }}>
            <View
              style={{
                backgroundColor: Colors.white,
                borderRadius: 8,
                padding: 24,
                minWidth: 500,
                width: (width - 500) / 2,
              }}
            >
              <SectionHeading>Plan Details</SectionHeading>
              <PlanDetails />
            </View>
            <View
              style={{
                backgroundColor: Colors.white,
                borderRadius: 8,
                padding: 24,
                minWidth: 500,
                width: (width - 500) / 2,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginHorizontal: 20,
                  marginBottom: 16,
                }}
              >
                <SectionHeading>My Plans</SectionHeading>
                <Pressable
                  style={{}}
                  onPress={() => {
                    navigation.navigate('Plans');
                  }}
                >
                  <CustomText
                    weight="600"
                    style={{ color: Colors.ActionLinkColor, fontWeight: '600', fontSize: 12 }}
                  >
                    See All Plans
                  </CustomText>
                </Pressable>
              </View>
              <ScrollView
                contentContainerStyle={{ marginRight: 15 }}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                scrollEventThrottle={16}
                pagingEnabled={true}
              >
                <Plan />
                {/* Not Considering Terminated plans as its not part of the system yet */}
                {/* <Plan status="Terminated" /> */}
              </ScrollView>
            </View>

            {/* <View
              style={{
                backgroundColor: Colors.white,
                borderRadius: 8,
                padding: 24,
                minWidth: 500,
                width: (width - 500) / 2,
              }}
            >
              <SectionHeading>Plan Details (Home Internet)</SectionHeading>
              <HomeInternetPlanDetail />
            </View> */}

            {/* Data Usage for Staatus = VC, LS */}
            {/* {accountProfileCA.Suspended && */}
            {/* // ['VC', 'LS', 'UP'].includes(accountProfileCA.SuspensionReason!) ? ( */}
            <View
              style={{
                backgroundColor: Colors.white,
                borderRadius: 8,
                padding: 24,
                minWidth: 500,
                width: (width - 500) / 2,
              }}
            >
              <SectionHeading>Data Usage</SectionHeading>
              <View
                style={{
                  borderColor: Colors.HorizonalLineDefaultColor,
                  borderWidth: 1,
                  marginHorizontal: 20,
                  borderRadius: 8,
                  padding: 16,
                }}
              >
                {/* Data Usage */}
                <DataUsage />
              </View>
            </View>
            {/* // ) : null} */}
            <View
              style={{
                backgroundColor: Colors.white,
                borderRadius: 8,
                padding: 24,
                minWidth: 500,
                width: (width - 500) / 2,
              }}
            >
              <CustomText
                weight="600"
                style={{
                  fontWeight: '600',
                  fontSize: 10,
                  color: Colors.inputTextPlaceholderColor,
                  marginBottom: 8,
                }}
              >
                {isPrepaid ? 'Prepaid Plan' : 'Account Balance'}
              </CustomText>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {accountInfoCA.Prepaid && remainingDays < 0 ? (
                  <CustomText
                    weight="600"
                    style={{
                      fontSize: 12,
                      color: Colors.inputTextPlaceholderColor,
                    }}
                  >
                    Terminted
                    <CustomText style={{ color: Colors.errorRed, fontSize: 14, fontWeight: '600' }}>
                      {' '}
                      {Math.abs(remainingDays)}{' '}
                    </CustomText>
                    Days ago
                  </CustomText>
                ) : (
                  <CustomText
                    weight="600"
                    style={{
                      fontSize: 24,
                      fontWeight: '600',
                      color: isPrepaid && remainingDays < 3 ? Colors.errorRed : Colors.TextBlack,
                    }}
                  >
                    {isPrepaid ? remainingDays : `$${formatNumber(paymentDollar)}`}
                    <CustomText
                      style={[
                        { color: Colors.inputTextPlaceholderColor },
                        isPrepaid ? { fontSize: 15 } : {},
                      ]}
                    >
                      {isPrepaid ? ' Days left' : `.${paymentCent?.padEnd(2, '0') ?? '00'}`}
                    </CustomText>
                  </CustomText>
                )}
                <Button
                  isChildNonText
                  style={{
                    paddingVertical: 8,
                    paddingHorizontal: 12,
                    width: 160,
                  }}
                  color={
                    isPrepaid && remainingDays < 3
                      ? Colors.errorRed
                      : !isPrepaid && _paymentError
                        ? Colors.errorRed
                        : undefined
                  }
                  onPress={
                    isPrepaid
                      ? () => {
                          navigation.navigate('PrepaidPlanCheckout', {
                            update: 'extension',
                            planId: CurrentPlan_pfId,
                          });
                        }
                      : () => {
                          navigation.navigate('BillingPayBalance');
                        }
                  }
                >
                  <MaterialCommunityIcons name="plus" size={20} color={Colors.white} />
                  <CustomText
                    weight="600"
                    style={{ color: Colors.white, marginLeft: 2, fontWeight: '600' }}
                  >
                    {isPrepaid ? 'Extension' : 'Pay a Bill'}
                  </CustomText>
                </Button>
              </View>
              {_paymentString.paymentInfo ? (
                <CustomText
                  weight="500"
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                    fontWeight: '500',
                  }}
                >
                  <CustomText style={{ color: Colors.inputTextPlaceholderColor }}>
                    {_paymentString.paymentInfo}{' '}
                  </CustomText>
                  {_paymentString.paymentDate}
                </CustomText>
              ) : null}
            </View>
          </View>
        </View>
      </ScrollView>
      <AppDrawer
        visible={showMultiLineSwitch}
        height={385}
        onClose={() => {
          setShowMultiLineSwitch(false);
          dispatch(setDrawerVisible(false));
        }}
        showBackDrop
      >
        <CustomText weight="500" style={{ fontSize: 24, marginBottom: 4 }}>
          Select the Account
        </CustomText>
        <CustomText style={{ fontSize: 10, marginBottom: 16 }}>Tap to Switch</CustomText>

        <CustomText
          weight="500"
          style={{
            fontSize: 12,
            marginBottom: 16,
            paddingVertical: 10,
            paddingHorizontal: 20,
            backgroundColor: Colors.SelectLineTitleBackgroundColor,
          }}
        >
          Accounts
        </CustomText>

        <View>
          {(accounts as MultiLineAccountInfo[]).map((account, index) => (
            <View key={`${account.Cnum}-${index}`}>
              <Pressable
                style={{
                  borderRadius: 16,
                  padding: 10,
                  backgroundColor:
                    account.Cnum === selectedAccount?.Cnum
                      ? Colors.SelectLineItemHighlightColor
                      : Colors.white,
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 16,
                }}
                onPress={
                  account.Cnum === selectedAccount?.Cnum
                    ? () => {}
                    : async () => {
                        try {
                          dispatch(setIsLoading(true));
                          dispatch(
                            setActiveUser({
                              user: {
                                lastName: account.LastName,
                                firstName: account.FirstName,
                                cnum: account.Cnum,
                              },
                            })
                          );
                          navigation.navigate('Loading');
                          const accountInfoResponse = await accountService.getAccountInfo({});
                          const profileResponse = await accountService.getProfile({});
                          dispatch(setAccountInfoCA(accountInfoResponse.data));
                          dispatch(setAccountProfileCA(profileResponse.data));
                          navigation.navigate('Root');
                        } catch (_error) {
                          navigation.navigate('LogIn');
                        } finally {
                          dispatch(setIsLoading(false));
                          setShowMultiLineSwitch(false);
                          dispatch(setDrawerVisible(false));
                        }
                      }
                }
              >
                <Image
                  source={getProfileImage(Math.floor(Math.random() * 8))}
                  style={{ width: 40, height: 40 }}
                  resizeMode="cover"
                />
                <View style={{ flex: 1 }}>
                  <CustomText weight="500" style={{ fontSize: 16, color: Colors.TextBlack }}>
                    {account.FirstName} {account.LastName}
                  </CustomText>
                  <CustomText weight="300" style={{ fontSize: 12, color: Colors.grey }}>
                    {formatPhoneNumber(account.Pnum)}
                  </CustomText>
                </View>
                {account.Cnum === selectedAccount?.Cnum ? (
                  <CustomText style={{ fontSize: 14, color: Colors.canadaButtonColor }}>
                    Selected
                  </CustomText>
                ) : null}
              </Pressable>
              {index < accounts.length - 1 ? (
                <HorizontalLine thinkness={1} style={{ marginVertical: 6 }} />
              ) : null}
            </View>
          ))}
        </View>
        <Button
          style={{ marginTop: 10, marginHorizontal: 'auto' }}
          color={Colors.backgroundGrey}
          textColor={Colors.TextBlack}
          onPress={() => {
            setShowMultiLineSwitch(false);
            dispatch(setDrawerVisible(false));
          }}
        >
          <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
            Close
          </CustomText>
        </Button>
      </AppDrawer>
    </>
  );
};

export default HomePageDesktopView;
