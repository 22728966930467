export enum StepType {
  First = 1,
  Second = 2,
  Third = 3,
  Success = 4,
  Error = 5,
  Loading = 6,
}

export namespace StepType {
  export function after(value: StepType): StepType {
    return value + 1;
  }

  export function previous(value: StepType): StepType {
    return value - 1;
  }
}
