import { AntDesign } from '@expo/vector-icons';
import { Alert, TextInputProps, View, ViewStyle } from 'react-native';

import { Colors } from '../../../constants/Colors';
import { isWebsite } from '../../../utilities/functions';
import TextInput from '../../TextInput';
import CustomText from '../../utils/CustomText';

interface Props
  extends Omit<TextInputProps, 'secureTextEntry' | 'onChangeText' | 'value' | 'style'> {
  title: string;
  inputValue: string;
  onInputChange?: (text: string) => void;
  style?: ViewStyle;
  secureInput?: boolean;
  inputStyle?: ViewStyle;
  moreInfoIconText?: string;
}

const InputWithTitle = ({
  inputValue,
  onInputChange,
  title,
  style,
  secureInput,
  inputStyle,
  moreInfoIconText,
  ...props
}: Props) => {
  return (
    <View
      style={[
        {
          marginTop: 16,
          ...style,
        },
        isWebsite()
          ? {
              maxWidth: 400,
              marginHorizontal: 'auto',
              width: '100%',
              ...style,
            }
          : null,
      ]}
    >
      <View style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
        <CustomText style={{ fontSize: 12, color: Colors.greyColor }} weight="500">
          {title}
        </CustomText>
        {moreInfoIconText ? (
          <AntDesign
            name="questioncircle"
            size={18}
            color={Colors.ActionLinkColor}
            onPress={() => {
              if (isWebsite()) {
                alert(moreInfoIconText);
              } else {
                Alert.alert('Info:', moreInfoIconText);
              }
            }}
          />
        ) : null}
      </View>
      <TextInput
        placeholder={title}
        value={inputValue}
        secureTextEntry={secureInput}
        onChangeText={onInputChange ? onInputChange : () => {}}
        style={{ marginTop: 6, ...inputStyle }}
        {...props}
      />
    </View>
  );
};

export default InputWithTitle;
