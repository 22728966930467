import { Linking, View } from 'react-native';

import Button from '../Button';
import CustomText from '../utils/CustomText';

const TripPromo = () => {
  return (
    <View
      style={{
        gap: 8,
      }}
    >
      <CustomText style={{ fontSize: 16 }} weight="600">
        Big trip, small price—30 days for $15 in the US!
      </CustomText>
      <CustomText>Heading to the US? Our 30-day plans for $15 are just what you need!</CustomText>
      <CustomText style={{ marginLeft: 8 }}>&bull; Add international calls for $5</CustomText>
      <CustomText style={{ marginLeft: 8 }}>&bull; Top up more data for $10</CustomText>
      <CustomText>Enjoy affordable connectivity!</CustomText>
      <Button
        style={{ alignSelf: 'center', marginTop: 16 }}
        onPress={() => {
          Linking.openURL('https://gophonebox.com/plans/?plans=us');
        }}
      >
        Explore More
      </Button>
    </View>
  );
};

export default TripPromo;
