import { AntDesign } from '@expo/vector-icons';
import { Image, ScrollView, StyleSheet, View } from 'react-native';

import CheckmarkIconImage from '../../assets/icons/checkmark.png';
import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { isWebsite } from '../../utilities/functions';

const PortInDoneView = ({ onPress }: { onPress(option: string): void }) => {
  return (
    <View
      style={{
        position: 'relative',
        padding: 20,
        backgroundColor: Colors.white,
        flex: 1,
        height: '100%',
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginBottom: 150, paddingBottom: 20 }}
      >
        <CustomText
          weight="600"
          style={{ fontSize: 24, textAlign: 'center', color: Colors.canadaButtonColor }}
        >
          Port In Requested!
        </CustomText>
        <CustomText
          style={{ fontSize: 14, marginTop: 16, textAlign: 'center', color: Colors.TextBlack }}
        >
          You can track your progress in the activity tracking section.
        </CustomText>
        <CustomText
          style={{ fontSize: 14, marginTop: 16, textAlign: 'center', color: Colors.TextBlack }}
        >
          Once the process is complete, you can continue using your original number as before.
        </CustomText>
        <View
          style={[
            style.listItem,
            isWebsite()
              ? {
                  maxWidth: 800,
                  marginHorizontal: 'auto',
                  width: '100%',
                }
              : null,
          ]}
        >
          <View style={style.listItemIconContainer}>
            <AntDesign name="staro" size={16} color={Colors.ActionLinkColor} />
          </View>
          <View style={{ flex: 1 }}>
            <CustomText weight="500" style={style.listItemText}>
              Next Step
            </CustomText>
            <CustomText style={style.listItemText}>
              Please continue using your SIM card from your previous carrier and make sure to reply
              to the 90-minute SMS from your previous carrier within 90 minutes.
            </CustomText>
          </View>
        </View>
        <Image
          source={CheckmarkIconImage}
          style={{
            width: 150,
            height: 150,
            marginHorizontal: 'auto',
            marginTop: 20,
            alignSelf: isWebsite() ? undefined : 'center',
          }}
          resizeMode="contain"
        />
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={() => onPress('STATUS')}
          textStyle={{ fontSize: 14 }}
        >
          See The Status
        </Button>
        <Button
          style={{ marginTop: 10 }}
          color={Colors.backgroundGrey}
          textColor={Colors.TextBlack}
          onPress={() => onPress('HOME')}
        >
          <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
            Back To The Main Home
          </CustomText>
        </Button>
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  listItem: {
    borderColor: Colors.lightGrey,
    borderRadius: 8,
    borderWidth: 1,
    flexDirection: 'row',
    marginTop: 24,
    padding: 10,
  },
  listItemIconContainer: {
    alignItems: 'center',
    backgroundColor: Colors.ActionLinkColorBG,
    borderRadius: 10,
    display: 'flex',
    fontSize: 16,
    height: 26,
    justifyContent: 'center',
    marginRight: 10,
    width: 26,
  },
  listItemText: { fontSize: 14 },
});

export default PortInDoneView;
