import { View, ScrollView, Image } from 'react-native';

import CheckmarkImage from '../../assets/icons/checkmark.png';
import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { RootStackScreenProps } from '../../types';
import { isWebsite } from '../../utilities/functions';

export default function VacationHoldSuccessScreen({
  navigation,
}: RootStackScreenProps<'VacationHoldSuccess'>) {
  return (
    <View
      style={{
        position: 'relative',
        padding: 20,
        paddingTop: 32,
        backgroundColor: Colors.white,
        flex: 1,
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginBottom: 150, paddingBottom: 20 }}
      >
        <CustomText
          weight="600"
          style={{ fontSize: 24, textAlign: 'center', color: Colors.canadaButtonColor }}
        >
          Requested!
        </CustomText>
        <CustomText
          style={{ fontSize: 14, marginTop: 16, textAlign: 'center', color: Colors.TextBlack }}
        >
          Your vacation hold has been applied as requested. If you need to reactivate your line
          sooner, please contact our customer service team.
        </CustomText>
        <Image
          source={CheckmarkImage}
          style={{
            width: 220,
            height: 220,
            marginHorizontal: 'auto',
            marginTop: 100,
            alignSelf: 'center',
          }}
          resizeMode="contain"
        />
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            paddingVertical: 40,
            paddingHorizontal: 20,
            gap: 8,
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        {/* <Button style={{ marginTop: 32 }} onPress={() => {}} textStyle={{ fontSize: 14 }}>
          See The Hold History
        </Button> */}
        <Button
          color={Colors.backgroundGrey}
          textColor={Colors.greyColor}
          onPress={() => {
            navigation.navigate('Home');
          }}
        >
          Back To The Main Home
        </Button>
      </View>
    </View>
  );
}
