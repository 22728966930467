import { useState } from 'react';
import { ScrollView, View } from 'react-native';

import { AppDrawer } from '../../components/AppDrawer';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import InputWithSelectOption from '../../components/UI/Form/InputForSelectOption';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import InfoDescriptionToggle from '../../components/UI/InfoDescriptionToggle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { carriers, invalidPortCarriers } from '../../constants/constants';
import { PortCarrier } from '../../types/types';
import { isWebsite } from '../../utilities/functions';

const PortInDetailView = ({ onPress }: { onPress(): void }) => {
  const [showCarrierModel, setShowCarrierModel] = useState<boolean>(false);
  const [carrier, setCarrier] = useState<PortCarrier>();
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [imeiNumber, setImeiNumber] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  return (
    <View
      style={[
        {
          position: 'relative',
          padding: 20,
          backgroundColor: Colors.white,
          flex: 1,
          height: '100%',
        },
        isWebsite()
          ? {
              maxWidth: 400,
              marginHorizontal: 'auto',
              width: '100%',
            }
          : null,
      ]}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginBottom: 80, paddingBottom: 20 }}
      >
        <InputWithSelectOption
          value={carrier ?? 'Select the carrier*'}
          title="From which provider?*"
          onPress={() => setShowCarrierModel(true)}
        />
        <InputWithTitle
          inputValue={accountNumber}
          keyboardType="numeric"
          onInputChange={setAccountNumber}
          title={carrier === 'Freedom' ? 'Account Number' : 'Account Number*'}
        />
        <InputWithTitle
          inputValue={imeiNumber}
          keyboardType="numeric"
          onInputChange={setImeiNumber}
          title={carrier === 'Freedom' ? 'IMEI Number*' : 'IMEI Number'}
          moreInfoIconText="Please provide the IMEI (15 digits) to port in your number. Dial *#06# to get your phone's IMEI number or find it in your phone's settings."
        />
        <InputWithTitle
          inputValue={phoneNumber}
          maxLength={10}
          onInputChange={setPhoneNumber}
          keyboardType="numeric"
          title="Phone Number*"
        />

        <HorizontalLine
          thinkness={1}
          style={{
            marginVertical: 24,
          }}
        />

        <InfoDescriptionToggle
          title="What is Port in?"
          height={208}
          style={{
            marginHorizontal: 0,
            marginBottom: 16,
          }}
          desc="Porting in allows you to transfer your phone number from another provider to PhoneBox. To keep your current number, ensure your line remains active with your previous provider until the process is complete. You'll need your account number, IMEI, or PIN from your previous provider. Note: Only Canadian phone numbers are eligible."
        />

        <InfoDescriptionToggle
          title={`Why I don't See ${invalidPortCarriers.join(', ')} in the list of carriers?`}
          height={80}
          style={{
            marginHorizontal: 0,
            marginBottom: 16,
          }}
          desc="Due to certain restrictions, we are unable to process port requests from these carriers."
        />
      </ScrollView>

      <AppDrawer
        onClose={() => {
          setShowCarrierModel(false);
        }}
        height={500}
        visible={showCarrierModel}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24, textAlign: 'center', marginBottom: 32 }}>
          Select Carrier
        </CustomText>
        <ScrollView showsVerticalScrollIndicator={false}>
          {carriers
            .filter((c) => !invalidPortCarriers.includes(c))
            .map((carrier) => (
              <CustomText
                onPress={() => {
                  setCarrier(carrier);
                  setShowCarrierModel(false);
                }}
                style={{
                  fontSize: 15,
                  textAlign: 'center',
                  marginBottom: 8,
                }}
                key={carrier}
              >
                {carrier}
              </CustomText>
            ))}
        </ScrollView>
      </AppDrawer>

      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
        }}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={async () => {
            //  const response =
            onPress();
          }}
          textStyle={{ fontSize: 14 }}
          disabled={
            !carrier ||
            (carrier === 'Freedom' && !imeiNumber) ||
            (carrier !== 'Freedom' && !accountNumber) ||
            !phoneNumber
          }
        >
          Request Port in
        </Button>
      </View>
    </View>
  );
};

export default PortInDetailView;
