import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as AppleAuthentication from 'expo-apple-authentication';
import { makeRedirectUri } from 'expo-auth-session';
import * as Google from 'expo-auth-session/providers/google';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { View, Image, Platform } from 'react-native';
import { useDispatch } from 'react-redux';

import { AppGap } from '../components/AppGap';
import Button from '../components/Button';
import { Colors } from '../constants/Colors';
import { authService } from '../services/SSO/AuthService';
import { RootStackParamList } from '../types';
import { SystemCodes } from '../types/enums/SystemCodes';
import { SignInResponse } from '../types/responses/SSO/SignInResponse';
import DevLog from '../utilities/debug-error';
import { validateToken } from '../utilities/functions';
import CustomText from './utils/CustomText';
import GoogleGImage from '../assets/icons/google.png';

export type AppleGoogleProps = {
  setIsLoadingAPI(status: boolean): void;
  propsKey?: React.Key | null | undefined;
};

const AppleGoogleSSO = ({ setIsLoadingAPI, propsKey }: AppleGoogleProps) => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const dispatch = useDispatch();

  const [_response, response, promptAsync] = Google.useIdTokenAuthRequest({
    responseType: 'id_token',
    androidClientId: '327136114864-5jsbfh5qja6v2f4b19b376glcb0debjv.apps.googleusercontent.com',
    webClientId: '327136114864-71h2nrp5bt3v0307dh8ksivg8j3fkeun.apps.googleusercontent.com',
    iosClientId: '327136114864-an849icoaac2lm5i9429i041entmrsh2.apps.googleusercontent.com',
    redirectUri: Platform.select({
      // iOS handles redirectUri perfectly fine on it's own
      ios: undefined,
      // Due to Expo's bug, we need to manually encode the redirectUri
      // https://github.com/expo/expo/issues/12044
      android: makeRedirectUri({
        // intent filter set up in app.json
        // must be the same as "package name" in Google Cloud Console
        // native: `com.phoneboxexpo.selfserve2024://`,
        scheme: 'com.phoneboxexpo.selfserve2024',
      }),
    }),
  });

  const handleAppleLoginAsync = async () => {
    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [AppleAuthentication.AppleAuthenticationScope.EMAIL],
      });
      if (credential.authorizationCode) {
        setIsLoadingAPI(true);
        const signInAppleResponse = await authService.signInApple({
          data: {
            code: credential.authorizationCode,
            isMobile: Platform.OS !== 'web',
            systemCode: SystemCodes.CA_SELFSERVE,
          },
        });

        if (signInAppleResponse && signInAppleResponse.data && signInAppleResponse.data.success) {
          setTokensAndContentBasedOnSignInResponse(signInAppleResponse.data.data);
        }
      }
      DevLog.log('Success? : ', credential);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      DevLog.error('Apple Login Error: ', e);
      if (e.code === 'ERR_REQUEST_CANCELED') {
        // error code
      } else {
        // handle error
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAppleLoginWeb = async (event: any) => {
    DevLog.log('event loaded: ', event);
    if (event.detail?.authorization?.code) {
      setIsLoadingAPI(true);
      const signInAppleResponse = await authService.signInApple({
        data: {
          code: event.detail.authorization.code,
          isMobile: Platform.OS !== 'web',
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (signInAppleResponse && signInAppleResponse.data && signInAppleResponse.data.success) {
        setTokensAndContentBasedOnSignInResponse(signInAppleResponse.data.data);
      }
    }
  };

  const setTokensAndContentBasedOnSignInResponse = async (data: SignInResponse) => {
    await validateToken(data, dispatch, navigation);

    // dispatch(setIsLoading(true));
    // dispatch(setLoggedIn(true));
    setIsLoadingAPI(false);
  };

  useEffect(() => {
    const handleReceivedGoogleToken = async (idToken: string) => {
      setIsLoadingAPI(true);
      try {
        const signInGoogleResponse = await authService.signInGoogle({
          data: {
            token: idToken,
            systemCode: SystemCodes.CA_SELFSERVE,
          },
        });

        if (signInGoogleResponse?.data?.data && signInGoogleResponse.data.success) {
          DevLog.log('sign in response from phonebox id server: ', signInGoogleResponse.data.data);
          if (
            signInGoogleResponse.data.data.idToken &&
            signInGoogleResponse.data.data.idRefreshToken
          ) {
            setTokensAndContentBasedOnSignInResponse(signInGoogleResponse.data.data);
          }
        }
      } catch (error) {
        DevLog.error('Something went wrong with Auth/google_signin: ', error);
        setIsLoadingAPI(false);
      }
    };

    if (response?.type === 'success') {
      // for some reason, depending on the platform, "response" either has
      // "params" with the needed id_token or
      // "authentication" with the needed idToken.
      // functionally they are the same to our backend.

      if (response.params && response.params.id_token) {
        handleReceivedGoogleToken(response.params.id_token);
      } else if (response.authentication && response.authentication.idToken) {
        handleReceivedGoogleToken(response.authentication.idToken);
      }
    }
  }, [response]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      const script = document.createElement('script');
      script.src =
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
      script.onload = () => {
        DevLog.log('Script loaded');
        // @ts-expect-error: Property 'initAppleAuth' does not exist on type 'Window & typeof globalThis'.ts(2339)
        window.initAppleAuth = function () {
          // @ts-expect-error: Property 'AppleID' does not exist on type 'Window & typeof globalThis'.
          window.AppleID.auth.init({
            clientId: 'com.phoneboxexpo.newselfserve',
            scope: 'email',
            redirectURI: window.location.origin + '/login',
            nonce: new Date().getTime().toString(),
            usePopup: true,
          });
        };
        // Perform actions after the script is loaded, if needed
        // @ts-expect-error: Property 'initAppleAuth' does not exist on type 'Window & typeof globalThis'.
        window.initAppleAuth();
      };
      document.head.appendChild(script);
      document.addEventListener('AppleIDSignInOnSuccess', handleAppleLoginWeb);

      return () => {
        DevLog.log('Removing Everytinh');
        // Clean up by removing the script when the component unmounts
        document.head.removeChild(script);
        document.removeEventListener('AppleIDSignInOnSuccess', handleAppleLoginWeb);
      };
    }
  }, []);

  const onPressLoginWithGoogle = () => {
    promptAsync();
  };

  return (
    <>
      <Helmet key={`${propsKey}-helmet`}>
        <style type="text/css">{`
        #appleid-signin div {
            background-color: ${Colors.backgroundGrey};
            border-radius: 8px;
        }
    `}</style>
      </Helmet>
      <View
        key={`${propsKey}-view`}
        style={{
          marginTop: 15,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {Platform.OS === 'ios' && (
          <>
            <AppleAuthentication.AppleAuthenticationButton
              buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
              buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE_OUTLINE}
              cornerRadius={5}
              style={{ width: 310, height: 45 }}
              onPress={handleAppleLoginAsync}
            />
            <AppGap size={20} />
          </>
        )}
        {Platform.OS === 'web' && (
          <>
            <Button
              size="NORMAL"
              color={Colors.backgroundGrey}
              style={{ width: 48, height: 48, padding: 0 }}
              isChildNonText={true}
            >
              <div
                style={{ width: '207px', height: '55px' }}
                data-color="white"
                data-border="false"
                id="appleid-signin"
                data-mode="center-align"
                data-logo-size="large"
              ></div>
            </Button>
            <AppGap size={20} />
          </>
        )}
        <Button
          color={Colors.white}
          style={{
            width: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 10,
          }}
          onPress={onPressLoginWithGoogle}
        >
          <Image
            source={GoogleGImage}
            style={{
              width: 16,
              height: 16,
            }}
            resizeMode="contain"
          />
          <CustomText weight="500" style={{ fontSize: 16, marginLeft: 8 }}>
            Sign in with Google
          </CustomText>
        </Button>
      </View>
    </>
  );
};

export default AppleGoogleSSO;
