import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Pressable, ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';

import ChatImage from '../../assets/icons/action/chat.png';
import ProfileImage from '../../assets/icons/action/profile.png';
import ReferralImage from '../../assets/icons/action/referral.png';
import { AppGap } from '../../components/AppGap';
import AppGradientContainer from '../../components/AppGradientContainer';
import Button from '../../components/Button';
import { Action } from '../../components/QuickAction/QuickAction';
import QuickActions from '../../components/QuickAction/QuickActions';
import Offering from '../../components/Section/Offering';
import Donut from '../../components/UI/Donut';
import PageHeader from '../../components/UI/Header/PageHeader';
import ExploreProductHero from '../../components/UI/Hero/ExploreProductHero';
import NoPlan from '../../components/UI/Plans/NoPlan';
import SectionHeading from '../../components/UI/SectionHeading';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { RootState } from '../../store';
import DevLog from '../../utilities/debug-error';
import { crispChatAction } from '../../utilities/functions';

const noPlanQuickActions: Action[][] = [
  [
    {
      image: ProfileImage,
      title: 'Profile Change',
      tag: 'PROFILE_CHANGE',
    },
    {
      image: ChatImage,
      title: 'Support Chat',
      tag: 'SUPPORT_CHAT',
    },
    {
      image: ReferralImage,
      title: 'Friend Referral',
      tag: 'FRIEND_REFERRAL',
    },
  ],
];

export default function NoPlanView() {
  const { PhoneNumber } = useSelector((state: RootState) => state.selfServeCA.accountProfileCA);
  const { loggedIn, id, email, firstName, lastName } = useSelector(
    (state: RootState) => state.user
  );
  const navigation = useNavigation();
  return (
    <>
      <PageHeader />
      <ScrollView showsVerticalScrollIndicator={false}>
        <AppGradientContainer
          autoHeight
          colorLeft={Colors.gradientDarkBlue}
          colorRight={Colors.gradientLightBlue}
          direction="LEFT-RIGHT"
          style={{ paddingBottom: 15, paddingTop: 80 }}
        >
          <ExploreProductHero />
        </AppGradientContainer>
        <View
          style={{
            width: '100%',
            marginTop: -20,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            backgroundColor: Colors.white,
          }}
        >
          <QuickActions
            quickActions={noPlanQuickActions}
            onActionSelected={(action) => {
              switch (action) {
                case 'PROFILE_CHANGE':
                  navigation.navigate('Account');
                  break;
                case 'SUPPORT_CHAT':
                  crispChatAction(loggedIn, id, email, firstName, lastName, PhoneNumber);
                  break;
                case 'FRIEND_REFERRAL':
                  break;
                default:
                  break;
              }
              DevLog.log(action);
            }}
          />

          <View>
            <SectionHeading style={{ marginLeft: 15 }}>Best Offering</SectionHeading>
            <Offering />
          </View>

          <View style={{ marginTop: 32 }}>
            <SectionHeading style={{ marginLeft: 20 }}>Data Usage</SectionHeading>
            <View
              style={{
                borderColor: Colors.HorizonalLineDefaultColor,
                borderWidth: 1,
                marginHorizontal: 20,
                borderRadius: 8,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 16,
              }}
            >
              <Donut totalData={'No Plan'} unit="" usedData={'0.0'} />
              <View style={{ alignItems: 'center', width: 120 }}>
                <CustomText style={{ textAlign: 'center', marginBottom: 24 }}>
                  Explore Perfect plan for you!
                </CustomText>
                <Button
                  isChildNonText
                  size="NORMAL"
                  style={{ width: 110, paddingVertical: 8, paddingHorizontal: 12 }}
                >
                  <AntDesign name="shoppingcart" color={Colors.white} size={15} />
                  <CustomText
                    weight="600"
                    style={{ color: Colors.white, fontWeight: '600', fontSize: 12, marginLeft: 4 }}
                  >
                    Shop Now
                  </CustomText>
                </Button>
              </View>
            </View>
          </View>

          <View style={{ marginTop: 32 }}>
            <SectionHeading style={{ marginLeft: 20 }}>Account Balance</SectionHeading>
            <View
              style={{
                borderColor: Colors.HorizonalLineDefaultColor,
                borderWidth: 1,
                marginHorizontal: 20,
                borderRadius: 8,
                padding: 16,
              }}
            >
              <CustomText
                weight="600"
                style={{
                  fontSize: 10,
                  fontWeight: '600',
                  marginBottom: 12,
                  color: Colors.inputTextPlaceholderColor,
                }}
              >
                Account Balance
              </CustomText>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <CustomText
                  weight="600"
                  style={{
                    fontSize: 24,
                    fontWeight: '600',
                  }}
                >
                  $0.00
                </CustomText>
                <Button
                  isChildNonText
                  size="NORMAL"
                  style={{ width: 110, paddingVertical: 8, paddingHorizontal: 12 }}
                >
                  <MaterialCommunityIcons name="plus" color={Colors.white} size={15} />
                  <CustomText
                    weight="600"
                    style={{ color: Colors.white, fontWeight: '600', fontSize: 12, marginLeft: 4 }}
                  >
                    Pay a Bill
                  </CustomText>
                </Button>
              </View>
            </View>
          </View>

          <View style={{ marginTop: 32, marginBottom: 20 }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginHorizontal: 20,
                marginBottom: 16,
              }}
            >
              <SectionHeading>No Plans</SectionHeading>
              <Pressable
                style={{}}
                onPress={() => {
                  navigation.navigate('Plans');
                }}
              >
                <CustomText
                  weight="600"
                  style={{ color: Colors.ActionLinkColor, fontWeight: '600', fontSize: 12 }}
                >
                  See All Plans
                </CustomText>
              </Pressable>
            </View>

            <NoPlan />

            <AppGap direction="vertical" size={40} />
          </View>
        </View>
      </ScrollView>
    </>
  );
}
