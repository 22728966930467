import { ScrollView, StyleSheet, View } from 'react-native';

import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { isWebsite } from '../../utilities/functions';

const CancelPlanView = ({ onPress }: { onPress(): void }) => {
  return (
    <View
      style={{
        position: 'relative',
        padding: 20,
        backgroundColor: Colors.white,
        flex: 1,
        height: '100%',
      }}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <CustomText
          weight="600"
          style={{ fontSize: 24, textAlign: 'center', color: Colors.errorRed }}
        >
          Port the Number out
        </CustomText>
        <CustomText style={{ fontSize: 14, marginTop: 16, textAlign: 'center', marginBottom: 24 }}>
          Port-out is the process of transferring your existing phone number from PhoneBox to
          another provider.
        </CustomText>
        <View
          style={[
            { gap: 16 },
            isWebsite()
              ? {
                  maxWidth: 800,
                  marginHorizontal: 'auto',
                  width: '100%',
                }
              : null,
          ]}
        >
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>1</CustomText>
            <CustomText weight="500" style={style.listItemText}>
              Request Port out.
            </CustomText>
          </View>
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>2</CustomText>
            <CustomText weight="500" style={style.listItemText}>
              Data service will be paused.
            </CustomText>
          </View>
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>3</CustomText>
            <CustomText weight="500" style={style.listItemText}>
              Account Number will be provided via email within 2-3 business day.
            </CustomText>
          </View>
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>4</CustomText>
            <CustomText weight="500" style={style.listItemText}>
              Submit Port In with new service provider.{' '}
              <CustomText
                weight="400"
                style={{ fontSize: 10, color: Colors.errorRed, display: 'flex' }}
              >
                Please cancel the port-in request if you have already submitted it before receiving
                your account number.
              </CustomText>
            </CustomText>
          </View>
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>5</CustomText>
            <CustomText weight="500" style={style.listItemText}>
              Once port in is done, you need to request termination.
            </CustomText>
          </View>
        </View>
      </ScrollView>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={onPress}
          textStyle={{ fontSize: 14 }}
          color={Colors.errorRed}
        >
          Next
        </Button>
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  listItem: {
    alignItems: 'center',
    borderColor: Colors.lightGrey,
    borderRadius: 8,
    borderWidth: 1,
    flexDirection: 'row',
    padding: 10,
  },
  listItemNumber: {
    alignItems: 'center',
    backgroundColor: Colors.ActionLinkColorBG,
    borderRadius: 10,
    color: Colors.ActionLinkColor,
    display: 'flex',
    fontSize: 16,
    height: 26,
    justifyContent: 'center',
    marginRight: 20,
    overflow: 'hidden',
    textAlign: 'center',
    textAlignVertical: 'center',
    width: 26,
  },
  listItemText: { fontSize: 14, paddingRight: 28 },
});
export default CancelPlanView;
