import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useEffect, useState } from 'react';
import { RefreshControl, SafeAreaView, ScrollView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { AppDrawer } from '../../components/AppDrawer';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import FilterChip from '../../components/UI/FilterChip';
import StatusTrackingListItem from '../../components/UI/ListItem/StatusTrackingListItem';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { accountService } from '../../services/MyAccount/AccountService';
import { RootState } from '../../store';
import {
  setCustomerShippingOrders,
  setSelectedCustomerShippingOrders,
} from '../../store/selfServeCA';
import { RootStackParamList } from '../../types';
import { CustomerShippingOrder } from '../../types/models/models';
import { ShippingStatus } from '../../types/types';

type Props = {
  filterShown: boolean;
  setFilterShown: (value: boolean) => void;
  onFilterApply: (value: ShippingStatus[]) => void;
  selectedFilters: ShippingStatus[];
  navigation: NativeStackNavigationProp<RootStackParamList, 'StatusTracking', undefined>;
};

const _statusFilter: { title: ShippingStatus; selected: boolean }[] = [
  { title: 'queued', selected: false },
  { title: 'sent/in-transit', selected: false },
  { title: 'invalid address', selected: false },
  { title: 'incomplete', selected: false },
  { title: 'ready', selected: false },
  { title: 'received', selected: false },
  { title: 'returned', selected: false },
];

const ShippingTab = ({
  filterShown,
  setFilterShown,
  onFilterApply,
  selectedFilters,
  navigation,
}: Props) => {
  const dispatch = useDispatch();

  const userEmail = useSelector((state: RootState) => state.user.email);
  const { shippingOrder } = useSelector((state: RootState) => state.selfServeCA);
  const [filteredShippingOrders, setFilteredShippingOrders] =
    useState<CustomerShippingOrder[]>(shippingOrder);

  const [statusList, updateStatusList] = useState(
    _statusFilter.map((s) =>
      selectedFilters.indexOf(s.title) !== -1
        ? {
            title: s.title,
            selected: true,
          }
        : s
    )
  );
  const [allSelected, updateAllSelected] = useState(true);

  const fetchOrders = async () => {
    const response = await accountService.getCustomerShippingOrders(userEmail);
    dispatch(setCustomerShippingOrders(response.data));
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    const selectedFilter = statusList.filter((s) => s.selected).map((s) => s.title);
    if (selectedFilter.length === 0) {
      setFilteredShippingOrders(shippingOrder);
    } else {
      const _filteredShippingOrder = shippingOrder.filter((o) =>
        selectedFilter.includes(o.StatusDescription.toLowerCase() as ShippingStatus)
      );
      setFilteredShippingOrders(_filteredShippingOrder);
    }
  }, [shippingOrder]);

  useEffect(() => {
    const selectedFilter = statusList.filter((s) => s.selected);

    if (selectedFilter.length === statusList.length) {
      updateStatusList(_statusFilter);
      return;
    }
    updateAllSelected(selectedFilter.length === 0);
  }, [statusList]);

  return (
    <SafeAreaView style={{ backgroundColor: Colors.white, height: '100%', flex: 1 }}>
      <CustomText
        weight="500"
        style={{ fontSize: 12, color: Colors.greyColor, marginVertical: 16, marginHorizontal: 20 }}
      >
        Your Orders
      </CustomText>
      <ScrollView refreshControl={<RefreshControl refreshing={false} onRefresh={fetchOrders} />}>
        {filteredShippingOrders.map((shippingItem, index) => (
          <StatusTrackingListItem
            key={shippingItem.ShippingOrderId}
            title={'Canadian SIM Card'}
            date={shippingItem.OrderDate}
            status={shippingItem.StatusDescription}
            isEven={(index + 1) % 2 === 0}
            onPress={() => {
              dispatch(setSelectedCustomerShippingOrders(shippingItem));
              navigation.navigate('ShippingTracking', {
                tracking: shippingItem.ShippingOrderId,
              });
            }}
          />
        ))}
      </ScrollView>
      <AppDrawer
        visible={filterShown}
        height={400}
        onClose={() => {
          setFilterShown(false);
        }}
        showBackDrop
      >
        <CustomText weight="500" style={{ fontSize: 24 }}>
          Filters
        </CustomText>
        <HorizontalLine style={{ marginVertical: 16 }} thinkness={1} />
        <CustomText weight="500" style={{ fontSize: 12, marginBottom: 8 }}>
          Status
        </CustomText>
        <View
          style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 8, justifyContent: 'flex-start' }}
        >
          <FilterChip
            title="All"
            selected={allSelected}
            onPress={() => {
              updateStatusList(_statusFilter);
            }}
          />
          {statusList.map((status) => (
            <FilterChip
              title={status.title}
              selected={status.selected}
              onPress={() => {
                const _stautsList = statusList.map((s) =>
                  s.title !== status.title ? s : { title: s.title, selected: !s.selected }
                );
                updateStatusList(_stautsList);
              }}
              key={status.title}
            />
          ))}
        </View>
        <Button
          style={{ marginTop: 25 }}
          onPress={() => {
            const selectedFilter = statusList.filter((s) => s.selected).map((s) => s.title);
            onFilterApply(selectedFilter);
            setFilterShown(false);
            if (selectedFilter.length === 0) {
              setFilteredShippingOrders(shippingOrder);
            } else {
              const _filteredShippingOrder = shippingOrder.filter((o) =>
                selectedFilter.includes(o.StatusDescription.toLowerCase() as ShippingStatus)
              );
              setFilteredShippingOrders(_filteredShippingOrder);
            }
          }}
          textStyle={{ fontSize: 14 }}
        >
          Apply
        </Button>
        <Button
          style={{ marginTop: 10 }}
          color={Colors.backgroundGrey}
          textColor={Colors.inputTextPlaceholderColor}
          onPress={() => {
            setFilterShown(false);
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Close
        </Button>
      </AppDrawer>
    </SafeAreaView>
  );
};

export default ShippingTab;
