import { View } from 'react-native';

import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { RootStackScreenProps } from '../../types';
import { isWebsite } from '../../utilities/functions';

const TerminationSuccessScreen = ({ navigation }: RootStackScreenProps<'TerminationSuccess'>) => {
  return (
    <View style={{ flex: 1, paddingHorizontal: 20 }}>
      <CustomText
        weight="600"
        style={{
          marginTop: 32,
          fontSize: 24,
          textAlign: 'center',
          color: Colors.canadaButtonColor,
        }}
      >
        Termination requested
      </CustomText>
      <CustomText
        style={{
          marginTop: 8,
          fontSize: 14,
          textAlign: 'center',
          color: Colors.TextBlack,
        }}
      >
        We&apos;re sad to see you go! But before you leave, why not check out the amazing deals
        we&apos;ve got for you? There&apos;s always time to change your mind!
      </CustomText>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            paddingBottom: 40,
            paddingHorizontal: 20,
          },
          isWebsite()
            ? {
                maxWidth: 400,
                marginHorizontal: 'auto',
                width: '100%',
              }
            : null,
        ]}
      >
        <Button
          onPress={() => {
            navigation.navigate('Plans');
          }}
          style={{
            marginBottom: 8,
          }}
        >
          See Other Plans
        </Button>
        <Button
          onPress={() => {
            navigation.navigate('Root');
          }}
          color={Colors.backgroundGrey}
          textColor={Colors.greyColor}
        >
          Back to the Main Home
        </Button>
      </View>
    </View>
  );
};

export default TerminationSuccessScreen;
