import { View } from 'react-native';

import { Colors } from '../../constants/Colors';
import { isWebsite } from '../../utilities/functions';
import CustomText from '../utils/CustomText';

type Props = {
  title: string;
  supTitle: string;
};

const TitleWithSupTitle = ({ title, supTitle }: Props) => {
  return (
    <View
      style={
        isWebsite()
          ? {
              maxWidth: 400,
              marginHorizontal: 'auto',
              width: '100%',
            }
          : null
      }
    >
      <CustomText
        style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 4 }}
        weight="500"
      >
        {supTitle}
      </CustomText>
      <CustomText weight="500" style={{ fontSize: 16, color: Colors.TextBlack, marginBottom: 16 }}>
        {title}
      </CustomText>
    </View>
  );
};

export default TitleWithSupTitle;
