import { FontAwesome6 } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import { useState } from 'react';
import { ScrollView, View } from 'react-native';

import Button from '../../components/Button';
import CheckboxToggle from '../../components/UI/CheckboxToggle';
import InputWithSelectOption from '../../components/UI/Form/InputForSelectOption';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { ManagePaymentFlowStep } from '../../types/enums/enums';
import DevLog from '../../utilities/debug-error';
import { isWebsite } from '../../utilities/functions';
import { getCreditCardType, numbersOnly } from '../../utilities/utilities';

interface BasePaymentOption {
  isAutoRenewalChecked: boolean;
  isDefaultChecked: boolean;
}

export interface CardPayment extends BasePaymentOption {
  newCreditCardExpirationMonth: string;
  newCreditCardExpirationYear: string;
  newCreditCardHolderName: string;
  newCreditCardNumber: string;
  newCreditCardType: string;
  newCreditCardCVV: string;
}

export interface BankPayment extends BasePaymentOption {
  newBankInstitution: string;
  newBankTransit: string;
  newBankAccount: string;
  newBankAccountHolder: string;
  newBankImageProof: ImagePicker.ImagePickerAsset;
}

const AddPaymentMethodView = ({
  step,
  onAction,
}: {
  step: ManagePaymentFlowStep;
  onAction(action: 'SAVE_CARD' | 'SAVE_BANK' | 'CANCEL', data?: CardPayment | BankPayment): void;
}) => {
  const [isAutoRenewalChecked, setIsAutoRenewalChecked] = useState<boolean>(false);
  const [isDefaultChecked, _] = useState<boolean>(false);
  const [__, setNewCreditCardExpirationMonth] = useState<string>('');
  const [___, setNewCreditCardExpirationYear] = useState<string>('');
  const [newCreditCardHolderName, setNewCreditCardHolderName] = useState<string>('');
  const [newCreditCardNumber, setNewCreditCardNumber] = useState<string>('');
  const [____, setNewCreditCardType] = useState<string>('');
  const [newCreditCardCVV, setNewCreditCardCVV] = useState<string>('');
  const [newCreditCardExpiration, setNewCreditCardExpiration] = useState<string>('');

  const [newBankInstitution, setNewBankInstitution] = useState<string>('');
  const [newBankTransit, setNewBankTransit] = useState<string>('');
  const [newBankAccount, setNewBankAccount] = useState<string>('');
  const [newBankAccountHolder, setNewBankAccountHolder] = useState<string>('');
  const [newBankImageProof, setNewBankImageProof] = useState<ImagePicker.ImagePickerAsset | null>();

  const cardview = (
    <View
      style={
        isWebsite()
          ? {
              maxWidth: 400,
              marginHorizontal: 'auto',
              width: '100%',
            }
          : null
      }
    >
      <InputWithTitle
        maxLength={16}
        title="Card Number"
        keyboardType="numeric"
        inputValue={newCreditCardNumber}
        onInputChange={(value) => setNewCreditCardNumber(numbersOnly(value))}
      />
      <InputWithTitle
        title="Name on Card"
        inputValue={newCreditCardHolderName}
        onInputChange={setNewCreditCardHolderName}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ width: 150 }}>
          <InputWithTitle
            maxLength={4}
            title="CVV"
            secureInput={true}
            keyboardType="numeric"
            inputValue={newCreditCardCVV}
            onInputChange={(value) => setNewCreditCardCVV(numbersOnly(value))}
          />
        </View>
        <View style={{ width: 150 }}>
          <InputWithTitle
            maxLength={4}
            title="Expiry Date (MMYY)"
            keyboardType="numeric"
            inputValue={newCreditCardExpiration}
            onInputChange={(value) => setNewCreditCardExpiration(numbersOnly(value))}
          />
        </View>
      </View>
      <CustomText
        weight="500"
        style={{
          color: Colors.ActionLinkColor,
          paddingVertical: 10,
          paddingHorizontal: 24,
          width: '100%',
          textAlign: 'center',
          marginVertical: 20,
          backgroundColor: Colors.DonutEmptyColor,
          borderRadius: 8,
          fontSize: 14,
        }}
      >
        Get $5 off with Auto Renewal!
      </CustomText>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 14 }}>
          <CustomText weight="600" style={{ width: 60, fontSize: 12 }}>
            Auto Renewal
          </CustomText>
          <CheckboxToggle value={isAutoRenewalChecked} onValueChange={setIsAutoRenewalChecked} />
        </View>
        {/* <View style={{ flexDirection: 'row', alignItems: 'center', gap: 14 }}>
          <CustomText weight="600" style={{ width: 70, fontSize: 12 }}>
            Set as Default
          </CustomText>
          <CheckboxToggle value={isDefaultChecked} onValueChange={setIsDefaultChecked} />
        </View> */}
      </View>
      <Button
        style={{ marginTop: 32 }}
        disabled={
          !newCreditCardExpiration ||
          !newCreditCardHolderName ||
          !newCreditCardNumber ||
          !newCreditCardCVV
        }
        onPress={() => {
          setNewCreditCardExpirationMonth(newCreditCardExpiration.slice(0, 2));
          setNewCreditCardExpirationYear(`20${newCreditCardExpiration.slice(-2)}`);
          setNewCreditCardType(getCreditCardType(newCreditCardNumber));
          onAction('SAVE_CARD', {
            isAutoRenewalChecked,
            isDefaultChecked,
            newCreditCardExpirationMonth: newCreditCardExpiration.slice(0, 2),
            newCreditCardExpirationYear: `20${newCreditCardExpiration.slice(-2)}`,
            newCreditCardHolderName,
            newCreditCardNumber,
            newCreditCardType: getCreditCardType(newCreditCardNumber),
            newCreditCardCVV,
          });
        }}
        textStyle={{ fontSize: 14 }}
      >
        Add Card
      </Button>
      <Button
        style={{ marginTop: 16 }}
        color={Colors.backgroundGrey}
        textColor={Colors.inputTextPlaceholderColor}
        onPress={() => {
          onAction('CANCEL');
        }}
        textStyle={{ fontSize: 14, fontWeight: '600' }}
      >
        Cancel
      </Button>
    </View>
  );

  const bankview = (
    <View
      style={
        isWebsite()
          ? {
              maxWidth: 400,
              marginHorizontal: 'auto',
              width: '100%',
            }
          : null
      }
    >
      <InputWithTitle
        maxLength={3}
        title="Bank Institution (3 Digit)"
        keyboardType="numeric"
        inputValue={newBankInstitution}
        onInputChange={(value) => setNewBankInstitution(numbersOnly(value))}
      />
      <InputWithTitle
        maxLength={5}
        title="Bank Transit  # (5 Digit)"
        keyboardType="numeric"
        inputValue={newBankTransit}
        onInputChange={(value) => setNewBankTransit(numbersOnly(value))}
      />
      <InputWithTitle
        title="Bank Account #"
        keyboardType="numeric"
        inputValue={newBankAccount}
        onInputChange={(value) => setNewBankAccount(numbersOnly(value))}
      />
      <InputWithTitle
        title="Bank Holder Name"
        inputValue={newBankAccountHolder}
        onInputChange={(value) => setNewBankAccountHolder(value)}
      />
      <InputWithSelectOption
        title="Proof Document"
        value={newBankImageProof ? 'Image Selected' : 'Upload file'}
        icon={
          <FontAwesome6 name="folder-closed" size={14} color={Colors.inputTextPlaceholderColor} />
        }
        onPress={async () => {
          const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            quality: 1,
          });

          DevLog.log(result);

          if (!result.canceled) {
            setNewBankImageProof(result.assets[0]);
          } else {
            setNewBankImageProof(null);
          }
        }}
      />
      <CustomText
        weight="500"
        style={{
          color: Colors.ActionLinkColor,
          paddingVertical: 10,
          paddingHorizontal: 24,
          width: '100%',
          textAlign: 'center',
          marginVertical: 20,
          backgroundColor: Colors.DonutEmptyColor,
          borderRadius: 8,
          fontSize: 14,
        }}
      >
        Get $5 off with Auto Renewal!
      </CustomText>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 14 }}>
          <CustomText weight="600" style={{ width: 60, fontSize: 12 }}>
            Auto Renewal
          </CustomText>
          <CheckboxToggle value={isAutoRenewalChecked} onValueChange={setIsAutoRenewalChecked} />
        </View>
        {/* <View style={{ flexDirection: 'row', alignItems: 'center', gap: 14 }}>
          <CustomText weight="600" style={{ width: 70, fontSize: 12 }}>
            Set as Default
          </CustomText>
          <CheckboxToggle value={isDefaultChecked} onValueChange={setIsDefaultChecked} />
        </View> */}
      </View>
      <Button
        style={{ marginTop: 32 }}
        disabled={
          !newBankImageProof ||
          !newBankInstitution ||
          newBankInstitution.length !== 3 ||
          !newBankTransit ||
          newBankTransit.length !== 5 ||
          !newBankAccountHolder ||
          !newBankAccount ||
          newBankAccount.length < 7 ||
          newBankAccount.length > 12
        }
        onPress={() => {
          if (!newBankImageProof) {
            return;
          }
          onAction('SAVE_BANK', {
            isAutoRenewalChecked,
            isDefaultChecked,
            newBankInstitution,
            newBankTransit,
            newBankAccount,
            newBankAccountHolder,
            newBankImageProof,
          });
        }}
        textStyle={{ fontSize: 14 }}
      >
        Add Bank
      </Button>
      <Button
        style={{ marginTop: 16 }}
        color={Colors.backgroundGrey}
        textColor={Colors.inputTextPlaceholderColor}
        onPress={() => {
          onAction('CANCEL');
        }}
        textStyle={{ fontSize: 14, fontWeight: '600' }}
      >
        Cancel
      </Button>
    </View>
  );

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      {step === ManagePaymentFlowStep.AddPaymentCard ||
      step === ManagePaymentFlowStep.EditPaymentCard
        ? cardview
        : bankview}
    </ScrollView>
  );
};

export default AddPaymentMethodView;
