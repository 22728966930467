import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { Alert, Linking, Platform, Pressable, ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';

import BlogImage from '../../assets/icons/action/4x/blog.png';
import CardImage from '../../assets/icons/action/4x/card.png';
import ChatImage from '../../assets/icons/action/4x/chat.png';
import DataTopUpImage from '../../assets/icons/action/4x/data-top-up.png';
import InvoiceImage from '../../assets/icons/action/4x/invoice.png';
import LostImage from '../../assets/icons/action/4x/lost.png';
import PhoneImage from '../../assets/icons/action/4x/phone.png';
import PortInImage from '../../assets/icons/action/4x/port-in.png';
import PortOutImage from '../../assets/icons/action/4x/port-out.png';
import ProfileImage from '../../assets/icons/action/4x/profile.png';
import ReferralImage from '../../assets/icons/action/4x/referral.png';
import TrackingImage from '../../assets/icons/action/4x/tracking.png';
import VacationImage from '../../assets/icons/action/4x/vacation.png';
import { AppGap } from '../../components/AppGap';
import AppGradientContainer from '../../components/AppGradientContainer';
import { Action } from '../../components/QuickAction/QuickAction';
import QuickActions from '../../components/QuickAction/QuickActions';
import Offering from '../../components/Section/Offering';
import DataUsage from '../../components/UI/DataUsage';
import PageHeader from '../../components/UI/Header/PageHeader';
import ActiveUserHero from '../../components/UI/Hero/ActiveUserHero';
// import HomeInternetPlanDetail from '../../components/UI/Plans/HomeInternetPlanDetail';
import Plan from '../../components/UI/Plans/Plan';
import PlanDetails from '../../components/UI/Plans/PlanDetails';
import SectionHeading from '../../components/UI/SectionHeading';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { DATE_FORMAT } from '../../constants/constants';
import { RootState } from '../../store';
import DevLog from '../../utilities/debug-error';
import { crispChatAction, getGradientInfo, isWebsite } from '../../utilities/functions';

// importImage Ticket from '../../assets/icons/action/4x/ticket.png'
// importImage HelpCenter from '../../assets/icons/action/4x/help-center.png'

const prePaidActions: Action[][] = [
  [
    {
      image: ProfileImage,
      title: 'Profile Change',
      tag: 'PROFILE_CHANGE',
    },
    {
      image: PhoneImage,
      title: 'Plan Change',
      tag: 'PLAN_CHANGE',
    },
    {
      image: DataTopUpImage,
      title: 'Data Top Up',
      tag: 'DATA_TOP_UP',
    },
    {
      image: CardImage,
      title: 'Auto Payment',
      tag: 'AUTO_PAYMENT',
    },
  ],
  [
    {
      image: ReferralImage,
      title: 'Friends Referral',
      tag: 'FRIENDS_REFERRAL',
    },
    {
      image: InvoiceImage,
      title: 'View Invoice',
      tag: 'VIEW_INVOICE',
    },
    {
      image: TrackingImage,
      title: 'Status Tracking',
      tag: 'STATUS_TRACKING',
    },
    {
      image: PortInImage,
      title: 'Port In',
      tag: 'PORT_IN',
    },
  ],
  [
    // {
    //   image: TicketImage,
    //   title: 'Support Ticket',
    //   tag: 'SUPPORT_TICKET',
    // },
    // {
    //   image: HelpCenterImage,
    //   title: 'Help Center',
    //   tag: 'HELP_CENTER',
    // },
    {
      image: PortOutImage,
      title: 'Port Out',
      tag: 'PORT_OUT',
    },
  ],
];

const postPaidActions: Action[][] = [
  [
    {
      image: ProfileImage,
      title: 'Profile Change',
      tag: 'PROFILE_CHANGE',
    },
    {
      image: PhoneImage,
      title: 'Plan Change',
      tag: 'PLAN_CHANGE',
    },
    {
      image: InvoiceImage,
      title: 'View Invoice',
      tag: 'VIEW_INVOICE',
    },
    {
      image: CardImage,
      title: 'Auto Payment',
      tag: 'AUTO_PAYMENT',
    },
  ],
  [
    {
      image: TrackingImage,
      title: 'Status Tracking',
      tag: 'STATUS_TRACKING',
    },
    {
      image: PortInImage,
      title: 'Port In',
      tag: 'PORT_IN',
    },
    {
      image: ReferralImage,
      title: 'Friends Referral',
      tag: 'FRIENDS_REFERRAL',
    },
    {
      image: VacationImage,
      title: 'Vacation Hold',
      tag: 'VACATION_HOLD',
    },
  ],
  [
    {
      image: ChatImage,
      title: 'Support Chat',
      tag: 'SUPPORT_CHAT',
    },
    {
      image: PortOutImage,
      title: 'Port Out',
      tag: 'PORT_OUT',
    },
    {
      image: LostImage,
      title: 'Lost or Stolen',
      tag: 'LOST_OR_STOLEN',
    },
    {
      image: BlogImage,
      title: 'Blog',
      tag: 'BLOG_ACTION',
    },
  ],
];

export default function ActiveUserView() {
  const navigation = useNavigation();

  const { Prepaid: isPrepaid, EndDate } = useSelector(
    (state: RootState) => state.selfServeCA.accountInfoCA
  );
  const accountProfileCA = useSelector((state: RootState) => state.selfServeCA.accountProfileCA);
  const accountInfoCA = useSelector((state: RootState) => state.selfServeCA.accountInfoCA);
  const { loggedIn, id, email, firstName, lastName } = useSelector(
    (state: RootState) => state.user
  );

  const gradientInfo = getGradientInfo(accountProfileCA, accountInfoCA);

  return (
    <>
      <PageHeader />
      <ScrollView showsVerticalScrollIndicator={false}>
        <AppGradientContainer
          autoHeight
          colorStart={gradientInfo.start}
          colorEnd={gradientInfo.end}
          direction={gradientInfo.direction}
          style={{
            paddingBottom: 20,
            paddingTop: Platform.select({ default: 80, ios: 60 }),
          }}
        >
          <ActiveUserHero />
        </AppGradientContainer>

        <View
          style={{
            width: '100%',
            marginTop: -20,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            backgroundColor: Colors.white,
          }}
        >
          <QuickActions
            quickActions={isPrepaid ? prePaidActions : postPaidActions}
            onActionSelected={(action) => {
              switch (action) {
                case 'PROFILE_CHANGE':
                  navigation.navigate('Account');
                  break;
                case 'PLAN_CHANGE':
                  navigation.navigate('ChangePlan');
                  break;
                case 'STATUS_TRACKING':
                  navigation.navigate('StatusTracking');
                  break;
                case 'DATA_TOP_UP':
                  {
                    const endDateString = moment(EndDate).format(DATE_FORMAT);
                    if (Platform.OS === 'web') {
                      if (
                        confirm(
                          `Your Current term end date is ${endDateString}. Do you still want to Top Up`
                        )
                      ) {
                        navigation.navigate('AddMoreData');
                      }
                    } else {
                      Alert.alert(
                        'Confirmation',
                        `Your Current term end date is ${endDateString}. Do you still want to Top Up`,
                        [
                          {
                            isPreferred: true,
                            onPress: () => {
                              navigation.navigate('AddMoreData');
                            },
                            text: 'Yes',
                          },
                          {
                            text: 'Cancel',
                          },
                        ]
                      );
                    }
                  }
                  break;
                case 'PORT_OUT':
                case 'PORT_IN':
                  if (isWebsite()) {
                    alert(
                      'Porting is not available in the app right now. Please contact our customer service team to complete the porting process.'
                    );
                  } else {
                    Alert.alert(
                      'Porting Unavailable',
                      'Porting is not available in the app right now. Please contact our customer service team to complete the porting process.'
                    );
                  }
                  break;
                case 'VIEW_INVOICE':
                  navigation.navigate('Invoices');
                  break;
                case 'SUPPORT_CHAT':
                  crispChatAction(
                    loggedIn,
                    id,
                    email,
                    firstName,
                    lastName,
                    accountProfileCA.PhoneNumber
                  );
                  break;
                case 'AUTO_PAYMENT':
                  navigation.navigate('ManagePayment');
                  break;
                case 'LOST_OR_STOLEN':
                  navigation.navigate('LostOrStolen');
                  break;
                case 'FRIENDS_REFERRAL':
                  navigation.navigate('Benefits', { benefit: 'refer_a_friend_promo' });
                  break;
                case 'VACATION_HOLD':
                  navigation.navigate('VacationHold');
                  break;
                case 'SUPPORT_TICKET':
                  break;
                case 'BLOG_ACTION':
                  Linking.openURL('https://blog.gophonebox.com/');
                  break;
                default:
                  break;
              }
              DevLog.log(action);
            }}
          />

          <View>
            <SectionHeading style={{ marginLeft: 20 }}>Plan Details</SectionHeading>
            <PlanDetails />
          </View>

          {/* <View style={{ marginTop: 32 }}>
            <SectionHeading style={{ marginLeft: 20 }}>Plan Details (Home Internet)</SectionHeading>
            <HomeInternetPlanDetail />
          </View> */}

          <View style={{ marginTop: 32 }}>
            <SectionHeading style={{ marginLeft: 20 }}>Best Offering</SectionHeading>
            <Offering />
          </View>

          {/* No final yet, hidden till it get finalize */}
          {/* <PBPointCard points={2700} /> */}

          <View style={{ marginTop: 32 }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginHorizontal: 20,
                marginBottom: 16,
              }}
            >
              <SectionHeading>My Plans</SectionHeading>
              <Pressable
                style={{}}
                onPress={() => {
                  navigation.navigate('Plans');
                }}
              >
                <CustomText
                  weight="600"
                  style={{ color: Colors.ActionLinkColor, fontWeight: '600', fontSize: 12 }}
                >
                  See All Plans
                </CustomText>
              </Pressable>
            </View>
            <ScrollView
              contentContainerStyle={{ marginRight: 15 }}
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              scrollEventThrottle={16}
              pagingEnabled={true}
            >
              <Plan />
              {/* Not Considering Terminated plans as its not part of the system yet */}
              {/* <Plan status="Terminated" /> */}
            </ScrollView>
          </View>
          {/* Data Usage for Staatus = VC, LS */}
          {accountProfileCA.Suspended &&
          ['VC', 'LS', 'UP'].includes(accountProfileCA.SuspensionReason!) ? (
            <View style={{ marginTop: 10 }}>
              <SectionHeading style={{ marginLeft: 20 }}>Data Usage</SectionHeading>
              <View
                style={{
                  borderColor: Colors.HorizonalLineDefaultColor,
                  borderWidth: 1,
                  marginHorizontal: 20,
                  borderRadius: 8,
                  padding: 16,
                }}
              >
                {/* Data Usage */}
                <DataUsage />
              </View>
            </View>
          ) : null}
          <AppGap direction="vertical" size={20} />
        </View>
      </ScrollView>
    </>
  );
}
