import { Alert, Linking, View } from 'react-native';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import { isWebsite } from '../../utilities/functions';
import Button from '../Button';
import CustomText from '../utils/CustomText';

const CarRentalPromo = () => {
  const notLoggedInError = 'Please Log in to get your discount';
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);

  return (
    <View
      style={{
        gap: 8,
      }}
    >
      <CustomText style={{ fontSize: 16 }} weight="600">
        Get great discounts on car rentals!
      </CustomText>
      <CustomText>Get 35% on pay-now rates of car rentals with Budget below!</CustomText>
      <Button
        style={{ alignSelf: 'center', marginBottom: 10 }}
        onPress={() => {
          if (loggedIn) {
            Linking.openURL('https://www.budget.com/en/association/B104800');
          } else {
            if (isWebsite()) {
              alert(notLoggedInError);
            } else {
              Alert.alert('Login Required', notLoggedInError);
            }
          }
        }}
      >
        Claim Offer with Budget
      </Button>
      <CustomText>Or Get up to 30% off pay-now rates with Avis!</CustomText>
      <Button
        style={{ alignSelf: 'center', marginBottom: 10 }}
        onPress={() => {
          if (loggedIn) {
            Linking.openURL('https://www.avis.com/en/association/A842600');
          } else {
            if (isWebsite()) {
              alert(notLoggedInError);
            } else {
              Alert.alert('Login Required', notLoggedInError);
            }
          }
        }}
      >
        Claim Offer with Alvis
      </Button>
      <CustomText>Remember to check out using the pages above!</CustomText>
    </View>
  );
};

export default CarRentalPromo;
