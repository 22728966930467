/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { View, TextInput, Platform } from 'react-native';
import {
  GooglePlacesAutocomplete,
  GooglePlacesAutocompleteRef,
} from 'react-native-google-places-autocomplete';

import { Colors } from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import useIsMobile from '../hooks/useIsMobile';

interface GoogleAutoCompleteAddressProps {
  onAutoCompleteAddress: (full_address: string[]) => void;
  country?: string;
}

const GoogleAutoCompleteAddress: React.FC<GoogleAutoCompleteAddressProps> = (
  props: GoogleAutoCompleteAddressProps
) => {
  const [query, setQuery] = useState('');
  const isMobile = useIsMobile();
  const inputBorderColor = isMobile ? Colors.white : Colors.grey;

  const mobileSearchInputRef = useRef<GooglePlacesAutocompleteRef | null>(null); // Mobile Google Search Input Ref

  const { ref: inputRef } = usePlacesWidget<TextInput>({
    apiKey: process.env.GOOGLE_MAP_API_KEY,
    onPlaceSelected: (place) => {
      handlePlaceSelect(props.onAutoCompleteAddress, place);
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: props.country },
    },
  });

  const handlePlaceSelect = (onAutoCompleteAddress: any, addressObject: any) => {
    setQuery('');
    const addressComponents = addressObject.address_components;
    const streetAddress = `${findAddressNameByType(
      addressComponents,
      'street_number',
      false
    )} ${findAddressNameByType(addressComponents, 'route', false)}`;
    let city = findAddressNameByType(addressComponents, 'locality', false);
    if (city === '') {
      city = findAddressNameByType(addressComponents, 'neighborhood', false);
    }

    const stateProvinceShortName = findAddressNameByType(
      addressComponents,
      'administrative_area_level_1',
      false
    );
    const stateProvinceLongName = findAddressNameByType(
      addressComponents,
      'administrative_area_level_1',
      true
    );
    const countryShortName = findAddressNameByType(addressComponents, 'country', false);
    const countryLongName = findAddressNameByType(addressComponents, 'country', true);
    const zipPostal = findAddressNameByType(addressComponents, 'postal_code', false);

    const addressArray = [
      streetAddress,
      city,
      stateProvinceShortName,
      countryShortName,
      zipPostal,
      countryLongName,
      stateProvinceLongName,
    ];
    onAutoCompleteAddress(addressArray);
  };

  const findAddressNameByType = (addressComponents: any, componentType: any, isLongName: any) => {
    for (let i = 0; i < addressComponents.length; i++) {
      const item = addressComponents[i];
      if (item.types.includes(componentType) && isLongName) {
        return item.long_name;
      } else if (item.types.includes(componentType) && !isLongName) {
        return item.short_name;
      }
    }
    return '';
  };

  const webGoogleAutoComplete = (
    <TextInput
      ref={inputRef}
      style={{
        ...CommonStyles.input,
        padding: 25,
        backgroundColor: Colors.white,
        borderColor: inputBorderColor,
      }}
      onChangeText={(event: any) => setQuery(event)}
      value={query}
      multiline={false}
      numberOfLines={1}
    />
  );

  const mobileGoogleAutoComplete = (
    <GooglePlacesAutocomplete
      ref={mobileSearchInputRef}
      styles={{
        ...CommonStyles.input,
        padding: 25,
        backgroundColor: Colors.white,
        borderColor: inputBorderColor,
      }}
      textInputProps={{
        value: query,
        onChangeText: (event) => setQuery(event),
      }}
      placeholder="Enter a location"
      fetchDetails={true}
      onPress={(_, details) => {
        handlePlaceSelect(props.onAutoCompleteAddress, details);
        setTimeout(() => {
          mobileSearchInputRef.current?.setAddressText('');
          mobileSearchInputRef.current?.clear();
        }, 0);
      }}
      keepResultsAfterBlur={true}
      query={{
        key: process.env.GOOGLE_MAP_API_KEY,
        language: 'en',
        components: props.country ? `country:${props.country}` : '',
      }}
      keyboardShouldPersistTaps="handled"
    />
  );

  return (
    <View style={{ marginBottom: 25 }}>
      {isMobile
        ? Platform.OS === 'web'
          ? webGoogleAutoComplete
          : mobileGoogleAutoComplete
        : webGoogleAutoComplete}
    </View>
  );
};

export default GoogleAutoCompleteAddress;
